import { Box, Button, CircularProgress, Container, CssBaseline, Divider, FormControl, Grid, IconButton, List, MenuItem, Paper, Select, SelectChangeEvent, TextField, ThemeProvider, Typography } from "@mui/material";
import { RootState, useAppDispatch } from "../../store/store";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { bookRateConvertMoney, bookRateDimissMessage, bookRatePointRedeem, changeAmountRm, changeCountry, changeProvider, clearSelectedBene, createWhatsAppBooking, fetchWhatsAppRates, resetBookRate, showErrorMessage } from "../../actions/BookRateAction";
import { getLabelValue, getConversionString, numberWithCommas, extendedAmountTextInput, callBackendAPI, logError, formatCardNumberWithDashes } from "../../utils";
import { theme, tokens } from "../../theme";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer";
import { CONFIG } from "../../config";
import { BackendResponse, TUser } from "../../type";
import BeneficiaryCard from "../../components/BeneficiaryCard";
import { BOOK_RATE_MENU, SHORT_LANGUAGES } from "../../constants/data";
import { BOOK_RATE_PRE_CREATE_SUCCESS } from "../../actions/type";
import Grid2 from "@mui/material/Unstable_Grid2";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import DialogAlert from "../../components/DialogAlert";
import { redeemWhatsAppPoints } from "../../actions/RedeemAction";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CheckCircle } from '@mui/icons-material';
import { updateLanguage } from "../login/userSlice";

const colors = tokens();

const WhatsAppBooking = () => {
    const [search] = useSearchParams();
    const hash = search.get('rq');
    const [value, setValue] = useState("");
    const [toAmount, setToAmount] = useState("");
    const [fromAmount, setFromAmount] = useState("");
    const [user, setUser] = useState<TUser>();

    const [showSelectBene, setShowSelectBene] = useState(false);
    const [provider_value, setProviderValue] = useState("");
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [beneficiary, setBeneficiary] = useState(undefined);
    const [purposes, setPurposes] = useState([]);
    const [purpose, setPurpose] = useState<string | undefined>(undefined);
    const [isSelectedBene, setIsSelectedBene] = useState(-1);

    const [showBookConfirm, setShowBookConfirm] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [redeemAlert, setRedeemAlert] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false);

    const [success, setSuccess] = useState(false);

    const [loading1, setloading1] = useState(false);
    const [error1, setError] = useState({ error: false, message: '' });
    const { lang } = useSelector((state: RootState) => state.user);
    const { loading, error, message, rates, country, provider, amountRM, selectedBene, preBookBody, showMessage, pointredeem, discountAmount, usedPoint, bookRateResp } = useSelector((state: RootState) => state.bookrate);
    const { points } = useSelector((state: RootState) => state.redeem);

    const navigate = useNavigate();

    const bene_country = 'Indonesia';
    const pickup_method_value = undefined;

    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    console.log('provider', provider);
    console.log('country', country);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(updateLanguage('bm'));
            fetchUserData();
        }
        if (shouldLockEffect?.current === false) {
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, []);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            if (preBookBody.trx_queue === 1) {
                setShowAlerts(true);
            }
            dispatch(bookRateConvertMoney(0, 0));
            dispatch(bookRatePointRedeem(false));
        }
        if (shouldLockEffect?.current === false) {
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [preBookBody]);

    const fetchUserData = async (): Promise<void> => {
        try {
            setloading1(true);
            let headers: HeadersInit = {
                'Content-Type': 'application/json'
            }
            let input: any = {
                ver: CONFIG.ver,
                act: 9004,
                user_agent: navigator.userAgent,
                hash: hash
            }
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('whatsapp/request', 'POST', headers, input);
            if (resultFromAPI === undefined)
                return setloading1(false);

            if (resultFromAPI?.error !== undefined)
                return setloading1(false);

            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results?.code && results.code !== 0) {
                    setloading1(false);
                    return setError({ error: true, message: results.message })
                }

                setloading1(false);
                setUser(results.user);

                dispatch(fetchWhatsAppRates(lang, bene_country, results.user.app_id, results.user.aa_uuid));
                dispatch(resetBookRate());
            }

            return setloading1(false);
        }
        catch (e) {
            logError('Failed to get user info', e);
            return setloading1(false);
        }
    }

    const arrangedproviders = rates[country]?.providers.sort((x: { pickup_method: any; }, y: { pickup_method: any; }) => {
        if (x.pickup_method === pickup_method_value) return -1;  // Move elements with pickup_method value of `a` to the beginning of the array
        if (y.pickup_method === pickup_method_value) return 1;
        return 0;
    });

    const handleToAmountChange = (val: any) => {
        let fromamt: any = isNaN(val) ? 0.00 : extendedAmountTextInput(val);
        setFromAmount(fromamt);

        if (rates[country] && rates[country].providers[provider]) {
            let rate = rates[country].providers[provider].rate_multiple;
            let special_rate = rates[country].providers[provider].rate_special;
            if (!isNaN(rate) && rate !== 0) {
                if (rates[country].hasOwnProperty('special_rate')) {
                    if (rates[country].providers[provider].special_flag == 1) {
                        let preamount: any = Math.round((fromamt * rate) * rates[country]?.ttodecimal) / rates[country]?.ttodecimal
                        let toAmount: any = Math.round((preamount * rates[country]?.special_rate) * rates[country]?.stodecimal) / rates[country]?.stodecimal
                        setToAmount(numberWithCommas(toAmount));
                    }
                    else {
                        let toAmounts: any = Math.round((fromamt * special_rate) * rates[country]?.todecimal) / rates[country]?.todecimal
                        setToAmount(numberWithCommas(toAmounts));
                    }
                }
                else {
                    let namount: any = Math.round((fromamt * rate) * rates[country]?.todecimal) / rates[country]?.todecimal
                    setToAmount(numberWithCommas(namount));
                }
            } else {
                setToAmount("");
            }
        }
    };

    const handleFromAmountChange = (val: any) => {
        let toamt: any = isNaN(val) ? 0.00 : extendedAmountTextInput(val);
        setToAmount(toamt);
        if (rates[country] && rates[country].providers[provider]) {
            let rate = rates[country].providers[provider].rate_multiple;
            if (!isNaN(rate) && rate !== 0) {
                if (rates[country].hasOwnProperty('special_rate')) {
                    let fromAmounts: any = Math.round((toamt / rate) * rates[country]?.sdecimal) / rates[country]?.sdecimal
                    setFromAmount(numberWithCommas(fromAmounts));
                }
                else {
                    let fromnamount: any = Math.round((toamt / rate) * rates[country]?.decimal) / rates[country]?.decimal
                    setFromAmount(numberWithCommas(fromnamount));
                }
            } else {
                setFromAmount("");
            }
        }
    };

    const handleSelectBeneficiary = () => {
        const fromAmountNumber = parseFloat(fromAmount);
        const provider_value = value ? value : arrangedproviders[0]?.value
        const filteredProviders = rates[country]?.providers.filter((provider: { value: any; }) => provider.value === provider_value);
        if (selectedBene) {
            if (filteredProviders[0].pickup_method != pickup_method_value) {
                dispatch(clearSelectedBene());
            }
        }
        if (isNaN(fromAmountNumber) || fromAmountNumber <= 0) {
            dispatch(showErrorMessage(true, isNaN(fromAmountNumber) ? "Amount Cannot be Empty" : "Amount Cannot be Less than or Equal to Zero"));
        } else {
            dispatch(changeAmountRm(fromAmount));
            fetchBeneficiaryData(provider_value);
            dispatch(redeemWhatsAppPoints(user, lang));
        }
    }

    const fetchBeneficiaryData = async (provider_value: any): Promise<void> => {
        try {
            setloading1(true);
            let input: any = {
                ver: CONFIG.ver,
                act: 30015,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                m1: 'Get Beneficiary Data',
                lg: lang,
                user: user
            }
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('whatsapp/request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return setError({ error: true, message: '001: Something went wrong, try again' });
            }
            if (resultsFromAPI?.error !== undefined) {
                return setError({ error: true, message: '002: Something went wrong, try again' });
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return setError({ error: true, message: '003: Something went wrong, try again' });
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0)
                    return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}` })

                if (results.hasOwnProperty("purposes")) {
                    setPurposes(results.purposes);
                    if (results.purposes.length > 0)
                        setPurpose(results.purposes[0]?.value)
                }

                if (results.hasOwnProperty("beneficiaries")) {
                    // filter bene list by selected country
                    let selectedCountry = rates[country]?.country;
                    let selectedProvider = rates[country]?.providers.find((i: { name: any; }) => i?.name === provider_value);

                    // if the bene was selected from the Beneficiary screen
                    if (selectedBene) {
                        let filteredBeneList = results.beneficiaries
                            .filter((i: { value: any; card_id: any; }) =>
                                i.value === selectedCountry &&
                                i.card_id === selectedBene
                            )
                        const massagedFilteredBeneList = filteredBeneList.map((i: { ic_acc_name: any; card_id: any; }) => {
                            return { ...i, label: i?.ic_acc_name, value: i?.card_id }
                        })
                        setBeneficiaries(massagedFilteredBeneList);
                        // if (filteredBeneList.length > 0)
                        //   setBeneficiary(filteredBeneList[0]?.card_id);
                    } else {
                        let filteredBeneList =
                            results.beneficiaries
                                .filter((i: { value: any; pickup_method_value: any; }) =>
                                    i.value === selectedCountry &&
                                    i.pickup_method_value === selectedProvider?.pickup_method
                                );
                        const massagedFilteredBeneList = filteredBeneList.map((i: { ic_acc_name: any; card_id: any; }) => {
                            return { ...i, label: i?.ic_acc_name, value: i?.card_id }
                        })

                        setBeneficiaries(massagedFilteredBeneList);
                    }
                }
                setloading1(false);
                setProviderValue(provider_value);
                return setShowSelectBene(true);
            }
        }
        catch (e) {
            console.log(e)
            setloading1(false);
            return setError({ error: true, message: '99: OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER' })
        }
    }

    const handleProBookCheck = async (): Promise<void> => {
        if (rates === undefined || rates.length === 0) {
            return setError({ error: true, message: "Submit 001: Something went wrong, try again" });
        }
        else if (country < 0) { //if taking index, index = -1 issue
            return setError({ error: true, message: "Submit 002: Something went wrong, try again" });
        }
        else if (!rates[country].hasOwnProperty("country")) {
            return setError({ error: true, message: "Submit 003: Something went wrong, try again" });
        }
        else if (provider < 0) {//if taking index, index = -1 issue
            return setError({ error: true, message: "Submit 004: Something went wrong, try again" });
        }
        else if (!rates[country].hasOwnProperty("providers")) {
            return setError({ error: true, message: "Submit 005: Something went wrong, try again" });
        }
        else if (amountRM === undefined) {
            return setError({ error: true, message: "Submit 006: Something went wrong, try again" });
        }

        if (!beneficiary) {
            return setError({ error: true, message: "Please select a beneficiary" });
        }
        if (!purpose) {
            return setError({ error: true, message: "Please select a purpose" });
        }

        let preBookActId = BOOK_RATE_MENU[0];
        let selectedBene: any = beneficiary;
        let selectedPurpose: any = purposes.find((i: { value: any }) => i?.value === purpose);
        let providerPrefix = rates[country].providers.find((item: { name: any; }) => item.name === provider_value)

        await createPreBooking(lang, preBookActId, amountRM, selectedBene?.card_id, selectedPurpose?.value, rates[country].country, providerPrefix?.prefix, "1", selectedBene?.ic_bank_label, selectedBene?.ic_account_number);
    }

    const createPreBooking = async (
        lang: any,
        params: any,
        req_send_amount: any,
        card_no: any,
        remittance_purpose_id: any,
        country: any,
        provider: any,
        currency_type: any,
        bank: any,
        ic_account_no: any
    ): Promise<void> => {
        try {
            setloading1(true);
            if (params === undefined) {
                setloading1(false);
                return setError({ error: true, message: "Submit 007: Something went wrong, try again" });
            }
            if (!params.hasOwnProperty("act_id")) {
                setloading1(false);
                return setError({ error: true, message: "Submit 008: Something went wrong, try again" });
            }
            let act_id = params.act_id,
                m1 = params.name || '';
            let input: any = {
                ver: CONFIG.ver,
                act: act_id,
                user_agent: navigator.userAgent,
                app_id: user?.app_id,
                p1: {
                    user_trans_id: new Date().valueOf(),
                    req_send_amount: req_send_amount,
                    card_no: card_no,
                    remittance_purpose_id: remittance_purpose_id,
                    country: country,
                    provider: provider,
                    currency_type: currency_type,
                    bank: bank,
                    ic_account_no: ic_account_no
                },
                lg: lang,
                m1: m1,
                user: user
            }
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

            let resultsFromAPI = await callBackendAPI('whatsapp/request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setloading1(false);
                return setError({ error: true, message: "Submit 009: Something went wrong, try again" });
            }
            if (resultsFromAPI?.error !== undefined) {
                setloading1(false);
                return setError({ error: true, message: "Submit 010: Something went wrong, try again" });
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setloading1(false);
                return setError({ error: true, message: "Submit 011: Something went wrong, try again" });
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0) {
                    setloading1(false);
                    return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}` });
                }
                if (results.hasOwnProperty("providers")) {
                    let providers = results.providers;
                    dispatch({ type: BOOK_RATE_PRE_CREATE_SUCCESS, payload: providers.header, payload2: providers.body });
                    setloading1(false);
                    setShowBookConfirm(true);
                }
            }
        }
        catch (e) {
            console.log(e);
            setloading1(false);
            return setError({ error: true, message: "Submit 99: Something went wrong, try again" });
        }
    }

    const handleRedeemClick = async () => {
        if (pointredeem) {
            dispatch(bookRateConvertMoney(0, 0, 0));
            dispatch(bookRatePointRedeem(false));
        }
        else {
            if (points <= 0) {
                setRedeemAlert(true);
            }
            else {
                dispatch(bookRateConvertMoney(preBookBody.remit_rm_fee, points, preBookBody.remit_rm_amt));
                dispatch(bookRatePointRedeem(true));
            }
        }
    }

    const handleSubmitClick = async () => {
        setSubmitClicked(true);
        let BookActId = BOOK_RATE_MENU[1];
        dispatch(createWhatsAppBooking(lang, user, BookActId, preBookBody, usedPoint, hash, user?.app_id))
    }

    const handleSelectLanguage = (event: SelectChangeEvent) => {
        dispatch(updateLanguage(event.target.value));
    };

    if (success) {
        return (
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="sm" style={{ textAlign: 'center' }}>
                    <CssBaseline />
                    <Box mt="5px" mb="25px" ml="5px">
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="10px"
                            overflow="hidden"

                        >
                            <img
                                alt="logo"
                                width="230px"
                                height="60px"
                                src={'../../assets/mmlogo.png'}
                                style={{ cursor: "pointer" }}
                            />
                        </Box>
                    </Box>
                    <CheckCircle sx={{ color: 'green', fontSize: 100 }} />
                    <Typography variant="h3" component="h1" gutterBottom>
                        Thank You for Booking!
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Your booking has been successfully completed.
                    </Typography>
                </Container>
                <Footer />
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                {!showBookConfirm ? (
                    <>
                        <FormControl sx={{ m: 0.5, position: 'absolute', top: 0 }}>
                            <Select
                                value={lang}
                                onChange={handleSelectLanguage}
                            >
                                {SHORT_LANGUAGES.map((i, v) => (
                                    <MenuItem key={v} value={i?.value || ''}>{i?.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box mt="5px" mb="25px" ml="5px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="10px"
                                overflow="hidden"

                            >
                                <img
                                    alt="logo"
                                    width="230px"
                                    height="60px"
                                    src={'../../assets/mmlogo.png'}
                                    style={{ cursor: "pointer" }}
                                />
                            </Box>
                        </Box>
                        {!showSelectBene ? (
                            <>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                                    <Box
                                        sx={{ textAlign: 'left' }}
                                        mt="5px"
                                        mb="10px"
                                        ml="5px"
                                        mr="5px"
                                    >
                                        {getLabelValue(lang, 'kirim.bookrate.title')}
                                    </Box>
                                    <FormControl>
                                        <Select
                                            value={rates[country]?.country || (rates.length > 0 ? rates[0].country : '')}
                                            onChange={(event) => {
                                                const selectedItemValue = event.target.value;
                                                const selectedIndex = rates.findIndex(
                                                    (rate: any) => rate.value === selectedItemValue
                                                );
                                                dispatch(changeCountry(selectedIndex, selectedItemValue));
                                                dispatch(clearSelectedBene());
                                                setToAmount("");
                                                setFromAmount("");
                                                // Reset provider to the first one of the new country
                                                const newCountryProviders = rates[selectedIndex]?.providers || [];
                                                if (newCountryProviders.length > 0) {
                                                    const firstProvider = newCountryProviders[0];
                                                    // Dispatch provider change to the first provider
                                                    dispatch(changeProvider(0));
                                                    setValue(firstProvider.value);  // Set value to the first provider
                                                }
                                            }}
                                        >
                                            {rates.map((rate: any) => (
                                                <MenuItem key={rate.country} value={rate.value}>
                                                    {rate.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {rates && rates[country] && rates[country]?.providers && (
                                        <FormControl sx={{ mt: 2 }}>
                                            <Select
                                                value={value || (arrangedproviders.length > 0 ? arrangedproviders[0]?.value : '')}
                                                onChange={(event) => {
                                                    const selectedItemValue = event.target.value;
                                                    const selectedIndex = arrangedproviders.findIndex((rate: any) => rate.value === selectedItemValue);
                                                    dispatch(changeProvider(selectedIndex))
                                                    setToAmount("");
                                                    setFromAmount("");
                                                    setValue(selectedItemValue)
                                                }}
                                            >
                                                {arrangedproviders.map((provider: any) => (
                                                    <MenuItem key={provider.name} value={provider.value}>
                                                        {provider.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    <Box sx={{ mt: 2, p: 2, borderRadius: '10px', backgroundColor: 'success.main', color: 'success.contrastText', textAlign: 'center' }}>
                                        {getConversionString(rates, country, provider)}
                                    </Box>
                                </Paper>
                                <Paper sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                                    <Box
                                        sx={{ textAlign: 'left' }}
                                        mt="5px"
                                        mb="10px"
                                        ml="5px"
                                        mr="5px"
                                    >
                                        {getLabelValue(lang, 'transfer.selected.title')}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                                        <TextField
                                            margin="none"
                                            required
                                            fullWidth
                                            type='text'
                                            value={`MYR          |          ${fromAmount}`}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const extractedAmount = inputValue.split('|')[1].trim();
                                                let allowedValue = '';
                                                if (/^\d*\.?\d{0,2}$/.test(extractedAmount)) {
                                                    allowedValue = extractedAmount;
                                                } else {
                                                    allowedValue = fromAmount;
                                                }
                                                handleToAmountChange(allowedValue);
                                                setFromAmount(allowedValue);
                                            }}
                                            sx={{ mb: "5px", "& input": { textAlign: "center" } }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                                        <TextField
                                            margin="none"
                                            required
                                            fullWidth
                                            type='text'
                                            value={`${rates[country] ? rates[country].currency || '' : ''}          |          ${toAmount}`}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const extractedAmount = inputValue.split('|')[1].trim();
                                                let allowedValue = '';
                                                if (/^\d*\.?\d{0,2}$/.test(extractedAmount)) {
                                                    allowedValue = extractedAmount;
                                                } else {
                                                    allowedValue = fromAmount;
                                                }
                                                handleFromAmountChange(allowedValue);
                                                setToAmount(allowedValue);
                                            }}
                                            sx={{ mb: "5px", mt: "5px", "& input": { textAlign: "center" } }}
                                        />
                                    </Box>
                                    {error && (
                                        <Grid
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography textAlign="center" color={colors.primary[500]}>
                                                {message || ''}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 2, mb: 1 }}
                                        onClick={handleSelectBeneficiary}
                                        disabled={loading1}
                                        endIcon={loading1 ? <CircularProgress size={20} /> : null}
                                    >
                                        {getLabelValue(lang, 'kirim.bookrate.button')}
                                    </Button>
                                </Paper>
                                {error1.error &&
                                    <DialogAlert
                                        open={error1.error}
                                        title={'Attention'}
                                        content={new Array(error1.message)}
                                    />
                                }
                            </>
                        ) : (
                            <>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                                    <Box
                                        sx={{ textAlign: 'left' }}
                                        mt="5px"
                                        mb="10px"
                                        ml="5px"
                                        mr="5px"
                                    >
                                        {getLabelValue(lang, 'transfer.confirm.amount')}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                                        <TextField
                                            margin="none"
                                            required
                                            fullWidth
                                            type='text'
                                            value={`MYR          |          ${amountRM}`}
                                            sx={{ mb: "5px", "& input": { textAlign: "center" } }}
                                            inputMode="numeric"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{ textAlign: 'left' }}
                                        mt="5px"
                                        mb="10px"
                                        ml="5px"
                                        mr="5px"
                                    >
                                        {getLabelValue(lang, 'kirim.bookrate.selectpurpose')}
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={purpose || ""}
                                            onChange={(event) => {
                                                const selectedItemValue = event.target.value;
                                                setPurpose(selectedItemValue)
                                            }}
                                        >
                                            {purposes.map((purpose: any, index) => (
                                                <MenuItem key={index} value={purpose.value}>
                                                    {purpose.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Paper>
                                <Paper sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                                    <Box
                                        sx={{ textAlign: 'left' }}
                                        mt="5px"
                                        mb="10px"
                                        ml="5px"
                                        mr="5px"
                                    >
                                        {getLabelValue(lang, 'kirim.bookrate.selectbene.title')}
                                    </Box>
                                    <List component="nav" sx={{ flexGrow: 1, overflowY: 'auto', alignItems: 'center' }}>
                                        {beneficiaries.map((item, index) => (
                                            <BeneficiaryCard
                                                beneficiary={item}
                                                selected={isSelectedBene}
                                                onSelect={(index) => {
                                                    setIsSelectedBene(index);
                                                    setBeneficiary(beneficiaries[index])
                                                }}
                                                key={index}
                                                index={index}
                                            />
                                        ))}
                                        {beneficiaries.length === 0 && (
                                            <Box sx={{ textAlign: 'center', p: 3 }}>
                                                <Typography variant="h6" sx={{ mb: 2 }}>
                                                    {'Harap Maaf! Anda belum daftar penerima atau mungkin penerima anda tidak aktif.Sila pergi ke kedai untuk daftar/aktifkan penerima sebelum buat Pesan Kirim.'}
                                                </Typography>
                                            </Box>
                                        )}
                                    </List>
                                    {error1.error && (
                                        <Grid
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography textAlign="center" color={colors.primary[500]}>
                                                {error1.message || ''}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="success"
                                                onClick={() => {
                                                    setError({ error: false, message: "" });
                                                    setShowSelectBene(false);
                                                }}
                                                disabled={loading1}
                                            >
                                                {getLabelValue(lang, 'login.account.button.goback')}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                onClick={async () => await handleProBookCheck()}
                                                variant="contained"
                                                color="primary"
                                                disabled={loading1}
                                                endIcon={loading1 ? <CircularProgress size={20} /> : null}
                                            >
                                                {getLabelValue(lang, 'kirim.bookrate.selectbene.button')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Paper sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                            <Box>
                                <IconButton onClick={() => setShowBookConfirm(false)} aria-label="back">
                                    <ArrowBackIcon />
                                </IconButton>
                                <Box display='flex' justifyContent={'center'} alignItems={'center'}>
                                    <img
                                        alt="logo"
                                        src={'../../assets/mmlogo.png'}
                                        style={{
                                            maxWidth: '100%',
                                            height: 'auto',
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    />
                                </Box>
                                <Box
                                    mt={2}
                                    mb={2}
                                    display='flex'
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography variant='h5'>
                                        Book Rate Summary
                                    </Typography>
                                </Box>
                                <Divider />
                                {preBookBody !== undefined &&
                                    <>
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Beneficiary:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {preBookBody?.bene_name || ''}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                        {/* {preBookBody.bank_acc_number !== "0" && */}
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Account Number
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {preBookBody.bank_acc_number || '0'}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                        {/* } */}
                                        {(preBookBody !== undefined && preBookBody?.bank_name !== '') && (
                                            <Grid2
                                                container
                                                p={1.5}
                                                pb={0}
                                            >
                                                <Grid2 xs={4} md={5}>
                                                    <Typography variant={'body1'}>
                                                        Bank Name:
                                                    </Typography>
                                                </Grid2>
                                                <Grid2 xs={8} md={7}>
                                                    <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                        {preBookBody?.bank_name}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                        )}
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Member Card No:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {formatCardNumberWithDashes(preBookBody?.card_no)}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                    </>
                                }
                                <Divider sx={{ mt: 2 }} />
                                {preBookBody !== undefined &&
                                    <>
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Country:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {preBookBody.country}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Provider
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {preBookBody?.provider || ''}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                    </>
                                }
                                <Divider sx={{ mt: 2 }} />
                                <Grid2
                                    container
                                    p={1.5}
                                    pb={0}
                                >
                                    <Grid2 xs={4} md={5}>
                                        <Typography variant="body1">
                                            Send Amount in {preBookBody.remit_foreign_currency || ''}
                                            <span style={{ color: 'red' }}>{preBookBody.special_ex_ratecontent || ''}</span>:
                                        </Typography>
                                    </Grid2>
                                    <Grid2 xs={8} md={7}>
                                        <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                            {preBookBody.special_ex_rate > 0 ? preBookBody.special_ex_rate : preBookBody.remit_foreign_amt || ''}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                                <Grid2
                                    container
                                    p={1.5}
                                    pb={0}
                                >
                                    <Grid2 xs={4} md={5}>
                                        <Typography variant={'body1'}>
                                            Send Amount in MYR:
                                        </Typography>
                                    </Grid2>
                                    <Grid2 xs={8} md={7}>
                                        <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                            {preBookBody.remit_rm_amt || ''}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                                <Grid2
                                    container
                                    p={1.5}
                                    pb={0}
                                >
                                    <Grid2 xs={4} md={5}>
                                        <Typography variant={'body1'}>
                                            Service Charge in RM:
                                        </Typography>
                                    </Grid2>
                                    <Grid2 xs={8} md={7}>
                                        <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                            {preBookBody.remit_rm_fee || ''}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                                {pointredeem ? (
                                    <>
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Total Discount in RM:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {discountAmount || ''}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={1.5}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Total Charge in RM:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {(preBookBody.pymt_rm_amt - discountAmount).toFixed(2) || ''}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                    </>
                                ) : (
                                    <Grid2
                                        container
                                        p={1.5}
                                        pb={0}
                                    >
                                        <Grid2 xs={4} md={5}>
                                            <Typography variant={'body1'}>
                                                Total Charge in RM:
                                            </Typography>
                                        </Grid2>
                                        <Grid2 xs={8} md={7}>
                                            <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                {preBookBody.pymt_rm_amt || ''}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                )}
                                <Divider />
                                <Grid2
                                    mt={2}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {pointredeem ? (
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={handleRedeemClick}
                                            disabled={submitClicked || loading}
                                            color="success"
                                            sx={{
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <MonetizationOnOutlinedIcon sx={{ fontSize: 25, marginRight: '8px' }} />
                                            <div>
                                                {`${points - usedPoint} ${getLabelValue(lang, 'kita.points')}`} ({getLabelValue(lang, 'kita.revert')})
                                            </div>
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={handleRedeemClick}
                                            disabled={submitClicked || loading}
                                            color="success"
                                            sx={{
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <MonetizationOnOutlinedIcon sx={{ fontSize: 25, marginRight: '8px' }} />
                                            <div>
                                                {`${points - usedPoint} ${getLabelValue(lang, 'kita.points')}`} ({getLabelValue(lang, 'kita.redeem')})
                                            </div>
                                        </Button>
                                    )}
                                    <div style={{ marginLeft: '10px' }}></div>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSubmitClick}
                                        disabled={loading}
                                        endIcon={loading ? <CircularProgress size={10} /> : null}
                                        color='primary'
                                        sx={{
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {getLabelValue(lang, 'kirim.bookrate.confirm.button')}
                                    </Button>
                                </Grid2>
                                {showAlerts &&
                                    <DialogAlert
                                        open={showAlerts}
                                        onSuccess={() => setShowAlerts(false)}
                                        title={'Attention'}
                                        content={new Array(preBookBody.trxcontent)}
                                        yesButton={'OK'}
                                    />
                                }
                                {redeemAlert &&
                                    <DialogAlert
                                        open={redeemAlert}
                                        onSuccess={() => setRedeemAlert(false)}
                                        title={'Attention'}
                                        content={new Array('Cannot Redeem')}
                                        yesButton={'OK'}
                                    />
                                }
                                {error &&
                                    <DialogAlert
                                        open={error}
                                        onSuccess={() => {
                                            dispatch(bookRateDimissMessage());
                                            navigate(0);
                                        }}
                                        title={'Attention'}
                                        content={new Array(message)}
                                        yesButton={'OK'}
                                        reportIssue={error.reportIssue?.show}
                                        req_idx={error.reportIssue?.idx}
                                        //onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
                                        onReportIssueDone={() => dispatch(bookRateDimissMessage())}
                                        reportTitle={error?.title}
                                        reportDetails={error?.details}
                                        trans_id={error?.trans_id}
                                        ticket_type={error?.ticket_type}
                                    />
                                }
                                {showMessage &&
                                    <DialogAlert
                                        open={showMessage}
                                        onSuccess={async () => {
                                            dispatch(bookRateDimissMessage());
                                            setSuccess(true);
                                        }}
                                        title={'Book Rate Success'}
                                        content={new Array('Booking ID: ' + bookRateResp.body.booking_id)}
                                        yesButton={'YES'}
                                    />
                                }
                            </Box>
                        </Paper>
                    </>
                )}
            </Container>
            <Footer />
        </ThemeProvider>
    )
}

export default WhatsAppBooking;