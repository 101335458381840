import { BORNEO_ADD_TO_CART, BORNEO_ADD_TO_CART_FAILED, BORNEO_ADD_TO_CART_SUCCESS, BORNEO_FETCH_CART, BORNEO_FETCH_CART_FAILED, BORNEO_FETCH_CART_SUCCESS, BORNEO_REMOVE_FROM_CART, BORNEO_REMOVE_FROM_CART_FAILED, BORNEO_REMOVE_FROM_CART_SUCCESS, BORNEO_FETCH_ADDRESS, BORNEO_FETCH_ADDRESS_FAILED, BORNEO_FETCH_ADDRESS_SUCCESS, BORNEO_ADD_ADDRESS, BORNEO_ADD_ADDRESS_FAILED, BORNEO_ADD_ADDRESS_SUCCESS, BORNEO_FETCH_PRODUCTS, BORNEO_FETCH_PRODUCTS_FAILED, BORNEO_FETCH_PRODUCTS_SUCCESS, BORNEO_FETCH_FEATURED_PRODUCTS, BORNEO_FETCH_FEATURED_PRODUCTS_FAILED, BORNEO_FETCH_FEATURED_PRODUCTS_SUCCESS, BORNEO_FETCH_CATEGORIES, BORNEO_FETCH_CATEGORIES_FAILED, BORNEO_FETCH_CATEGORIES_SUCCESS, BORNEO_FETCH_PURCHASE_HISTORY, BORNEO_FETCH_PURCHASE_HISTORY_FAILED, BORNEO_FETCH_PURCHASE_HISTORY_SUCCESS, BORNEO_FETCH_SHIPPING_STATUS, BORNEO_FETCH_SHIPPING_STATUS_FAILED, BORNEO_FETCH_SHIPPING_STATUS_SUCCESS, BORNEO_FETCH_COINS_HISTORY, BORNEO_FETCH_COINS_HISTORY_FAILED, BORNEO_FETCH_COINS_HISTORY_SUCCESS, BORNEO_FETCH_COINS, BORNEO_FETCH_COINS_FAILED, BORNEO_FETCH_COINS_SUCCESS, BORNEO_REDEEM_COINS, BORNEO_REDEEM_COINS_FAILED, BORNEO_REDEEM_COINS_SUCCESS, BORNEO_CHECKOUT_CART, BORNEO_CHECKOUT_CART_FAILED, BORNEO_CHECKOUT_CART_SUCCESS, BORNEO_UNMOUNT } from "../actions/type";

const initialState = {
    products: [],
    featured_products: [],
    address: [],
    default_address: [],
    cart: [],
    categories: [],
    message: '',
    purchase_history: [],
    shipping: [],
    coins_history: [],
    coins: '',
    redeem_coins: 0,
    loading: false,
    error: false,
};

export default (state = initialState, action : any) => {
    switch (action.type) {
        // cart
        case BORNEO_ADD_TO_CART:
            return { ...state, loading: true };
        case BORNEO_ADD_TO_CART_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_ADD_TO_CART_SUCCESS:
            return { ...state, loading: false, message: action.payload };

        case BORNEO_FETCH_CART:
            return { ...state, loading: true };
        case BORNEO_FETCH_CART_FAILED:
            return { ...state, loading: false, message: action.payload, cart: [] };
        case BORNEO_FETCH_CART_SUCCESS:
            return { ...state, loading: false, cart: action.payload.results.data };

        case BORNEO_REMOVE_FROM_CART:
            return { ...state, loading: true };
        case BORNEO_REMOVE_FROM_CART_FAILED:
            return { ...state, loading: false, error: true, message: action.payload.result.message };
        case BORNEO_REMOVE_FROM_CART_SUCCESS:
            return { ...state, loading: false, message: action.payload.result.message, cart: action.payload.results.data };

        // address 
        case BORNEO_FETCH_ADDRESS:
            return { ...state, loading: true };
        case BORNEO_FETCH_ADDRESS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_FETCH_ADDRESS_SUCCESS:
            return {
                ...state, address: action.payload.results.data, loading: false,
                default_address: action.payload.results.data ? action.payload.results.data.find((address: { isDefaultAddress: number; }) => address.isDefaultAddress === 1) : '',
            };

        case BORNEO_ADD_ADDRESS:
            return { ...state, loading: true };
        case BORNEO_ADD_ADDRESS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_ADD_ADDRESS_SUCCESS:
            return { ...state, loading: false, message: action.payload.results.message };

        // product listing
        case BORNEO_FETCH_PRODUCTS:
            return { ...state, loading: true };
        case BORNEO_FETCH_PRODUCTS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_FETCH_PRODUCTS_SUCCESS:
            return { ...state, products: action.payload.results.data, loading: false };

        // featured products
        case BORNEO_FETCH_FEATURED_PRODUCTS:
            return { ...state, loading: true };
        case BORNEO_FETCH_FEATURED_PRODUCTS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_FETCH_FEATURED_PRODUCTS_SUCCESS:
            return { ...state, featured_products: action.payload.results.data, loading: false };

        // categories
        case BORNEO_FETCH_CATEGORIES:
            return { ...state, loading: true };
        case BORNEO_FETCH_CATEGORIES_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_FETCH_CATEGORIES_SUCCESS:
            return { ...state, categories: action.payload.results.data, loading: false };

        // purchase history
        case BORNEO_FETCH_PURCHASE_HISTORY:
            return { ...state, loading: true };
        case BORNEO_FETCH_PURCHASE_HISTORY_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_FETCH_PURCHASE_HISTORY_SUCCESS:
            return { ...state, purchase_history: action.payload.results.data, loading: false };

        // shipping status
        case BORNEO_FETCH_SHIPPING_STATUS:
            return { ...state, loading: true };
        case BORNEO_FETCH_SHIPPING_STATUS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_FETCH_SHIPPING_STATUS_SUCCESS:
            return { ...state, shipping: action.payload.results.data, loading: false };

        // coins
        case BORNEO_FETCH_COINS_HISTORY:
            return { ...state, loading: true };
        case BORNEO_FETCH_COINS_HISTORY_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_FETCH_COINS_HISTORY_SUCCESS:
            return { ...state, coins_history: action.payload.results.data, loading: false };

        case BORNEO_FETCH_COINS:
            return { ...state, loading: true };
        case BORNEO_FETCH_COINS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_FETCH_COINS_SUCCESS:
            return { ...state, coins: action.payload.results.data.borneokakicredit, loading: false };

        case BORNEO_REDEEM_COINS:
            return { ...state, loading: true };
        case BORNEO_REDEEM_COINS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_REDEEM_COINS_SUCCESS:
            return { ...state, redeem_coins: action.payload.results.data, loading: false };

        // checkout 
        case BORNEO_CHECKOUT_CART:
            return { ...state, loading: true };
        case BORNEO_CHECKOUT_CART_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BORNEO_CHECKOUT_CART_SUCCESS:
            return { ...state, loading: false, message: action.payload, error: false };

        case BORNEO_UNMOUNT:
            return { ...state, error: false }
        default:
            return state;
    }
}
