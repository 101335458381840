import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button, Card, CardContent, CircularProgress,TextField, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { RootState, useAppDispatch } from '../../store/store';
import { createPrepaidTransaction, getPrepaidList, unMountPrepaidView } from '../../actions/PrepaidAction';
import { useSelector } from 'react-redux';
import { getLabelValue } from '../../utils';
import useCheckIsMobile from '../../hooks/useCheckIsMobile';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import { IError, RouteComponent } from '../../type';
import PinInput from '../../components/PinInput';
import DialogAlert from '../../components/DialogAlert';

interface Props extends RouteComponent{
    logOut: () => void;
}

const colors = tokens();

const Prepaid: FC<Props> = (props) => {
    const { logOut } = props;   
    const isMobile: boolean = useCheckIsMobile();
    const [reloadNumber, setReloadNumber] = useState<string | undefined>('')
    const [selectedTelco, setSelectedTelco] = useState<any>('');
    const [denos, setDenos] = useState<any>('');
    const [selectedDenos, setSelectedDenos] = useState<any>('');
    const [amount, setAmount] = useState('');
    const [showPin, setShowPin] = useState(false);
    const [pinNumber, setPinNumber] = useState<string>('');
    const { loading, error, message, prepaidList, renderForgotPin } =  useSelector((state: RootState) => state.prepaid)
    const { lang, user } = useSelector((state: RootState) => state.user);
    const [error1, setError] = useState<IError>({ error: false, message: "" });
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(getPrepaidList(lang));
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            dispatch(unMountPrepaidView());
            shouldLockEffect.current = true;
        }
    }, [])

    const handleSubmitButton = () => {
        setError({ error: false, message: '' })
        if (amount === '')
            return setError({ error: true, message: getLabelValue(lang, 'prepaidselected.error.message1')});
        if (reloadNumber === "")
            return setError({ error: true, message: getLabelValue(lang, 'prepaidselected.error.message2')});
        setShowPin(true);
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >            
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'home.mmproducts.product3')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                            {selectedTelco ? (
                                    <>
                                        <Box display='flex' justifyContent={'center'} alignItems={'center'}>
                                            <img
                                                src={selectedTelco.logo_url}
                                            />
                                        </Box>                                    
                                        <Container>
                                            <TextField
                                                label={getLabelValue(lang, 'prepaidselected.header.title')}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={reloadNumber}
                                                onChange={(e) => setReloadNumber(e.target.value)}
                                                type="tel"
                                                inputProps={{
                                                    maxLength: 13
                                                }}
                                            />                                     
                                        </Container>
                                        <Container>
                                            <TextField
                                                label={getLabelValue(lang, 'prepaid.header.title')}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={selectedTelco.name}
                                                disabled
                                            />                                   
                                        </Container>
                                        <Container sx={{ mt: 1 }}>  
                                            {denos ? (
                                                <>
                                                <Box 
                                                    sx={{ textAlign: 'left'  }}
                                                    mt="5px"
                                                    mb="10px"
                                                    ml="5px" 
                                                    mr="5px"
                                                >
                                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                        {getLabelValue(lang, 'prepaidselected.header.description')}
                                                    </Typography>                                           
                                                </Box>        
                                                <Grid container spacing={2}>
                                                    {denos.map((item: any, index: React.Key) => (
                                                        <Grid item xs={6} sm={4} key={index}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                setError({ error: false, message: ''});
                                                                setSelectedDenos(item);
                                                                setAmount(item.value);
                                                            }}
                                                            style={{ width: '100%'}}
                                                        >
                                                            <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                            RM {item.label}
                                                            </Typography>
                                                        </Button>
                                                        </Grid>
                                                    ))}
                                                </Grid>                            
                                                </>
                                            ) : (
                                                <Grid2
                                                    display="flex"
                                                    justifyContent="center"
                                                >  
                                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                                        {getLabelValue(lang, 'prepaidselected.no.denos')}
                                                    </Typography>                            
                                                </Grid2>                                      
                                            )}
                                            {selectedTelco.isEReload !== undefined && selectedTelco.isEReload === 1 ? (
                                                <TextField
                                                    sx={{ mt: 3 }}
                                                    label={`${getLabelValue(lang, 'prepaidselected.main.amountinput')} (RM)`}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    value={amount}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        let val = e.target.value;
                                                        let allowedValue = '';
                                                        if (/^\d*\.?\d{0,2}$/.test(val)) {
                                                            allowedValue = val;
                                                        } else {
                                                            allowedValue = val.substr(0, val.length - 1);
                                                        }
                                                        setAmount(allowedValue);
                                                    }}
                                                />                                         
                                            ) : (
                                                <TextField
                                                    sx={{ mt: 3 }}
                                                    label={`${getLabelValue(lang, 'prepaidselected.main.amountinput')} (RM)`}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    disabled
                                                />
                                            )}
                                            {selectedTelco.showRemark === 1 && (
                                                <Box
                                                    sx={{
                                                        mt : 1
                                                    }}
                                                >
                                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                    {lang === "en" ? "ATTENTION: " : "Perhatian: "}
                                                </Typography>
                                                <Grid container alignItems="center">
                                                    <Typography variant="body1">
                                                    {lang === "en" ? "Payment of" : "Bayaran"}
                                                    {` RM${(Number(amount) + Number(amount) * selectedTelco.percentage_increase).toFixed(2)}`}
                                                    {lang === "en"
                                                        ? ` for ${selectedTelco.name || ''} RM${Number(amount).toFixed(2)} will be `
                                                        : ` untuk ${selectedTelco.name || ''} RM${Number(amount).toFixed(2)} akan `}
                                                    </Typography>
                                                </Grid>
                                                <Typography variant="body1">
                                                    {lang === "en"
                                                    ? "deducted from your MM Wallet account"
                                                    : "ditolak dari akaun MM Wallet anda"}
                                                </Typography>
                                                </Box>
                                            )}
                                            {error1 && (
                                                <Grid
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                <Typography textAlign="center" color={colors.primary[500]}>
                                                    {error1.message || ''}
                                                </Typography>
                                                </Grid>
                                            )}                                             
                                        </Container>                               
                                        <Container sx={{ mt: 2, mb: 2}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        color="success"
                                                        onClick={() => {
                                                            setReloadNumber('');
                                                            setSelectedTelco('');
                                                            setDenos('');
                                                            setSelectedDenos('');
                                                            setAmount('');
                                                            setPinNumber('');
                                                            setError({ error: false, message: '' });
                                                        }}
                                                        disabled={loading} 
                                                    >
                                                        {getLabelValue(lang, 'login.account.button.goback')}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Button
                                                    type="submit"
                                                    fullWidth
                                                    onClick={() => handleSubmitButton()}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={loading}
                                                    endIcon={loading ? <CircularProgress size={20} /> : null}
                                                    >
                                                        {getLabelValue(lang, 'prepaidselected.footer.button')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </>
                                ):(
                                    <Box sx={{ m:2 }}>
                                        <Typography variant={"h3"} 
                                        sx={{ wordWrap: 'break-word', textDecoration: 'underline', textDecorationThickness: '1px', fontWeight: '600', mb: '25px' }}>
                                            {getLabelValue(lang, 'prepaid.header.title')}
                                        </Typography>
                                        {prepaidList && prepaidList.length > 0 ? (
                                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                                {prepaidList.map((item: any, index: React.Key) => (
                                                    <Grid xs={2} sm={4} md={4} key={index}>
                                                        <Button
                                                            fullWidth
                                                            onClick={() => {
                                                                setReloadNumber(user?.mobile);
                                                                setSelectedTelco(item);
                                                                setDenos(item.denominations);
                                                            }} 
                                                            sx={{
                                                                mt: '10px',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                padding: '10px',
                                                                height: isMobile ? '50%' : '75%',
                                                            }}
                                                        >
                                                            <img
                                                                src={item.logo_url}
                                                                style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                            />
                                                            <Typography variant='h5' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                                                                {item.name}
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </Grid>                            
                                        ) : (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                                                <CircularProgress size={30} color="secondary" />
                                            </Box>
                                        )}
                                    </Box>                       
                                )}                
                            </CardContent>    
                        </Card>        
                    </Grid2>
                </Grid2>
                {showPin && 
                    <PinInput
                        open={showPin}
                        onCancel={() => setShowPin(false)}
                        onSuccess={() => {
                            dispatch(createPrepaidTransaction(lang, navigate, selectedTelco, reloadNumber, pinNumber, selectedDenos, user, amount));
                            setShowPin(false);
                        }}
                        yesButton={'Submit'}
                        cancelButton={'Cancel'}
                        pinNumber ={pinNumber}
                        onPinChange={(e: any)=>{
                            setPinNumber(e.target.value)
                        }}
                        lang={lang}
                    />
                }
                {error &&
                    <DialogAlert
                        open={error}
                        onCancel={() => {
                            dispatch(unMountPrepaidView());
                            logOut();
                            navigate('/forgotpin', { state: { user: user } });
                        }}
                        onSuccess={() => {
                            dispatch(unMountPrepaidView());
                            setPinNumber('');
                        }}
                        title={'Attention'}
                        content={new Array(message)}
                        yesButton={'OK'}
                        cancelButton={renderForgotPin === 1 ? (lang === 'en' ? 'FORGOT PIN' : 'LUPA PIN') : ''}
                    />
                }  
            </Box>          
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default Prepaid;
