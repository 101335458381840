import {
    TRANSACTION_HISTORY_FETCH,
    TRANSACTION_HISTORY_FETCH_FAILED,
    TRANSACTION_HISTORY_FETCH_SUCCESS,
    TRANSACTION_DOREFUND_FETCH,
    TRANSACTION_DOREFUND_FETCH_FAILED,
    TRANSACTION_DOREFUND_FETCH_SUCCESS,
    TRANSACTION_DO_CANCEL,
    TRANSACTION_DO_CANCEL_FAILED,
    TRANSACTION_DO_CANCEL_SUCCESS,
    TRANSACTION_HISTORY_RENDER_COMPONENT,
    ALL_TRANSACTIONS_CHANGE_QUERY_DATE,
    ALL_TRANSACTIONS_SCREEN_UNMOUNT
} from "../actions/type";
  
const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    transactions: [],
    showMessage: false,
    queryDate:7,
    booking_status:"ALL",
    transaction_status:"ALL",
}
  
export default (state = INITIAL_STATE, action : any) => {
    switch (action.type){
        case TRANSACTION_HISTORY_FETCH:
            return { ...state, loading: true ,queryDate: INITIAL_STATE.queryDate, booking_status:INITIAL_STATE.booking_status, transaction_status:INITIAL_STATE.transaction_status};
        case TRANSACTION_HISTORY_FETCH_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case TRANSACTION_HISTORY_FETCH_SUCCESS:
            return { ...INITIAL_STATE, transactions: action.payload,queryDate: state.queryDate , booking_status:state.booking_status,transaction_status:state.transaction_status};
        case ALL_TRANSACTIONS_CHANGE_QUERY_DATE:
            return {...state , transactions:state.transactions ,queryDate:action.payload ,booking_status:action.payload1,transaction_status:action.payload2, loading:true}
        case TRANSACTION_DOREFUND_FETCH:
            return { ...state, error: false, loading: true }
        case TRANSACTION_DOREFUND_FETCH_FAILED:
            return { ...state, error: true, loading:false, message: action.payload };
        case TRANSACTION_DOREFUND_FETCH_SUCCESS:
            return { ...state, error: false, loading:false, message: action.payload, showMessage: true };
        case TRANSACTION_DO_CANCEL:
            return { ...state, error: false, loading: true }
        case TRANSACTION_DO_CANCEL_FAILED:
            return { ...state, error: true, loading:false, message: action.payload };
        case TRANSACTION_DO_CANCEL_SUCCESS:
            return { ...state, error: false, loading:false, message: action.payload, showMessage: true };
        case TRANSACTION_HISTORY_RENDER_COMPONENT:
            return { ...state, error: false, message: '', loading: false, showMessage: false } ;
        case ALL_TRANSACTIONS_SCREEN_UNMOUNT:
            return { ...INITIAL_STATE, transactions: state.transactions };
        default:
            return state;
    }
}
  