import {useEffect, useState} from 'react';
import useGetScreenDimensions from "./useGetScreenDimensions";
import { Dimension } from '../type';

const useCheckIsMobile = (): boolean => {
    const [isMobile, setIsMobile] = useState<boolean>(true);
    const dimension: Dimension = useGetScreenDimensions()

    useEffect(() => {
        setIsMobile(dimension.width < 768);
    },[dimension]);

    return isMobile;
}

export default useCheckIsMobile;
