import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { ALL_TRANSACTIONS_CHANGE_QUERY_DATE, ALL_TRANSACTIONS_SCREEN_UNMOUNT, TRANSACTION_DOREFUND_FETCH, TRANSACTION_DOREFUND_FETCH_FAILED, TRANSACTION_DOREFUND_FETCH_SUCCESS, TRANSACTION_DO_CANCEL, TRANSACTION_DO_CANCEL_FAILED, TRANSACTION_DO_CANCEL_SUCCESS, TRANSACTION_HISTORY_FETCH, TRANSACTION_HISTORY_FETCH_FAILED, TRANSACTION_HISTORY_FETCH_SUCCESS, TRANSACTION_HISTORY_RENDER_COMPONENT } from "./type";

export const transactionHistoryFetch = (lang : any, params : any, numberOfDays : any, booking_status : any, transaction_status : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: TRANSACTION_HISTORY_FETCH });
            if (params === undefined)
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "002: Oops Something went wrong, Login in again"})
            if (!params.hasOwnProperty("item"))
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "003: Oops Something went wrong, Login in again"})
            if (!params.item.hasOwnProperty("act_id"))
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "004: Oops Something went wrong, Login in again"})

            let act_id = params.item.act_id,
                m1 = params.item.name || '';
            let input : any = {
                ver: CONFIG.ver,
                act: String(act_id),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: numberOfDays,
                p2: booking_status,
                p3:transaction_status,
                m1: m1,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "005: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "006: Something went wrong, please check your Internet Connection and try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0)
                    return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                if (results.hasOwnProperty("providers"))
                    return dispatch({ type: TRANSACTION_HISTORY_FETCH_SUCCESS, payload: results.providers || [] });       
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "Oops Something went wrong"})
        }
    }
}

export const changeQuerytransactionHistoryFetch = (days : number = 0 ,booking_status : any, transaction_status : any , params : any, lang : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: ALL_TRANSACTIONS_CHANGE_QUERY_DATE, payload: days ,payload1:booking_status, payload2: transaction_status });
            if (params === undefined)
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "002: Oops Something went wrong, Login in again"})
            if (!params.hasOwnProperty("item"))
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "003: Oops Something went wrong, Login in again"})
            if (!params.item.hasOwnProperty("act_id"))
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "004: Oops Something went wrong, Login in again"})

            let act_id = params.item.act_id,
                m1 = params.item.name || '';
            let input : any = {
                ver: CONFIG.ver,
                act: String(act_id),
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                p1 :days,
                p2: booking_status,
                p3 :transaction_status,
                m1: m1,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "005: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "006: Something went wrong, please check your Internet Connection and try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0)
                    return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                if (results.hasOwnProperty("providers"))
                    return dispatch({ type: TRANSACTION_HISTORY_FETCH_SUCCESS, payload: results.providers || [] })    
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: TRANSACTION_HISTORY_FETCH_FAILED, payload: "Oops Something went wrong"})
        }
    }
}

export const kirimDoRefund = (lang : any, refund_trans_id : any, refund_acc_bank : any, refund_acc_no : any, refund_acc_name : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: TRANSACTION_DOREFUND_FETCH });

            let input : any = {
                ver: CONFIG.ver,
                act: String(30013),
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "DoKirimRefund",
                lang: lang,
                p1: new Date().valueOf(),
                p2: refund_trans_id,
                p3: refund_acc_bank,
                p4: refund_acc_no,
                p5: refund_acc_name
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: TRANSACTION_DOREFUND_FETCH_FAILED, payload: "001: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: TRANSACTION_DOREFUND_FETCH_FAILED, payload: "002: Something went wrong, please check your Internet Connection and try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: TRANSACTION_DOREFUND_FETCH_FAILED, payload: "003: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0) {
                    return dispatch({ type: TRANSACTION_DOREFUND_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });
                }
                return dispatch({ type: TRANSACTION_DOREFUND_FETCH_SUCCESS, payload: `${results.message || ""}` }); 
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: TRANSACTION_DOREFUND_FETCH_FAILED, payload: "Oops something went wrong!"})
        }
    }
}

export const kirimDoCancel = (lang : any, cancel_trans_id : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: TRANSACTION_DO_CANCEL });

            let input : any = {
                ver: CONFIG.ver,
                act: String(30014),
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "DoKirimCancel",
                lang: lang,
                p1: new Date().valueOf(),
                p2: cancel_trans_id
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: TRANSACTION_DO_CANCEL_FAILED, payload: "001: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: TRANSACTION_DO_CANCEL_FAILED, payload: "002: Something went wrong, please check your Internet Connection and try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: TRANSACTION_DO_CANCEL_FAILED, payload: "003: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0) {
                    return dispatch({ type: TRANSACTION_DO_CANCEL_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });
                }
                return dispatch({ type: TRANSACTION_DO_CANCEL_SUCCESS, payload: `${results.message || ""}` });
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: TRANSACTION_DO_CANCEL_FAILED, payload: "Oops something went wrong!"})
        }
    }
}

export const transactionHistoryRenderComponenet = () => {
    return { type: TRANSACTION_HISTORY_RENDER_COMPONENT };
}
  
  export const unMountTransactionHistoryScreen = () => {
    return { type: ALL_TRANSACTIONS_SCREEN_UNMOUNT}
}