import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import Header from "../../components/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { IError } from "../../type";
import { CONFIG } from "../../config";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../utils";
import QRCode from 'qrcode.react';
import DialogAlert from "../../components/DialogAlert";

const Pay = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [qrCodeStr, setQRCodeStr] = useState('');
    const { lang } = useSelector((state: RootState) => state.user);
    const [error, setError] = useState<IError>({ error: false, message: "" });
    
    useEffect(() => {
        let isActive = true; 
        const initialize = async () => {
            if (isActive) {
                await getQRCodeFromServer();
                setTimeout(() => {
                    if (isActive) {
                        initialize();
                    }
                }, 60000); 
            }
        };
        initialize();
        return () => {
            isActive = false; 
        };
    }, []); 
    
    const getQRCodeFromServer = async () => {
        try{
            setQRCodeStr('');
            let input: any = {
                ver: CONFIG.ver,
                act: String(60000),
                app_id: 6,
                lg: lang
            };
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                return setError({error: true, message: '001: Something went wrong, please try again'});
            }
            if (resultsFromAPI.error !== undefined){
                return setError({error: true, message: '002: Something went wrong, please check your Internet Connection and try again'});
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                return setError({error: true, message: '003: Something went wrong, try again'});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0) {
                return setError({ error: true, message: `[${results.code}] : ${results.message || ""}` });
                }
                console.log(results.qrstring)
                setQRCodeStr(results.qrstring);
                return setError({ error: false, message: ''});
            }
        
            return setError({error: true, message: '004: Something went wrong, try again'});
        }
        catch (e) {
            console.log(e);
            return setError({error: true, message: 'Oops Something went wrong'});
        }
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={"Pay"} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                            {qrCodeStr !== '' &&
                                <Container sx={{ textAlign: 'center' }}>
                                    <QRCode
                                        value={qrCodeStr}
                                        size={isMobile ? 150 : 250}
                                    />
                                    <Typography variant={"h4"} sx={{ mt: 2, wordWrap: 'break-word', textAlign: 'center' }}>
                                    {getLabelValue(lang, 'pay.qrcode.desc')}
                                    </Typography>                                
                                </Container>
                            }
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
                {error.error &&
                    <DialogAlert
                        open={error.error}
                        onCancel={() => setError({ error: false, message: ''})}
                        onSuccess={() => setError({ error: false, message: ''})}
                        title={'Attention'}
                        content={new Array(error.message)}
                        yesButton={'OK'}
                        reportIssue={error.reportIssue?.show}
                        req_idx={error.reportIssue?.idx}
                        onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
                        reportTitle={error?.title}
                        reportDetails={error?.details}
                        trans_id={error?.trans_id}
                        ticket_type={error?.ticket_type}
                    />
                }   
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default Pay;
