import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { tokens } from "../../theme";
import { CircularProgress, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackendResponse, Error, TUser } from '../../type';
import { CONFIG } from '../../config';
import { callBackendAPI, getLabelValue, logError } from '../../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Footer from '../../components/Footer';

const colors = tokens();

const ResetPin = () => {
    const {state} = useLocation();
    const user: TUser | undefined = state?.user;
    const [pin, setPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ error: false, message: ""});
    const navigate = useNavigate();
    
    const handleCreatePin = async (): Promise<void> => {
        try {
            if (pin === '')
                return setError({ error: true, message: ''});
            if (confirmPin === '')
                return setError({ error: true, message: ''});
            if (pin !== confirmPin)
                return setError({ error: true, message: ''});
            if (pin.length <6)
                return setError ({ error: true, message: ''});           
            if (error.error)
            setError({ error: false, message: ''});

            setLoading(true);
            let headers: HeadersInit = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }       
            let input: any = {
                ver: CONFIG?.ver,
                act: 903,
                p1: user?.mobile?.trim(),
                p2: pin ? pin.trim() : '',
                m1: 'UpdatePin',
                m2: '',
                user: user
            }
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('login', 'POST',headers, input);
            if (resultFromAPI === undefined) {
            setLoading(false);
            return setError({ error: true, message: "001: Something went wrong, please try again."});
            }

            if (resultFromAPI?.error !== undefined) {
            setLoading(false);
            return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
            }

            if (resultFromAPI?.results) {
            let results: any = resultFromAPI.results;
            if (results?.code && results.code !== 0) {
                setLoading(false);
                return setError({
                    error: true,
                    message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`});
            }
            return navigate('/login');
            }
            setLoading(false);
            return setError({ error: false, message: "004: Something went wrong, please try again."})
        }
        catch (e) {
            logError('handleCreatePin', e);
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
            sx={{
                boxShadow: 3,
                borderRadius: 2,
                px: 4,
                py: 4,
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            >
            <Box mt="5px" mb="25px" ml="5px">
                <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="10px"
                overflow="hidden"
                boxShadow={2}
                >
                    <img
                        alt="logo"
                        width="230px"
                        height="60px"
                        src={'../../assets/mmlogo.png'}
                        style={{ cursor: "pointer"}}
                    />
                </Box>
            </Box>
            <Box
                sx={{ fontStyle: 'normal', textAlign: 'center' }}
                mb="5px"
                ml="50px"
                mr="50px"
            >
            <Typography variant="h5" sx={{fontWeight: 'bold'}} color='#ef233e'>
                {getLabelValue(lang, 'register.pin.title1')}
            </Typography>
            </Box>
            <Box
            sx={{ textAlign: 'left' }}
            mt="15px"
            mb="5px"
            ml="5px"
            mr="5px"
            >
                {getLabelValue(lang, 'register.pin.subTitle')}
            </Box>            
            <Box sx={{ mt: 2 }} minWidth={'100%'}>
                <Box
                sx={{ textAlign: 'left'  }}
                mt="5px"
                mb="5px"
                ml="5px"
                mr="5px"
                >
                {getLabelValue(lang, 'register.account.pin')}
                </Box>                         
                <TextField
                    margin="none"
                    required
                    fullWidth
                    name="pin"
                    placeholder=""
                    type='password'
                    id="pin"
                    value={pin}
                    autoFocus
                    onChange={(e) => setPin(e.target.value)}
                    inputProps={{ maxLength: 6, inputMode: 'numeric'}}
                    sx={{
                    '& input': {
                        textAlign: 'center',
                    },
                    }}                 
                />
                <Box
                sx={{ textAlign: 'left'  }}
                mt="15px"
                mb="5px"
                ml="5px"
                mr="5px"
                >
                {getLabelValue(lang, 'register.account.confirmPin')}
                </Box>
                <TextField
                    margin="none"
                    required
                    fullWidth
                    name="confirmPin"
                    placeholder=""
                    type='password'
                    id="confirm-pin"
                    value={confirmPin}
                    autoFocus
                    onChange={(e) => setConfirmPin(e.target.value)}
                    inputProps={{ maxLength: 6, inputMode: 'numeric'}}
                    sx={{
                    '& input': {
                        textAlign: 'center',
                    },
                    }}                 
                />    
                <Button
                    type="submit"
                    fullWidth
                    onClick={async () => await handleCreatePin()}
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                >
                    {getLabelValue(lang, 'login.account.button.submit')}
                </Button>
                {error.error && (
                    <Typography
                    sx={{
                        mt: '10px',
                        textAlign: 'center',
                        color: colors.primary[500],
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                    {error.message || getLabelValue(lang, 'register.pin.mismatch')}
                    </Typography>
                )}                               
            </Box>
            </Box>
        </Container>
        <Footer />
        </ThemeProvider>
    );
}

const theme = createTheme({
    palette: {
      secondary: {
        //MMApp signature red
        main: '#ef233e',
      },
      primary: {
        main: '#212121',
      }
    },
  });
  
export default ResetPin;
  