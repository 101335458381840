import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, Link, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { tokens } from '../../theme';
import { useRegister } from '../../actions/RegisterAction';
import { useState } from 'react';
import { BackendResponse, Error } from '../../type';
import { useLocation, useNavigate } from 'react-router-dom';
import { callBackendAPI, getLabelValue, logError, validateEmail, validatePostCode } from '../../utils';
import {CONFIG} from "../../config";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Footer from '../../components/Footer';
import { COLORS } from '../../constants/data';

const colors =  tokens();

const RegisterPageTwo = () => {
    const {state} = useLocation();
    const userinput: any = state?.userinput;
    const [states] = useRegister();
    const [, , , ,tnc_version_idx] = useRegister();
    const [userInfo, setUserInfo] = useState(state.hasOwnProperty("user") ? state.user : {
        aa_uuid: '', aa_notiphoneid: 0, name: '', email: '', address: '', state: '', city: '', lotno: '', address2: '', postcode:''
    });
    const [terms, setTerms] = useState<boolean>(false);
    const [userToggleUpgradeTNC, setUserToggleUpgradeTNC] = useState<boolean>(false);
    const [validateError, setValidateError] = useState({ email: false, postcode: false });
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ error: false, message: ""});
    const navigate = useNavigate();

    const handleAccountCreation = async () : Promise<void> => {
        try {
            if (!state.hasOwnProperty("userinput")) {
                return setError({ error: true, message: "001: Oops something went wrong!"});
            }
            if (!userinput.hasOwnProperty("otp")) {
                return setError({ error: true, message: "OTP is missing, please try again."});
            }
            if (userinput.otp === undefined || userinput.otp.length < 6)
                return setError({ error: true, message: lang === 'bm' ? "OTP tidak sah, Minta lagi" : "Invalid OTP , Request again"});
            if (!userinput.hasOwnProperty("phoneNumber")) {
                return setError({ error: true, message: "Phone Number is required, please try again"});
            }
            if (!userinput.hasOwnProperty("idType") || userinput.idType === undefined)
                return setError({ error: true, message: "ID Type is required, please try again"});
            if (!userinput.hasOwnProperty("nationality") || userinput.nationality === undefined)
                return setError({ error: true, message: "INationality is required, please try again"});
            if (!userinput.hasOwnProperty("idNumber")) {
                return setError({ error: true, message: "ID Number is required, please try again"});
            }
            if (userInfo?.name === '')
                return setError({ error: true, message: "Name is required"});
            if (userInfo?.email !== '') {
                if (!validateEmail(userInfo?.email))
                    return setError({ error: true, message: "Invalid email"});
            }
            if (userInfo?.lotno === '' || !userInfo?.lotno)
                return setError({ error: true, message: "Lot Number is required"});
            if (userInfo?.address === '')
                return setError({ error: true, message: "Address is required"});
            if (userInfo?.address2 === '' || !userInfo?.address2)
                return setError({ error: true, message: "Address 2 is required"});
            if (userInfo?.postcode === '' || !validatePostCode(userInfo?.postcode))
                return setError({ error: true, message: "Invalid or empty Post Code"});
            if (userInfo?.state === '' || userInfo?.state === undefined)
                return setError({ error: true, message: "Please select a state"});
            if (userInfo?.city === '')
            return setError({ error: true, message: "City is required"});
            if (!terms)
                return setError({ error: true, message: 'Please agree and accept the Terms and conditions'});
            if (!userToggleUpgradeTNC)
                return setError({ error: true, message: "Please agree and accept the Upgraded Terms and conditions"});
            if (error.error)
                setError({ error: false, message: ''});
        
            setLoading(true);
            let input: any = {
                ver: CONFIG.ver,
                act: 902,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: userinput.phoneNumber ? userinput.phoneNumber.trim() : '',
                p2: userinput.idType,
                p3: userinput.idNumber ? userinput.idNumber.trim() : '',
                p4: userinput.nationality,
                p5: userInfo?.name ? userInfo.name.trim() || '' : "",
                p6: userInfo?.email ? userInfo.email.trim() || '' : "",
                p7: userInfo?.address ? userInfo.address.trim() || '' : '',
                p8: userInfo?.state ? userInfo.state.trim() || '' : '',
                p9: userInfo?.city ? userInfo.city.trim() : '',
                p10: userinput.otp.trim() || '',
                p11: userInfo?.aa_uuid ? userInfo.aa_uuid || '' : '',
                p12: userInfo?.aa_notiphoneid ? userInfo.aa_notiphoneid || 0 : 0,
                p13: '',
                p14: userinput.referralCode || '',
                p15: 1,
                p16: userInfo?.lotno ? userInfo.lotno.trim() || '' : '',
                p17: userInfo?.address2 ? userInfo.address2.trim() : '',
                p18: userInfo?.postcode ? userInfo.postcode.trim() || '' : '',
                p19: tnc_version_idx ? tnc_version_idx : 0,
                m1: "Register",
                m2: "",
                platform: 'WebApp',
                lg: lang
            }
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('register', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error: true, message: "003: Something went wrong, try again"});
            }
            if (resultsFromAPI.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: "004: Something went wrong, please check your Internet Connection and try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error: true, message: "004: Something went wrong, try again"});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0) {
                    setLoading(false);
                    return setError({
                        error: true, message: `[${results.code}] : ${results.message || ""}`
                    });
                }
                setLoading(false);
                return navigate('/registerenterpin', {
                    state: {
                        user: results.user
                    },
                });  
            }
        
            setLoading(false);
            return setError({ error: true, message: "005: Something went wrong, try again"});
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: "OOPS SOMETHING WENT WRONG"})
        }
    }

    const handleWhatsappOnboard = async () : Promise<void> => {
        navigate('/registerenterpin', {
            state: {
                user: state.user
            }
        });      
    }

    const handleSendOTPToUser = async () : Promise<void> => {
        try {
            if (userInfo?.name === '')
                return setError({ error: true, message: 'Name is required'});
            if (userInfo?.name.trim().length < 5) 
                return setError({ error: true, message: 'Please provide your name as per IC/Passport' });
            if (userInfo?.email !== '') {
                if (!validateEmail(userInfo?.email))
                    return setError({ error: true, message: "Invalid email"});
            }
            if (userInfo?.lotno === '')
                return setError({ error: true, message: 'Lot Number is required'});
            if (userInfo?.address ==='')
                return setError({ error: true, message: 'Name of Flat / Apt / Street is required'});
            if (userInfo?.address2 ==='')
                return setError({ error: true, message: 'Name of Area / Taman is required'});
            if (userInfo?.postcode ==='' || !validatePostCode(userInfo?.postcode))
                return setError({ error: true, message: 'Invalid or empty Post Code' });
            if (userInfo?.state === '' || userInfo?.state === undefined)
                return setError({ error: true, message: 'Please select a state'});
            if (userInfo?.city === '')
                return setError({ error: true, message: 'City is required' });
            if (!terms)
                return setError({ error: true, message: 'Please agree and accept the Terms and conditions'});
            if (!state.hasOwnProperty("userinput"))
                return setError({ error: true, message: "001: Oops something went wrong, Please try again later"});
            if (!userinput.hasOwnProperty("phoneNumber"))
                return setError({ error: true, message: "002: Oops something went wrong, Please try again later"});
            if (error.error)
                setError({ error: false, message: ''});
            
            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let input = {
                ver: CONFIG?.ver,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                mobileno: userinput?.phoneNumber.trim(),
                purpose: "MM App Registration OTP",
                lg: lang
            }
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('sendotp', 'POST', headers, input);
            if (resultFromAPI === undefined) {
                setLoading(false);
                return setError({ error: true, message: "001: Something went wrong, please try again."});
            }
            if (resultFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
            }
            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results?.code && results.code !== 0) {
                    setLoading(false);
                    return setError({
                        error: true,
                        message: `${results?.message || "003: Something went wrong, please try again."}`});
                }
                setLoading(false);
                return navigate('/registerotp', {
                    state: {
                        userinfo: userInfo,
                        userinput: state?.userinput,
                        shouldSendOtp: false
                    },
                });
            }
            setLoading(false);
            return setError({ error: false, message: "002: Something went wrong, please try again."})
        }
        catch (e) {
            logError('Register : handleSubmit', e);
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});      
        }
    };

    const handleSelect = (event: SelectChangeEvent<string>) => {
        setUserInfo({...userInfo, state: event.target.value})
    };

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
            sx={{
                boxShadow: 3,
                borderRadius: 2,
                px: 4,
                py: 3,
                marginTop: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            >
            <Box mt="5px" mb="25px" ml="5px">
                <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center"
                    borderRadius="10px"
                    overflow="hidden"
                    boxShadow={2}
                >
                    <img
                        alt="logo"
                        width="230px"
                        height="60px"
                        src={'../../assets/mmlogo.png'}
                        style={{ cursor: "pointer"}}
                    />
                </Box>
            </Box>
            <Typography variant="h5" sx={{fontWeight: 'bold'}} color='#ef233e'>
                {getLabelValue(lang, 'register.title')}
            </Typography>
            <Box 
                sx={{ textAlign: 'left' }}
                mt="15px" 
                mb="5px" 
                ml="5px" 
                mr="5px"
            >
                {getLabelValue(lang,'register.account.title')}
            </Box>
            <Box sx={{ mt: 1 }}>
                <Grid container spacing={1} sx={{ display: 'flex', alignItems: "flex-start" }}>
                <Grid item xs={12} sm={6}>
                    <Box 
                        sx={{ textAlign: 'left'  }}
                        mt="5px"
                        mb="5px"
                        ml="5px" 
                        mr="5px"
                    >
                        {getLabelValue(lang,'register.account.name')}
                    </Box>
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        type='text'
                        autoFocus
                        value={userInfo.name || ''}
                        onChange={(e) => setUserInfo({...userInfo, name: e.target.value})}
                    />
                    <Typography variant="h6" sx={{fontSize: '12px', fontStyle: 'italic', color: COLORS.PRIMARY}}>
                        {getLabelValue(lang,'register.account.name.helpertext')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box 
                        sx={{ textAlign: 'left'  }}
                        mt="5px"
                        mb="5px"
                        ml="5px" 
                        mr="5px"
                    >
                        {getLabelValue(lang,'register.account.email')}
                    </Box>
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        type="text"
                        value={userInfo.email || ''}
                        onChange={(e) => {
                            setUserInfo({...userInfo, email: e.target.value});
                            if (!validateEmail(e.target.value))
                                setValidateError({ ...validateError, email: true });
                            else
                                setValidateError({ ...validateError, email: false });
                        }}
                    />
                    {validateError.email &&
                        <Typography variant="h6" sx={{fontSize: '12px', fontStyle: 'italic', color: COLORS.PRIMARY}}>
                            {getLabelValue(lang,'register.account.invalid.email')}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box 
                        sx={{ textAlign: 'left'  }}
                        mt="5px"
                        mb="5px"
                        ml="5px" 
                        mr="5px"
                    >
                        {getLabelValue(lang,'register.account.lotno')}
                    </Box>
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        type="text"
                        value={userInfo.lotno|| ''}
                        onChange={(e) => setUserInfo({...userInfo, lotno: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Box 
                        sx={{ textAlign: 'left' }}
                        mt="5px"
                        mb="5px"
                        ml="5px" 
                        mr="5px"
                    >
                        {getLabelValue(lang,'register.account.address')}
                    </Box>
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        type="text"
                        id="firstAddress"
                        value={userInfo.address || ''}
                        onChange={(e) => setUserInfo({...userInfo, address: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12} sm={8.8}>
                    <Box 
                        sx={{ textAlign: 'left' }}
                        ml="5px" 
                        mr="5px"
                    >
                        {getLabelValue(lang,'register.account.address2')}
                    </Box>
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        type="text"
                        value={userInfo.address2 || ''}
                        onChange={(e) => setUserInfo({...userInfo, address2: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12} sm={3.2}>
                    <Box 
                        sx={{ textAlign: 'left' }}
                        ml="5px" 
                        mr="5px"
                    >
                        {getLabelValue(lang,'register.account.postcode')}
                    </Box>
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        type="text"
                        value={userInfo.postcode || ''}
                        onChange={(e) => {
                            setUserInfo({...userInfo, postcode: e.target.value});
                            if (!validatePostCode(e.target.value))
                                setValidateError({ ...validateError, postcode: true });
                            else
                                setValidateError({ ...validateError, postcode: false });
                        }}
                    />
                    {validateError.postcode &&
                        <Typography variant="h6" sx={{fontSize: '12px', fontStyle: 'italic', color: COLORS.PRIMARY}}>
                            {getLabelValue(lang,'register.account.invalid.postcode')}
                        </Typography>
                    }                    
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box 
                        sx={{ textAlign: 'left' }}
                        ml="5px" 
                        mr="5px"
                    >
                        {getLabelValue(lang,'register.account.city')}
                    </Box>
                    <TextField
                        margin="none"
                        required
                        fullWidth
                        type="text"
                        value={userInfo.city || ''}
                        onChange={(e) => setUserInfo({...userInfo, city: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Box 
                        sx={{ textAlign: 'left'  }}
                        ml="5px" 
                        mr="5px"
                    >
                        {getLabelValue(lang,'register.account.state')}
                    </Box>
                    <FormControl sx={{ mr: 0.5, width: '100%', borderRadius: '20px' }}>
                    <Select
                        required
                        labelId="states"
                        value={userInfo.state || ''}
                        onChange={handleSelect}
                        sx={{ borderRadius: '20px' }}
                        MenuProps={{
                            MenuListProps: {
                                style: { maxHeight: '150px' },
                            },
                        }}
                    >
                        {states.map((i: any) => (
                            <MenuItem key={i} value={i}>{i}</MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
                <Grid container spacing={1} sx={{ display: 'flex', alignItems: "flex-start" }}>
                <Grid item>
                    <Checkbox 
                        checked={terms}
                        onChange={(e) => setTerms(e.target.checked)}
                    />
                </Grid>
                <Grid item xs>
                    <Typography variant="h6" sx={{
                        fontSize: '13px',
                        fontStyle: 'italic',
                        textAlign: 'justify',
                        marginTop: '10px',
                        marginLeft: '5px'
                    }}>
                    {getLabelValue(lang, 'register.tnc')}
                    <Link href="https://mobile-money.com.my/Terms_Customer.pdf" target="_blank" rel="noopener noreferrer" sx={{
                        fontWeight: 'bold',
                        color: colors.blue[800]
                    }}>
                        https://mobile-money.com.my/Terms_Customer.pdf
                    </Link>
                    </Typography>
                </Grid>
                </Grid>
                {(state.hasOwnProperty("rba") && state.rba === true || state.hasOwnProperty("wa") && state.wa === true) ? (
                    <Grid container spacing={1} sx={{ display: 'flex', alignItems: "flex-start" }}>
                    <Grid item>
                        <Checkbox
                            checked={userToggleUpgradeTNC}
                            onChange={(e) => setUserToggleUpgradeTNC(e.target.checked)}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6" sx={{
                            fontSize: '13px',
                            fontStyle: 'italic',
                            textAlign: 'justify',
                            marginTop: '10px',
                            marginLeft: '5px'
                        }}>
                            {getLabelValue(lang, 'register.upgrade.tnc')}
                        <Link href="https://mobile-money.com.my/Terms_Customer2022.pdf" target="_blank" rel="noopener noreferrer" sx={{
                            fontWeight: 'bold',
                            color: colors.blue[800]
                        }}>
                            https://mobile-money.com.my/Terms_Customer2022.pdf
                        </Link>
                        </Typography>
                    </Grid>
                    </Grid>
                ) : (
                    null
                )}
                </Grid>
                {error.error && (
                    <Typography
                        sx={{
                        mt: '10px',
                        textAlign: 'center',
                        color: colors.primary[500],
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        }}
                    >
                        {error.message || ''}
                    </Typography>
                )}              
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    sx={{ mt: 1, mb: 1 }}
                    onClick={async () => {
                        if (state.hasOwnProperty("rba") && state.rba === true)
                            await handleAccountCreation();
                        else if (state.hasOwnProperty("wa") && state.wa === true)
                            await handleWhatsappOnboard();
                        else
                            await handleSendOTPToUser();
                    }}
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                >
                    {getLabelValue(lang, 'login.account.button.next')}
                </Button>
                <Button
                    type="submit"
                    fullWidth
                    onClick={() => navigate(-1)}
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 1 }}
                >
                    {getLabelValue(lang, 'login.account.button.goback')}
                </Button>
            </Box>
            </Box>
        </Container>
        <Footer />
        </ThemeProvider>
    );
}

const theme = createTheme({
    palette: {
        secondary: {
        //MMApp signature red 
        main: '#ef233e',
        },
        primary: {
        main: '#212121',
        }
    }
});

export default RegisterPageTwo;