import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import Header from "../../components/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import { getLabelValue } from "../../utils";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import QRCode from 'qrcode.react';
import { useLocation } from "react-router-dom";

const QRCodePage = () => {
    const isMobile: boolean = useCheckIsMobile();
    const {state} = useLocation();
    const value = state?.value;
    const labelKey = state?.labelKey;
    const name = state?.name;
    const { lang } = useSelector((state: RootState) => state.user);

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={"QR Code"} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <Container sx={{ textAlign: 'center' }}>
                                    <QRCode
                                        value={value ? String(value || "") : ""}
                                        size={isMobile ? 150 : 250}
                                    />
                                    <Typography variant={"h4"} sx={{ mt: 2, wordWrap: 'break-word', textAlign: 'center', fontWeight: 'bold' }}>
                                        {name ? name : ""}
                                    </Typography>                                    
                                    <Typography variant={"h5"} sx={{ mt: 2, wordWrap: 'break-word', textAlign: 'center' }}>
                                        {getLabelValue(lang, labelKey)}
                                    </Typography>                                
                                </Container>                            
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        m: 2,
        borderRadius: 3,
        px: 1
    }
})

export default QRCodePage;
