import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { BORNEO_FETCH_PRODUCTS, BORNEO_FETCH_PRODUCTS_FAILED, BORNEO_FETCH_PRODUCTS_SUCCESS, BORNEO_FETCH_FEATURED_PRODUCTS, BORNEO_FETCH_FEATURED_PRODUCTS_FAILED, BORNEO_FETCH_FEATURED_PRODUCTS_SUCCESS, BORNEO_FETCH_CATEGORIES, BORNEO_FETCH_CATEGORIES_FAILED, BORNEO_FETCH_CATEGORIES_SUCCESS, BORNEO_UNMOUNT } from "./type";

// products
export const fetchProducts = (lang: string, user: any, category: any, subcategory: any) => {
    return async (dispatch: (arg0: { type: string; payload?: string | { results: any; }; }) => void) => {
        try {
            dispatch({ type: BORNEO_FETCH_PRODUCTS });
            let input: any = {
                ver: CONFIG.ver,
                act: 60220,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                m1: "Fetch Borneo Products",
                products_action_name: "read",
                lang: lang,
                category: category,
                subcategory: subcategory,
                community: user?.community
            };
            let headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
            if (!resultsFromAPI)
                return dispatch({
                    type: BORNEO_FETCH_PRODUCTS_FAILED,
                    payload: "001: Something went wrong, try again",
                });
            if (resultsFromAPI.error !== undefined)
                return dispatch({
                    type: BORNEO_FETCH_PRODUCTS_FAILED,
                    payload: "002: Something went wrong, please check your Internet Connection and try again",
                });

            if (!resultsFromAPI.hasOwnProperty("results"))
                return dispatch({
                    type: BORNEO_FETCH_PRODUCTS_FAILED,
                    payload: "003: Something went wrong, try again",
                });
            let results = resultsFromAPI.results;
            console.log("resultssd", results);
            if (results.hasOwnProperty("code")) {

                if (results.code !== 0) {

                    return dispatch({
                        type: BORNEO_FETCH_PRODUCTS_FAILED,
                        payload: `[${results.code}] : ${results.message || ""}`,
                    });
                }

            }

            return dispatch({
                type: BORNEO_FETCH_PRODUCTS_SUCCESS,
                payload: { results },
            });

        } catch (e) {
            console.log(e);
            return dispatch({
                type: BORNEO_FETCH_PRODUCTS_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};


export const fetchFeaturedProducts = (lang: string, user: any) => {
    return async (dispatch: (arg0: { type: string; payload?: string | { results: any; }; }) => void) => {
        try {

            dispatch({ type: BORNEO_FETCH_FEATURED_PRODUCTS });
            let input: any = {
                ver: CONFIG.ver,
                act: 60220,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                m1: "Fetch Borneo Featured Products",
                action_name: "featureprod",
                lang: lang,
                category: '',
                subcategory: '',
                community: user?.community,

            };
            let headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
            if (!resultsFromAPI)
                return dispatch({
                    type: BORNEO_FETCH_FEATURED_PRODUCTS_FAILED,
                    payload: "001: Something went wrong, try again",
                });
            if (resultsFromAPI.error !== undefined)
                return dispatch({
                    type: BORNEO_FETCH_FEATURED_PRODUCTS_FAILED,
                    payload: "002: Something went wrong, please check your Internet Connection and try again",
                });

            if (!resultsFromAPI.hasOwnProperty("results"))
                return dispatch({
                    type: BORNEO_FETCH_FEATURED_PRODUCTS_FAILED,
                    payload: "003: Something went wrong, try again",
                });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {

                if (results.code !== 0) {

                    return dispatch({
                        type: BORNEO_FETCH_FEATURED_PRODUCTS_FAILED,
                        payload: `[${results.code}] : ${results.message || ""}`,
                    });
                }

            }

            return dispatch({
                type: BORNEO_FETCH_FEATURED_PRODUCTS_SUCCESS,
                payload: { results },
            });

        } catch (e) {
            console.log(e);
            return dispatch({
                type: BORNEO_FETCH_FEATURED_PRODUCTS_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const fetchCategories = (lang: string, user: any) => {
    return async (dispatch: (arg0: { type: string; payload?: string | { results: any; }; }) => void) => {
        try {
            dispatch({ type: BORNEO_FETCH_CATEGORIES });
            let input: any = {
                ver: CONFIG.ver,
                act: 60221,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                lang: lang, 
                community: user?.community,
            };
            let headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
            if (!resultsFromAPI)
                return dispatch({
                    type: BORNEO_FETCH_CATEGORIES_FAILED,
                    payload: "001: Something went wrong, try again",
                });
            if (resultsFromAPI.error !== undefined)
                return dispatch({
                    type: BORNEO_FETCH_CATEGORIES_FAILED,
                    payload: "002: Something went wrong, please check your Internet Connection and try again",
                });

            if (!resultsFromAPI.hasOwnProperty("results"))
                return dispatch({
                    type: BORNEO_FETCH_CATEGORIES_FAILED,
                    payload: "003: Something went wrong, try again",
                });
            let results = resultsFromAPI.results;

            if (results.hasOwnProperty("code")) {
                if (results.code !== 0) {
                    return dispatch({
                        type: BORNEO_FETCH_CATEGORIES_FAILED,
                        payload: `[${results.code}] : ${results.message || ""}`,
                    });
                }

            }

            return dispatch({
                type: BORNEO_FETCH_CATEGORIES_SUCCESS,
                payload: { results },
            });

        } catch (e) {
            console.log(e);
            return dispatch({
                type: BORNEO_FETCH_CATEGORIES_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const unmountBorneo = () => {
    return { type: BORNEO_UNMOUNT };
};
