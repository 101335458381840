export const CONFIG = {
    "apiUrlTest": "https://uat-mmappwebapi.mobile-money.com.my/api",
    "apiUrlLive": "https://api-mmappweb.mobile-money.com.my/api",
    "ekycUrl": "https://mmregwebtest.mobile-money.com.my/home",
    "ekycUrlLive": "https://mmregistrationweb.mobile-money.com.my/home",
    "referralURL": "https://mmapp.mobile-money.com/r",
    "domainURL": "https://mmapp.mobile-money.com.my/mmapp",
    "imageUploadURL": "https://mmwalletapp.ezeemoney.biz:4322/createPhoto",
    "m1ReturnUrlTest": "https://tpg-test.mobile-money.com.my/payment/payment-gateway-result",
    "m1ReturnUrlLive": "https://tpg.mobile-money.com.my/payment/payment-gateway-result",
    "env": "test",
    "ver": "w0.0.4",
    "app_id": 'YlhIEYf1Xi5V2PXQNeCBLg=='
}
