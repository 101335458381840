import { CONFIG } from "../config";
import { TUser } from "../type";
import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { RECEIPT_TRANSACTIONS_FETCH, RECEIPT_TRANSACTIONS_FETCH_FAILED, HOME_UPDATE_WALLET_BALANCE, RECEIPT_TRANSACTIONS_FETCH_SUCCESS, RECEIPT_GET_TRANSACTION, RECEIPT_GET_TRANSACTION_FAILED, RECEIPT_GET_TRANSACTION_SUCCESS, RECEIPT_UNMOUNT, RECEIPT_CHANGE_QUERY_DATE, RECEIPT_SCREEN_UNMOUNT } from "./type";

export const receiptTransactionsFetch = (lang: string, user: TUser | undefined, act_id: number, numberOfDays: number) => {
    return async (dispatch: any) => {
        try{
            dispatch({ type: RECEIPT_TRANSACTIONS_FETCH });
            if (user === undefined)
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "001: Oops Something went wrong, Login in again"})
            if (act_id === undefined)
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "002: Oops Something went wrong, Login in again"})
    
            let input: any = {
                ver: CONFIG.ver,
                act: String(act_id),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: numberOfDays,
                m1: "All Transaction History",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI)
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "003: Something went wrong, try again"});
            if (resultsFromAPI.error !== undefined)
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "004: Something went wrong, please check your Internet Connection and try again"});
            if (!resultsFromAPI.hasOwnProperty("results"))        
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "005: Something went wrong, try again"});
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0)
                    return dispatch({
                        type: RECEIPT_TRANSACTIONS_FETCH_FAILED,
                        payload: `[${results.code}] : ${results.message || ""}`
                    });
        
                if (results.hasOwnProperty("balance"))
                    dispatch({ type: HOME_UPDATE_WALLET_BALANCE,
                    payload: {balance: results.balance, pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : undefined } });
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_SUCCESS, payload: results.transactions || [] })
            }
            return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "006: Something went wrong, try again"});
    
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "OOPS SOMETHING WENT WRONG"})
        }
    }
}
  
export const getTransactionReceipt = (trans_id: string, gl_id: string, user: TUser | undefined, lang: string) => {
    return async (dispatch: any) => {
        try{
            dispatch({ type: RECEIPT_GET_TRANSACTION });
            if (user === undefined)
            return dispatch({ type: RECEIPT_GET_TRANSACTION_FAILED, payload: "001: Oops Something went wrong, Login in again"})
            if (trans_id === undefined)
            return dispatch({ type: RECEIPT_GET_TRANSACTION_FAILED, payload: "002: Oops Something went wrong, Login in again"})
    
            let input: any = {
                ver: CONFIG.ver,
                act: String(20012),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: String(trans_id),
                p2: String(gl_id),
                m1: "Get Transaction Receipt",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI)
                return dispatch({ type: RECEIPT_GET_TRANSACTION_FAILED, payload: "003: Something went wrong, try again"});
            if (resultsFromAPI.error !== undefined)
                return dispatch({ type: RECEIPT_GET_TRANSACTION_FAILED, payload: "004: Something went wrong, please check your Internet Connection and try again"});
            if (!resultsFromAPI.hasOwnProperty("results"))
                return dispatch({ type: RECEIPT_GET_TRANSACTION_FAILED, payload: "005: Something went wrong, try again"});
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
            if (results.code !== 0)
                return dispatch({
                    type: RECEIPT_GET_TRANSACTION_FAILED,
                    payload: `[${results.code}] : ${results.message || ""}`
                });
            
                return dispatch({ type: RECEIPT_GET_TRANSACTION_SUCCESS, payload: results.hasOwnProperty("receipt") ? results.receipt : undefined })
            }
            return dispatch({ type: RECEIPT_GET_TRANSACTION_FAILED, payload: "006: Something went wrong, try again"});
    
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: RECEIPT_GET_TRANSACTION_FAILED, payload: "OOPS SOMETHING WENT WRONG"})
        }
    }
}
  
export const unMountRetrievedReceipt = () => {
    return { type: RECEIPT_UNMOUNT }
}

export const changeQueryDateAndFetchNewTransactions = (days = 0, user: TUser | undefined, act_id: number, lang: string) => {
    return async (dispatch: any) => {
        try{
            dispatch({ type: RECEIPT_CHANGE_QUERY_DATE, payload: days });
    
            if (user === undefined)
            return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "001: Oops Something went wrong, Login in again"})
            if (act_id === undefined)
            return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "002: Oops Something went wrong, Login in again"})
    
            let input: any = {
                ver: CONFIG.ver,
                act: String(act_id),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: days,
                m1: "All Transaction History",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI)
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "003: Something went wrong, try again"});
            if (resultsFromAPI.error !== undefined)
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "004: Something went wrong, please check your Internet Connection and try again"});
            if (!resultsFromAPI.hasOwnProperty("results"))
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "005: Something went wrong, try again"});
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0)
                    return dispatch({
                    type: RECEIPT_TRANSACTIONS_FETCH_FAILED,
                    payload: `[${results.code}] : ${results.message || ""}`
                    });
        
                if (results.hasOwnProperty("balance"))
                    dispatch({ type: HOME_UPDATE_WALLET_BALANCE,
                    payload: {balance: results.balance, pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : undefined } });
        
                return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_SUCCESS, payload: results.transactions || [] })
            }
            return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "006: Something went wrong, try again"});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: RECEIPT_TRANSACTIONS_FETCH_FAILED, payload: "OOPS SOMETHING WENT WRONG"})
        }
    }
  }
  
export const unMountReceiptScreen = () => {
    return { type: RECEIPT_SCREEN_UNMOUNT}
}
  