import {
    EKYC_CHECK,
    EKYC_CHECK_FAILED,
    EKYC_CHECK_SUCCESS,
    EKYC_UNMOUNT,
    EKYC_CHECK_SHOW_EKYC_PAGE,
    EKYC_VKYC_REQUIRE
} from "../actions/type";
  
const INITIAL_STATE = {
    ekycLoader: false,
    ekycError: false,
    ekycMessage: '',
    ekycVerified: false,
    ekycShowPage: false,
    ekycShowVideo: false
}
  
export default (state = INITIAL_STATE, action: any) => {
    switch (action.type){
        case EKYC_CHECK:
            return { ...INITIAL_STATE, ekycLoader: true }
        case EKYC_CHECK_SUCCESS:
            return { ...INITIAL_STATE, ekycVerified: true };
        case EKYC_CHECK_FAILED:
            return { ...INITIAL_STATE, ekycError: true, ekycMessage: action.payload, ekycShowPage: false };
        case EKYC_CHECK_SHOW_EKYC_PAGE:
            return { ...INITIAL_STATE, ekycError: true, ekycMessage: action.payload, ekycShowPage: true };
        case EKYC_VKYC_REQUIRE:
            return { ...INITIAL_STATE, ekycError: true, ekycMessage: action.payload, ekycShowVideo: true};
        case EKYC_UNMOUNT:
            return { ...INITIAL_STATE, ekycVerified: state.ekycVerified };
        default:
            return state;
    }
}
  