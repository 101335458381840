import React, { useEffect, useRef, useState } from "react";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI, getLabelValue, getLastUpdatedTime } from "../utils";
import { CircularProgress, Box, Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@mui/material";
import moment from "moment";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import useCheckIsMobile from "../hooks/useCheckIsMobile";

const Notification = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({error: false, message: ""});
    const [notifications, setNotifications] = useState([]);
    const { lang } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            getNotifications();
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])
    
    const getNotifications = async () => {
        try{
            setLoading(true);
            let input: any = {
                ver: CONFIG.ver,
                act: String(952),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({error: true, message: '001: Something went wrong, please try again'});
            }
            if (resultsFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({error: true, message: '002: Something went wrong, please check your Internet Connection and try again'});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({error: true, message: '003: Something went wrong, try again'});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0){
                    setLoading(false);
                    return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}` });
                }   
                setNotifications(massageReturnedData(results?.data));
                setLoading(false);
                return setError({ error: false, message: ''});
            }
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({error: true, message: 'Oops Something went wrong'});
        }
    }

    const massageReturnedData = (notificationsData = []) => {
        try{
            if (notificationsData.length === 0) return [];
            let newNotificationData : any = [];
            notificationsData.map((i : any,v) => {
                if (i.hasOwnProperty("body")){
                    if (i.body.hasOwnProperty("data")){
                        if (i.body.data.hasOwnProperty("notifee")){
                                newNotificationData.push({
                                idx: i?.idx,
                                dtCreated: i?.dtCreated,
                                notification: JSON.parse(i.body.data.notifee)
                            })
                        }   
                    }
                }
            });
            return newNotificationData;
        }
        catch (e) {
          console.log(e);
          return [];
        }
    }

    const handleRowClick = (item: any) => {
        console.log(item)
        if (item.hasOwnProperty("notification") && item.notification.hasOwnProperty("data")) {
            if (item.notification.data.hasOwnProperty("screen")) {
                try {
                    if (item.notification.data.screen === 'RetrievedReceipt'){
                        navigate('/retrievedreceipt', { state: { item: {...item.notification.data, shouldPop: false} } })
                    }
                    if (item.notification.data.screen === 'PointHistory'){
                        navigate('/kita/history')
                    }
                } catch (e) {
                    console.log("FAILED TO NAVIGATE");
                }
            }
        }
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const notificationsRows = notifications
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    .map((item: any, index: any) => {
        const isClickable = item.notification?.data?.screen;
        return (
            <TableRow 
                key={index} 
                onClick={() => isClickable && handleRowClick(item)}
                style={{ 
                    cursor: isClickable ? 'pointer' : 'default',
                    backgroundColor: isClickable ? '#f5f5f5' : 'inherit',
                    fontWeight: isClickable ? 'bold' : 'normal' 
                }}
            >
                <TableCell>{moment(item.dtCreated).format("MMM DD, hh:mm A")}</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>{item.notification.title || ""}</TableCell>
                <TableCell>{item.notification.body || ""}</TableCell>
            </TableRow>
        );
    });

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'notifications.header.title')} subtitle={getLastUpdatedTime(lang)} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <TableContainer>
                                    <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={3}>{getLabelValue(lang, 'kirim.moneypin.time')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {error.error && (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">{error.message || ''}</TableCell>
                                        </TableRow>                                                  
                                    )}
                                    {loading && (
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <CircularProgress color="primary" />
                                            </TableCell>
                                        </TableRow>                                               
                                    )}       
                                    {notificationsRows}
                                    </TableBody>
                                    </Table>
                                </TableContainer>      
                                <TablePagination
                                    component="div"
                                    count={notifications.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default Notification;