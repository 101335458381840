import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { getTransactionReceipt, unMountRetrievedReceipt } from "../../actions/ReceiptAction";
import { tokens } from "../../theme";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getLabelValue } from "../../utils";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";

const colors = tokens();
const RetrievedReceipt = () => {
    const {state} = useLocation();
    const item: any = state.item;
    const isMobile: boolean = useCheckIsMobile();
    let trans_id = item?.id;
    let gl_id = item?.gl_id;
    let pop = item?.shouldPop;
    const { lang, user } = useSelector((state: RootState) => state.user);
    const { receiptInfo, loading, error, message } = useSelector((state: RootState) => state.receipt);

    const navigate = useNavigate();
    
    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(getTransactionReceipt(trans_id, gl_id, user, lang));
        }   
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            dispatch(unMountRetrievedReceipt());
            shouldLockEffect.current = true;
        }
    }, [])

    const renderReceiptData = () => {
        let receiptData: any = [];
        try {
            if (receiptInfo) {
                receiptData = receiptInfo[lang] ? receiptInfo[lang] : receiptInfo['bm'];
            }
        } catch (e) {
            console.log('Error processing receipt data:', e);
        }   
        
        if (!receiptData.hasOwnProperty("data")) return null;

        return(
            <>
                {receiptData.data.map((i: any, v: any) => (
                <Grid container key={v} alignItems="center" sx={{ m: 2, my: 1 }}>
                    <Grid item xs={6} md={5} sx={{ py: 0.5 }} style={{ textAlign: 'left' }}>
                        <Typography variant="h6" sx={styles(isMobile).labelStyle}>
                            {i?.label || ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} md={6} sx={{ py: 0.5 }} style={{ textAlign: 'right' }}>
                        <Typography variant="h6" sx={styles(isMobile).valueStyle}>
                            {i?.value || ''}
                        </Typography>
                    </Grid>
                    {i.url ? (
                    <Grid2
                        xs={12} md={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            variant="contained"
                            sx={[styles(isMobile).submitButtonStyle, {px: 5, }]}
                            onClick={() => {
                                if (i.url) {
                                    window.open(i.url, '_blank');
                                }
                            }}
                        >
                            {i?.action || ''}
                        </Button>
                    </Grid2>
                    ) : (
                        null
                    )}
                </Grid>                
                ))}
                <Grid2
                    xs={12} 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ m: 2, my: 1}}
                >
                    <Typography variant="h6" sx={styles(isMobile).valueStyle}>
                        {receiptData.remarks || ''}
                    </Typography>
                </Grid2>
            </>
        )
    }

    const renderMoneyPinReceiptInformation = () => {
        let receiptData: any = [];
        try {
            if (receiptInfo) {
                receiptData = receiptInfo[lang] ? receiptInfo[lang] : receiptInfo['bm'];
            }
        } catch (e) {
            console.log('Error processing receipt data:', e);
        }   
        
        if (!receiptData.hasOwnProperty("data")) return null;

        return(
            <>
                {receiptData.data.map((i: any, v: any) => (
                <Grid container key={v} alignItems="center" sx={{ m: 2, my: 1 }}>
                    <Grid item xs={6} md={5} sx={{ py: 0.5 }} style={{ textAlign: 'left' }}>        
                        <Typography variant="h6" sx={styles(isMobile).labelStyle}>
                            {i?.label || ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} md={6} sx={{ py: 0.5 }} style={{ textAlign: 'right' }}>
                        {i.label === 'Status' ? (
                            <Typography variant="h6" sx={{color: "red", fontWeight: 600,  fontSize: 13, wordBreak: 'break-word'}}>
                                {i?.value || ''}
                            </Typography>
                        ) : (
                            <Typography variant="h6" sx={styles(isMobile).valueStyle}>
                                {i?.value || ''}
                            </Typography>
                        )}
                    </Grid>
                    <Grid2
                        xs={5} md={6}
                    >
                        <Typography variant="h6" sx={styles(isMobile).valueStyle}>
                            {receiptData.remarks || ''}
                        </Typography>
                    </Grid2>                    
                </Grid>  
                ))}          
                <Grid2
                    xs={12} 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ m: 2, my: 1}}
                >
                    <Typography variant="h6" sx={styles(isMobile).valueStyle}>
                        {receiptData.remarks || ''}
                    </Typography>
                </Grid2>                
            </>
        )
    }

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "75vh" }}>
                <CircularProgress color="primary" />
            </Box>
        )
    }

    if (error) {
        return (
            <Box m="20px">
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={5}
                        mdOffset={3}
                    >
                        <Card
                            sx={styles(isMobile).cardStyle}
                            elevation={15}>
                            <CardContent>
                                <Grid2
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                                    {message || ''}
                                </Typography>                                
                                </Grid2>
                            </CardContent>
                            <Grid2
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={[styles(isMobile).submitButtonStyle, {backgroundColor: colors.green[700]}]}
                                    onClick={() => {
                                        dispatch(unMountRetrievedReceipt());
                                        if (pop){
                                            navigate('/');
                                        }
                                        else {
                                            navigate(-1);
                                        }
                                    }}
                                >
                                    OK
                                </Button>
                            </Grid2>                        
                        </Card>
                    </Grid2>
                </Grid2>
            </Box>            
        )
    }
    
    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={5}
                        mdOffset={3}
                    >
                        <Card
                            sx={styles(isMobile).cardStyle}
                            elevation={15}>
                            <CardContent>
                                {(receiptInfo && receiptInfo.isMoneyPin !== '1') && 
                                <>
                                    <Grid2
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <CheckCircleIcon
                                            sx={{
                                                color: colors.green[700],
                                                fontSize: 70
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                                        {`RM ${receiptInfo ? receiptInfo.amount || '' : ''}`}
                                    </Typography>                                
                                    </Grid2>
                                    <Grid2
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                        {getLabelValue(item.lang, item ? item.messageKey || '' : '')}
                                    </Typography>                                
                                    </Grid2>
                                    {renderReceiptData()}                                   
                                </>        
                                }
                                {(receiptInfo && receiptInfo.isMoneyPin === '1') && 
                                <>
                                    <Grid container alignItems="flex-start">
                                        {receiptInfo.MMAddress1 && (
                                        <Grid item xs={12}>
                                            <Typography variant="h6">{receiptInfo.MMAddress1}</Typography>
                                        </Grid>
                                        )}
                                        {receiptInfo.MMAddress2 && (
                                        <Grid item xs={12}>
                                            <Typography variant="h6">{receiptInfo.MMAddress2}</Typography>
                                        </Grid>
                                        )}
                                        {receiptInfo.MMAddress3 && (
                                        <Grid item xs={12}>
                                            <Typography variant="h6">{receiptInfo.MMAddress3}</Typography>
                                        </Grid>
                                        )}
                                        {receiptInfo.MMAddress4 && (
                                        <Grid item xs={12}>
                                            <Typography variant="h6">{receiptInfo.MMAddress4}</Typography>
                                        </Grid>
                                        )}
                                    </Grid>
                                    {renderMoneyPinReceiptInformation()}    
                                    {receiptInfo.ReceiptURL ? (
                                    <Grid2
                                        xs={12} md={12}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Button
                                            variant="contained"
                                            sx={[styles(isMobile).submitButtonStyle, {px: 5, }]}
                                            onClick={() => {
                                                if (receiptInfo.ReceiptURL) {
                                                    window.open(receiptInfo.ReceiptURL, '_blank');
                                                }
                                            }}
                                        >
                                            {getLabelValue(lang, "kirim.moneypin.receipt")}
                                        </Button>
                                    </Grid2>
                                    ) : (
                                        null
                                    )}                                               
                                </>
                                }
                                <Grid2
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={[styles(isMobile).submitButtonStyle, {backgroundColor: colors.green[700]}]}
                                        onClick={() => {
                                            dispatch(unMountRetrievedReceipt());
                                            if (pop){
                                                navigate('/');
                                            }
                                            else {
                                                navigate(-1);
                                            }
                                        }}
                                    >
                                        OK
                                    </Button>
                                </Grid2>                            
                            </CardContent>          
                        </Card>
                    </Grid2>
                </Grid2>
            </Box>  
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mt: 2,
        borderRadius: 2,
    },
    labelStyle: {
        color: colors.gray[300],
        fontWeight: 400,
        fontSize: 13
    },
    valueStyle: {
        colors: colors.black[900],
        fontWeight: 600,
        fontSize: 13,
        wordBreak: 'break-word'
    },
    submitButtonStyle: {
        px: 4,
        py: 0.9,
        mt: 2,
        fontWeight: 'bold'
    }
})

export default RetrievedReceipt;
