import { Box, Button, Divider, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { RootState, useAppDispatch } from "../../store/store";
import { getBalance } from "../../actions/AccountAction";
import { CONFIG } from "../../config";
import { addUserObjectToRequest, callBackendAPI, numberWithCommas } from "../../utils";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const colors =  tokens();

const BorneoLanding = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [coins, setCoins] = useState(0);
    const [categories, setCategories] = useState([]);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [promotions, setPromotions] = useState([]);
    const { balance } = useSelector((state: RootState) => state.account);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(getBalance(lang, user));
            fetchData();
            fetchPromotions();
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])

    const fetchData = async () : Promise<void> => {
        try {
            let input: any = {
                ver: CONFIG?.ver,
                act: 60235,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                lg: lang,
                community: user?.community,
                products_action_name: "featureprod",
                coins_action_name: "read",
            };
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return;
            }
            if (resultsFromAPI.error !== undefined) {
                return;
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return;
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0) {
                    return;
                }
                setCoins(results?.coins?.data?.borneokakicredit || 0)
                setCategories(results?.categories?.data || [])
                setFeaturedProducts(results?.featured?.data || [])
                return;
            }
        } catch (e) {
            console.log(e);
        }
    }
    
    const fetchPromotions = async () : Promise<void> => {
        try {
            let input: any = {
                ver: CONFIG?.ver,
                act: String(60230),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: user?.community,
                lg: lang,
                m1: 'Get promotions'
            };
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                return;
            }
            if (resultsFromAPI.error !== undefined){
                return;
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                return;
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0) {
                    return;
                }
                return setPromotions(results?.results[0]?.data || [])
            }
        } catch (e) {
            console.log(e);
        }
    }
    
    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                {!isMobile &&             
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Header title={"Borneokaki"} subtitle={""} />
                    </Box>
                }
                <Grid2
                    container
                >
                    <Grid2
                        xs={12}
                    >
                        {isMobile && 
                            <Box sx={{ my: 1 }} display='flex' justifyContent={'center'} alignItems={'center'}>
                                <img
                                    src={user?.community?.banner}
                                    style={{
                                        width: '100%', 
                                        height: 'auto',
                                        maxHeight: isMobile ? 'auto' : '200px',
                                        borderRadius: 10
                                    }}
                                />
                            </Box>                       
                        }
                        <Box display="flex" justifyContent="space-between" alignItems="center" p={2} borderRadius={2} boxShadow={2} width={isMobile ? 'auto' : '400px'}>
                            <Box>
                                <Typography variant="body1" color={colors.primary[500]} sx={{ fontWeight: 'bold' }}>
                                        {"E-Wallet Balance:"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    color={colors.black[900]}
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {numberWithCommas(balance)}
                                </Typography>
                            </Box>
                            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                            <Button 
                                // onClick={onCreditClick} 
                                sx={{ textTransform: 'none' }}>
                                <Box>
                                    <Typography variant="body1" color={colors.primary[500]} sx={{ fontWeight: 'bold' }}>
                                        {user?.communityName} Credit:
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color={colors.black[900]}
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        {coins.toFixed(2)}
                                    </Typography>
                                </Box>
                            </Button>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
                                Offer & Promotions
                            </Typography>
                            <Box
                                sx={{
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    '&::-webkit-scrollbar': {
                                        display: 'none'
                                    },
                                    cursor: 'pointer' 
                                }}
                            >
                                {promotions.map((promotion: any, index: React.Key) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'inline-flex',
                                            flexDirection: 'column',
                                            width: isMobile ? 200 : 300,
                                            marginRight: index !== categories.length - 1 ? 2 : 0, // Add spacing between items, except the last one
                                            textAlign: 'center',
                                            '&:last-child': {
                                                marginRight: 0,
                                            }
                                        }}
                                    >
                                        <img
                                            src={promotion.banner}
                                            style={{
                                                width: '100%',
                                                objectFit: 'cover',
                                                height: isMobile ? 100 : 150,
                                                borderRadius: 10,
                                            }}
                                        />
                                        <Typography variant="body1" noWrap sx={{ fontWeight: 'bold' }}>
                                            {promotion.title}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1}}>
                                Categories
                            </Typography>
                            <Box
                                sx={{
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    '&::-webkit-scrollbar': {
                                        display: 'none'
                                    }
                                }}
                            >
                                {categories.map((category: any, index: React.Key) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'inline-block',
                                            width: isMobile ? 120 : 200,
                                            marginRight: index !== categories.length - 1 ? 2 : 0,
                                            textAlign: 'center',
                                            whiteSpace: 'normal',
                                            verticalAlign: 'top',
                                            '&:last-child': {
                                                marginRight: 0,
                                            },
                                            cursor: 'pointer' 
                                        }}
                                        onClick={() => navigate('/borneokaki/subcategory')}
                                    >
                                        <img
                                            src={category.image ? category.image : 'https://via.placeholder.com/80x80'}
                                            style={{
                                                width: '100%',
                                                objectFit: 'cover',
                                                height: isMobile ? 100 : 150,
                                            }}
                                        />
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
                                            {category.title}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        {featuredProducts.length !== 0 &&
                            <Box sx={{ my: 2 }}>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1}}>
                                    Featured Products
                                </Typography>
                                <Box
                                    sx={{
                                        overflowX: 'auto', 
                                        whiteSpace: 'nowrap',
                                        '&::-webkit-scrollbar': {
                                            display: 'none'
                                        },
                                        cursor: 'pointer'
                                    }}
                                >
                                {featuredProducts.map((product: any, index: React.Key) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'inline-block',
                                            width: isMobile ? 120 : 200,
                                            marginRight: index !== featuredProducts.length - 1 ? 2 : 0,
                                            textAlign: 'center',
                                            whiteSpace: 'normal',
                                            verticalAlign: 'top',
                                            '&:last-child': {
                                                marginRight: 0,
                                            }
                                        }}
                                        onClick={() => {navigate('/borneokaki/productdetails', { state: { product: product }} )}}
                                    >
                                        <img
                                            src={product.image[0].url}
                                            alt={product.title}
                                            style={{
                                                width: '100%',
                                                objectFit: 'cover',
                                                height: isMobile ? 100 : 150,
                                            }}
                                        />
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
                                            {product.title}
                                        </Typography>
                                        <Typography variant="body1">
                                            RM {product.price.toFixed(2)}
                                        </Typography>
                                    </Box>
                                ))}
                                </Box>
                            </Box>
                        }
                    </Grid2>
                </Grid2> 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default BorneoLanding;

