import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { useRef, useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchCategories } from "../../actions/BorneoAction";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { tokens } from "../../theme";

const colors =  tokens();

const SubCategoryPage = () => {
    const {state} = useLocation();
    const isMobile: boolean = useCheckIsMobile();
    const [expandedPanels, setExpandedPanels] = useState<number[]>([]);
    const { categories } = useSelector((state: RootState) => state.borneo);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    
    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(fetchCategories(lang, user));
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])

    const handleChange = (panelIndex: number) => () => {
        const isExpanded = expandedPanels.includes(panelIndex);
        if (isExpanded) {
            setExpandedPanels(expandedPanels.filter((index) => index !== panelIndex));
        } else {
            setExpandedPanels([...expandedPanels, panelIndex]);
        }
    };

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >            
                <Box display="flex" alignItems="center">
                    <IconButton onClick={() => navigate(-1)} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography                 
                        variant="h3"
                        color={colors.black[500]}
                        fontWeight="bold"
                    >
                        Categories
                    </Typography>
                </Box>      
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Box
                            sx={styles(isMobile).cardStyle}
                        >
                            {categories.map((category: any, index: number) => {
                                const subCategories: any = JSON.parse(category.subCategory);
                                return(
                                    <Accordion key={index} expanded={expandedPanels.includes(index)} onChange={handleChange(index)} sx={{
                                        boxShadow: 2,
                                        marginTop: 1,
                                        '&.Mui-expanded': {
                                            backgroundColor: 'white',
                                        },
                                    }}>
                                        <AccordionSummary sx={{ marginTop: 3, minHeight: "40px" }}
                                            expandIcon={<ExpandMoreIcon />}
                                            id={`category-${index}`}
                                        >
                                            <Typography variant='h5'>
                                                {category.title}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                                                {subCategories.length > 0 ? (
                                                    subCategories.map((subCat: any) => (
                                                        <Grid item key={subCat.sub_id} xs={2} sm={4} md={4}>
                                                            <Button
                                                                onClick={() => {navigate('/borneokaki/product', { state:{ category: category.id, subCategory: subCat.sub_id } }) }}
                                                                style={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}
                                                            >
                                                                <img src={subCat.image} style={{ width: 100, height: 100 }} />
                                                                <Typography variant="body1">{subCat.type}</Typography>
                                                            </Button>
                                                        </Grid>
                                                    ))
                                                ) : (
                                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                        <Typography variant="body1">No subcategories available</Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </Box>
                    </Grid2>
                </Grid2> 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default SubCategoryPage;

