import { useLocation, useNavigate } from "react-router-dom";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { FC, useState } from "react";
import { IError, ISuccess, RouteComponent } from "../../type";
import { Box, Button, Card, CardContent, CircularProgress, Container, Grid, TextField, Typography } from "@mui/material";
import Header from "../../components/Header";
import { addUserObjectToRequest, callBackendAPI, formatMoneyAsK, getLabelValue } from "../../utils";
import Grid2 from "@mui/material/Unstable_Grid2";
import { CONFIG } from "../../config";
import PinInput from "../../components/PinInput";
import DialogAlert from "../../components/DialogAlert";
import { HOME_UPDATE_WALLET_BALANCE } from "../../actions/type";
import ReceiptMessage from "../../components/ReceiptMessage";

interface Props extends RouteComponent{
    logOut: () => void;
}

const Pulsa: FC<Props> = (props) => {
    const { logOut } = props;
    const {state} = useLocation();
    const item: any = state.item;
    const isMobile: boolean = useCheckIsMobile();
    const [verified, setVerified] = useState(false);
    const [firstInput, setFirstInput] = useState('');
    const [returnedData, setReturnedData] = useState<any>('');
    const [selected, setSelected] = useState(-1);
    const [showPin, setShowPin] = useState(false);
    const [pinNumber, setPinNumber] = useState<string>('');
    const { lang, user } = useSelector((state:RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<IError>({ error: false, message: "", forgot_pin: 0});
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    
    const verifyNumber = async () => {
        try{
            setLoading(true);
            if (firstInput === ''){
                setError({ error: true, message: "Phone Number cannot be empty" });
                setLoading(false);
                return;
            }
            let input: any = {
                ver: CONFIG.ver,
                act: String(40000),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: firstInput ? firstInput.trim() : '',
                m1: "Pulsa Verify Number",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                setLoading(false);
                setError({ error: true, message: "001: Something went wrong, try again" });
                return
            }
            if (resultsFromAPI.error !== undefined){
                setError({ error: true, message: "002: Something went wrong, try again" });
                setLoading(false);
                return
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                setError({ error: true, message: "003: Something went wrong, try again" });
                setLoading(false);
                return
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")){
                if (results.code !== 0){
                    setError({ error: true, message: `[${results.code}] : ${results.message || ""}` });
                    setLoading(false);
                    return;
                }
                if (!results.hasOwnProperty("data")){
                    setError({ error: true, message: "004: Something went wrong, try again" });
                    setLoading(false);
                    return;
                }
        
                setLoading(false);
                setReturnedData(results.data);
                setVerified(true);
                return setError({ error: false, message: "" });
            }
            setShowPin(false);
            setLoading(false);
            return setError({ error: true, message: "005: Something went wrong, try again" });
        }catch (e) {
            console.log(e);
            setError({ error: true, message: "Oops something went wrong!" });
            setLoading(false);
        }
    }

    const createPulsaTransaction = async () => {
        try{
            setLoading(true);
            if (!pinNumber || pinNumber === ''){
                setShowPin(false);
                setLoading(false);
                return setError({ error: true, message: "Please enter Pin" });
            }
            let input: any = {
                ver: CONFIG.ver,
                act: String(40001),
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                p1: firstInput ? firstInput.trim() : '',
                p2: returnedData ? returnedData.hasOwnProperty("denominations") ? returnedData.denominations[selected] : '' : '',
                p3: returnedData,
                p4: pinNumber ? pinNumber.trim() : '',
                m1: "Pulsa",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                setLoading(false);
                setPinNumber('');
                return setError({ error: true, message: "001: Something went wrong, try again" });
            }
            if (resultsFromAPI.error !== undefined){
                setLoading(false);
                setPinNumber('');
                return setError({ error: true, message: "002: Something went wrong, try again" });
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                setLoading(false);
                setPinNumber('');
                return setError({ error: true, message: "003: Something went wrong, try again" });
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")){
                if (results.code !== 0){
                setLoading(false);
                setPinNumber('');
                if (results?.renderForgotPin)
                    return setError({error: true, message: `[${results.code}] : ${results.message || ""}`, forgot_pin: results.renderForgotPin});
                }

                if (results.hasOwnProperty("balance") && results.balance)
                dispatch({ type: HOME_UPDATE_WALLET_BALANCE,
                    payload: {balance: results.balance, pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null }})
        
                if (results.code === 0){
                    return navigate('/receipt', { state : { receiptInfo: results.receipt, messageKey: 'prepaidselected.receipt.subTitle' } });  
                }
            }
        
            setLoading(false);
            setPinNumber('');
            return setError({ error: true, message: "004: Something went wrong, try again" });
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setPinNumber('');
            return setError({ error: true, message: "005: Something went wrong, try again" });
        }
    }

    const renderReturnedData = (data: any) => {
        if (typeof data !== "object") return null;
        return (
            <Grid container spacing={2}>
                {Object.keys(data).map((key) => {
                if (key !== "denominations" && key !== 'operator_id') {
                    return (
                    <Grid item xs={12} sm={6} key={key}>
                        <Typography variant="subtitle1" style={{ textTransform: "capitalize" }}>
                        {key}
                        </Typography>
                        <TextField
                        variant="outlined"
                        fullWidth
                        value={data[key]}
                        InputProps={{
                            readOnly: true,
                        }}
                        />
                    </Grid>
                    );
                }
                return null;
                })}
            </Grid>
        );
    }

    const renderDenominations = (data: any) => {
        if (data === undefined) return null;
        if (!data.hasOwnProperty("denominations")) return null;
        if (!Array.isArray(data.denominations)) return null;
        return (
            <>
            <Box 
                sx={{ textAlign: 'left'  }}
                mt="10px"
                ml="5px" 
                mr="5px"
            >
                {getLabelValue(lang, "paybill.denominations")}
            </Box>            
            <Grid container spacing={2}>
                {data.denominations.map((denomination: any, index: any) => (
                    <Grid item xs={12} sm={6}>
                    <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setSelected(index)}
                    color={selected === index ? "inherit" : "primary"}
                    sx={{
                        fontFamily: "semiBold",
                        color: selected === index ? "#000" : "#fff",
                    }}
                    >
                    {denomination.hasOwnProperty("value")
                        ? data.currency + formatMoneyAsK(denomination.value.split("|")[0])
                        : ""}
                    </Button>
                    <Typography variant="body1" fontFamily="semiBold">
                    {denomination.hasOwnProperty("value")
                        ? "RM" + formatMoneyAsK(denomination.value.split("|")[1])
                        : ""}
                    </Typography>
                    </Grid>
                ))}
            </Grid>
            </>
        )
    }

    const shouldShowPin = () => {
        if (verified !== false && selected !== -1)
            setShowPin(true);
        else {
            setError({error: true, message: "Please select amount"})
        }
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'home.mmproducts.product2')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <Box display='flex' justifyContent={'center'} alignItems={'center'}>
                                    <img
                                        src={item.icon}
                                        style={{
                                            width: '100px',  
                                            height: '100px', 
                                            objectFit: 'contain'
                                        }}                                    
                                    />
                                </Box>                            
                                <Typography variant='h6' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                                    {item.name}
                                </Typography>
                                <Container>
                                    <Box 
                                        sx={{ textAlign: 'left'  }}
                                        mt="10px"
                                        ml="5px" 
                                        mr="5px"
                                        mb="10px"
                                    >
                                        {getLabelValue(lang, 'paybill.description')}
                                    </Box>
                                    <TextField
                                        label="Phone Number"
                                        placeholder="6283830771998"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={firstInput}
                                        onChange={(e) => setFirstInput(e.target.value)}
                                    />                                
                                </Container>
                                <Container>
                                {(returnedData !== undefined) && renderReturnedData(returnedData)}
                                {verified && renderDenominations(returnedData)}
                                </Container>
                                <Container sx={{ mt: 2, mb: 2}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="success"
                                                onClick={() => {
                                                    navigate('/bill')
                                                    setError({ error: false, message: '' });
                                                }}
                                                disabled={loading} 
                                            >
                                                {getLabelValue(lang, 'login.account.button.goback')}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                            type="submit"
                                            fullWidth
                                            onClick={() => verified ? shouldShowPin() : verifyNumber()}
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                            endIcon={loading ? <CircularProgress size={20} /> : null}
                                            >
                                                {getLabelValue(lang, 'paybill.proceed')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>                              
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
                {showPin && 
                    <PinInput
                        open={showPin}
                        onCancel={() => setShowPin(false)}
                        onSuccess={async () => {
                            setShowPin(false);
                            await createPulsaTransaction();
                        }}
                        yesButton={'Submit'}
                        cancelButton={'Cancel'}
                        pinNumber ={pinNumber}
                        onPinChange={(e: any)=>{
                            setPinNumber(e.target.value)
                        }}
                        lang={lang}
                    />
                }
                {error.error && (
                    <DialogAlert
                        open={error.error}
                        onCancel={() => {
                            setError({ error: false, message: '', forgot_pin: 0 });
                            logOut();
                            navigate('/forgotpin', { state: { user: user } });
                        }}
                        onSuccess={() => {
                            setError({ error: false, message: '', forgot_pin: 0 });
                            setPinNumber('');
                        }}
                        title={'Attention'}
                        content={new Array(error.message)}
                        yesButton={'OK'}
                        cancelButton={error.forgot_pin === 1 ? (lang === 'en' ? 'FORGOT PIN' : 'LUPA PIN') : ''}
                        reportIssue={error.reportIssue?.show}
                        req_idx={error.reportIssue?.idx}
                        onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
                        reportTitle={error?.title}
                        reportDetails={error?.details}
                        trans_id={error?.trans_id}
                        ticket_type={error?.ticket_type}
                    />
                )} 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default Pulsa