export const EN_LANG_CONFIG: { [key: string]: any } = {
  /*  APP NAVIGATOR */
  'navigation.tabs.home.title': 'Home',
  'navigation.tabs.receipt.title': 'Receipt',
  'navigation.tabs.profile.title': 'Account',
  'navigation.tabs.kita.title': 'Kita',

  /* LOGIN PAGE */
  'login.title': 'Please select a language',
  'login.newuser': 'New User?',
  'login.button.newuser': 'Register',
  'login.button.login': 'Login',
  'login.account.login.title': 'Login',
  'login.account.login': 'No account yet?',
  'login.account.button.next': 'Next',
  'login.account.button.submit': 'Proceed',
  'login.account.button.goback': 'Back',
  'login.account.button.newuser': 'Register',
  'login.account.title': 'Please complete the information below to enter your profile page.',
  'login.account.handphone': 'Handphone No.',
  'login.account.id': 'ID Number',
  'login.acoount.forgotpassword': 'Forgot PIN',
  'login.account.warning.title': 'Warning!',
  'login.account.warning.subTitle1': 'Do not enter your PIN if this is',
  'login.account.warning.subTitle2': 'not your profile photo.',
  'login.account.photo.confirm': 'Yes, this is my photo.',
  'login.account.pin.title': 'Enter your 6 -digit PIN number',
  'login.scan.instructions': 'Please scan the Account ID QR Code now',

  /* REGISTER PAGE */
  'register.title': 'REGISTER',
  'register.account.title': 'Please complete the information below to register a new account',
  'register.account.handphone': 'Handphone No.',
  'register.account.idType': 'Select an ID type',
  'register.account.id': 'ID Number',
  'register.account.nationality': 'Select Nationality',
  'register.account.rfCode': 'Referral Code (Optional)',
  'register.account.name': 'Full Name',
  'register.account.name.helpertext': '** Please enter your full name as per IC / Passport **',
  'register.account.email': 'Email',
  'register.account.invalid.email': 'Invalid Email Address',
  'register.account.invalid.postcode': 'Invalid Post Code',
  'register.account.lotno': 'Lot No.',
  'register.account.address': 'Name of Flat / Apmt / Street',
  'register.account.address2': 'Name of Area / Taman / Village',
  'register.account.postcode': 'Post Code',
  'register.account.state': 'State',
  'register.account.choosestate': 'Select State',
  'register.account.city': 'City',
  'register.otp.title': 'One-Time Password (OTP) for',
  'register.otp.subTitle': 'login has been sent to',
  'register.otp.instructions': 'Please find the OTP in the SMS and enter it in the space below',
  'register.otp.smsnotrceived': 'SMS not received? Request again',
  'register.otp.request': 'Request another OTP',
  'register.account.pin': 'Enter a 6 -digit PIN number',
  'register.account.confirmPin': 'Repeat the 6 -digit PIN number',
  'register.initialize': 'One moment we are setting up your account!',
  'register.pin.title1': 'Create 6-PIN',
  'register.pin.title2': 'New Numbers',
  'register.pin.subTitle': 'This 6 -digit PIN will be required when you make a transaction.',
  'register.pin.mismatch': 'PIN MISMATCH, TRY AGAIN',
  'register.pin.success.title': 'TRANSACTION PIN SUCCESSFULLY CREATED',
  'register.pin.success.message': 'Please write down and keep this pin in a safe place. Without a PIN, transactions cannot proceed',
  'register.photo.title': 'Profile photo',
  'register.photo.subTitle': 'Please select your profile photo',
  'register.success.title': 'Registration Successful',
  'register.success.subTitle': 'Your profile photo was successfully added',
  'register.success.warning': 'Warning!',
  'register.success.message': '*At login time if your profile photo is incorrect. Don\'t continue*',
  'register.tnc': '"I hereby agree and accept the terms and conditions regarding the use of this service as stated in the Terms and Conditions for Customer. The Terms and Conditions for Customer can be read at Mobile Money International Sdn Bhd\'s website ',
  'forgot.otp.title': 'One-Time Password (OTP) for',
  'forgot.otp.subTitle': 'forgot pin has been sent to',
  'reset.otp.subTitle': 'reset device has been sent to',
  'reset.device.title1': 'Confirm Reset Device',
  'reset.device.subTitle': 'Your previous device no longer able to login into this account anymore.',
  'reset.device.reset': 'Reset',
  'register.upgrade.tnc': 'You also acknowledge that by proceeding with MM App application, your Mobile Money Wallet size will increase from RM1,500 to RM10,000 as stated in the Schedule A in the MM Terms & Conditions for Customers.',
  'register.rfCode.qrcode': 'Please scan an Account ID',
  'register.enterpin.close.message': 'Oops your account is almost set up. Please keep in mind that if you press YES , you may have trouble creating your account. To continue please press NO.',
  'register.enterpin.close.title': 'Are you sure you want to close',

  /* HOME PAGE */
  'home.header.title': 'MM Wallet Balance',
  'home.header.button.topup': 'Top Up',
  'home.header.button.fpx': 'FPX',
  'home.header.button.transactions': 'Transactions',
  'home.header.button.transhistory': 'Transaction History',
  'home.mmproducts.product1': 'Send',
  'home.mmproducts.product2': 'Pay Bill',
  'home.mmproducts.product3': 'Prepaid',
  'home.mmproducts.product4': '2FA',
  'home.mmproducts.product5': 'Transfer',
  'home.mmproducts.product6': 'Kita',
  'home.transaction.status.processing': 'Processing',
  'home.transaction.status.success': 'Success',
  'home.transaction.status.all': 'All',

  /* TOP UP PAGE */
  'topup.header.title': 'Fill in the Top Up Amount',
  'topup.header.subTitle': 'Top up amount',
  'topup.amount.title': 'Total Amount',
  'topup.error.message1': 'Please Choose an amount',
  'topup.footer.button': 'PAY NOW',
  'topup.receipt.subTitle': 'Already Added',
  'topup.disclaimer.title': 'Prevent deposit rejections',
  'topup.disclaimer.desc1': 'Make the deposit from your personal bank account',
  'topup.disclaimer.desc2': 'The name of the bank account holder MUST MATCH the name you signed up with at MM APP',
  'topup.disclaimer.desc3': 'Deposits made from someone else\'s bank account will be refunded',
  'topup.disclaimer.desc4': 'Refunds can take 14 business days to process',

  /* PREPAID PAGE */
  'prepaid.header.title': 'Select Telco',

  /* Prepaid Selected PAGE */
  'prepaidselected.header.title': 'Prepaid Number',
  'prepaidselected.header.description': 'Select the amount',
  'prepaidselected.main.amountinput': 'Fill in the amount',
  'prepaidselected.footer.button': 'Top Up',
  'prepaidselected.error.message1': 'Please Choose an amount',
  'prepaidselected.error.message2': 'Please provide a phone number',
  'prepaidselected.receipt.subTitle': 'Paid',
  'prepaidselected.error.title': 'Error',
  'prepaidselected.no.denos': 'No denominations available!',

  /* PIN */
  'pin.title': 'Enter a 6 -digit PIN for verification',

  /* Transaction PAGE */
  'transaction.sendTo': 'Send To',
  'transaction.button.title1': 'Yes',
  'transaction.button.title2': 'Cancel',
  'transaction.report.problem': 'Report a Problem',
  'transaction.receipt': 'Receipt',

  /* KIRIM PAGE */
  'kirim.bookrate.title': 'Check current rate',
  'kirim.bookrate.button': 'Next',
  'kirim.bookrate.selectbene.title': 'Choose a Receiver',
  'kirim.bookrate.selectbene.button': 'NEXT',
  'kirim.bookrate.confirm.button': 'SUBMIT',
  'kirim.bookrate.noBene': 'You currently dont have any beneficiaries setup. Please add a beneficiary to continue.',
  'kirim.bookrate.cancel':'Cancel Booking',

  /* TRANSFER PAGE */
  'transfer.selected.title': 'Enter the amount to be sent',
  'transfer.selected.balance': 'Your current balance',
  'transfer.selected.send': 'Send',
  'transfer.confirm.amount': 'Amount to send',
  'transfer.receipt.subTitle': 'Successfully Transferred',

  /* PROFILE */
  'profile.name': 'Name.',
  'profile.mobile': 'Handphone No.',
  'profile.idtype': 'ID Type.',
  'profile.id': 'ID Number.',
  'profile.account_id': 'Account ID',
  'profile.scan_payroll': 'Scan Corporate ID',
  'profile.email': 'Email.',
  'profile.lotno': 'Lot No.',
  'profile.address1': 'Address 1.',
  'profile.address2': 'Address 2.',
  'profile.postcode': 'Postcode.',
  'profile.city': 'City.',
  'profile.state': 'State.',
  'profile.change_language': 'Language',
  'profile.t2p_card': 'Tap 2 Pay Card',
  'profile.changepin.title': 'Change 6-Digit PIN',
  'profile.changeT2Ppin.title': 'Change T2P 6-Digit PIN',
  'profile.lost_card.title': 'Lost MM Card',
  'profile.lost_card.disclaimer': 'Your MM card shall be blocked and will not be useable anymore. If you agree please select OK.',
  'profile.lost_card.success': 'Your MM card has been blocked, MM shall contact you shortly and advise you on how to obtain a card replacement.',
  'profile.changepin.current.title': 'Enter current pin',
  'profile.changepin.new.title': 'Enter New 6-Digit pin',
  'profile.changepin.confirmnew.title': 'Repeat the New 6-Digit pin',
  'profile.changepin.success.message': 'Your PIN has been changed successfully.',
  'profile.scan.instructions': 'Please scan the Corporate ID now',
  'profile.scan.pin': 'Enter pin',
  'profile.user_qrcode': 'QR Code',
  'profile.user_qrcode.description': "Scan this User's QR Code",
  'profile.upgrade_membership': 'Membership',
  'profile.upgrade.button': 'Upgrade',
  'profile.upgrade.subtitle': 'You are currently under',
  'profile.upgrade.description': 'Upgrade to get better benefits and features',
  'profile.upgrade.alert.title': 'Upgrade to',
  'profile.upgrade.alert.message.gold': 'To upgrade to Gold membership, you need to undergo the eKYC process. Please click OK to continue.',
  'profile.upgrade.alert.message.silver': 'To upgrade to Silver membership, you need to at least perform one FPX transaction to verify your account Name. Please click OK to continue.',
  'profile.upgrade.alert.message.platinum': 'To upgrade to Platinum membership, you need to go to any of our nearest MM Promoter agents to continue with this process. Please click OK to continue.',
  'profile.referral.menu.title': 'Invite A Friend',
  'profile.referral.menu.subtitle': 'Get more points when you invite a friend to download and use the app',
  'profile.referral.menu.description': 'You and your friend get free points!',
  'profile.referral.header.title': 'Referral',
  'profile.referral.question': 'How does it work?',
  'profile.referral.instruction1': 'Share your link with a friend.',
  'profile.referral.instruction2': 'Your friend needs to download the MM App & successfully sign up.',
  'profile.referral.instruction3': 'You and your friend will get free points.',
  'profile.referral.share.button': 'Share my Link',
  'profile.referral.share.description': 'Have you tried the MM App yet? They have something exciting for you, Check it out here: ',
  'profile.delete.account': 'Delete Account',
  'profile.delete.account.message': 'MM will remove your MM App user login account as requested. To terminate your MM wallet account, please contact or email to our Customer Support at crt@mobile-money.com.my. For further detail, please click here',
  'profile.delete.account.submessage': 'Please refer to Clause 18 in the MM Terms & Conditions for Customers at',
  'profile.payslip': 'Pay Slip',
  'profile.payslip.emptylist': 'No data available at the moment!',
  'profile.payslip.date': 'Please select a date',
  'profile.payslip.employee_id': 'Please enter your employee ID',
  'profile.accountID_qrcode.description': "Scan this User's Account ID",
  'profile.upgrade.ic.attention': 'ATTENTION',
  'profile.upgrade.ic.desc': 'is expired, please click here to update it',
  'profile.upgrade.ic.message': 'Passport has expired. Do you want to update your new passport? Please press Update',
  'profile.upgrade.ic': 'Update Passport',
  'profile.upgrade.ic.header': 'Please provide all the necessary information below',
  'profile.upgrade.ic.oldid': 'Current ID / Passport number',
  'profile.upgrade.ic.newid': 'Enter your new ID / Passport number',
  'profile.upgrade.ic.submit': 'Submit',
  'profile.upgrade.ic.errornewid': 'Please ENTER the new ID / Passport',
  'profile.upgrade.ic.errorimage': 'Please provide a picture of the new ID / Passport',
  'profile.upgrade.ic.expired_dt': 'Expiry Date',
  'profile.upgrade.ic.newidissuedt': 'New ID / Passport Issue Date (DD/MM/YYYY)',
  'profile.upgrade.ic.newidexpirydt': 'New ID / Passport Expiry Date (DD/MM/YYYY)',
  'profile.upgrade.ic.errornewidissuedt': 'Please ENTER the new ID / Passport Issue Date in the format (DD/MM/YYYY)',
  'profile.upgrade.ic.errornewidexpirydt': 'Please ENTER the new ID / Passport Expiry Date (DD/MM/YYYY)',
  'profile.community.header': 'Subscribe and discover new communities',
  'profile.community.scan.instructions': 'Please scan the Account ID QR Code now',


  /* PAY BILL */
  'paybill.description': 'Refer to your bill for account details',
  'paybill.proceed': 'Proceed',
  'paybill.paynow': 'Pay Now',
  'paybill.denominations': 'Choose denomination',

  /*  RECEIPT PAGE */
  'receipt.copyToClipboard': 'has been copied to clipboard',

  /* KIRIM BENEFICIARY PAGE */
  'kirim.beneficiary.title': 'Add Beneficiary', // Tambah Penerima
  'kirim.beneficiary.benename': 'Beneficiary Name', // Nama Penerima
  'kirim.beneficiary.middlename': 'Beneficiary Middle Name (If have)',
  'kirim.beneficiary.lastname': 'Beneficiary Last Name',
  'kirim.beneficiary.benephone': 'Beneficiary Mobile Number', // Nombor Telefon Penerima
  'kirim.beneficiary.benekadno': 'Member Card No', // Nomor Rekening Penerima
  'kirim.beneficiary.benecity': 'Beneficiary City', // Bandar Penerima
  'kirim.beneficiary.bene': 'Beneficiary', // Penerima
  'kirim.beneficiary.benerelationship': 'Beneficiary Relationship', // Hubungan Penerima
  'kirim.beneficiary.benebank': 'Beneficiary Bank', // Hubungan Penerima
  'kirim.beneficiary.benebankbranch': 'Beneficiary Bank Branch', // Hubungan Penerima
  'kirim.beneficiary.ifsc': 'IFSC', // IFSC
  'kirim.beneficiary.benecountry': 'Beneficiary Country', // Negara Penerima
  'kirim.beneficiary.beneaccno': 'Account Number', // Negara Penerima
  'kirim.beneficiary.nobene': 'No Beneficiary', // Negara Penerima
  'kirim.beneficiary.ifsccode':'ISFC Code',  // ISFC Kod
  'kirim.beneficiary.address':"Address",   // address

  /* KIRIM FPX PAGE */
  'kirim.fpx.bankname': 'Select a Bank', // Nombor Akaun bank
  'kirim.fpx.accname': 'Account Name', // Nama Akaun Bank
  'kirim.fpx.accno': 'Account Number', // Nombor Akaun bank
  'kirim.fpx.status': 'Verification Status', // Nombor Akaun bank
  'kirim.fpx.email': 'Email', // Emel

    /* KIRIM PAGE */
  'kirim.menu.bookrate': 'Book Rate',
  'kirim.bookrate.beneficiary': 'Beneficiary',
  'kirim.bookrate.paynow': 'Pay Now',
  'kirim.bookrate.transactionstatus': 'Transaction Status',
  'kirim.bookrate.documents': 'Documents for MM',
  'kirim.bookrate.help': 'Help Line',
  'kirim.bookrate.fpx': 'Verify my bank account',
  'kirim.bookrate.selectpurpose': 'Select Purpose',

  /* KIRIM HEADDER PAGE */
  'kirim.header.bookrate': 'Book Rate',
  'kirim.header.beneficiary': 'Beneficiary',
  'kirim.header.beneficiary.add': 'Add Beneficiary',
  'kirim.header.paynow': 'Pay Now',
  'kirim.header.transactionstatus': 'Transaction Status',
  'kirim.header.documents': 'Documents for MM',
  'kirim.header.help': 'Help Line',
  'kirim.header.beneficiary.add.country': 'Beneficiary Country',
  'kirim.header.beneficiary.add.rship': 'Beneficiary Relationship',

  /* KIRIM STATUS TRANSAKSI PAGE */
  'kirim.transaction.transid': 'Transaction ID',
  'kirim.transaction.beneficiary': 'Beneficiary',
  'kirim.transaction.cardno': 'Member Card No',
  'kirim.transaction.country': 'Country',
  'kirim.transaction.provider': 'Provider',
  'kirim.transaction.booktime': 'Booking Date',
  'kirim.transaction.rate': 'Exchange rate',
  'kirim.transaction.date': 'Transaction Date',
  'kirim.transaction.receipt': 'Receipt',
  'kirim.transaction.refund': 'Refund',
  'kirim.transaction.cancel': 'Cancel',
  'kirim.transaction.customerhp':'Mobile No',

  /* KIRIM PAY NOW PAGE */
  'kirim.paynow.nobook': 'No Bookings available',
  'kirim.paynow.nobooking.selected': 'Please select a booking',

  /* KIRIM MONEY PIN PAGE */
  'kirim.moneypin.message': 'Message',
  'kirim.moneypin.promotion': 'Promo',
  'kirim.moneypin.redeem': 'Redeem Money Pin',
  'kirim.moneypin.time': 'Date & Time',
  'kirim.moneypin.send': 'Send',
  'kirim.moneypin.receipt': 'eRemit Receipt',
  'kirim.moneypin.receiver':'Receiver',
  'kirim.moneypin.amount':'Amount',
  'kirim.moneypin.pin':'PIN',
  'kirim.moneypin.provider':'Provider',
  'kirim.moneypin.details':'Cash PIN Details',


  /* KITA PAGE */
  'kita.date': 'Updated on',
  'kita.header.title': 'Redemption',
  'kita.points': 'points',
  'kita.points.toptab1': 'used',
  'kita.points.toptab2': 'received',
  'kita.earned': 'earned',
  'kita.water': 'Water',
  'kita.water.litre': 'litre',
  'kita.water.point_description': 'Total Points',
  'kita.water.button': 'REDEEM WITH POINTS',
  'kita.water.qrcode_message': 'Scan your QR code at the water machine scanner.\n' +
    'You will lose the QR code after you press close',
  'kita.water.qrcode_button': 'Close',
  'kita.prepaid.header.title': 'Prepaid Redemption',
  'kita.voucher': 'Voucher',
  'kita.history.toptab1': 'TO USE',
  'kita.history.toptab2': 'USED',
  'kita.history.points_used': 'Points Used',
  'kita.history.voucher_code': 'Voucher Code',
  'kita.history.redeemed': 'Redeemed',
  'kita.history.header': 'Redeem History',
  'kita.point.header': 'Point History',
  'kita.advertisement': 'Advertisement',
  'kita.point.empty': 'No data available at the moment!',
  'kita.earn.title' : 'YOU HAVE EARNED',
  'kita.earn.description': 'Watch & Earn Points',
  'kita.earn.header' : 'Earn Points',
  'kita.earn.emptylist.label': 'No ads at the moment, please try later.',
  'kita.earn.ad.component.description': 'Watch & Earn points',
  'kita.earn.counter': 'seconds remaining',
  'kita.earn.counter.close': 'Click to earn points',
  'kita.stamps.title': 'E-Stamp',
  'kita.cash.voucher.title' : 'Cash Voucher',
  'kita.cash.voucher.accumulated' : 'Accumulated Cash Vouchers',
  'kita.redeem': 'Redeem',
  'kita.revert': 'Revert',
  'kita.cash.voucher.qrcode.message': 'Present this QR code to the merchant to use cash voucher',
  'kita.cash.voucher.merchants': 'Merchants',
  'kita.cash.voucher.purchase' : 'Purchase With Wallet',
  'kita.cash.voucher.vouchers': 'Merchant Vouchers',
  'kita.cash.voucher.balance' : 'Voucher Balance',
  'kita.cash.voucher.enter' : 'Enter Amount',
  'kita.cash.voucher.generate' : 'Generate QR',
  'kita.cash.voucher.invalid' : 'Invalid Amount',
  'kita.cash.voucher.error' : 'Enter amount more than 0',
  'kita.cash.voucher.collected': 'My Vouchers',
  'kita.cash.voucher.history': 'History',
  'kita.cash.voucher.use': 'Use Voucher',
  'kita.cash.voucher.usage_left': 'Valid Till',
  'kita.cash.voucher.usage': 'Usage',
  'kita.cash.voucher.days': 'days',
  'kita.cash.voucher.times': 'times',
  'kita.update.version': 'A newer version is available. Please update now',



  /* KADAR PAGE */
  'kadar.header.title': 'Rates',

  /* PAY PAGE */
  'pay.header.title': 'Pay',
  'pay.qrcode.desc': 'This QR Code will refresh every 60 seconds.',

  /* NOTIFICATIONS */
  'notifications.header.title': 'Notifications',

  /* CASH OUT */
  'cashout.bank_name.label': 'Bank Name',
  'cashout.acc_name.label': 'Account Name',
  'cashout.acc_no.label': 'Account Number',
  'cashout.amount.label': 'Enter amount',
}
