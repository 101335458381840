import { NavigateFunction } from "react-router-dom";
import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI, extendedAmountTextInput, navigateToExternalUrl } from "../utils";
import { HOME_UPDATE_WALLET_BALANCE, TOPUP_AMOUNT_CHANGE, TOPUP_FAILED, TOPUP_FETCH, TOPUP_SUCCESS, TOPUP_TRANSACTION, TOPUP_TRANSACTION_FAILED, TOPUP_UNMOUNT } from "./type";

export const topUpAmountChange = (amount : any) => {
    try{
        return { type: TOPUP_AMOUNT_CHANGE, payload: extendedAmountTextInput(amount)}
    }
    catch (e) {
        console.log(e);
        return { type: TOPUP_AMOUNT_CHANGE, payload: 0.00 }
    }
}

export const unMountTopUpPayPage = () => {
    return { type: TOPUP_UNMOUNT }
}

export const createTopUpTransaction = (params : any, lang : any, user: any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: TOPUP_TRANSACTION });
            if (user === undefined)
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "9999: Something went wrong, try again"});
            if (params === undefined)
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "001: Something went wrong, try again"});
            if (!params.hasOwnProperty("act_id"))
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "002: Something went wrong, try again"});
            if (!params.hasOwnProperty("amount"))
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "003: Something went wrong, try again"});
            if (lang === undefined)
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "004: Something went wrong, try again"});
            if (!params.hasOwnProperty("paymentMode"))
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "005: Something went wrong, try again"});
            if (!params.paymentMode.hasOwnProperty("id"))
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "006: Something went wrong, try again"});
            let m2 = params.paymentMode[lang].description;
                
            let input : any = {
                ver: CONFIG.ver,
                act: String(params.act_id),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: String(params.amount),
                p2: String(params.paymentMode.id),
                m1: "TopUp",
                m2: m2,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            console.log(input)
            let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "008: Something went wrong, please check your Internet Connection and try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "009: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0){
                    return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                }
                if (results?.url && results?.request_id) {
                   return navigateToExternalUrl(results.url);
                }
            }         
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: TOPUP_TRANSACTION_FAILED, payload: "Oops something went wrong!"});
        }
    }
}

export const unmountFPXWebView = () => {
    return { type: TOPUP_UNMOUNT }
}

export const getFPXTransaction = (navigate: NavigateFunction, request_id : any, lang : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: TOPUP_FETCH });
            if (request_id === undefined)
                return dispatch({ type: TOPUP_FAILED , payload: `001: OOPS SOMETHING WENT WRONG`});
            let input : any = {
                ver: CONFIG.ver,
                act: "20001",
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: request_id,
                m1: "TopUp",
                lg: lang
            }
            input = addUserObjectToRequest(input);
    
            let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
    
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: TOPUP_FAILED, payload: `002: Something went wrong: REQUEST ID = ${request_id}`});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: TOPUP_FAILED, payload: `003: Something went wrong, please check your Internet Connection and try again: REQUEST ID = ${request_id}`});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: TOPUP_FAILED, payload: `004: Something went wrong: REQUEST ID = ${request_id}`});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0){
                    return dispatch({ type: TOPUP_FAILED, payload: `Request ID: ${request_id} \nError Code: ${results.code} :: ${results.message || ""} \n`});
                }
                if (results.hasOwnProperty("balance") && results.balance){
                    dispatch({ type: HOME_UPDATE_WALLET_BALANCE,
                        payload: {balance: results.balance, pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null }})
            
                    dispatch({ type: TOPUP_SUCCESS });
                    return navigate('/receipt', { state : { receiptInfo: results.receipt, messageKey: 'topup.receipt.subTitle' } });
                }
            }         
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: TOPUP_FAILED, payload: "OOPS SOMETHING WENT WRONG!"})
        }
    }
}