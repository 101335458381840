import { PROFILE_FETCH, PROFILE_FETCH_FAILED, PROFILE_FETCH_SUCCESS, PROFILE_LOST_CARD, PROFILE_LOST_CARD_FAILED, PROFILE_LOST_CARD_SUCCESS, PROFILE_RESET_PIN, PROFILE_RESET_PIN_FAILED, PROFILE_RESET_PIN_SUCCESS, PROFILE_UNMOUNT, PROFILE_VERIFY_CURRENT_PIN, PROFILE_VERIFY_CURRENT_PIN_FAILED, PROFILE_VERIFY_CURRENT_PIN_SUCCESS } from "../actions/type";

const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    verified: false,
    success: false
}
  
export default (state = INITIAL_STATE, action : any) => {
    switch (action.type){
        case PROFILE_VERIFY_CURRENT_PIN:
            return { ...INITIAL_STATE, loading: true };
        case PROFILE_VERIFY_CURRENT_PIN_FAILED:
            return { ...INITIAL_STATE, error: true, message: action.payload };
        case PROFILE_VERIFY_CURRENT_PIN_SUCCESS:
            return { ...INITIAL_STATE, verified: true }
        case PROFILE_UNMOUNT:
            return INITIAL_STATE;
        case PROFILE_RESET_PIN:
            return { ...state, loading: true, error: false, message: '', success: false };
        case PROFILE_RESET_PIN_FAILED:
            return { ...state, loading: false, error: true, message: action.payload, success: false };
        case PROFILE_RESET_PIN_SUCCESS:
            return { ...INITIAL_STATE, verified: true, success: true };
        case PROFILE_LOST_CARD:
            return { ...state, loading: true, error: false, message: '', success: false};
        case PROFILE_LOST_CARD_FAILED:
            return { ...state, loading: false, error: true, message: action.payload, success: false};
        case PROFILE_LOST_CARD_SUCCESS:
            return { ...INITIAL_STATE, success: true };
        case PROFILE_FETCH:
            return { ...state, loading: true };
        case PROFILE_FETCH_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case PROFILE_FETCH_SUCCESS:
            return INITIAL_STATE;
        default:
            return state;
    }
}