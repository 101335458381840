import { NavigateFunction } from "react-router-dom";
import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { PAY_NOW_DISMISS_ERROR, PAY_NOW_FPX_STATUS, PAY_NOW_FPX_STATUS_FAILED, PAY_NOW_FPX_STATUS_SUCCESS } from "./type";

export const payNowDismissError = () => {
    return { type: PAY_NOW_DISMISS_ERROR }
}

export const getKirimFPXTransaction = (navigate: NavigateFunction, request_id : any, lang : any) => {
    return async (dispatch: any) => {
        try{
            dispatch({ type: PAY_NOW_FPX_STATUS });
            if (request_id === undefined)
                return dispatch({ type: PAY_NOW_FPX_STATUS_FAILED , payload: `001: OOPS SOMETHING WENT WRONG`});
    
            let input: any = {
                ver: CONFIG.ver,
                act: "30006",
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: String(request_id),
                m1: "PayNowFPXStatus",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI)
                return dispatch({ type: PAY_NOW_FPX_STATUS_FAILED, payload: `002: Something went wrong: REQUEST ID = ${request_id}`});
            if (resultsFromAPI.error !== undefined)
                return dispatch({ type: PAY_NOW_FPX_STATUS_FAILED, payload: `003: Something went wrong, please check your Internet Connection and try again: REQUEST ID = ${request_id}`});
            if (!resultsFromAPI.hasOwnProperty("results"))
                return dispatch({ type: PAY_NOW_FPX_STATUS_FAILED, payload: `004: Something went wrong: REQUEST ID = ${request_id}`});
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0)
                    return dispatch({
                        type: PAY_NOW_FPX_STATUS_FAILED,
                        payload:
                            `Request ID: ${request_id} \n[${results.code}] : ${results.message || ""} \n`
                            // Status: ${results.status || ''} \nFPX Code: ${results.fpx_result_code || ''} \n`
                    });
        
                dispatch({ type: PAY_NOW_FPX_STATUS_SUCCESS });
                return navigate('/receipt', { state : { receiptInfo: results.receipt, messageKey: 'topup.receipt.subTitle' } });
            }
    
            return dispatch({ type: PAY_NOW_FPX_STATUS_FAILED, payload: `005: Something went wrong: REQUEST ID = ${request_id}`});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: PAY_NOW_FPX_STATUS_FAILED, payload: "OOPS SOMETHING WENT WRONG!"})
        }
    }
}