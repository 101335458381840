import {
    GET_REDEEM_PRODUCTS,
    GET_REDEEM_PRODUCTS_FAILED,
    GET_REDEEM_PRODUCTS_SUCCESS,
    GET_REDEEM_VOUCHERS,
    GET_REDEEM_VOUCHERS_FAILED,
    GET_REDEEM_VOUCHERS_SUCCESS,
    GET_REDEEM_PREPAID,
    GET_REDEEM_PREPAID_SUCCESS,
    GET_REDEEM_PREPAID_FAILED,
    GET_USED_POINT_HISTORY,
    GET_USED_POINT_HISTORY_FAILED,
    GET_USED_POINT_HISTORY_SUCCESS,
    GET_RECEIVED_POINT_HISTORY,
    GET_RECEIVED_POINT_HISTORY_FAILED,
    GET_RECEIVED_POINT_HISTORY_SUCCESS,
    REDEEM_PREPAID_TRANSACTION,
    REDEEM_PREPAID_TRANSACTION_FAILED,
    REDEEM_LOCATIONS,
    REDEEM_LOCATIONS_SUCCESS,
    REDEEM_LOCATIONS_FAILED,
    REDEEMED_ITEMS,
    REDEEM_POINTS,
    REDEEM_POINTS_FAILED,
    REDEEM_POINTS_SUCCESS,
    REDEEMED_PRODUCTS,
    REDEEMED_PRODUCTS_FAILED,
    REDEEMED_PRODUCTS_SUCCESS,
    REDEEM_WATER,
    REDEEM_WATER_FAILED,
    REDEEM_WATER_SUCCESS,
    GET_REDEEMED_LIST,
    GET_REDEEMED_LIST_FAILED,
    GET_REDEEMED_LIST_SUCCESS,
    SHOW_QR_MODAL,
    UNMOUNT_QR_MODAL,
    WATER_UNMOUNT,
    REDEEM_PREPAID_UNMOUNT,
    REDEEM_VOUCHER_UNMOUNT,
    GET_STAMPS_HISTORY,
    GET_STAMPS_HISTORY_FAILED,
    GET_STAMPS_HISTORY_SUCCESS,
    FETCH_STAMPS_LIST,
    FETCH_STAMPS_LIST_FAILED,
    FETCH_STAMPS_LIST_SUCCESS,
    PURCHASE_CASH_VOUCHER,
    PURCHASE_CASH_VOUCHER_FAILED,
    PURCHASE_CASH_VOUCHER_SUCCESS,
    VOUCHER_CHECK_PIN,
    VOUCHER_CHECK_PIN_FAILED,
    VOUCHER_CHECK_PIN_SUCCESS,
    FETCH_CASH_VOUCHER_MERCHANTS,
    FETCH_CASH_VOUCHER_MERCHANTS_FAILED,
    FETCH_CASH_VOUCHER_MERCHANTS_SUCCESS,
    FETCH_MERCHANT_CASH_VOUCHERS,
    FETCH_MERCHANT_CASH_VOUCHERS_FAILED,
    FETCH_MERCHANT_CASH_VOUCHERS_SUCCESS,
    FETCH_CASH_VOUCHER_HISTORY,
    FETCH_CASH_VOUCHER_HISTORY_FAILED,
    FETCH_CASH_VOUCHER_HISTORY_SUCCESS,
    FETCH_ADS,
    FETCH_ADS_FAILED,
    FETCH_ADS_SUCCESS,
    FETCH_QR_STRING,
    FETCH_QR_STRING_FAILED,
    FETCH_QR_STRING_SUCCESS,
    UNMOUNT_PURCHASE_VOUCHER,
    UNMOUNT_USER_VOUCHERS,
} from "../actions/type";
  
const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    verified: false,
    success: false,
    products: [],
    redeemed_products: [],
    used_point_history: [],
    received_point_history: [],
    redeemed_list: [],
    vouchers: [],
    prepaid: [],
    points: '',
    locations: [],
    location: [],
    count: '',
    showQR: false,
    items: [] as { key: number; payload: string; }[],
    stamps_history: [],
    stamps_list: [],
    merchant_list: [],
    merchant_vouchers: [],
    cash_voucher_history: [],
    ads: [],
    qr_string: '',
};
  
  
export default (state = INITIAL_STATE, action : any) => {
    switch (action.type) {
        case GET_REDEEM_PRODUCTS:
            return {...state, loading: true};
        case GET_REDEEM_PRODUCTS_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case GET_REDEEM_PRODUCTS_SUCCESS:
            return {...state, products: action.payload.results.results, loading: false};
        case GET_REDEEM_VOUCHERS:
            return {...state, loading: true};
        case GET_REDEEM_VOUCHERS_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case GET_REDEEM_VOUCHERS_SUCCESS:
            return {...state, vouchers: action.payload.results.results, loading: false};
        case GET_REDEEM_PREPAID:
            return {...state, loading: true};
        case GET_REDEEM_PREPAID_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case GET_REDEEM_PREPAID_SUCCESS:
            return {...state, loading: false, prepaid: action.payload.results.results};
    
        case REDEEM_PREPAID_TRANSACTION:
            return {...state, loading: true};
        case REDEEM_PREPAID_TRANSACTION_FAILED:
            return {...state, error: true, message: action.payload, prepaid: state.prepaid};
    
        // case REDEEM_LOCATIONS:
        //   return {...state, loading: true};
        // case REDEEM_LOCATIONS_FAILED:
        //   return {...state, loading: false, error: true, message: action.payload};
        // case REDEEM_LOCATIONS_SUCCESS:
        //   return {...state, loading: false, locations: action.payload.results.results};
    
        case REDEEM_POINTS:
            return {...state, loading: true};
        case REDEEM_POINTS_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case REDEEM_POINTS_SUCCESS:
            return {...state, loading: false, message: action.payload.results.message, points: action.payload.results.results[0].reward_point};
    
        case REDEEMED_PRODUCTS:
            return {...state, loading: true};
        case REDEEMED_PRODUCTS_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case REDEEMED_PRODUCTS_SUCCESS:
            return {...state, loading: false, message: action.payload};
    
        case REDEEM_WATER:
            return {...state, loading: true};
        case REDEEM_WATER_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case REDEEM_WATER_SUCCESS:
            return {...state, loading: false, showQR: true};
    
        case PURCHASE_CASH_VOUCHER: 
            return {...state, loading: true};
        case PURCHASE_CASH_VOUCHER_FAILED:
            return{...state, loading: false, error:true, message: action.payload};
        case PURCHASE_CASH_VOUCHER_SUCCESS:
            return {...state, loading: false, showQR: true};
    
        case WATER_UNMOUNT:
            return {...INITIAL_STATE};
    
        case REDEEM_PREPAID_UNMOUNT:
            return {...INITIAL_STATE, vouchers:state.vouchers, prepaid:state.prepaid};
    
        case REDEEM_VOUCHER_UNMOUNT:
            return {...INITIAL_STATE, vouchers:state.vouchers, prepaid:state.prepaid};
        
        case UNMOUNT_PURCHASE_VOUCHER:
            return {...state, error: false, message: ''};
        
        case UNMOUNT_USER_VOUCHERS:
            return {...state, cash_voucher_history: []};
    
        case SHOW_QR_MODAL:
            return {...state, showQR: true};
    
        case UNMOUNT_QR_MODAL:
            return {...state , showQR: false};
    
        case GET_REDEEMED_LIST:
            return {...state, loading: true};
        case GET_REDEEMED_LIST_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case GET_REDEEMED_LIST_SUCCESS:
            return {
            ...state,
            loading: false,
            redeemed_list: action.payload.results.results,
            count: action.payload.results.results.length,
            };
    
        case GET_USED_POINT_HISTORY:
            return {...state, loading: true};
        case GET_USED_POINT_HISTORY_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case GET_USED_POINT_HISTORY_SUCCESS:
            return {
            ...state,
            loading: false,
            used_point_history: action.payload.results.results,
            };
        case GET_RECEIVED_POINT_HISTORY:
            return {...state, loading: true};
        case GET_RECEIVED_POINT_HISTORY_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case GET_RECEIVED_POINT_HISTORY_SUCCESS:
            return {
            ...state,
            loading: false,
            received_point_history: action.payload.results.results,
            };
    
        //   case REDEEMED_ITEMS:
        //     return {
        //         ...state,
        //         items: state.items.concat({key: Math.random(), payload: JSON.stringify(action.payload.results)}),
        //     };

        case REDEEMED_ITEMS:
            const newItem = { key: Math.random(), payload: JSON.stringify(action.payload.results) };
            return {
            ...state,
            items: [...state.items, newItem],
            };
    
        case GET_STAMPS_HISTORY:  
            return {...state, loading: true};
        case GET_STAMPS_HISTORY_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case GET_STAMPS_HISTORY_SUCCESS:
            return {
            ...state,
            loading: false,
            stamps_history: action.payload.results.results,
            };
    
        case FETCH_STAMPS_LIST:
            return {...state, loading: true};
        case FETCH_STAMPS_LIST_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case FETCH_STAMPS_LIST_SUCCESS:
            return {...state, loading: false, stamps_list:action.payload.results.results}
    
        case VOUCHER_CHECK_PIN:
            return {...state, loading: true};
        case VOUCHER_CHECK_PIN_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case VOUCHER_CHECK_PIN_SUCCESS:
            return {...state, loading: false, showQR: true}
    
        case FETCH_CASH_VOUCHER_MERCHANTS:
            return {...state, loading: true};
        case FETCH_CASH_VOUCHER_MERCHANTS_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case FETCH_CASH_VOUCHER_MERCHANTS_SUCCESS:
            return {...state, loading: false, merchant_list:action.payload.results.results}
        
        case FETCH_MERCHANT_CASH_VOUCHERS:
            return {...state, loading: true};
        case FETCH_MERCHANT_CASH_VOUCHERS_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case FETCH_MERCHANT_CASH_VOUCHERS_SUCCESS:
            return {...state, loading: false, merchant_vouchers:action.payload.results.results}
    
        case FETCH_CASH_VOUCHER_HISTORY:
            return {...state, loading: true};
        case FETCH_CASH_VOUCHER_HISTORY_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case FETCH_CASH_VOUCHER_HISTORY_SUCCESS:
            return {...state, loading: false, cash_voucher_history:action.payload.results.results}
    
        case FETCH_ADS:
            return {...state, loading: true};
        case FETCH_ADS_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case FETCH_ADS_SUCCESS:
            return {...state, loading: false, ads:action.payload.results.results}
    
        case FETCH_QR_STRING:
            return {...state, loading: true};
        case FETCH_QR_STRING_FAILED:
            return {...state, loading: false, error: true, message: action.payload};
        case FETCH_QR_STRING_SUCCESS:
            return {...state, loading: false, qr_string:action.payload.results.results}
        
        default:
            return state;
    }
};
  
