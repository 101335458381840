import { Box, Card, CardContent } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";

const ClaimItemPrepaid = () => {
    const isMobile: boolean = useCheckIsMobile();
    const location = useLocation();

    const value = new URLSearchParams(location.search).get('value');
    const label = new URLSearchParams(location.search).get('label');

    console.log(value);
    console.log(label);

    const [phoneNumber, setPhoneNumber] = useState<string>("0191231234");
    const [amount, setAmount] = useState<string>("");
    const [pinNumber, setPinNumber] = useState<string>("");

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const phoneNumber = formData.get('accountNumber') as string;
        const amount = formData.get('amount') as string;
        const pinNumber = formData.get('pin') as string;
        console.log({ phoneNumber, amount, pinNumber });
    };

    const handleItemClick = () => {
        setAmount(amount)
    };

    return (

        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={"Claim Prepaid"} subtitle={"Select prepaid value"} />
            </Box>
            <Grid2
                container
            >
                <Grid2
                    xs={12} md={6}
                >
                    <Card
                        sx={styles(isMobile).cardStyle}
                        elevation={3}
                    >
                        <CardContent>

                        </CardContent>
                    </Card>
                </Grid2>
            </Grid2>
        </Box>
    )
}

const styles = (isMobile: boolean) => ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)',
        overflow: 'auto'
    },
    cardStyle: {
        mb: 2,
        borderRadius: 2
    },
    img: {
        width: 45,
        height: 38,
    }
});

export default ClaimItemPrepaid;
