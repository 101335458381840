import {
    TOPUP_AMOUNT_CHANGE,
    TOPUP_FAILED, TOPUP_FETCH, TOPUP_SUCCESS,
    TOPUP_TRANSACTION, TOPUP_TRANSACTION_FAILED, TOPUP_UNMOUNT,
} from "../actions/type";
  
const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    amount: 0.00
}
  
export default (state = INITIAL_STATE, action : any) => {
    switch (action.type){
        case TOPUP_TRANSACTION:
            return { ...state, loading: true };
        case TOPUP_TRANSACTION_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case TOPUP_UNMOUNT:
            return INITIAL_STATE;
        case TOPUP_FETCH:
            return { ...state, loading: true, error: false, message: ''}
        case TOPUP_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case TOPUP_SUCCESS:
            return INITIAL_STATE;
        case TOPUP_AMOUNT_CHANGE:
            return { ...state, amount: action.payload };
        default:
            return state;
    }
}
  