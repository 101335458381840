import { createTheme } from "@mui/material/styles";

export const tokens = () => ({
    primary: {
        100: '#FABBC3',
        200: '#F795A2',
        300: '#F56F81',
        400: '#F2495F',
        500: '#EF233E',
        600: '#CB0F28',
        700: '#970B1D',
        800: '#630713',
        900: '#2E0309'
    },
    black: {
        100: '#B8B8B8',
        200: '#A3A3A3',
        300: '#8F8F8F',
        400: '#7A7A7A',
        500: '#666666',
        600: '#4A4A4A',
        700: '#2E2E2E',
        800: '#121212',
        900: '#000000'
    },
    green: {
        100: '#CCF1E7',
        200: '#ACE8D9',
        300: '#8CE0CA',
        400: '#6CD7BB',
        500: '#4CCEAC',
        600: '#31B190',
        700: '#24856C',
        800: '#185948',
        900: '#0C2D25'
    },
    blue: {
        100: '#F4F7FD',
        200: '#D2E1F5',
        300: '#B0CAEE',
        400: '#8FB4E7',
        500: '#6D9DE0',
        600: '#3F7ED6',
        700: '#2763B6',
        800: '#1D4A88',
        900: '#13305A'
    },
    white: {
        100: '#FFFFFF',
        200: '#FFFFFF',
        300: '#FFFFFF',
        400: '#FFFFFF',
        500: '#FFFFFF',
        600: '#E3E3E3',
        700: '#C7C7C7',
        800: '#ABABAB',
        900: '#8F8F8F'
    },
    gray: {
        100: '#D2D2D2',
        200: '#BDBDBD',
        300: '#A9A9A9',
        400: '#949494',
        500: '#808080',
        600: '#646464',
        700: '#484848',
        800: '#2C2C2C',
        900: '#101010'
    },
    gold: {
      100: '#FFDF00',
      200: '#FFD700',
      300: '#C8B560',
      400: '#AF9B43',
      500: '#967C1E',
      600: '#765016',
      700: '#5A3B10',
      800: '#3D290B',
      900: '#201506',
    }    
});

// theme settings
export const themeSettings = () => {
    const colors = tokens();
    return {
        palette: {
            primary: {
                main: colors.primary[500]
            },
            secondary: {
                main: colors.white[500]
            },
            neutral: {
                dark: colors.black[700],
                main: colors.black[500],
                light: colors.black[100]
            },
            background: {
                default: colors.white[500]
            }
        },
        typography: {
            fontFamily: ["Montserrat", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Montserrat", "sans-serif"].join(","),
                fontSize: 40
            },
            h2: {
                fontFamily: ["Montserrat", "sans-serif"].join(","),
                fontSize: 32
            },
            h3: {
                fontFamily: ["Montserrat", "sans-serif"].join(","),
                fontSize: 24
            },
            h4: {
                fontFamily: ["Montserrat", "sans-serif"].join(","),
                fontSize: 20
            },
            h5: {
                fontFamily: ["Montserrat", "sans-serif"].join(","),
                fontSize: 16
            },
            h6: {
                fontFamily: ["Montserrat", "sans-serif"].join(","),
                fontSize: 14
            }
        }
    }
}

export const theme = createTheme(themeSettings());

export { createTheme };

