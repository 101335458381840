import {
    PAY_NOW_FETCH_BOOKINGS,
    PAY_NOW_FETCH_BOOKINGS_FAILED,
    PAY_NOW_FETCH_BOOKINGS_SUCCESS,
    PAY_NOW_WALLET,
    PAY_NOW_WALLET_FAILED,
    PAY_NOW_DISMISS_ERROR,
    PAY_NOW_FPX,
    PAY_NOW_FPX_FAILED,
    PAY_NOW_FPX_STATUS,
    PAY_NOW_FPX_STATUS_FAILED,
    PAY_NOW_FPX_STATUS_SUCCESS,
    BOOK_RATE_CANCEL,
    BOOK_RATE_CANCEL_FAILED,
    BOOK_RATE_CANCEL_SUCCESS,
    BOOK_RATE_CANCEL_DISMISS_MESSAGE,
    BOOK_RATE_QUEUE,
    BOOK_RATE_QUEUE_FAILED,
    BOOK_RATE_QUEUE_SUCCESS 
} from "../actions/type";

const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    status:'',
    bookings: [],
    showMessage: false,
    error1:false,
    loading1:false,
    message1:'',
    error2 :false,
    loading2:false,
    message2:'',
    renderForgotPin: undefined
}

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type){
        case PAY_NOW_FETCH_BOOKINGS:
            return { ...state, loading: true };
        case PAY_NOW_FETCH_BOOKINGS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload, renderForgotPin: INITIAL_STATE.renderForgotPin };
        case PAY_NOW_FETCH_BOOKINGS_SUCCESS:
            return { ...state, loading: false, error: false, bookings: action.payload }
        case PAY_NOW_WALLET:
            return { ...state, loading: true, error: false, message: '' };
        case PAY_NOW_WALLET_FAILED:
            return { ...state, loading: false, error: true, message: action.payload, renderForgotPin: action.payload2 };
        case PAY_NOW_DISMISS_ERROR:
            return { ...state, error: false, message: ''};
        case BOOK_RATE_CANCEL:
            return { ...state, loading1: true};
        case BOOK_RATE_CANCEL_FAILED:
            return { ...state, loading1: false, error1: true, message1: action.payload };
        case BOOK_RATE_CANCEL_SUCCESS:
            return {...state, loading1: false, error1: false, message1: action.payload ,showMessage:true};
        case BOOK_RATE_CANCEL_DISMISS_MESSAGE:
            return INITIAL_STATE
        case BOOK_RATE_QUEUE:
            return { ...state, loading2: false };
        case BOOK_RATE_QUEUE_FAILED:
            return { ...state, loading2: false, error2: true, message2: action.payload };
        case BOOK_RATE_QUEUE_SUCCESS:
            return { ...state, loading2: false, error2: false, status: action.payload , message2: action.payload1, showMessage: true };
        case PAY_NOW_FPX:
            return { ...state, loading: true, error: false, message: '' };
        case PAY_NOW_FPX_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case PAY_NOW_FPX_STATUS:
            return { ...state, loading: true, error: false, message: ''}
        case PAY_NOW_FPX_STATUS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case PAY_NOW_FPX_STATUS_SUCCESS:
            return INITIAL_STATE;
        default:
            return state;
    }
}
  