import {
    HOME_DISMISS_ERROR,
    HOME_GET_BALANCE,
    HOME_GET_BALANCE_FAILED,
    HOME_GET_BALANCE_SUCCESS, HOME_UPDATE_WALLET_BALANCE,
    HOME_GET_BALANCE_UNUSED_MONEYPIN,
    HOME_GET_BALANCE_UNUSED_MONEYPIN_SUCCESS,
    HOME_GET_BALANCE_UNUSED_MONEYPIN_FAILED,
} from "../actions/type";
  
const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    balance: "0.00",
    pending_amt: null,
    balance_unusedpin: "0.00",
    current_app_version: undefined,
    is_ic_expired: 0
}
  
export default (state = INITIAL_STATE, action : any) => {
    switch (action.type){
    case HOME_GET_BALANCE:
        return { ...state, loading: true, error: false, message: ''}
    case HOME_GET_BALANCE_FAILED:
        return { ...state, loading: false, error: true, message: action.payload };
    case HOME_GET_BALANCE_SUCCESS:
        return { ...INITIAL_STATE, balance: action.payload.balance, pending_amt: action.payload.pending_amt,
        balance_unusedpin: action.payload.balance_unusedpin, current_app_version: action.payload.current_app_version,
        is_ic_expired: action.payload?.is_ic_expired }
    case HOME_DISMISS_ERROR:
        return { ...state, error: false, message: ''}
    case HOME_UPDATE_WALLET_BALANCE:
        return { ...INITIAL_STATE, balance: action.payload.balance,
        pending_amt: action.payload.pending_amt || null, current_app_version: state.current_app_version,
        is_ic_expired: state.is_ic_expired };
    case HOME_GET_BALANCE_UNUSED_MONEYPIN:
        return { ...state, loading: true, error: false, message: ''}
    case HOME_GET_BALANCE_UNUSED_MONEYPIN_FAILED:
        return { ...state, loading: false, error: true, message: action.payload };
    case HOME_GET_BALANCE_UNUSED_MONEYPIN_SUCCESS:
        return { ...state, balance_unusedpin: action.payload.balance_unusedpin }
    default:
        return state;
    }
}
  