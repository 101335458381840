import { NavigateFunction, useNavigate } from "react-router-dom";
import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { HOME_UPDATE_WALLET_BALANCE, PREPAID_LIST_FETCH, PREPAID_LIST_FETCH_FAILED, PREPAID_LIST_FETCH_SUCCESS, PREPAID_PHONE_NUMBER_CHANGE, PREPAID_TRANSACTION, PREPAID_TRANSACTION_FAILED, PREPAID_TRANSACTION_SUCCESS, PREPAID_UNMOUNT } from "./type";

export const getPrepaidList = (lang: any) => {
    return async (dispatch: any) => {
        try{
            dispatch({ type: PREPAID_LIST_FETCH });
            let input: any = {
            ver: CONFIG.ver,
            user_agent: navigator.userAgent,
            app_id: CONFIG.app_id,
            m1: "GetPrepaidList",
            lang: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('prepaidlist', 'POST', headers, input);
            if (!resultsFromAPI)
                return dispatch({ type: PREPAID_LIST_FETCH_FAILED, payload: "001: Something went wrong, try again"});
            if (resultsFromAPI.error !== undefined)
                return dispatch({ type: PREPAID_LIST_FETCH_FAILED, payload: "002: Something went wrong, please check your Internet Connection and try again"});
    
            if (!resultsFromAPI.hasOwnProperty("results"))
                return dispatch({ type: PREPAID_LIST_FETCH_FAILED, payload: "003: Something went wrong, try again"});
            let results = resultsFromAPI.results;
    
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0)
                    return dispatch({ type: PREPAID_LIST_FETCH_FAILED, payload: `[${results.code}] : ${results.message || ""}` });
        
                if (!results.hasOwnProperty("list"))
                    return dispatch({ type: PREPAID_LIST_FETCH_FAILED, payload: "004: Something went wrong, try again"});
        
                return dispatch({ type: PREPAID_LIST_FETCH_SUCCESS, payload: results.list})
            }
    
            return dispatch({ type: PREPAID_LIST_FETCH_FAILED, payload: "005: Something went wrong, try again"});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: PREPAID_LIST_FETCH_FAILED, payload: "OOPS SOMETHING WENT WRONG"});
        }
    }
}

export const unMountPrepaidView = () => {
    return { type: PREPAID_UNMOUNT }
}
  
export const prepaidChangePhoneNumber = (number = '') => {
    return { type: PREPAID_PHONE_NUMBER_CHANGE, payload: number };
}
  
export const createPrepaidTransaction = (lang: any, navigate: NavigateFunction, selectedTelco: any, phone: any, pin: any, selectedDenominator: any, user: any, enteredAmount: any) => {
    return async (dispatch: any) => {
        dispatch({ type: PREPAID_TRANSACTION })
        try{
            if (user === '')
                return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "9999: Something went wrong, try again"});
            if (phone === "")
                return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "001: Phone number required"});
            let amount = '';
            if (!pin || pin === '')
                return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "002: Please Enter Pin"});
            if (selectedDenominator === ''){
                if (enteredAmount === '')
                    return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "Please Select Amount or Enter amount for EReload"});
                else
                    amount = enteredAmount; //incase of EReload
            }else {
                if (!selectedDenominator.hasOwnProperty("value"))
                    return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "003: Something went wrong, try again"});
                amount = selectedDenominator.value;
            }
            // if (navigation === undefined)
            //     return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "004: Something went wrong, try again"});
            if (selectedTelco === undefined)
                return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "005: Something went wrong, try again"});
            // if (!selectedTelco.hasOwnProperty("params"))
            //     return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "006: Something went wrong, try again"});
            if (!selectedTelco.hasOwnProperty("act_id"))
                return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "007: Something went wrong, try again"});
            if (!selectedTelco.hasOwnProperty("id"))
                return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "008: Something went wrong, try again"});
            
            let m1 = selectedTelco.id;
            let m2 = selectedTelco.name;
            let act = selectedTelco.act_id;
        
            let input: any = {
                ver: CONFIG.ver,
                act: String(act),
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                p1: phone,
                p2: String(amount),
                p3: pin,
                p4: selectedTelco.hasOwnProperty("isEReload") ? selectedTelco.isEReload : 0,
                m1: m1,
                m2: m2,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            console.log(input)
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI)
                return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "009: Something went wrong, try again"});
            if (resultsFromAPI.error !== undefined)
                return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "010: Something went wrong, please check your Internet Connection and try again"});
            if (!resultsFromAPI.hasOwnProperty("results"))
                return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "011: Something went wrong, try again"});
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")){
                if (results.code !== 0)
                    return dispatch({ type: PREPAID_TRANSACTION_FAILED,
                    payload: `[${results.code}] : ${results.message || ""}`,
                    payload2: results?.renderForgotPin || undefined
                    });
        
                if (results.hasOwnProperty("balance") && results.balance)
                    dispatch({ type: HOME_UPDATE_WALLET_BALANCE,
                    payload: {balance: results.balance, pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null }});

                return navigate('/receipt', { state : { receiptInfo: results.receipt, messageKey: 'prepaidselected.receipt.subTitle' } });
            }
    
            return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "012: Something went wrong, try again"});
        }
        catch (e){
            console.log(e);
            return dispatch({ type: PREPAID_TRANSACTION_FAILED, payload: "Oops something went wrong!"})
        }
    }
}