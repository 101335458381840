import { Box, Button, Card, CardContent, CircularProgress, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import Header from "../../components/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../utils";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { SetStateAction, useState } from "react";
import QrReader from "react-qr-reader";
import { CONFIG } from "../../config";
import { useNavigate } from "react-router-dom";
import DialogAlert from "../../components/DialogAlert";

const ScanCorporateID = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [corporateID, setCorporateID] = useState('');
    const [loaderObject, setLoaderObject] = useState({loading: false, error: false, message:''});
    const [pin, setPin] = useState('');
    const [scanQR, setScanQR] = useState(false);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const [selected, setSelected] = useState<"environment" | "user">("environment");

    const setCorporateIDToUser = async () : Promise<void> => {
        try {
            if (pin === '') {
                return setLoaderObject({ loading: false, error: true, message: 'Pin required' });
            }
            setLoaderObject({ loading: true, error: false, message: '' });
            let input: any = {
                ver: CONFIG.ver,
                act: String(911),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: user?.hasOwnProperty("account_id") ? user?.account_id : '',
                p2: corporateID,
                p3: pin || '',
                m1: "Set Corporate ID",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI)
                return setLoaderObject({ loading: false, error: true, message: '001: Something went wrong, try again' });
            if (resultsFromAPI.error !== undefined)
                return setLoaderObject({
                    loading: false,
                    error: true,
                    message: '002: Something went wrong, please check your Internet Connection and try again'
                });
            if (!resultsFromAPI.hasOwnProperty("results"))
                return setLoaderObject({ loading: false, error: true, message: '003: Something went wrong, try again' });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0)
                return setLoaderObject({
                    loading: false,
                    error: true,
                    message: `[${results.code}] : ${results.message || ""}`
                });
        
                setLoaderObject({loading: false, error: false, message: ''});
                return navigate(-1);
            }
            return setLoaderObject({ loading: false, error: true, message: '004: Something went wrong, try again' });
        }
        catch (e) {
            console.log(e);
            setLoaderObject({loading: false, error: true, message: 'OOPS SOMETHING WENT WRONG'})
        }
    }

    if(scanQR){
        return(
            <Box m="20px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={'Community Details'} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6} lg={4}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>                     
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="h5">
                                    {getLabelValue(lang, 'profile.scan.instructions')}
                                </Typography>
                            </Box>
                            <QrReader
                                facingMode={selected}
                                delay={1000}
                                onError={() => setLoaderObject({ loading: false, error: false, message: 'QRCode Error'})}
                                onScan={(data: any) => {
                                    if (data) {
                                        setCorporateID(data);
                                        setScanQR(false);
                                    }
                                }}
                            />
                            <Grid container spacing={2} alignItems="center" justifyContent="center" mt={1}>
                            <Grid item xs={6} md={6} lg={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <FormControl variant="outlined" sx={{ width: '100%' }}>
                                    <Select
                                    value={selected}
                                    onChange={(e) => setSelected(e.target.value as "environment" | "user")}
                                    >
                                    <MenuItem value={"environment"}>Back Camera</MenuItem>
                                    <MenuItem value={"user"}>Front Camera</MenuItem>
                                    </Select>
                                </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setScanQR(false)}
                                    sx={{ width: '100%' }}
                                >
                                    Stop Scan
                                </Button>
                                </Box>
                            </Grid>
                            </Grid>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
            </Box>
        )
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={"Scanned Corporate ID"} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6} lg={4}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <Box
                                    sx={{ textAlign: 'left' }}
                                >
                                    <Typography variant="h5" sx={{ fontWeight:'bold' }}>
                                        {'Corporate ID'}
                                    </Typography>
                                </Box>                                     
                                <TextField
                                    label={'Scanned Corporate ID'}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={corporateID}
                                    disabled
                                />
                                <TextField
                                    label={getLabelValue(lang, 'profile.scan.pin')}
                                    margin="normal"
                                    required
                                    fullWidth
                                    type='password'
                                    autoFocus
                                    value={pin}
                                    onChange={(e: { target: { value: SetStateAction<string>; }; }) => {
                                        if (loaderObject.error)
                                        setLoaderObject({ loading: false, error: false, message: ''});
                                        setPin(e.target.value);
                                    }}
                                    inputProps={{
                                        maxLength: 6,
                                        style: { textAlign: 'center' }, 
                                    }}                    
                                />
                                <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={loaderObject.loading}
                                    onClick={async () => await setCorporateIDToUser()}
                                    endIcon={loaderObject.loading ? <CircularProgress size={20} /> : null}
                                    sx={{ mt: 3, mb: 1 }}
                                    >
                                    {getLabelValue(lang, 'profile.upgrade.ic.submit')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        onClick={() => {
                                            setCorporateID('');
                                            setLoaderObject({loading: false, error: false, message: ''});
                                            setPin('');
                                            setScanQR(true);
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        sx={{ mt: 3, mb: 1 }}
                                    >
                                    {'Scan'}
                                    </Button>
                                </Grid>
                                </Grid>                            
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
                {loaderObject.error &&
                    <DialogAlert
                        open={loaderObject.error}
                        onSuccess={() => setLoaderObject({loading: false, error: false, message: ''})}
                        title={'Attention'}
                        content={new Array(loaderObject.message)}
                        yesButton={'OK'}
                    />
                }
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default ScanCorporateID;
