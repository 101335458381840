import {useState, useEffect} from "react";
import { Dimension } from "../type";

const useGetScreenDimensions = (): Dimension => {
    const [dimension, setDimension] = useState<Dimension>({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleScreenSizeChange = () => {
        return setDimension({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    useEffect(() => {
        window.addEventListener('resize', handleScreenSizeChange);

        return () => {
            window.removeEventListener('resize', handleScreenSizeChange);
        }
    },[]);

    return dimension;
}

export default useGetScreenDimensions;
