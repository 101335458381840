import { useEffect, useRef } from "react";
import { redeemPoints } from "../actions/RedeemAction";
import { RootState, useAppDispatch } from "../store/store";
import { useSelector } from "react-redux";
import { getLabelValue } from "../utils";
import { Box, Typography } from "@mui/material";
import { tokens } from "../theme";

const colors =  tokens();
const KitaHeader = () => {
    const {loading, message, points} = useSelector((state: RootState) => state.redeem);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(redeemPoints(user, lang));
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {!loading && 
                <Box display="flex" flexDirection="row" alignItems="center" sx={{ mb: 1, borderRadius: '10px', backgroundColor: 'success.main', color: 'success.contrastText', textAlign: 'center', p: 1 }}>
                    <Typography variant="h4">
                        <span>{points}</span>
                        <span> {getLabelValue(lang, 'kita.points')} {getLabelValue(lang, 'kita.earned')}</span>
                    </Typography>
                </Box>
            }
            <Typography
                variant="h6"
                color={colors.black[100]}
                sx={{ mb: 1, textAlign: 'left' }} 
            >
                {message}
            </Typography>            
        </Box>
    )
}

export default KitaHeader;
