import { PREPAID_LIST_FETCH, PREPAID_LIST_FETCH_FAILED, PREPAID_LIST_FETCH_SUCCESS, PREPAID_PHONE_NUMBER_CHANGE, PREPAID_TRANSACTION, PREPAID_TRANSACTION_FAILED, PREPAID_TRANSACTION_SUCCESS, PREPAID_UNMOUNT } from "../actions/type";

const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    phone_number: '',
    prepaidList: [],
    renderForgotPin: undefined
}
  
export default (state = INITIAL_STATE, action: any) => {
    switch (action.type){
        case PREPAID_LIST_FETCH:
            return { ...state, loading: true, error: false, message: ''};
        case PREPAID_LIST_FETCH_FAILED:
            return { ...state, loading: false, error: true, message: action.payload,
            renderForgotPin: INITIAL_STATE.renderForgotPin };
        case PREPAID_LIST_FETCH_SUCCESS:
            return { ...state, loading: false, error: false, message: '', prepaidList: action.payload };
        case PREPAID_TRANSACTION:
            return { ...state, loading: true, renderForgotPin: INITIAL_STATE.renderForgotPin };
        case PREPAID_TRANSACTION_FAILED:
            return { ...state, loading: false, error: true, message: action.payload, prepaidList: state.prepaidList,
            renderForgotPin: action?.payload2};
        case PREPAID_UNMOUNT:
            return { ...INITIAL_STATE, phone_number: state.phone_number, prepaidList: state.prepaidList };
        case PREPAID_PHONE_NUMBER_CHANGE:
            return { ...state, phone_number: action.payload, renderForgotPin: INITIAL_STATE.renderForgotPin };
        default:
            return state;
    }
}
  