import { RECEIPT_TRANSACTIONS_FETCH, RECEIPT_TRANSACTIONS_FETCH_FAILED, RECEIPT_TRANSACTIONS_FETCH_SUCCESS, RECEIPT_GET_TRANSACTION, RECEIPT_GET_TRANSACTION_FAILED, RECEIPT_GET_TRANSACTION_SUCCESS, RECEIPT_UNMOUNT, RECEIPT_CHANGE_QUERY_DATE, RECEIPT_SCREEN_UNMOUNT } from "../actions/type";

const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    transactions: [],
    receiptInfo: undefined,
    queryDate: 7
}
  
export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case RECEIPT_TRANSACTIONS_FETCH:
            return { ...state, loading: true, error: false, message: '', receiptInfo: undefined, queryDate: INITIAL_STATE.queryDate};
        case RECEIPT_TRANSACTIONS_FETCH_FAILED:
            return { ...INITIAL_STATE, loading: false, error: true, message: action.payload };
        case RECEIPT_TRANSACTIONS_FETCH_SUCCESS:
            return { ...state, loading:false, transactions: action.payload, queryDate: state.queryDate };
        case RECEIPT_GET_TRANSACTION:
            return { ...state, transactions: state.transactions, loading: true, queryDate: state.queryDate };
        case RECEIPT_GET_TRANSACTION_FAILED:
            return { ...INITIAL_STATE, transactions: state.transactions, error: true, message: action.payload, queryDate: state.queryDate }
        case RECEIPT_GET_TRANSACTION_SUCCESS:
            return { ...state, loading: false, transactions: state.transactions, receiptInfo: action.payload, queryDate: state.queryDate };
        case RECEIPT_UNMOUNT:
            return { ...INITIAL_STATE, transactions: state.transactions };
        case RECEIPT_CHANGE_QUERY_DATE:
            return { ...state, transactions: state.transactions, queryDate: action.payload, loading: true }
        case RECEIPT_SCREEN_UNMOUNT:
            return { ...INITIAL_STATE, transactions: state.transactions };
        default:
            return state;
    }
}
  