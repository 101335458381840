import { useState } from "react";
import { createTheme, tokens } from "../../theme";
import React from "react";
import { useRegister } from "../../actions/RegisterAction";
import { BackendResponse, Error } from "../../type";
import { useNavigate } from "react-router-dom";
import { CONFIG } from "../../config";
import { callBackendAPI, getLabelValue } from "../../utils";
import { Box, Button, Card, CardContent, CircularProgress, Container, CssBaseline, FormControl, Grid, IconButton, MenuItem, Select, SelectChangeEvent, TextField, ThemeProvider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QrReader from 'react-qr-reader'
import Footer from "../../components/Footer";

type IItems = {
    label: string;
    value: number;
    [key: string]: any | undefined;
}

const colors =  tokens();

const Register = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [idNumber, setIDNumber] = useState<string>("");
    const [idType, setIDType] = React.useState('');
    const [nationality, setNationality] = React.useState('');
    const [referralCode, setReferralCode] = useState<string>("");
    const [scanQR, setScanQR] = useState(false);
    const [, idTypes, nationalities] = useRegister();
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ error: false, message: ""});
    const navigate = useNavigate();
    
    const [selected, setSelected] = useState<"environment" | "user">("environment");

    const handleAccountRegistrationPreChecks = async () : Promise<void> => {
        try {
        if (phoneNumber === '')
            return setError({ error: true, message: 'Handphone No. is required'});
        if (!idType)
            return setError({ error: true, message: 'Please select an ID Type'});
        if (idNumber === '')
            return setError({ error: true, message: 'ID Number is required'});
        if (idNumber.includes('-'))
            return setError({ error: true, message: 'ID Number should not have "-" characters'})
        if (!nationality)
            return setError({ error: true, message: 'Please select your nationality'});
        if (error.error)
            setError({ error: false, message: ''});

        let selectedIdType = idTypes.find((item: IItems) => item.label === idType);
        let selectedNationality = nationalities.find((item: IItems) => item.label === nationality);

        setLoading(true);
        let headers: HeadersInit = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }      
        let input: any = {
            ver: CONFIG?.ver,
            act: 901,
            user_agent: navigator.userAgent,
            app_id: CONFIG.app_id,
            p1: phoneNumber.trim(),
            p2: selectedIdType,
            p3: idNumber.trim(),
            p4: selectedNationality,
            p5: referralCode.trim(),
            p6: 0, //rbaRegister
            m1: "Register",
            m2: "",
            lg: lang,
        }
        let resultFromAPI: BackendResponse | undefined = await callBackendAPI('register', 'POST', headers, input);
        if (resultFromAPI === undefined) {
            setLoading(false);
            return setError({ error: true, message: "001: Something went wrong, please try again."});
        }
        if (resultFromAPI?.error !== undefined) {
            setLoading(false);
            return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
        }
        if (!resultFromAPI.hasOwnProperty("results")) {
            setLoading(false);
            return setError({ error: true, message: resultFromAPI.error || "003: Something went wrong, please try again."});
        }
        if (resultFromAPI?.results) {
            let results: any = resultFromAPI.results;
            if (results.code !== 0){
                setLoading(false);
                return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}`});
            }
            if (!results.hasOwnProperty("user")) {
                setLoading(false);
                return setError({ error: true, message: "004: Something went wrong, try again"});
            }
            setLoading(false);
            return navigate('/registerpagetwo', {
                state: {
                    userinput: {
                        phoneNumber,
                        idType: selectedIdType,
                        idNumber,
                        nationality: selectedNationality,
                        referralCode,
                    },
                }
            });         
        }
        setLoading(false);
        return setError({ error: false, message: "005: Something went wrong, please try again."})
        }
        catch (e) {
        setLoading(false);
        return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        if (name === 'idType') {
        setIDType(value as string);
        } else if (name === 'nationality') {
        setNationality(value as string);
        }
    };  
    
    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
            <CssBaseline />
                {scanQR ? (
                    <Card  
                        elevation={3}
                    >
                        <CardContent>
                            <Box mt="5px" mb="25px" ml="5px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="10px"
                                overflow="hidden"
                                boxShadow={2}
                            >
                                <img
                                    alt="logo"
                                    width="230px"
                                    height="60px"
                                    src={'../../assets/mmlogo.png'}
                                    style={{ cursor: "pointer"}}
                                />
                            </Box>
                            </Box>
                            <QrReader
                                facingMode={selected}
                                delay={1000}
                                onError={() => setError({ error: false, message: 'QRCode Error'})}
                                onScan={(data) => {
                                    if (data) {
                                        setReferralCode(data);
                                        setScanQR(false);
                                    }
                                }}
                            />
                            <Grid container spacing={2} alignItems="center" justifyContent="center" mt={1}>
                            <Grid item xs={6} md={6} lg={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <FormControl variant="outlined" sx={{ width: '100%' }}>
                                    <Select
                                    value={selected || undefined}
                                    onChange={(e) => setSelected(e.target.value as "environment" | "user")}
                                    >
                                    <MenuItem value={"environment"}>Back Camera</MenuItem>
                                    <MenuItem value={"user"}>Front Camera</MenuItem>
                                    </Select>
                                </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setScanQR(false)}
                                    sx={{ width: '100%' }}
                                >
                                    Stop Scan
                                </Button>
                                </Box>
                            </Grid>
                            </Grid>                    
                        </CardContent>
                    </Card>
                ) :(
                    <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        py: 3,
                        marginTop: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    >
                        <Box mt="5px" mb="25px" ml="5px">
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="10px"
                            overflow="hidden"
                            boxShadow={2}
                        >
                            <img
                            alt="logo"
                            width="230px"
                            height="60px"
                            src={'../../assets/mmlogo.png'}
                            style={{ cursor: "pointer"}}
                            />
                        </Box>
                        </Box>
                        <Typography variant="h5" sx={{fontWeight: 'bold'}} color='#ef233e'>
                            {getLabelValue(lang, 'register.title')}
                        </Typography>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="15px"
                            mb="5px"
                            ml="5px"
                            mr="5px"
                        >
                            {getLabelValue(lang, 'register.account.title')}
                        </Box>
                        <Box sx={{ mt: 1 }} minWidth={'100%'}>
                            <Box
                                sx={{ textAlign: 'left'  }}
                                mt="5px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.handphone')}
                            </Box>
                            <TextField
                                margin="none"
                                required
                                fullWidth
                                name="phone"
                                type="text"
                                id="phone"
                                autoFocus
                                value={phoneNumber}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const re = /^[0-9\b]+$/;
                                    if (value === '' || re.test(value)) {
                                        setPhoneNumber(value);
                                    }
                                }}
                            />
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="15px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.idType')}
                            </Box>
                            <FormControl sx={{width: '100%', borderRadius: '20px' }}>
                            <Select
                                required
                                labelId="idType"
                                id="idType"
                                name="idType"
                                value={idType}
                                onChange={handleChange}
                            >
                                {idTypes.map((idType) => (
                                <MenuItem key={idType.value} value={idType.label}>
                                    {idType.label}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>             
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="15px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.id')}
                            </Box>
                            <TextField
                                margin="none"
                                required
                                fullWidth
                                name="id"
                                type="text"
                                id="id"
                                value={idNumber}
                                onChange={(e) => setIDNumber(e.target.value)}
                            />
                            <Box
                                sx={{ textAlign: 'left'  }}
                                mt="20px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.nationality')}
                            </Box>
                            <FormControl sx={{width: '100%', borderRadius: '20px', maxHeight: '200px', }}>
                            <Select
                                required
                                labelId="nationality"
                                id="nationality"
                                name="nationality"
                                value={nationality}
                                onChange={handleChange}
                            >
                                {nationalities.map((nationality) => (
                                <MenuItem key={nationality.value} value={nationality.label}>
                                    {nationality.label}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="20px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.rfCode')}
                                <IconButton
                                    onClick={() => setScanQR(true)}
                                    sx={{ marginRight: 1, color: 'black' }} 
                                >
                                    <QrCodeScannerIcon sx={{ fontSize: '1.5rem' }} />
                                </IconButton>
                            </Box>
                            <TextField
                                margin="none"
                                required
                                fullWidth
                                name="referralCode"
                                type="text"
                                id="referralCode"
                                value={referralCode}
                                onChange={(e) => setReferralCode(e.target.value)}
                            /> 
                            {error.error && (
                            <Typography
                                sx={{
                                    mt: '10px',
                                    textAlign: 'center',
                                    color: colors.primary[500],
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {error.message || ''}
                            </Typography>
                            )}           
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                onClick={async () => await handleAccountRegistrationPreChecks()}
                                endIcon={loading ? <CircularProgress size={20} /> : null}
                                sx={{ mt: 3, mb: 1 }}
                            >
                                {getLabelValue(lang, 'login.account.button.next')}
                            </Button>
                            <Button
                                type="submit"
                                fullWidth
                                onClick={() => navigate('/login')}
                                variant="outlined"
                                color="primary"
                                sx={{ mt: 1, mb: 1 }}
                            >
                                {getLabelValue(lang, 'login.account.button.goback')}
                            </Button>
                        </Box>
                    </Box>                 
                )}
        </Container>
        <Footer />  
        </ThemeProvider>
    );
}

const theme = createTheme({
    palette: {
        secondary: {
        main: '#ef233e',
        },
        primary: {
        main: '#212121',
        }
    }
});

const styles = (isMobile: boolean) =>  ({
    cardStyle: {
        mt: 2,
        borderRadius: 2,
        maxHeight: isMobile ? 'auto' : '70vh', 
        overflow: 'auto'
    }
})

export default Register;