import { Box, Button, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { RootState, useAppDispatch } from "../../../store/store";
import { changeAmountRm, changeCountry, changeProvider, clearSelectedBene, fetchRates, resetBookRate, showErrorMessage } from "../../../actions/BookRateAction";
import { extendedAmountTextInput, getConversionString, getLabelValue, numberWithCommas } from "../../../utils";
import Header from "../../../components/Header";
import { BOOK_RATE_CHANGE_BENE } from "../../../actions/type";
import Grid2 from "@mui/material/Unstable_Grid2";
import useCheckIsMobile from "../../../hooks/useCheckIsMobile";

const colors = tokens();

const BookRate = () => {
    const { state } = useLocation();
    const isMobile: boolean = useCheckIsMobile();
    const [value, setValue] = useState("");
    const [toAmount, setToAmount] = useState("");
    const [fromAmount, setFromAmount] = useState("");
    const { lang } = useSelector((state: RootState) => state.user);
    const { loading, error, message, rates, country, provider, amountRM, countryname, providerlabel, selectedBene } = useSelector((state: RootState) => state.bookrate);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    let item: any;
    const bene_country = state?.bene_country;
    const pickup_method_value = state?.pickup_method_value;

    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(fetchRates(lang, bene_country));
            dispatch(resetBookRate());
        }
        if (shouldLockEffect?.current === false) {
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, []);

    let providers = rates[country]?.providers.filter((item: { pickup_method: any; }) => item.pickup_method === pickup_method_value)

    const arrangedproviders = rates[country]?.providers.sort((x: { pickup_method: any; }, y: { pickup_method: any; }) => {
        if (x.pickup_method === pickup_method_value) return -1;  // Move elements with pickup_method value of `a` to the beginning of the array
        if (y.pickup_method === pickup_method_value) return 1;
        return 0;
    });

    const handleToAmountChange = (val: any) => {
        let fromamt: any = isNaN(val) ? 0.00 : extendedAmountTextInput(val);
        setFromAmount(fromamt);

        if (rates[country] && rates[country].providers[provider]) {
            let rate = rates[country].providers[provider].rate_multiple;
            let special_rate = rates[country].providers[provider].rate_special;
            if (!isNaN(rate) && rate !== 0) {
                if (rates[country].hasOwnProperty('special_rate')) {
                    if (rates[country].providers[provider].special_flag == 1) {
                        let preamount: any = Math.round((fromamt * rate) * rates[country]?.ttodecimal) / rates[country]?.ttodecimal
                        let toAmount: any = Math.round((preamount * rates[country]?.special_rate) * rates[country]?.stodecimal) / rates[country]?.stodecimal
                        setToAmount(numberWithCommas(toAmount));
                    }
                    else {
                        let toAmounts: any = Math.round((fromamt * special_rate) * rates[country]?.todecimal) / rates[country]?.todecimal
                        setToAmount(numberWithCommas(toAmounts));
                    }
                }
                else {
                    let namount: any = Math.round((fromamt * rate) * rates[country]?.todecimal) / rates[country]?.todecimal
                    setToAmount(numberWithCommas(namount));
                }
            } else {
                setToAmount("");
            }
        }
    };

    const handleFromAmountChange = (val: any) => {
        let toamt: any = isNaN(val) ? 0.00 : extendedAmountTextInput(val);
        setToAmount(toamt);
        if (rates[country] && rates[country].providers[provider]) {
            let rate = rates[country].providers[provider].rate_multiple;
            if (!isNaN(rate) && rate !== 0) {
                if (rates[country].hasOwnProperty('special_rate')) {
                    let fromAmounts: any = Math.round((toamt / rate) * rates[country]?.sdecimal) / rates[country]?.sdecimal
                    setFromAmount(numberWithCommas(fromAmounts));
                }
                else {
                    let fromnamount: any = Math.round((toamt / rate) * rates[country]?.decimal) / rates[country]?.decimal
                    setFromAmount(numberWithCommas(fromnamount));
                }
            } else {
                setFromAmount("");
            }
        }
    };

    const handleSelectBeneficiary = () => {
        const fromAmountNumber = parseFloat(fromAmount);
        const provider_value = value ? value : arrangedproviders[0]?.value
        const filteredProviders = rates[country]?.providers.filter((provider: { value: any; }) => provider.value === provider_value);
        if (selectedBene) {
            if (filteredProviders[0].pickup_method != pickup_method_value) {
                dispatch(clearSelectedBene());
            }
        }
        if (fromAmountNumber <= 0) {
            dispatch(showErrorMessage(true, "Amount Cannot be Less than Zero"))
        } else {
            dispatch(changeAmountRm(fromAmount))
            navigate("selectbeneficiary", { state: { item, selectedProviderValue: provider_value } })
        }
    }

    return (
        <Box m="20px">
            <Box
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={"Book Rate"} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6} lg={6}
                    >
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="5px"
                                mb="10px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'kirim.bookrate.title')}
                            </Box>
                            <FormControl>
                                <Select
                                    value={rates[country]?.country || (rates.length > 0 ? rates[0].country : '')}
                                    onChange={(event) => {
                                        const selectedItemValue = event.target.value;
                                        const selectedIndex = rates.findIndex(
                                            (rate: any) => rate.value === selectedItemValue
                                        );
                                        dispatch(changeCountry(selectedIndex, selectedItemValue));
                                        dispatch(clearSelectedBene());
                                        setToAmount("");
                                        setFromAmount("");
                                    }}
                                >
                                    {rates.map((rate: any) => (
                                        <MenuItem key={rate.country} value={rate.value}>
                                            {rate.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {rates && rates[country] && rates[country]?.providers && (
                                <FormControl sx={{ mt: 2 }}>
                                    <Select
                                        value={value || (arrangedproviders.length > 0 ? arrangedproviders[0]?.value : '')}
                                        onChange={(event) => {
                                            const selectedItemValue = event.target.value;
                                            const selectedIndex = arrangedproviders.findIndex((rate: any) => rate.value === selectedItemValue);
                                            dispatch(changeProvider(selectedIndex))
                                            setToAmount("");
                                            setFromAmount("");
                                            setValue(selectedItemValue)
                                        }}
                                    >
                                        {arrangedproviders.map((provider: any) => (
                                            <MenuItem key={provider.name} value={provider.value}>
                                                {provider.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                            <Box sx={{ mt: 2, p: 2, borderRadius: '10px', backgroundColor: 'success.main', color: 'success.contrastText', textAlign: 'center' }}>
                                {getConversionString(rates, country, provider)}
                            </Box>
                        </Paper>
                        <Paper sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="5px"
                                mb="10px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'transfer.selected.title')}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                                <TextField
                                    margin="none"
                                    required
                                    fullWidth
                                    type='text'
                                    value={`MYR          |          ${fromAmount}`}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const extractedAmount = inputValue.split('|')[1].trim();
                                        let allowedValue = '';
                                        if (/^\d*\.?\d{0,2}$/.test(extractedAmount)) {
                                            allowedValue = extractedAmount;
                                        } else {
                                            allowedValue = fromAmount;
                                        }
                                        handleToAmountChange(allowedValue);
                                        setFromAmount(allowedValue);
                                    }}
                                    sx={{ mb: "5px", "& input": { textAlign: "center" } }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                                <TextField
                                    margin="none"
                                    required
                                    fullWidth
                                    type='text'
                                    value={`${rates[country] ? rates[country].currency || '' : ''}          |          ${toAmount}`}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const extractedAmount = inputValue.split('|')[1].trim();
                                        let allowedValue = '';
                                        if (/^\d*\.?\d{0,2}$/.test(extractedAmount)) {
                                            allowedValue = extractedAmount;
                                        } else {
                                            allowedValue = fromAmount;
                                        }
                                        handleFromAmountChange(allowedValue);
                                        setToAmount(allowedValue);
                                    }}
                                    sx={{ mb: "5px", mt: "5px", "& input": { textAlign: "center" } }}
                                />
                            </Box>
                            {error && (
                                <Grid
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography textAlign="center" color={colors.primary[500]}>
                                        {message || ''}
                                    </Typography>
                                </Grid>
                            )}
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, mb: 1 }}
                                onClick={handleSelectBeneficiary}
                            >
                                {getLabelValue(lang, 'kirim.bookrate.button')}
                            </Button>
                        </Paper>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) => ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)',
        overflow: 'auto'
    },
    cardStyle: {
        m: 2,
        borderRadius: 3,
        px: 1
    }
})


export default BookRate;