import { FC, useEffect, useRef, useState } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { IError, ISuccess, RouteComponent } from "../../type";
import { CONFIG } from "../../config";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../utils";
import { Box, Button, Card, CardContent, CircularProgress, TextField } from "@mui/material";
import Header from "../../components/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import DialogAlert from "../../components/DialogAlert";
import { useNavigate } from "react-router-dom";
import PinInput from "../../components/PinInput";
import { HOME_UPDATE_WALLET_BALANCE } from "../../actions/type";
import ReceiptMessage from "../../components/ReceiptMessage";

interface Props extends RouteComponent{
    logOut: () => void;
}

const CashOut: FC<Props> = (props) => {
    const { logOut } = props;
    const isMobile: boolean = useCheckIsMobile();
    const [bankDetails, setBankDetails] = useState<any>(undefined);
    const [amount, setAmount] = useState('0.00');
    const [showPin, setShowPin] = useState(false);
    const [pinNumber, setPinNumber] = useState<string>('');
    const { balance, pending_amt } = useSelector((state: RootState) => state.account);
    const [success, setSuccess] = useState<ISuccess>({success: false, data: undefined});
    const { lang, user } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [memberError, setMemberError] = useState<IError>({ error: false, message: "" });
    const [fpxError, setFPXError] = useState<IError>({ error: false, message: "" });
    const [error, setError] = useState<IError>({ error: false, message: "", forgot_pin: 0, navigate: false});
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);
    const TOTAL_BALANCE = Number(balance || 0) + Number(pending_amt || 0);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
           fetchBankDetails();
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])

    const fetchBankDetails = async () => {
        try {
            if (user === undefined) {
                return setError({
                    error: true,
                    message: "001: Something went wrong, try again, please log out and log back in",
                    forgot_pin: 0,
                    navigate: false
                });
            }
            setLoading(true);
            let input: any = {
                ver: CONFIG.ver,
                act: 60200,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                m1: "GetBankDetails",
                lang: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                setLoading(false);
                return setError({ error:true, message: "002: Something went wrong, try again", forgot_pin: 0, navigate: false});
            }
            if (resultsFromAPI.error !== undefined){
                setLoading(false);
                return setError({ error:true,  message: "003: Something went wrong, please check your Internet Connection and try again", forgot_pin: 0, navigate: false});
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                setLoading(false);
                return setError({ error:true, message: "004: Something went wrong, try again", forgot_pin: 0, navigate: false});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0){
                    setLoading(false);

                    if (results.code === -1){
                        return setMemberError({ error: true, message: `${results.message || ""}`})
                    }
    
                    if (results.code === -2){
                        return setFPXError({ error: true, message: `${results.message || ""}`})
                    }

                    return setError({
                        error:true,
                        message: `[${results.code}] : ${results.message || ""}`,
                        forgot_pin: 0,
                        navigate: results?.navigate
                    });
                }
        
                setBankDetails(results?.bank);
                return setLoading(false);
            }
            setLoading(false);
            return setError({ error: true, message: "005: Something went wrong, try again", forgot_pin: 0, navigate: false})
        } catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: "Oops something went wrong!", forgot_pin: 0, navigate: false})
        }
    }
    
    const renderContent = () => {
        if(bankDetails === undefined)
            return null;
        return(
            <CardContent>
                <TextField
                    label={getLabelValue(lang, 'cashout.bank_name.label')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={bankDetails?.bank_name || ''}
                    disabled
                /> 
                <TextField
                    label={getLabelValue(lang, 'cashout.acc_name.label')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={bankDetails?.acc_name || ''}
                    disabled
                /> 
                <TextField
                    label={getLabelValue(lang, 'cashout.acc_no.label')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={bankDetails?.acc_no || ''}
                    disabled
                /> 
                <TextField
                    label={getLabelValue(lang, 'cashout.amount.label')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
                <Box sx={{ mt: 2, mb:2, p: 2, borderRadius: '10px', backgroundColor: 'success.main', color: 'success.contrastText', textAlign: 'center' }}>
                    {`Your current balance: RM ${TOTAL_BALANCE || '0.00'}`}
                </Box>
                <Button
                    type="submit"
                    fullWidth
                    onClick={() => {handleSubmitButton()}}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                >
                    {getLabelValue(lang, 'paybill.proceed')}
                </Button>                                            
            </CardContent>
        )
    }

    const handleSubmitButton = () => {
        try{
            if (bankDetails === undefined) {
                return setError({ error: true,
                    message: 'Failed to execute the operation. Invalid Bank details', 
                    forgot_pin: 0, 
                    navigate: false
                });
            }
            if (Number(amount) === 0) {
                return setError({ error: true, message: 'Amount has to be greater than RM 0.00', forgot_pin: 0, navigate: false});
            }
            if (Number(amount) > TOTAL_BALANCE) {
                return setError({ error: true, message: 'You have Insufficient balance', forgot_pin: 0, navigate: false});
            }

            setError({error: false, message: '', forgot_pin: 0, navigate: false})
            setShowPin(true);
        }
        catch (e) {
            console.log(e);
            setError({error: true, message: 'OOPS SOMETHING WENT WRONG', forgot_pin: 0, navigate: false})
        }
    }

    const handleCashOut = async () => {
        try {
            setLoading(true);
            if (bankDetails === undefined) {
                return setError({
                    error: true,
                    message: 'Failed to execute the operation. Invalid Bank details'
                });
            }
            if (Number(amount) === 0) {
                return setError({
                    error: true,
                    message: 'Amount has to be greater than RM 0.00', 
                    forgot_pin: 0, 
                    navigate: false
                });
            }
            if (Number(amount) > TOTAL_BALANCE) {
                return setError({ error: true, message: 'You have Insufficient balance', forgot_pin: 0, navigate: false});
            }
            if (!pinNumber || pinNumber === ''){
                setShowPin(false);
                setLoading(false);
                return setError({ error: true, message: "Please enter Pin", forgot_pin: 0, navigate: false});
            }
            let input: any = {
                ver: CONFIG.ver,
                act: 60201,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                p1: bankDetails,
                p2: Number(amount),
                p3: pinNumber,
                m1: "CASH OUT",
                lang: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                setLoading(false);
                setPinNumber('');
                return setError({ error:true, message: "002: Something went wrong, try again", forgot_pin: 0, navigate: false});
            }
            if (resultsFromAPI.error !== undefined){
                setLoading(false);
                setPinNumber('');
                return setError({
                    error:true,
                    message: "003: Something went wrong, please check your Internet Connection and try again", 
                    forgot_pin: 0, 
                    navigate: false
                });
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                setLoading(false);
                setPinNumber('');
                return setError({ error:true, message: "004: Something went wrong, try again", forgot_pin: 0, navigate: false});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0){
                    setLoading(false);
                    setPinNumber('');
                    return setError({
                        error:true,
                        message: `[${results.code}] : ${results.message || ""}`, 
                        forgot_pin: 0, 
                        navigate: false
                    });
                }
        
                if (results.hasOwnProperty("balance") && results.balance){
                    dispatch({
                        type: HOME_UPDATE_WALLET_BALANCE,
                        payload: { balance: results.balance,pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null }
                    })
                }

                if (results.code === 0){
                    return setSuccess({success: true, data: {title: results?.message}});             
                }

                return setError({error: true, message: `[${results.code}] : ${results.message || ""}`});
            }
            setLoading(false);
            setPinNumber('');
            return setError({ error: true, message: "005: Something went wrong, try again", forgot_pin: 0, navigate: false})
        } catch (e) {
            console.log(e);
            setLoading(false);
            setPinNumber('');
            return setError({ error: true, message: "Oops something went wrong!", forgot_pin: 0, navigate: false})
        }
    }

    if (success.success) {
        return (
            <ReceiptMessage
                success={success.success}
                data={success.data}
                print
                onOKClick={() => navigate('/')}
            />
        )
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={"Cash Out"} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6} lg={4}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            {renderContent()}
                        </Card>
                    </Grid2>
                </Grid2>
                {showPin && 
                    <PinInput
                        open={showPin}
                        onCancel={() => setShowPin(false)}
                        onSuccess={async () => {
                            setShowPin(false);
                            await handleCashOut();
                        }}
                        yesButton={'Submit'}
                        cancelButton={'Cancel'}
                        pinNumber ={pinNumber}
                        onPinChange={(e: any)=>{
                            setPinNumber(e.target.value)
                        }}
                        lang={lang}
                    />
                }
                {error.error && (
                    <DialogAlert
                        open={error.error}
                        onCancel={() => {
                            setError({ error: false, message: '', forgot_pin: 0, navigate: false});
                            logOut();
                            navigate('/forgotpin', { state: { user: user } });
                        }}
                        onSuccess={() => {
                            if(error.navigate){
                                setError({ error: false, message: '', forgot_pin: 0, navigate: false});
                                navigate('/kirim/verify');
                            }
                            setError({ error: false, message: '', forgot_pin: 0, navigate: false});
                            setPinNumber('');
                        }}
                        title={'Attention'}
                        content={new Array(error.message)}
                        yesButton={'OK'}
                        cancelButton={error.forgot_pin === 1 ? (lang === 'en' ? 'FORGOT PIN' : 'LUPA PIN') : ''}
                        reportIssue={error.reportIssue?.show}
                        req_idx={error.reportIssue?.idx}
                        onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
                        reportTitle={error?.title}
                        reportDetails={error?.details}
                        trans_id={error?.trans_id}
                        ticket_type={error?.ticket_type}
                    />
                )}   
                {memberError.error && (
                    <DialogAlert
                        open={memberError.error}
                        onSuccess={() => {
                            setMemberError({ error: false, message: "" });
                            navigate('/membership');
                        }}
                        title={'Attention'}
                        content={new Array(memberError.message)}
                        yesButton={'OK'}
                    />
                )} 
                {fpxError.error && (
                    <DialogAlert
                        open={fpxError.error}
                        onSuccess={() => {
                            setFPXError({ error: false, message: "" });
                            navigate('/kirim/verify');
                        }}
                        title={'Bank Details Error'}
                        content={new Array(fpxError.message)}
                        yesButton={'Continue'}
                    />
                )}                 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default CashOut;
