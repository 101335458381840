import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Link, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import React from 'react';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { LANGUAGES } from '../../constants/data';
import { addUserObjectToRequest, callBackendAPI, getLabelValue, logError } from '../../utils';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { resetPin, resetPinT2P, unMountProfilePin, verifyCurrentPin, verifyCurrentPinT2P } from '../../actions/ProfileAction';
import { CONFIG } from '../../config';
import { useNavigate } from 'react-router-dom';
import { IError, RouteComponent } from '../../type';
import { updateLanguage } from '../login/userSlice';
import useCheckIsMobile from '../../hooks/useCheckIsMobile';
import Grid2 from '@mui/material/Unstable_Grid2';
import ExpiredPassport from '../../components/ExpiredPassport';

const colors = tokens();
interface Props extends RouteComponent {
    logOut: () => void;
}

const Settings: FC<Props> = (props) => {
    const isMobile: boolean = useCheckIsMobile();
    const { logOut } = props;
    const [currentPin, setCurrentPin] = useState<string>("");
    const [newPin, setNewPin] = useState<string>("");
    const [confirmPin, setConfirmPin] = useState<string>("");
    const [pin, setPin] = useState<string>("");
    const [expanded, setExpanded] = useState<string | false>(false);
    const [loadingAccountDelete, setLoadingAccountDelete] = useState<boolean>(false);
    const [errorAccountDelete, setErrorAccountDelete] = useState<IError>({ error: false, message: '' })
    const { loading, error, message, verified, success } = useSelector((state: RootState) => state.profile);
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const { lang, user } = useSelector((state: RootState) => state.user);

    const isT2P = user && user.hasOwnProperty('t2p_card') && user.t2p_card !== "";
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (success) {
            setOpenDialog(true);
        }
    }, [success]);

    useEffect(() => {
        return () => {
            dispatch(unMountProfilePin());
        }
    }, []);

    const renderPinChangePanel = () => {
        return (
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{
                boxShadow: 2,
                marginTop: 1,
                '&.Mui-expanded': {
                    backgroundColor: 'white',
                },
            }}>
                <AccordionSummary sx={{ marginTop: 3, minHeight: "40px" }}
                    expandIcon={<ExpandMoreIcon />}
                    id="changePin"
                >
                    <Typography variant='h5'>
                        {isT2P ? getLabelValue(lang, 'profile.changeT2Ppin.title') : getLabelValue(lang, 'profile.changepin.title')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: 'center' }}>
                    <Box
                        component="form" noValidate>
                        <Grid container spacing={1.5}>
                            <Grid item xs={12}>
                                <Box
                                    sx={{ textAlign: 'left' }}
                                    mb="5px"
                                    ml="5px"
                                    mr="5px"
                                >
                                    {getLabelValue(lang, 'profile.changepin.current.title')}
                                </Box>
                                <TextField
                                    margin="none"
                                    required
                                    fullWidth
                                    name="oldPin"
                                    placeholder=""
                                    type='password'
                                    id="oldPin"
                                    value={currentPin}
                                    onChange={(e) => setCurrentPin(e.target.value)}
                                    inputProps={{ maxLength: 6, inputMode: 'numeric' }}
                                />
                            </Grid>
                            {verified && (
                                <>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{ textAlign: 'left' }}
                                            mb="5px"
                                            ml="5px"
                                            mr="5px"
                                        >
                                            {getLabelValue(lang, 'profile.changepin.new.title')}
                                        </Box>
                                        <TextField
                                            margin="none"
                                            required
                                            fullWidth
                                            name="newPin"
                                            placeholder=""
                                            type='password'
                                            id="newPin"
                                            value={newPin}
                                            onChange={(e) => setNewPin(e.target.value)}
                                            inputProps={{ maxLength: 6, inputMode: 'numeric' }}
                                            disabled={!verified}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{ textAlign: 'left' }}
                                            mb="5px"
                                            ml="5px"
                                            mr="5px"
                                        >
                                            {getLabelValue(lang, 'profile.changepin.confirmnew.title')}
                                        </Box>
                                        <TextField
                                            margin="none"
                                            required
                                            fullWidth
                                            name="confirmPin"
                                            placeholder=""
                                            type='password'
                                            id="confirmPin"
                                            value={confirmPin}
                                            onChange={(e) => setConfirmPin(e.target.value)}
                                            inputProps={{ maxLength: 6, inputMode: 'numeric' }}
                                            disabled={!verified}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            {error && (
                                <Grid
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography textAlign="center" color={colors.primary[500]}>
                                        {message || ''}
                                    </Typography>
                                </Grid>
                            )}
                        </Box>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmitRequest()}
                            disabled={loading}
                            endIcon={loading ? <CircularProgress size={20} /> : null}
                            sx={{ mt: 2, mb: 1 }}
                        >
                            {getLabelValue(lang, 'profile.upgrade.ic.submit')}
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        );
    };

    const handleSubmitRequest = () => {
        if (verified) {
            dispatch(
                isT2P
                    ? resetPinT2P(user, newPin, confirmPin, lang, user.t2p_card, currentPin)
                    : resetPin(user, newPin, confirmPin, lang)
            );
        }
        else if (!verified || (verified && !success)) {
            dispatch(
                isT2P
                    ? verifyCurrentPinT2P(user, currentPin, lang, user.t2p_card)
                    : verifyCurrentPin(user, currentPin, lang)
            );
        }
    };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSelectLanguage = (event: SelectChangeEvent) => {
        dispatch(updateLanguage(event.target.value));
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setNewPin("");
        setConfirmPin("");
        setCurrentPin("");
    };

    type AlertDialogProps = {
        open: boolean;
        onClose: () => void;
    };

    const AlertDialog: FC<AlertDialogProps> = ({ open, onClose }) => {
        const handleOkClick = () => {
            dispatch(unMountProfilePin());
            onClose();
        };

        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Successfully
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {getLabelValue(lang, 'profile.changepin.success.message')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOkClick} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleCloseConfirmDialog = () => {
        setErrorAccountDelete({ error: false, message: "" });
        setOpenConfirmDialog(false);
    }

    const ConfirmDialog: FC<AlertDialogProps> = ({ open, onClose }) => {
        const handleCancelClick = () => {
            onClose();
        };
        const handleOkClick = () => {
            handleDeleteAccount();
            onClose();
        };

        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title2"
                aria-describedby="alert-dialog-description2"
            >
                <DialogTitle id="alert-dialog-title2">
                    ATTENTION
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description2">
                        {getLabelValue(lang, 'profile.delete.account')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClick} autoFocus>CANCEL</Button>
                    <Button onClick={handleOkClick} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleDeleteAccount = async (): Promise<void> => {
        try {
            setLoadingAccountDelete(true);
            setErrorAccountDelete({ error: false, message: "" });
            if (user === undefined)
                return;

            let input: any = {
                ver: CONFIG?.ver,
                act: 916,
                p1: user.mobile,
                p2: pin
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (resultFromAPI === undefined) {
                setLoadingAccountDelete(false);
                return setErrorAccountDelete({ error: true, message: "001: Something went wrong, please try again." });
            }

            if (resultFromAPI?.error !== undefined) {
                setLoadingAccountDelete(false);
                return setErrorAccountDelete({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again." });
            }

            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results?.code && results.code !== 0) {
                    setLoadingAccountDelete(false);
                    return setErrorAccountDelete({
                        error: true,
                        message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`
                    });
                }

                logOut();
                return navigate('/')
            }

            setLoadingAccountDelete(false);
            return setErrorAccountDelete({ error: false, message: "002: Something went wrong, please try again." });
        }
        catch (e) {
            logError('handleResendOtp', e);
            setLoadingAccountDelete(false);
            return setErrorAccountDelete({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN" });
        }
    }
    const isICExpired = () => {
        try {
            if (user && user.hasOwnProperty("isICExpired") && user.isICExpired === 1) {
                return true;
            }
            return false;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    return (
        <Box m="20px">
            <Box
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={"Settings"} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        {isICExpired() ?
                            <ExpiredPassport
                                loggedUser={user}
                                lang={lang}
                                navigation={navigate}
                            /> :
                            null
                        }
                        <Card
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <Box>
                                    <Typography variant={"h3"}
                                        sx={{ wordWrap: 'break-word', textDecoration: 'underline', textDecorationThickness: '1px', fontWeight: '600' }}>
                                        {getLabelValue(lang, 'navigation.tabs.profile.title')}
                                    </Typography>
                                    {renderPinChangePanel()}
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{
                                        boxShadow: 2,
                                        marginTop: 2,
                                        '&.Mui-expanded': {
                                            backgroundColor: 'white',
                                        },
                                    }}>
                                        <AccordionSummary sx={{ marginTop: 3, minHeight: "40px" }}
                                            expandIcon={<ExpandMoreIcon />}
                                            id="changeLanguage"
                                        >
                                            <Typography variant='h5'>
                                                {getLabelValue(lang, 'profile.change_language')}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ textAlign: 'left' }}>
                                            <Box component="form" noValidate>
                                                <FormControl sx={{ ml: 0.5, width: '100%', borderRadius: '20px' }}>
                                                    <Select
                                                        required
                                                        labelId="language"
                                                        id="language"
                                                        name="language"
                                                        value={lang}
                                                        onChange={handleSelectLanguage}
                                                        sx={{ borderRadius: '20px' }}
                                                        MenuProps={{
                                                            MenuListProps: {
                                                                style: { maxHeight: '150px' },
                                                            },
                                                        }}
                                                    >
                                                        {LANGUAGES.map((i, v) => {
                                                            return (
                                                                <MenuItem key={v} value={i?.value || ''}>{i?.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{
                                        boxShadow: 2,
                                        marginTop: 2,
                                        '&.Mui-expanded': {
                                            backgroundColor: 'white',
                                        },
                                    }}>
                                        <AccordionSummary sx={{ marginTop: 3, minHeight: "40px" }}
                                            expandIcon={<ExpandMoreIcon />}
                                            id="deleteAccount"
                                        >
                                            <Typography variant='h5'>
                                                {getLabelValue(lang, 'profile.delete.account')}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ textAlign: 'left' }}>
                                            <Box component="form" noValidate>
                                                <Box
                                                    sx={{ textAlign: 'justify' }}
                                                    mb="5px"
                                                    ml="5px"
                                                    mr="15px"
                                                >
                                                    <Typography variant={"h5"}
                                                        sx={{ wordWrap: 'break-word', fontWeight: '600' }}>
                                                        {getLabelValue(lang, 'profile.delete.account.message')}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{ textAlign: 'justify' }}
                                                    mt="15px"
                                                    ml="5px"
                                                    mr="15px"
                                                >
                                                    {getLabelValue(lang, 'profile.delete.account.submessage')}
                                                </Box>
                                                <Box
                                                    sx={{ textAlign: 'left' }}
                                                    mt="15px"
                                                    ml="5px"
                                                    mr="15px"
                                                >
                                                    <Link href="https://mobile-money.com.my/Terms_Customer2022.pdf"
                                                        sx={{ fontWeight: 'bold', color: colors.blue[800], textDecorationColor: 'blue' }}>
                                                        https://mobile-money.com.my/Terms_Customer2022.pdf
                                                    </Link>
                                                </Box>
                                                <Grid item xs={12}>
                                                    <Box
                                                        sx={{ textAlign: 'left' }}
                                                        mt="15px"
                                                        mb="5px"
                                                        ml="5px"
                                                        mr="5px"
                                                    >
                                                        {getLabelValue(lang, 'pin.title')}
                                                    </Box>
                                                    <TextField
                                                        margin="none"
                                                        fullWidth
                                                        placeholder=""
                                                        type='password'
                                                        value={pin}
                                                        onChange={(e) => setPin(e.target.value)}
                                                        inputProps={{ maxLength: 6, inputMode: 'numeric' }}
                                                    />
                                                </Grid>
                                                <Box sx={{ mt: 2 }}>
                                                    {errorAccountDelete.error && (
                                                        <Grid
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <Typography textAlign="center" color={colors.primary[500]}>
                                                                {errorAccountDelete.message || ''}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Box>
                                                <Button
                                                    type="button"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => setOpenConfirmDialog(true)}
                                                    sx={{ mt: 3, mb: 1 }}
                                                    disabled={loadingAccountDelete}
                                                    endIcon={loadingAccountDelete ? <CircularProgress size={20} /> : null}
                                                >
                                                    {getLabelValue(lang, 'profile.delete.account')}
                                                </Button>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
                {openDialog && <AlertDialog open={openDialog} onClose={handleCloseDialog} />}
                {openConfirmDialog && <ConfirmDialog open={openConfirmDialog} onClose={handleCloseConfirmDialog} />}
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) => ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)',
        overflow: 'auto'
    },
    cardStyle: {
        borderRadius: 3,
    }
})

export default Settings;
