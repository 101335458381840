import { Typography, Box } from "@mui/material";
import { tokens } from "../theme";
import {FC} from "react";

interface Props {
    title: string
    subtitle: string
}

const Header: FC<Props> = (props) => {
    const {
        title, subtitle
    } = props;
    const colors =  tokens();

    return (
        <Box mb="30px">
            <Typography
                variant="h3"
                color={colors.black[500]}
                fontWeight="bold"
                sx={{ md: "5px"}}
            >
                {title}
            </Typography>
            <Typography
                variant="h6"
                color={colors.black[100]}
            >
                {subtitle}
            </Typography>
        </Box>
    )
}

export default Header;
