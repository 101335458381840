import {  Box, Button, ButtonBase, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { addUserObjectToRequest, callBackendAPI, getLabelValue, logError } from "../../utils";
import { MEMBERSHIP_TYPES } from "../../constants/data";
import { FC, useState } from "react";
import { CONFIG } from "../../config";
import { BackendResponse, IError } from "../../type";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Grid2 from "@mui/material/Unstable_Grid2";
import DialogAlert from "../../components/DialogAlert";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";

let EKYC_URL : string = CONFIG.env === "test" ? CONFIG.ekycUrl : CONFIG.ekycUrlLive;

const colors =  tokens();

const Membership = () => {
    const isMobile: boolean = useCheckIsMobile();
    const { lang, user } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<IError>({ error: false, message: ''})
    const [isAlertShown, setIsAlertShown] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [clickedCard, setClickedCard] = useState(null);
    const [resultMessage, setResultMessage] = useState('');
    const navigate = useNavigate();
    
    const currentMembershipLevel = user?.membership.value || 0;
    const availableMembershipsForUpgrade = MEMBERSHIP_TYPES.filter(m => m.id > currentMembershipLevel);

    const handleOpenDialog = (membershipcard : any) => {
        if(isAlertShown === false){
            setClickedCard(membershipcard)
            setIsAlertShown(true);
            setOpenDialog(true);
        }
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setIsAlertShown(false);
    };

    type AlertDialogProps = {
        open: boolean;
        onClose: () => void;
        membershipcard : any;
        resultMessage: string;
    };

    const AlertDialog: FC<AlertDialogProps> = ({ open, onClose, membershipcard, resultMessage }) => {
        const handleCancelClick = () => {
            if(resultMessage){
                setResultMessage('');
                onClose();
            }
            onClose();
        };
      
        const handleOkClick = () => {
            if(resultMessage){
                setResultMessage('');
                onClose();
            }

            else if (membershipcard.id === 1){
                console.log('silver')
                navigate('/topup', {state: { act_id: 20000 }})
                onClose();
            }
                
            else if (membershipcard.id === 2){
                window.open(
                    `${EKYC_URL}?id=2&uuid=${user?.hasOwnProperty("aa_uuid")
                      ? user.aa_uuid
                      : ""}&hp=${user?.hasOwnProperty("mobile") ? user?.mobile : ""}&lang=${'en'}`,
                    "_blank"
                );
                onClose();
            }
               
            else if (membershipcard.id === 3){
                handleUpgradeToPlatinum()
                onClose();
            }  
        };
    
        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {resultMessage ? "ATTENTION" : getLabelValue(lang, 'profile.upgrade.alert.title') + ' ' + membershipcard.name}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {resultMessage ? resultMessage : getLabelValue(lang, membershipcard.message)}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCancelClick}>CANCEL</Button>
                <Button onClick={handleOkClick} autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleUpgradeToPlatinum = async () : Promise<void> => {
        try {
            if (!user)
                return setError({ error: true, message: "001: Something went wrong"})
            if (error.error)
                setError({ error: false, message: ''});
        
            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let input : any = {
                ver: CONFIG?.ver,
                act: 920,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                m1: "Upgrade Membership to Platinum",
                p1: user.membership.value,
                lg: lang
            }
            input = addUserObjectToRequest(input)
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('request', 'POST',headers,input);
            if (resultFromAPI === undefined) {
                setLoading(false);
                return setError({ error: true, message: "002: Something went wrong, please try again."});
            }
        
            if (resultFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: resultFromAPI.error || "003: Something went wrong, please try again."});
            }
        
            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results.code === 0) {
                    setOpenDialog(true);
                    setResultMessage(results.message);
                }
                if (results?.code && results.code !== 0) {
                    setLoading(false);
                    return setError({
                        error: true,
                        message: `${results?.message || "004: Something went wrong, please try again."}`});
                }
            }
            setLoading(false);
            return setError({ error: false, message: "005: Something went wrong, please try again."})
            }
            catch (e) {
                logError('handleUpgradeToPlatinum', e);
                setLoading(false);
                return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'profile.upgrade_membership')} subtitle={""} />
                </Box>
                <Grid2 container>
                    <Grid2 xs={12} md={6}>
                    <Card
                        sx={styles(isMobile).cardStyle}
                        elevation={3}
                    >
                        <CardContent>
                            <Typography variant="h3" sx={{ wordWrap: 'break-word', textAlign: 'center', mt: '10px', mb: '10px', ml: '20px' }}>
                                {getLabelValue(lang, 'profile.upgrade.subtitle')}
                            </Typography>
                            <Box
                            sx={{ 
                                mt: '10px',
                                mb: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            >
                                <Box
                                sx={{                          
                                    backgroundColor: user?.membership ? (
                                    user.membership.color || "#CD7F32"
                                    ) : "#CD7F32",
                                    color: colors.white[100],
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '15px 0',
                                    width: '25%',
                                    fontWeight: 'bold',
                                    borderRadius: '10px',
                                }}
                                >
                                {user?.membership.name || ''}
                                </Box>
                            </Box>
                            <Typography variant="h4" sx={{ wordWrap: 'break-word', textAlign: 'center', mt: '10px', mb: '10px', ml: '20px' }}>
                                {getLabelValue(lang, 'profile.upgrade.description')}
                            </Typography>
                        </CardContent>
                    </Card>
                    {availableMembershipsForUpgrade.length > 0 && (
                        <Card
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                {availableMembershipsForUpgrade.map((membershipcard, index) => (
                                    <Card key={index} sx={{ mt: 2, backgroundColor: membershipcard.color, boxShadow: 4, borderRadius: '10px'}}>
                                        <ButtonBase 
                                            focusRipple
                                            style={{ width: '100%', display: 'flex' }}
                                            key={index}
                                            onClick={() => {    
                                                handleOpenDialog(membershipcard)}}
                                        >
                                            <CardContent sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: '25px 50px', width: '100%' }}>
                                                <div style={{ textAlign: 'center', width: '100%' }}>
                                                    <Typography variant="h5">{getLabelValue(lang, 'profile.upgrade.alert.title') + ' ' + membershipcard.name}</Typography>
                                                    <Typography variant="body2">{membershipcard.wallet_size}</Typography>
                                                </div>
                                            </CardContent>
                                        </ButtonBase>
                                    </Card>                 
                                ))} 
                            </CardContent>
                        </Card>
                    )} 
                    </Grid2>
                </Grid2>
                {error.error && (
                    <DialogAlert
                    open={error.error}
                    onSuccess={() => setError({ error: false, message: "" })}
                    title={"Attention"}
                    content={new Array(error.message)}
                    yesButton={"OK"}
                    />
                )}
                {openDialog && <AlertDialog open={openDialog} onClose={handleCloseDialog} membershipcard={clickedCard} resultMessage={resultMessage}/>}
            </Box>
        </Box> 
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        m: 2,
        borderRadius: 3,
        px: 1
    }
})

export default Membership;