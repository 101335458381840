import {Box, Button, Card, CardActions, CardContent, TextField, Typography} from "@mui/material";
import Header from "../../components/Header";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme, tokens } from "../../theme";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { extendedAmountTextInput, extendedAmountTextInputv2, getLabelValue } from "../../utils";
import { TOPUP } from "../../constants/data";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IError } from "../../type";
import DialogAlert from "../../components/DialogAlert";
import { CONFIG } from "../../config";

const colors =  tokens();

const TopUp = () => {
    const {state} = useLocation();
    const act_id: any = state?.act_id; 
    const [inputtedAmount, setInputtedAmount] = useState('');
    const [showAmount, setShowAmount] = useState('');
    const { lang } = useSelector((state: RootState) => state.user);
    const [error, setError] = useState<IError>({ error: false, message: ''})
    const [error1, setError1] = useState<IError>({ error: false, message: ''})
    const navigate = useNavigate();

    const shouldLockEffect = useRef(false);
    
    useEffect(() => {
        const initialize = async (): Promise<void> => {
            let check = CONFIG.env === "test" ? true : false;
            if (!check){
                setError1({error: true, message: 'Service not available yet'});
            } 
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    },[]);
    
    const handleAmountChange = (val: any) => {
        if (!/^\d+(\.\d{0,2})?$/.test(val)) {
            setShowAmount('');
            setInputtedAmount('0.00');
            return;
        }
        setShowAmount(val);
        setInputtedAmount(extendedAmountTextInputv2(val));
    }

    const handlePaymentModeClick = (paymentMode : any) => {
        if (inputtedAmount === undefined || inputtedAmount === "" || inputtedAmount === "0.00"){
            setError({ error: true, message: getLabelValue(lang,'topup.error.message1')});
        }
        else
            navigate("/topupfpx", {state: {amount: inputtedAmount, paymentMode: TOPUP.paymentModes[paymentMode], act_id}})
    }

    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box component="main" sx={{flexGrow: 1}}>
                <Box sx={{ mt: 3, ml: 3 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Header title={getLabelValue(lang, 'home.header.button.topup')} subtitle={""} />
                    </Box>
                    <Container maxWidth="lg" sx={{ mb: 3 }}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} md={7} lg={7}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                                    <Box 
                                        sx={{ textAlign: 'left'  }}
                                        mt="5px"
                                        mb="10px"
                                        ml="5px" 
                                        mr="5px"
                                    >
                                        {getLabelValue(lang, 'topup.header.title')}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                                    <TextField
                                        margin="none"
                                        required
                                        fullWidth
                                        type="text"
                                        autoFocus
                                        value={`RM ${showAmount}`}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const extractedAmount = inputValue.split('RM ')[1]; 
                                            handleAmountChange(extractedAmount);
                                            setError({ error: false, message: ''})
                                          }}
                                        sx={{ mb: "5px", "& input": { textAlign: "center", fontSize: "20px" } }}
                                        inputMode="numeric"
                                    />
                                    </Box>
                                    <Grid container spacing={2}>
                                        {TOPUP.denos.map((item, index) => (
                                            <Grid item xs={6} sm={4} key={index}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setInputtedAmount(extendedAmountTextInput(String(item.value)));
                                                    setShowAmount(extendedAmountTextInput(String(item.value)));
                                                    setError({ error: false, message: ''})
                                                }}
                                                style={{ width: '100%'}}
                                            >
                                                <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                RM {item.label}
                                                </Typography>
                                            </Button>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Box sx={{ mt: 2, mb: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Box sx={{ mr: 1, justifyContent: 'center',　alignItems: 'center' }}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 20 }}>
                                                {`${getLabelValue(lang, 'topup.amount.title')}: `}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ ml: 1, justifyContent: 'center',　alignItems: 'center' }}>
                                            <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: 20, color: 'red' }}>
                                                {`RM ${inputtedAmount === undefined ? '0.00' : inputtedAmount === '' ? '0.00' : inputtedAmount}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {error.error && (
                                        <Grid
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                        <Typography textAlign="center" color={colors.primary[500]} fontSize={16}>
                                            {error.message || ''}
                                        </Typography>
                                        </Grid>
                                    )}
                                </Paper>
                                <Paper sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>             
                                    {TOPUP.paymentModes.map((i : any, v) => (
                                        <Card>
                                            <CardContent>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography variant="h4">{i[lang].title || ''}</Typography>
                                                <img src={i.icon} alt="Icon" style={{ height: 25, width: 25 }} />
                                                </Box>
                                                <Typography variant="h6" color="textSecondary">
                                                {i[lang].description || ''}
                                                </Typography>
                                            </CardContent>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                                            <CardActions>
                                                <Button 
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handlePaymentModeClick(v)}
                                                endIcon={<ArrowForwardIcon />}
                                                >
                                                {getLabelValue(lang, 'topup.footer.button')}
                                                </Button>
                                            </CardActions>
                                            </Box>
                                        </Card>
                                    ))}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>                    
                </Box>
            </Box>
        </Box>
        {error1.error && (
            <DialogAlert
                open={error1.error}
                onSuccess={() => {
                    setError({ error: false, message: ''})
                    navigate('/');
                }}
                title={'Attention'}
                content={new Array(error1.message)}
                yesButton={'OK'}
            />
        )}        
        </ThemeProvider>
    )
}

export default TopUp;
