import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { Avatar, Button, Card, CardContent, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import {RootState, useAppDispatch} from "../../store/store";
import React, { useEffect, useRef, useState } from 'react';
import { getImage, getLabelValue } from '../../utils';
import { getBalance } from '../../actions/AccountAction';
import { fetchProfileData } from '../../actions/ProfileAction';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { moneyPinHistoryFetch } from '../../actions/MoneyPinAction';
import Grid2 from '@mui/material/Unstable_Grid2';
import useCheckIsMobile from '../../hooks/useCheckIsMobile';

const colors =  tokens();

const Account = () => {
    const isMobile: boolean = useCheckIsMobile();
    const { balance, pending_amt, balance_unusedpin } = useSelector((state: RootState) => state.account);
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [imageLoading, setImageLoading] = useState<boolean>(true);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);
    
    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(getBalance(lang, user));
            dispatch(fetchProfileData(lang, user));
            // dispatch(moneyPinHistoryFetch(lang));
            // let image : string | undefined = await getImage(user);
            // setImageUrl(image);
            // setImageLoading(false);
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    },[]);
    
    const shouldShowUpgradeButton = () => {
        try{
        if (!user) 
            return true;
        if (!user.hasOwnProperty("membership"))
            return true;
        if (!user.membership.hasOwnProperty("shouldUpgrade"))
            return true;
        return user.membership.shouldUpgrade;
        }
        catch (e) {
        return true;
        }
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'navigation.tabs.profile.title')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={5} lg={4}
                    >
                        <Grid2 xs={12} md={12} lg={12}>
                            <Card
                                sx={styles(isMobile).cardStyle}
                                elevation={3}
                            >
                                <CardContent>
                                    <Typography variant="h3" color={colors.primary[500]} sx={{ fontWeight: 'bold' }}>
                                        {getLabelValue(lang, 'home.header.title') + ": "}
                                    </Typography>
                                    <Grid2
                                        container
                                        sx={{ my: 1}}
                                    >
                                        <Grid2
                                            xs={8} md={8}
                                        >
                                            <Grid2
                                                container spacing={2}
                                            >
                                                <Grid2
                                                    xs={2.5} md={1.5}
                                                >
                                                    <Typography
                                                        variant="h5"
                                                        color={colors.black[900]}
                                                        sx={{ fontWeight: 'bold' }}
                                                    >
                                                        RM
                                                    </Typography>
                                                </Grid2>
                                                <Grid2
                                                    xs={9.5} md={10.5}
                                                >
                                                    <Typography
                                                        variant="h4"
                                                        color={colors.black[900]}
                                                        sx={{ fontWeight: 'bold' }}
                                                    >
                                                        {`${parseFloat(balance)+parseFloat(balance_unusedpin)}`}
                                                    </Typography>
                                                </Grid2>

                                            </Grid2>
                                            {pending_amt && pending_amt !== "0.00" &&
                                                <Typography
                                                    variant="h4"
                                                    color={colors.black[900]}
                                                    sx={{ fontWeight: 'bold' }}
                                                >
                                                    
                                                    {pending_amt && pending_amt !== "0" && ` (RM ${pending_amt})`}
                                                </Typography>
                                            }
                                        </Grid2>
                                    </Grid2>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="body2" color={colors.gray[400]}>
                                            As of {new Date().toLocaleString('en-us', {
                                            weekday: "long", month: "2-digit", day: "2-digit", year: "numeric",
                                            hour: "2-digit", minute: "2-digit", hour12: false, second: "2-digit"
                                        })}
                                        </Typography>
                                        <Button
                                            type="submit"
                                            onClick={() => navigate('/topup', { state: { act_id: 20000 } })}
                                            sx={{
                                                backgroundColor: colors.primary[500],
                                                color: colors.white[100],
                                                padding: '10px 20px',
                                                fontWeight: 'bold',
                                                height: '40px',
                                                borderRadius: '20px', 
                                                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)', 
                                                '&:hover': {
                                                    backgroundColor: colors.primary[700],
                                                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.24)',
                                                },
                                                '&:active': {
                                                    backgroundColor: colors.primary[800], 
                                                    boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.14)', 
                                                },
                                                transition: 'all 0.3s ease',
                                            }}
                                        >
                                            <AccountBalanceWalletIcon sx={{ marginRight: '5px' }} />
                                            <span>{getLabelValue(lang, 'home.header.button.topup')}</span>
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid2>
                        <Grid2 xs={12} md={12} lg={12}>
                            <Card
                                sx={styles(isMobile).cardStyle}
                                elevation={5}
                            >
                                <CardContent>
                                <Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Avatar variant="rounded" sx={{ width: 100, height: 100 }} src={user?.selected_image || imageUrl || ''}>
                                                    {/* {imageLoading && <CircularProgress />} */}
                                                </Avatar>
                                            </Grid>
                                            <Grid xs={12} md={12} lg={12} item sx={{ pl: 1, mt: 0.5 }}>
                                                <Typography variant="h3" sx={{ fontWeight: "bold", wordWrap: 'break-word' }}>
                                                    {user?.name || ''}
                                                </Typography>
                                                <Typography variant="h4">
                                                    {"User ID:"}
                                                    <Button onClick={() => navigate('/qrcode', { state: { value: user? user?.aa_uuid : '' , labelKey: 'profile.user_qrcode.description', name: user ? user.name : '' } })}>
                                                        <QrCode2Icon sx={{ color: 'black' }}/>
                                                    </Button>
                                                </Typography>
                                                <Typography variant="h6" sx={{ wordWrap: 'break-word', fontSize: '15px' }}>
                                                    {user?.aa_uuid || ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'center' }}>
                                    <Box sx={{
                                        backgroundColor: user?.membership?.color || "#CD7F32",
                                        color: colors.white[100],
                                        display: 'inline-block',
                                        padding: { xs: '10px 50px' },
                                        fontWeight: 'bold',
                                        borderRadius: '10px',
                                        mb: { xs: 2, md: 0 }
                                    }}>
                                        {user?.membership?.name || ""}
                                    </Box>
                                    {shouldShowUpgradeButton() && (
                                        <Box sx={{ ml: { xs: 0, md: '15px' }, mt: { xs: 2, md: 0 }, maxWidth: { md: 'initial' } }}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                onClick={() => navigate('/membership')}
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    padding: { xs: '10px 30px' }, 
                                                    borderRadius: "10px"
                                                }}
                                            >
                                                <Typography sx={{ color: colors.primary[500] }}>
                                                    {getLabelValue(lang, 'profile.upgrade.button')}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    )}
                                </Grid>
                                </CardContent>
                            </Card>                            
                        </Grid2>
                    </Grid2>
                    <Grid2
                        xs={12} md={7} lg={6}
                    >
                        <Card
                            sx={styles(isMobile).cardStyle}
                            elevation={5}
                        >
                            <CardContent>
                            {user?.community &&
                                <div>
                                    <Typography variant={"h3"}
                                        sx={{ wordWrap: 'break-word', mt: 1, ml: 2, textDecoration: 'underline', textDecorationThickness: '1px', fontWeight: '600' }}>
                                        {'Community'}
                                    </Typography>
                                    <Box>
                                    <IconButton
                                        onClick={() => navigate('/borneokaki')}
                                        sx={{ ml: 2 }}
                                    >
                                        <img
                                            src={user.community.icon}
                                            alt={user.community.name}
                                            style={{ width: 75, height: 75 }}
                                        />
                                    </IconButton>
                                    </Box>
                                </div>
                            }
                            <Typography variant={"h3"}
                                sx={{ wordWrap: 'break-word', mt: 1, ml: 2, textDecoration: 'underline', textDecorationThickness: '1px', fontWeight: '600' }}>
                                {getLabelValue(lang, 'navigation.tabs.profile.title')}
                            </Typography>
                                <Box sx={{ m: 2 }}>
                                    <Grid container spacing={2} sx={{ mb: 3 }}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography sx={{ mb: 1 }}>
                                        {getLabelValue(lang, 'profile.mobile').replace(/\.$/, '')}
                                        </Typography>
                                        <TextField
                                            margin="none"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={user?.mobile || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography sx={{ mb: 1 }}>{getLabelValue(lang, 'profile.idtype').replace(/\.$/, '')}</Typography>
                                        <TextField
                                            margin="none"
                                            id="idTpe"
                                            name="idType"
                                            value={user?.idType?.label || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography sx={{ mb: 1 }}>{getLabelValue(lang, 'profile.id').replace(/\.$/, '')}</Typography>
                                        <TextField
                                            margin="none"
                                            id="idTpe"
                                            name="idType"
                                            value={user?.idNumber || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ mb: 3 }}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ mb: 1 }}>
                                            {getLabelValue(lang, 'profile.account_id').replace(/\.$/, '')}
                                            <Button onClick={() => navigate('/qrcode', { state: { value: user? user?.account_id : '' , labelKey: 'profile.accountID_qrcode.description' } })}>
                                                <QrCode2Icon sx={{ color: 'black' }}/>
                                            </Button>
                                        </Typography>
                                        <TextField
                                            margin="none"
                                            id="accountNo"
                                            name="accountNo"
                                            value={user?.account_id || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography sx={{ mb: 1 }}>
                                            {getLabelValue(lang, 'profile.scan_payroll').replace(/\.$/, '')}
                                            {!user?.corporate_id ? (
                                                <Button onClick={() => navigate('/scanCorporateID')}>
                                                    <QrCode2Icon sx={{ color: 'black' }}/>
                                                </Button>                                            
                                            ):(
                                                <Box sx={{ height: 12 }}> 
                                                </Box>
                                            )}
                                        </Typography>
                                        <TextField
                                            margin="none"
                                            id="corporateId"
                                            name="corporateId"
                                            value={user?.corporate_id || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ mb: 3 }}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography sx={{ mb: 1 }}>{getLabelValue(lang, 'profile.email').replace(/\.$/, '')}</Typography>
                                        <TextField
                                            margin="none"
                                            id="email"
                                            name="email"
                                            value={user?.email || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ mb: 3 }}>
                                    <Grid item xs={12} sm={1.6}>
                                        <Typography sx={{ mb: 1 }}>{getLabelValue(lang, 'profile.lotno').replace(/\.$/, '')}</Typography>
                                        <TextField
                                            margin="none"
                                            id="lotno"
                                            name="lotno"
                                            value={user?.lotno || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5.2}>
                                        <Typography sx={{ mb: 1 }}>{getLabelValue(lang, 'profile.address1').replace(/\.$/, '')}</Typography>
                                        <TextField
                                            margin="none"
                                            id="address1"
                                            name="address1"
                                            value={user?.address1 || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5.2}>
                                        <Typography sx={{ mb: 1 }}>{getLabelValue(lang, 'profile.address2').replace(/\.$/, '')}</Typography>
                                        <TextField
                                            margin="none"
                                            id="address2"
                                            name="address2"
                                            value={user?.address2 || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ mb: 1 }}>
                                    <Grid item xs={12} sm={1.6}>
                                        <Typography sx={{ mb: 1 }}>{getLabelValue(lang, 'profile.postcode').replace(/\.$/, '')}</Typography>
                                        <TextField
                                            margin="none"
                                            id="postCode"
                                            name="postCode"
                                            value={user?.postcode || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5.2}>
                                        <Typography sx={{ mb: 1 }}>{getLabelValue(lang, 'profile.city').replace(/\.$/, '')}</Typography>
                                        <TextField
                                            margin="none"
                                            id="city"
                                            name="city"
                                            value={user?.city || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5.2}>
                                        <Typography sx={{ mb: 1 }}>{getLabelValue(lang, 'profile.state').replace(/\.$/, '')}</Typography>
                                        <TextField
                                            margin="none"
                                            id="state"
                                            name="state"
                                            value={user?.state || ''}
                                            variant="standard"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    </Grid>
                                </Box>                                
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        m: 2,
        borderRadius: 3,
        px: 1
    }
})

export default Account;
