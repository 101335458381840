import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, tokens } from "../../theme";
import { Avatar, CircularProgress, Stack, ThemeProvider, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CONFIG } from '../../config';
import { BackendResponse, Error } from '../../type';
import { callBackendAPI, getLabelValue, logError } from '../../utils';
import Footer from '../../components/Footer';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const colors = tokens();

const RegisterSuccess = () => {
    const {state} = useLocation();
    const user: any = state?.user;    
    const selectedProfilePic: any = state?.selectedProfilePic;
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ error: false, message: ""});
    const navigate = useNavigate();

    const handleFetchProfileDataInit = async (): Promise<void> => {
        try{
        setLoading(true);
        let headers: HeadersInit = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }     
        let input : any = {
            ver: CONFIG?.ver,
            act: 910,
            p1: user?.mobile.trim(),
            m1: "Profile",
            m2: "",
            user: user
        }
        let resultFromAPI: BackendResponse | undefined = await callBackendAPI('register', 'POST',headers, input);
        if (resultFromAPI === undefined) {
            setLoading(false);
            return setError({ error: true, message: "001: Something went wrong, please try again."});
        }

        if (resultFromAPI?.error !== undefined) {
            setLoading(false);
            return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
        }

        if (resultFromAPI?.results) {
            let results: any = resultFromAPI.results;
            if (results?.code && results.code !== 0) {
                setLoading(false);
                return setError({
                    error: true,
                    message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`});
            }
            
            return navigate('/login')
        }
        setLoading(false);
        return setError({ error: false, message: "004: Something went wrong, please try again."})
        }
        catch (e) {
        logError('handleFetchProfileDataInit', e);
        setLoading(false);
        return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

  return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
            sx={{
                boxShadow: 3,
                borderRadius: 2,
                px: 4,
                py: 4,
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            >
                <Box mt="5px" mb="25px" ml="5px">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="10px"
                        overflow="hidden"
                        boxShadow={2}
                        >
                            <img
                                alt="logo"
                                width="230px"
                                height="60px"
                                src={'../../assets/mmlogo.png'}
                                style={{ cursor: "pointer"}}
                            />
                    </Box>
                </Box>
                <Box
                    sx={{ fontStyle: 'normal', textAlign: 'center' }}
                    mb="5px"
                    ml="50px"
                    mr="50px"
                >
                <Typography variant="h5" sx={{fontWeight: 'bold'}} color='#ef233e'>
                    {getLabelValue(lang, 'register.success.title').toUpperCase()}
                </Typography>
                </Box>
                <Box
                    sx={{ textAlign: 'center' }}
                    mt="15px"
                    mb="5px"
                    ml="5px"
                    mr="5px"
                >
                    {getLabelValue(lang,'register.success.subTitle')}
                </Box> 
                <Stack direction="row" spacing={2} sx={{ mt: 2, mb: 5 }}>
                    <Avatar variant="rounded"
                        sx={{ width: 100, height: 100 }}
                        src={selectedProfilePic || ''}
                    >
                    </Avatar>
                </Stack>
                <Box sx={{
                    backgroundColor: colors.primary[500],
                    color: colors.white[100],
                    display: 'inline-block',
                    padding: '15px 25px',
                    fontWeight: 'bold',
                    mb: 2,
                }}>
                    {getLabelValue(lang,'register.success.warning').toUpperCase()}
                </Box>
                <Typography variant="h6" sx={{fontSize: '14px', fontStyle: 'italic', textAlign: 'justify', marginTop: '10px', marginLeft: '5px', marginRight: '5px'}}>
                    {getLabelValue(lang,'register.success.message')}
                </Typography>       
                <Box sx={{ mt: 2 }} minWidth={'100%'}>
                    <Button
                        type="submit"
                        fullWidth
                        onClick={async () => await handleFetchProfileDataInit()}
                        variant="contained"
                        color="success"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                        endIcon={loading ? <CircularProgress size={20} /> : null}
                    >
                        OK
                    </Button>
                    {error.error && (
                    <Typography
                        sx={{
                            mt: '10px',
                            textAlign: 'center',
                            color: colors.primary[500],
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {error.message || ''}
                    </Typography>
                    )}                             
                </Box>
            </Box>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}

const theme = createTheme({
    palette: {
      secondary: {
        //MMApp signature red
        main: '#ef233e',
      },
      primary: {
        main: '#212121',
      }
    },
  });
  
export default RegisterSuccess;