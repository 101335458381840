import {
    PAYBILL_FETCH_BILLERS,
    PAYBILL_FETCH_BILLERS_FAILED,
    PAYBILL_FETCH_BILLERS_SUCCESS,
  } from "./type";
  import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { CONFIG } from "../config";
  
  export const getPayBillBillers = (lang: any) => {
    return async (dispatch: any) => {
        try{
            dispatch({ type: PAYBILL_FETCH_BILLERS });
            let input: any = {
            ver: CONFIG.ver,
            user_agent: navigator.userAgent,
            app_id: CONFIG.app_id,
            m1: "GetPayBillList",
            lang: lang
            }
            input = addUserObjectToRequest(input);
    
            let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('paybilllist', 'POST', headers, input)
            if (!resultsFromAPI)
                return dispatch({ type: PAYBILL_FETCH_BILLERS_FAILED, payload: "001: Something went wrong, try again"});
            if (resultsFromAPI.error !== undefined)
                return dispatch({ type: PAYBILL_FETCH_BILLERS_FAILED, payload: "002: Something went wrong, please check your Internet Connection and try again"});
            if (!resultsFromAPI.hasOwnProperty("results"))
                return dispatch({ type: PAYBILL_FETCH_BILLERS_FAILED, payload: "003: Something went wrong, try again"});
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0)
                    return dispatch({ type: PAYBILL_FETCH_BILLERS_FAILED, payload: `[${results.code}] : ${results.message || ""}` });
                if (!results.hasOwnProperty("list"))
                    return dispatch({ type: PAYBILL_FETCH_BILLERS_FAILED, payload: "004: Something went wrong, try again"});
                return dispatch({ type: PAYBILL_FETCH_BILLERS_SUCCESS, payload: results.list})
            }
    
            return dispatch({ type: PAYBILL_FETCH_BILLERS_FAILED, payload: "005: Something went wrong, try again"});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: PAYBILL_FETCH_BILLERS_FAILED, payload: "O99: OOPS SOMETHING WENT WRONG!"})
        }
    }
  }
  