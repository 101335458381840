import { Box, Typography } from '@mui/material';
import { getLabelValue } from '../utils';
import FlaggedSVG from "../assets/icons/flagged.svg";
import { COLORS } from '../constants/data';

const ExpiredPassport = ({ loggedUser, lang, navigation }: { loggedUser: any; lang: any; navigation: any }) => {
    return (
        <Box sx={styles.container} onClick={() => navigation('/upgradepassport')}>
            <Box sx={styles.mainContent}>
                <Box sx={styles.iconView}>
                    <img src={FlaggedSVG} alt="svg" style={{ height: 75, width: 75 }} />
                </Box>
                <Box sx={styles.contentView}>
                <Box sx={styles.titleContainer}>
                    <Typography variant="h6" sx={styles.titleStyle}>
                        {getLabelValue(lang,'profile.upgrade.ic.attention')}!
                    </Typography>
                </Box>
                <Typography variant="body1" sx={styles.descStyle}>
                        {`${loggedUser?.idType?.label || 'Passport'} ${loggedUser?.idNumber || ''} ${getLabelValue(lang, 'profile.upgrade.ic.desc')}`}
                </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const styles = {
    container: {
        margin: '15px',
        backgroundColor: COLORS.PURPLE,
        marginX: '20px',
        paddingY: '15px',
        borderRadius: '20px',
        cursor: 'pointer',
    },
    titleContainer: {
        paddingBottom: '5px',
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
    },
    titleStyle: {
        color: COLORS.WHITE,
        fontWeight: 'bold',
        fontSize: '17px',
    },
    iconView: {
        flex: '0.3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentView: {
        flex: '0.8',
    },
    descStyle: {
        color: COLORS.WHITE,
        fontWeight: 'normal',
        fontSize: '12px',
    },
};

export default ExpiredPassport;
