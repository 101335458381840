import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {CircularProgress, Typography} from '@mui/material';
import { useEffect, useState } from 'react';
import { tokens } from '../../theme';
import { useSelector } from 'react-redux';
import {RootState} from "../../store/store";
import {callBackendAPI, getLabelValue, logError} from "../../utils";
import { BackendResponse, Error } from "../../type";
import {CONFIG} from "../../config";
import {useLocation, useNavigate} from "react-router-dom";
import { CONSTANTS } from '../../constants/data';
import Footer from '../../components/Footer';

const colors =  tokens();

const ForgotPin = () => {
    const {state} = useLocation();
    const user: any = state.user;
    const [otp, setOtp] = useState("");
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [otpCounter, setOtpCounter] = useState<number>(CONSTANTS.OTP_COUNTER);
    const [resendOtp, setResendOtp] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ error: false, message: ""});
    const navigate = useNavigate();

    useEffect(() => {
        let interval: string | number | NodeJS.Timer | undefined;
        const runInternal = (value: number, delay: number) => {
        return setInterval(() => {
            setOtpCounter(value);
        }, delay);
        }

        if (otpCounter > 0)
        interval = runInternal(otpCounter - 1, 1000);
        else
        setResendOtp(true);

        return () => {
        clearInterval(interval);
        }
    }, [otpCounter])
    
    const handleOTPVerify = async (): Promise<void> => {
        try {
        if (otp === undefined || otp.length < 6)
            return setError({ error: true, message: user === 'bm' ? "Sila berikan TAC, atau semak semula" : "Please provide a TAC, or check again"});
        if (user === undefined)
            return setError({ error: true, message: '002: FAILED TO VALIDATE PIN, PLEASE TRY AGAIN'});
        if (error.error)
            setError({ error: false, message: ''});

        setLoading(true);
        let headers: HeadersInit = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let input: any = {
            ver: CONFIG?.ver,
            mobileno: user?.mobile.trim(),
            tac: otp ? otp.trim() : '',
        }   
        let resultFromAPI: BackendResponse | undefined = await callBackendAPI('verifyotp', 'POST',headers, input);
        if (resultFromAPI === undefined) {
            setLoading(false);
            return setError({ error: true, message: "001: Something went wrong, please try again."});
        }

        if (resultFromAPI?.error !== undefined) {
            setLoading(false);
            return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
        }

        if (resultFromAPI?.results) {
            let results: any = resultFromAPI.results;
            if (results?.code && results.code !== 0) {
            setLoading(false);
            return setError({
                error: true,
                message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`});
            }
            return navigate('/resetpin', { state: { user: user} });
        }
        setLoading(false);
        return setError({ error: false, message: "004: Something went wrong, please try again."})
        }
        catch (e) {
        logError('handleOTPVerify', e);
        setLoading(false);
        return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    const handleOTPResend = async (): Promise<void> => {
        try {
        setResendOtp(false);
        setOtp("");
        if (error.error)
            setError({ error: false, message: ''});

        setLoading(true);
        let headers: HeadersInit = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let input = {
            ver: CONFIG?.ver,
            mobileno: user?.mobile.trim(),
            purpose: "MM App FORGOT PIN",
        }
        let resultFromAPI: BackendResponse | undefined = await callBackendAPI('sendotp', 'POST', headers, input);
        if (resultFromAPI === undefined) {
            setLoading(false);
            return setError({ error: true, message: "001: Something went wrong, please try again."});
        }

        if (resultFromAPI?.error !== undefined) {
            setLoading(false);
            return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
        }

        if (resultFromAPI?.results) {
            let results: any = resultFromAPI.results;
            if (results?.code && results.code !== 0) {
            setLoading(false);
            return setError({
                error: true,
                message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`});
            }

            setLoading(false);
            setError({ error: false, message: ''})
            return setOtpCounter(CONSTANTS.OTP_COUNTER);
        }

        setLoading(false);
        return setError({ error: false, message: "002: Something went wrong, please try again."});
        }
        catch (e) {
        logError('handleOTPResend', e);
        setLoading(false);
        return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
            sx={{
                boxShadow: 3,
                borderRadius: 2,
                px: 4,
                py: 4,
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            >
            <Box mt="5px" mb="25px" ml="5px">
                <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="10px"
                overflow="hidden"
                boxShadow={2}
                >
                    <img
                        alt="logo"
                        width="230px"
                        height="60px"
                        src={'../../assets/mmlogo.png'}
                        style={{ cursor: "pointer"}}
                    />
                </Box>
            </Box>
            <Box
                sx={{ fontStyle: 'italic', textAlign: 'center' }}
                mb="5px"
                ml="50px"
                mr="50px"
            >
                {getLabelValue(lang, 'forgot.otp.title')}
                {getLabelValue(lang, 'forgot.otp.subTitle')}
            </Box>
            <Box
                sx={{ mt: 3 }}>
                <Box
                sx={{ fontStyle: 'normal', textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
                mb="5px"
                ml="50px"
                mr="50px"
                >
                {user?.mobile}
                </Box>              
                <Box
                sx={{ textAlign: 'left', mt: 3 }}
                mt="10px"
                mb="5px"
                ml="5px"
                mr="5px"
                >
                {getLabelValue(lang, 'register.otp.instructions')}
                </Box>
                <TextField
                margin="none"
                required
                fullWidth
                name="pin"
                placeholder=""
                id="login-tac"
                value={otp}
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.value === ' ') return;
                    if (event?.target?.value.substring(0,1) === '0') {
                        return setOtp(event.target.value);
                    }else
                        if (!isNaN(Number(event.target.value)))
                            return setOtp(event.target.value)
                }}
                inputProps={{ maxLength: 6, inputMode: 'numeric'}}
                sx={{
                    '& input': {
                    textAlign: 'center',
                    },
                }}                 
                />
                <Button
                type="submit"
                fullWidth
                onClick={async () => await handleOTPVerify()}
                variant="contained"
                color="success"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
                endIcon={loading ? <CircularProgress size={20} /> : null}
                >
                {getLabelValue(lang, 'login.account.button.submit')}
                </Button>
                <Box
                sx={{ fontStyle: 'normal', textAlign: 'center', fontSize: '13px' }}
                mt="5px"
                mb="5px"
                ml="50px"
                mr="50px"
                >
                {getLabelValue(lang, 'register.otp.smsnotrceived')}
                </Box>
                {resendOtp ? (
                <Button
                type="submit"
                onClick={async () => await handleOTPResend()}
                fullWidth
                variant="text"
                color="primary"
                sx={{ mt: 2, mb: 0, textDecoration: "underline" }}
                >
                    {getLabelValue(lang, 'register.otp.request')}
                </Button>                           
                ) : (
                <Box
                sx={{ textAlign: 'center' }}
                mt="10px"
                mb="5px"
                ml="50px"
                mr="50px"
                >
                <Typography variant="h5" component="h6" color={colors.black}>
                    {otpCounter}
                </Typography>
                </Box>  
                )}
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => navigate('/loginpin', { state: { user: user} })}
                sx={{ mt: 3, mb: 1 }}
                >
                {getLabelValue(lang, 'login.account.button.goback')}
                </Button>
                {error.error && (
                <Typography
                    sx={{
                    mt: '10px',
                    textAlign: 'center',
                    color: colors.primary[500],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    }}
                >
                    {error.message || ''}
                </Typography>
                )}                               
            </Box>
            </Box>
        </Container>
        <Footer />
        </ThemeProvider>
    );
}

const theme = createTheme({
    palette: {
        secondary: {
        //MMApp signature red
        main: '#ef233e',
        },
        primary: {
        main: '#212121',
        }
    },
});

export default ForgotPin;
