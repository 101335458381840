import { configureStore } from '@reduxjs/toolkit';
import {useDispatch} from "react-redux";
import userReducer from "../screens/login/userSlice";
import ekycReducer from '../redux/ekycReducer';
import accountReducer from '../redux/accountReducer';
import profileReducer from '../redux/profileReducer';
import bookRateReducer from '../redux/bookRateReducer';
import redeemReducer from '../redux/redeemReducer';
import beneficiaryReducer from '../redux/beneficiaryReducer';
import topUpReducer from '../redux/topUpReducer';
import transactionHistoryReducer from '../redux/transactionHistoryReducer';
import prepaidReducer from '../redux/prepaidReducer';
import payBillReducer from '../redux/payBillReducer';
import receiptReducer from '../redux/receiptReducer';
import payNowReducer from '../redux/payNowReducer';
import borneoReducer from '../redux/borneoReducer';

const store = configureStore({
    reducer: {
        user: userReducer,
        ekyc: ekycReducer,
        account: accountReducer,
        topup: topUpReducer,
        profile: profileReducer,
        bookrate: bookRateReducer,
        paynow: payNowReducer,
        beneficiary: beneficiaryReducer,
        transaction: transactionHistoryReducer,
        prepaid: prepaidReducer,
        bill: payBillReducer,
        redeem: redeemReducer,
        receipt: receiptReducer,
        borneo: borneoReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch // export the useDispatch hook to be used in the app

export default store;
