import {
    BENEFICIARY_CHANGE_COUNTRY,
    BENEFICIARY_FETCH,
    BENEFICIARY_FETCH_FAILED,
    BENEFICIARY_FETCH_SUCCESS, BENEFICIARY_RELATIONSHIP_COUNTRY,
    BENEFICIARY_ADD_SETTING_FETCH,BENEFICIARY_ADD_SETTING_FETCH_FAILED,BENEFICIARY_ADD_SETTING_FETCH_SUCCESS,
    BENEFICIARY_ADD_BANK_FETCH,BENEFICIARY_ADD_BANK_FETCH_FAILED,BENEFICIARY_ADD_BANK_FETCH_SUCCESS,
    BENEFICIARY_ADD,BENEFICIARY_ADD_FAILED,BENEFICIARY_ADD_SUCCESS,
    BENEFICIARY_RENDER_COMPONENT,BENEFICIARY_ADD_DISMISS_MESSAGE,
    BENEFICIARY_FILTER,BENEFICIARY_FETCH_FILTER,
    BENEFICIARY_CHECK,BENEFICIARY_CHECK_FAILED,BENEFICIARY_CHECK_SUCCESS,
    BENEFICIARY_CHECK_DISMISS_MESSAGE,
    CHECK_IFSCCODE,
    CHECK_IFSCCODE_FAILED,
    CHECK_IFSCCODE_SUCCESS,
    CHECK_BKASH,
    CHECK_BKASH_FAILED,
    CHECK_BKASH_SUCCESS,
    CHECK_IFSCCODE_DISMISS_MESSAGE
} from "../actions/type";
  
const INITIAL_STATE = {
    loading: false,
    loading1: false,
    loading2:false,
    loading3: false,
    error1 :false,
    error2 :false,
    error3:false,
    error: false,
    message: "",
    message1:"",
    checkdetails:[],
    ifsccodelist:[],
    bkashlist:[],
    message2:"",
    beneficiaries: [],
    filteredbeneficiaries:[],
    countrybeneficiaries: [],
    selectedCountry: '',
    relationship: '',
    relationshipList:[],
    countryList:[],
    purposeList:[],
    addbenebanklist:[],
    addbenebranchlist:[],
    showMessage:false,
    showMessage1:false,
    ifsc:[],
    bankpinlist:[],
    bankorcaspickupList: [],
    showMessageTitle: undefined
}
  
export default (state = INITIAL_STATE, action : any) => {
    switch (action.type){
    case BENEFICIARY_FETCH:
        return { ...state, loading: true }
    case BENEFICIARY_FETCH_SUCCESS:
        return { ...state, loading: false, error: false, message: '', beneficiaries: action.payload, countrybeneficiaries: action.payload2 };
    case BENEFICIARY_FETCH_FAILED:
        return { ...state, loading: false, error: true, message: action.payload };
    case BENEFICIARY_CHANGE_COUNTRY:
        return { ...state, selectedCountry: action.payload };
    case BENEFICIARY_RELATIONSHIP_COUNTRY:
        return { ...state,loading:false, relationship: action.payload };
    case BENEFICIARY_ADD_SETTING_FETCH:
        return { ...state, loading: true }
    case BENEFICIARY_ADD_SETTING_FETCH_SUCCESS:
        return { ...state, loading: false, error: false, message: '', relationshipList: action.payload, countryList: action.payload2, purposeList: action.payload3, bankorcaspickupList: action.payload4, bangbankList:action.payload5 ,ifsclist:action.payload6 };
    case BENEFICIARY_ADD_SETTING_FETCH_FAILED:
        return { ...state, loading: false, error: true, message: action.payload };
    case BENEFICIARY_ADD_BANK_FETCH:
        return { ...state, loading: true }
    case BENEFICIARY_ADD_BANK_FETCH_SUCCESS:
        return { ...state, loading: false, error: false, message: '', addbenebanklist: action.payload, addbenebranchlist: action.payload2, ifsc: action.payload3, bankpinlist: action.payload4 };
    case BENEFICIARY_ADD_BANK_FETCH_FAILED:
        return { ...state, loading: false, error: true, message: action.payload };
    case BENEFICIARY_ADD:
        return { ...state, loading: true }
    case BENEFICIARY_ADD_SUCCESS:
        return { ...state, loading: false, error: false, message: action.payload?.message, showMessage: true ,showMessageTitle: action.payload?.title};
    case BENEFICIARY_ADD_FAILED:
        return { ...state, loading: false, error: true, message: action.payload };
    case BENEFICIARY_RENDER_COMPONENT:
        return { ...state, componentToShow: action.payload, error: false, message: '', loading: false }
    case BENEFICIARY_ADD_DISMISS_MESSAGE:
        return { ...state, showMessage: false, message: '', error:false, showMessageTitle: undefined}
    case BENEFICIARY_FILTER:
        return { ...state, filteredbeneficiaries: action.payload}
    case BENEFICIARY_FETCH_FILTER:
        return { ...state, filteredbeneficiaries: action.payload, loading: true}
    case BENEFICIARY_CHECK:
        return {...state,loading3:true}
    case BENEFICIARY_CHECK_SUCCESS:
        return {...state, loading3:false, error3:false, checkdetails:action.payload, message:''};
    case BENEFICIARY_CHECK_FAILED:
        return {...state, loading3:false,error3:true,message:action.payload}
    case BENEFICIARY_CHECK_DISMISS_MESSAGE:
        return {... state , loading3:false ,error3:false,  message:'', checkdetails:''}
    case CHECK_IFSCCODE:
        return {...state, loading1:true}
    case CHECK_IFSCCODE_SUCCESS:
        return {...state, loading1:false ,error1:false, message1 :'' ,ifsccodelist:action.payload ,showMessage1:true }
    case CHECK_IFSCCODE_FAILED:
        return {...state,loading1:false, error1:true,message1:action.payload ,showMessage1:false}
    case CHECK_IFSCCODE_DISMISS_MESSAGE:
        return {...state, error1:false, message1:'' ,ifsccodelist:''}
    case CHECK_BKASH:
        return {...state, loading2:true}
    case CHECK_BKASH_SUCCESS:
        return {...state, loading2:false ,error2:false, message2:'', bkashlist: action.payload, showMessage1:true }
    case CHECK_BKASH_FAILED:
        return {...state,loading2:false, error2:true, message2:action.payload}
    default:
        return state;
    }
}
  