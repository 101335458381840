import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { CONFIG } from "../../config";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../utils";
import { Box, Button, Card, CardContent, CircularProgress,　Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import Header from "../../components/Header";
import { kirimDoRefund, transactionHistoryRenderComponenet } from "../../actions/TransactionHistoryAction";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import Grid2 from "@mui/material/Unstable_Grid2";

const TransactionRefund = () => {
    const isMobile: boolean = useCheckIsMobile();
    const {state} = useLocation();
    const item : any = state?.item;
    const [fpxbanklist, setFpxbanklist ] = useState([]);
    const [fpxbankname, setFpxbankname] = useState(" ");
    const [edittable , setEdittable] =  useState (0);
    const [accname, setAccname] = useState(" ");
    const [accno, setAccno] = useState(" ");
    const [fpxstatus, setFpxstatus] = useState("");
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [error1, setError] = useState({error: false, message: ""});
    const { error, message, showMessage } = useSelector((state: RootState) => state.transaction);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        getFPXBankList();
    }, []);

    const getFPXBankList = async () => {
        try{
            setLoading(true);
            let input : any = {
                ver: CONFIG.ver,
                act: String(36000),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                m1: "GetAllFpxBankList",
                lang: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error:true, message: "001: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error:true, message: "002: Something went wrong, please check your Internet Connection and try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error:true, message: "003: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.hasOwnProperty("code")) {
                    if (results.code !== 0){
                        setLoading(false);
                        return setError({ error:true, message: `Error Code: ${results.code} :: ${results.message || ""}` });
                    }
                    setFpxbanklist(results.fpxbanklist)
                    setFpxbankname(results.fpxbankvalue)
                    setAccno (results.fpxaccno)
                    setAccname(results.fpxaccname)
                    setFpxstatus(results.fpxaccstatus)
                    setEdittable(results.fpxeditable)
                    setLoading(false);
                }
            }                           
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: "Oops something went wrong!"})
        }
    }

    type MessageDialogProps = {
        open: boolean;
    }

    const MessageDialog: FC<MessageDialogProps> = ({ open }) => {
        const handleOkClick = () => {
            dispatch(transactionHistoryRenderComponenet());
            navigate('/kirim/transaction');
        }

        return(
            <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
                {getLabelValue(lang, 'home.transaction.status.success')}
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOkClick} color="primary">
                    OK
                </Button>
            </DialogActions>
          </Dialog>
        );
    }

    const ErrorDialog: FC<MessageDialogProps> = ({ open }) => {
        const handleOkClick = () => {
            dispatch(transactionHistoryRenderComponenet());
            navigate('/kirim/transaction');
        }

        return(
            <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
                {getLabelValue(lang, 'register.success.warning')}
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOkClick} color="primary">
                    OK
                </Button>
            </DialogActions>
          </Dialog>
        );
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'kirim.transaction.refund')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            elevation={3}
                        >
                            <CardContent>
                                <Box 
                                    sx={{ textAlign: 'left', mt: 2}}
                                    mt="5px"
                                    mb="10px"
                                    ml="5px" 
                                    mr="5px"
                                >
                                    {getLabelValue(lang, 'kirim.fpx.bankname')}
                                </Box>                            
                                <FormControl fullWidth>
                                    <Select
                                        value={fpxbankname}
                                        onChange={(event) => setFpxbankname(event.target.value)}
                                        disabled={edittable === 1 ? true : false}
                                    >
                                    {fpxbanklist.map((bank: any) => (
                                        <MenuItem key={bank.name} value={bank.value}>
                                            {bank.label}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>    
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accname')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={accname}
                                    onChange={(e) => setAccname(e.target.value)}
                                    InputProps={{
                                        readOnly: edittable === 1 ? true : false,
                                    }}
                                />                                 
                                <TextField
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={accno}
                                    onChange={(e) => setAccno(e.target.value)}
                                    InputProps={{
                                        readOnly: edittable === 1 ? true : false,
                                    }}
                                /> 
                                <Button
                                    type="submit"
                                    fullWidth
                                    onClick={() => dispatch(kirimDoRefund(lang, item.remit_ref_trans_id, fpxbankname , accname, accno))}
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={loading}
                                    endIcon={loading ? <CircularProgress size={20} /> : null}
                                >
                                    {getLabelValue(lang, 'kirim.bookrate.confirm.button')}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
                {showMessage && <MessageDialog open={showMessage} />}
                {error && <ErrorDialog open={error} />}
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
})

export default TransactionRefund;