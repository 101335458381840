import { CONFIG } from "../config"
import { updateLoggedInUser } from "../screens/login/userSlice"
import { addUserObjectToRequest, callBackendAPI, setItemFromLS } from "../utils"
import { PROFILE_FETCH_FAILED, PROFILE_FETCH_SUCCESS, PROFILE_RESET_PIN, PROFILE_RESET_PIN_FAILED, PROFILE_RESET_PIN_SUCCESS, PROFILE_UNMOUNT, PROFILE_VERIFY_CURRENT_PIN, PROFILE_VERIFY_CURRENT_PIN_FAILED, PROFILE_VERIFY_CURRENT_PIN_SUCCESS, USER_UPDATE_USER_PROFILE } from "./type"

export const fetchProfileData = (lang : any, user : any) => {
    return async (dispatch : any) => {
        try{
            let input : any = {
                ver: CONFIG?.ver,
                act: 910,
                p1: user.mobile,
                m1: "Profile",
                m2: "",
                lg: lang
            }
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: PROFILE_FETCH_FAILED, payload: "003: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: PROFILE_FETCH_FAILED, payload: "004: Something went wrong, please check your Internet Connection and try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: PROFILE_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                if(!results.hasOwnProperty("user"))
                    return dispatch({ type: PROFILE_FETCH_FAILED, payload: "006: Something went wrong, try again"})
                
                dispatch(updateLoggedInUser(results.user), { type: USER_UPDATE_USER_PROFILE, payload: results.user || undefined });
                setItemFromLS('user', results.user)
                return dispatch({ type: PROFILE_FETCH_SUCCESS });
            }         
            return dispatch({ type: PROFILE_FETCH_FAILED, payload: "005: Something went wrong, try again"});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: PROFILE_FETCH_FAILED, payload: "OOPS SOMETHING WENT WRONG!"})
        }
    }
}

export const verifyCurrentPin = (loggedUser : any, pin = '', lang : any) => {
    return async (dispatch : any) => {
        try {
            dispatch({ type: PROFILE_VERIFY_CURRENT_PIN });
            if (pin === '')
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "Please provide a PIN"});
            if (loggedUser === undefined)
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "001: Oops something went wrong, Please log out and log back in"});

            let input : any = {
                ver: CONFIG?.ver,
                act: 905,
                p1: pin,
                m1: "VerifyPIN",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "002: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "003: Something went wrong, please check your Internet Connection and try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_SUCCESS })
            }         
            return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "004: Something went wrong, try again"});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "Oops something went wrong!" })
        }
    }
}

export const verifyCurrentPinT2P = (loggedUser : any, pin = '', lang : any, t2p_card : any) => {
    return async (dispatch : any) => {
        try {
            dispatch({ type: PROFILE_VERIFY_CURRENT_PIN });
            if (pin === '')
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "Please provide a PIN"});
            if (t2p_card === '')
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "This account does not have a T2P card linked to it"});
            if (loggedUser === undefined)
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "001: Oops something went wrong, Please log out and log back in"});

            let input : any = {
                ver: CONFIG?.ver,
                act: 907,
                p1: pin,
                p2: t2p_card,
                m1: "VerifyPINT2P",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "002: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "003: Something went wrong, please check your Internet Connection and try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_SUCCESS })
            }         
            return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "004: Something went wrong, try again"});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "Oops something went wrong!" })
        }
    }
}

export const resetPin = (loggedUser : any, newPin = '', confirmNewPin = '', lang : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: PROFILE_RESET_PIN });
            if (newPin === '')
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "Please provide a New PIN"});
            if (confirmNewPin === '')
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "Please repeat the New PIN"});
            if (newPin !== confirmNewPin)
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "New PIN and repeated PIN mismatch"});
            if (newPin.length < 6 || confirmNewPin.length < 6)
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "New PIN should be 6 digits"});
            if (loggedUser === undefined)
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "001: Oops something went wrong, Please log out and log back in"});
 
            let input : any = {
                ver: CONFIG?.ver,
                act: 906,
                p1: newPin,
                m1: "ResetPIN",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }                 
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "002: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "003: Something went wrong, please check your Internet Connection and try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });

                return dispatch({ type: PROFILE_RESET_PIN_SUCCESS })
            }         
            return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "005: Something went wrong, try again"});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "Oops something went wrong!" })
        }
    }
}

export const resetPinT2P = (loggedUser : any, newPin = '', confirmNewPin = '', lang : any, t2p_card : any, old_pin ='') => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: PROFILE_RESET_PIN });
            if (newPin === '')
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "Please provide a New PIN"});
            if (confirmNewPin === '')
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "Please repeat the New PIN"});
            if (newPin !== confirmNewPin)
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "New PIN and repeated PIN mismatch"});
            if (newPin.length < 6 || confirmNewPin.length < 6)
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "New PIN should be 6 digits"});
            if (t2p_card === '')
                return dispatch({ type: PROFILE_VERIFY_CURRENT_PIN_FAILED, payload: "This account does not have a T2P card linked to it"});
            if (loggedUser === undefined)
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "001: Oops something went wrong, Please log out and log back in"});
            if (old_pin === '')
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "002: Oops something went wrong, Try again"});
                
            let input : any = {
                ver: CONFIG?.ver,
                act: 908,
                p1: newPin,
                p2: t2p_card,
                p3: old_pin,
                m1: "ResetPIN",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }                 
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "002: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "003: Something went wrong, please check your Internet Connection and try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code === 0)
                    return dispatch({ type: PROFILE_RESET_PIN_SUCCESS })
                if(results.code !== 0)
                    return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}` });
            }         
            return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "005: Something went wrong, try again"});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: PROFILE_RESET_PIN_FAILED, payload: "Oops something went wrong!" })
        }
    }
}

export const unMountProfilePin = () => {
    return { type: PROFILE_UNMOUNT }
}