import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Card, CardContent, CircularProgress, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import Grid2 from "@mui/material/Unstable_Grid2";
import { RootState, useAppDispatch } from "../../../store/store";
import { bookRateConvertMoney, bookRateDimissMessage, bookRatePointRedeem, createBooking } from "../../../actions/BookRateAction";
import { BOOK_RATE_MENU } from "../../../constants/data";
import Header from "../../../components/Header";
import { formatCardNumberWithDashes, getLabelValue } from "../../../utils";
import DialogAlert from "../../../components/DialogAlert";
import { redeemPoints } from "../../../actions/RedeemAction";
import useCheckIsMobile from "../../../hooks/useCheckIsMobile";

const BookRateConfirmation = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [submitClicked, setSubmitClicked] = useState(false);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const { error, message, loading, preBookBody, showMessage, pointredeem, discountAmount, usedPoint, bookRateResp, expired } = useSelector((state: RootState) => state.bookrate);
    const { points } = useSelector((state: RootState) => state.redeem);
    const [redeemAlert, setRedeemAlert] = useState(false);
    const [showAlerts, setShowAlerts] = useState(false);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            if(preBookBody.trx_queue === 1){
                setShowAlerts(true);
            }
            dispatch(bookRateConvertMoney(0, 0));
            dispatch(bookRatePointRedeem(false));
            dispatch(redeemPoints(user, lang));
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    },[preBookBody]);

    const handleRedeemClick = async () => {
        if(pointredeem){
            dispatch(bookRateConvertMoney(0, 0, 0));
            dispatch(bookRatePointRedeem(false));
        }
        else {
            if(points <= 0){
                setRedeemAlert(true);
            }
            else{
                dispatch(bookRateConvertMoney(preBookBody.remit_rm_fee, points, preBookBody.remit_rm_amt));
                dispatch(bookRatePointRedeem(true));
            }
        }
    }

    const handleSubmitClick = async () => {
        setSubmitClicked(true);
        let BookActId = BOOK_RATE_MENU[1];
        dispatch(createBooking(lang, user, BookActId ,preBookBody, usedPoint))
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={"Book Rate Confirmation"} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6} lg={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                            <Box>
                                <Box display='flex' justifyContent={'center'} alignItems={'center'}>
                                    <img
                                        alt="logo"
                                        src={'../../assets/mmlogo.png'}
                                    />
                                </Box>
                                <Box
                                    mt={2}
                                    mb={2}
                                    display='flex'
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography variant='h5'>
                                        Book Rate Summary
                                    </Typography>
                                </Box>
                                <Divider />
                                {preBookBody !== undefined &&
                                    <>
                                        {/* <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Sender No:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {preBookBody.customer_hp}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>                                      */}
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Beneficiary:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {preBookBody?.bene_name || ''}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                        {/* {preBookBody.bank_acc_number !== "0" && */}
                                            <Grid2
                                                container
                                                p={1.5}
                                                pb={0}
                                            >
                                                <Grid2 xs={4} md={5}>
                                                    <Typography variant={'body1'}>
                                                        Account Number
                                                    </Typography>
                                                </Grid2>
                                                <Grid2 xs={8} md={7}>
                                                    <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                        {preBookBody.bank_acc_number || '0'}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>                                                
                                        {/* } */}
                                        {(preBookBody !== undefined && preBookBody?.bank_name !== '') && (
                                            <Grid2
                                                container
                                                p={1.5}
                                                pb={0}
                                            >
                                                <Grid2 xs={4} md={5}>
                                                    <Typography variant={'body1'}>
                                                        Bank Name:
                                                    </Typography>
                                                </Grid2>
                                                <Grid2 xs={8} md={7}>
                                                    <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                        {preBookBody?.bank_name}
                                                    </Typography>
                                                </Grid2>
                                            </Grid2>
                                        )}                                    
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Member Card No:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {formatCardNumberWithDashes(preBookBody?.card_no)}
                                                </Typography>
                                            </Grid2>
                                        </Grid2> 
                                    </>
                                }
                                <Divider sx={{mt: 2}} />
                                {preBookBody !== undefined &&
                                <>
                                    <Grid2
                                        container
                                        p={1.5}
                                        pb={0}
                                    >
                                        <Grid2 xs={4} md={5}>
                                            <Typography variant={'body1'}>
                                                Country:
                                            </Typography>
                                        </Grid2>
                                        <Grid2 xs={8} md={7}>
                                            <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                {preBookBody.country}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Grid2
                                        container
                                        p={1.5}
                                        pb={0}
                                    >
                                        <Grid2 xs={4} md={5}>
                                            <Typography variant={'body1'}>
                                                Provider
                                            </Typography>
                                        </Grid2>
                                        <Grid2 xs={8} md={7}>
                                            <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                {preBookBody?.provider || ''}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>                                    
                                </>
                                }
                                {/* {(preBookBody !== undefined && preBookBody?.ic_account_number !== '') && (
                                    <Grid2
                                        container
                                        p={1.5}
                                        pb={0}
                                    >
                                        <Grid2 xs={4} md={5}>
                                            <Typography variant={'body1'}>
                                                Account No:
                                            </Typography>
                                        </Grid2>
                                        <Grid2 xs={8} md={7}>
                                            <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                {preBookBody?.ic_account_number}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                )} */}
                                <Divider sx={{mt: 2}} />
                                <Grid2
                                    container
                                    p={1.5}
                                    pb={0}
                                >
                                    <Grid2 xs={4} md={5}>
                                        <Typography variant="body1">
                                            Send Amount in {preBookBody.remit_foreign_currency || ''} 
                                            <span style={{ color: 'red' }}>{preBookBody.special_ex_ratecontent || ''}</span>:
                                        </Typography>
                                    </Grid2>
                                    <Grid2 xs={8} md={7}>
                                        <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                            {preBookBody.special_ex_rate > 0 ? preBookBody.special_ex_rate : preBookBody.remit_foreign_amt || ''} 
                                        </Typography>
                                    </Grid2>
                                </Grid2>                                    
                                <Grid2
                                    container
                                    p={1.5}
                                    pb={0}
                                >
                                    <Grid2 xs={4} md={5}>
                                        <Typography variant={'body1'}>
                                            Send Amount in MYR: 
                                        </Typography>
                                    </Grid2>
                                    <Grid2 xs={8} md={7}>
                                        <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                            {preBookBody.remit_rm_amt || ''}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                                <Grid2
                                    container
                                    p={1.5}
                                    pb={0}
                                >
                                    <Grid2 xs={4} md={5}>
                                        <Typography variant={'body1'}>
                                            Service Charge in RM:
                                        </Typography>
                                    </Grid2>
                                    <Grid2 xs={8} md={7}>
                                        <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                            {preBookBody.remit_rm_fee || ''}
                                        </Typography>
                                    </Grid2>
                                </Grid2>
                                {pointredeem ? (
                                    <>
                                        <Grid2
                                            container
                                            p={1.5}
                                            pb={0}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Total Discount in RM:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {`(${discountAmount || ''})`}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>
                                            <Grid2
                                            container
                                            p={1.5}
                                            pb={1.5}
                                        >
                                            <Grid2 xs={4} md={5}>
                                                <Typography variant={'body1'}>
                                                    Total Charge in RM:
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={8} md={7}>
                                                <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                    {(preBookBody.pymt_rm_amt-discountAmount).toFixed(2) || ''}
                                                </Typography>
                                            </Grid2>
                                        </Grid2>  
                                    </>                           
                                ) : (
                                    <Grid2
                                        container
                                        p={1.5}
                                        pb={0}
                                    >
                                        <Grid2 xs={4} md={5}>
                                            <Typography variant={'body1'}>
                                                Total Charge in RM:
                                            </Typography>
                                        </Grid2>
                                        <Grid2 xs={8} md={7}>
                                            <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                {preBookBody.pymt_rm_amt || ''}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>                                        
                                )}
                                <Divider/>
                                {/* <Grid2
                                    container
                                    p={1.5}
                                    pb={1.5}
                                >
                                    <Grid2 xs={4} md={5}>
                                        <Box display="flex" alignItems="center">
                                            <MonetizationOnOutlinedIcon
                                                sx={{
                                                    fontSize: 25
                                                }}
                                            />
                                            <Typography variant={'body1'}>
                                                Points: 
                                            </Typography>
                                        </Box>
                                    </Grid2>
                                    <Grid2 xs={8} md={7}>
                                        <Typography variant={'h6'} fontWeight={'bold'} align={"right"}>
                                                {`${points-usedPoint} ${getLabelValue(lang, 'kita.points')}`}
                                        </Typography>
                                    </Grid2>
                                </Grid2> */}
                                <Grid2
                                    mt={2}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {pointredeem ? (
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={handleRedeemClick}
                                            disabled={submitClicked || loading}
                                            color="success"
                                        >
                                        <MonetizationOnOutlinedIcon
                                            sx={{
                                            fontSize: 25,
                                            marginRight: '8px', // Adjust the margin as needed
                                            }}
                                        />
                                            {`${points - usedPoint} ${getLabelValue(lang, 'kita.points')}`} ({getLabelValue(lang, 'kita.revert')})
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={handleRedeemClick}
                                            disabled={submitClicked || loading}
                                            color="success"
                                        >
                                        <MonetizationOnOutlinedIcon
                                            sx={{
                                                fontSize: 25,
                                                marginRight: '8px', 
                                            }}
                                        />                                        
                                            {`${points-usedPoint} ${getLabelValue(lang, 'kita.points')}`}{`(${getLabelValue(lang, 'kita.redeem')})`}
                                        </Button>                                                    
                                    )}
                                    <div style={{ marginLeft: '10px' }}></div>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSubmitClick}
                                        disabled={loading}
                                        endIcon={loading ? <CircularProgress size={10} /> : null}
                                        color='primary'
                                    >
                                        {getLabelValue(lang, 'kirim.bookrate.confirm.button')}
                                    </Button>
                                </Grid2>
                            </Box>                            
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
                {showAlerts &&
                    <DialogAlert
                        open={showAlerts}
                        onSuccess={() => setShowAlerts(false)}
                        title={'Attention'}
                        content={new Array(preBookBody.trxcontent)}
                        yesButton={'OK'}
                    />
                }
                {redeemAlert &&
                    <DialogAlert
                        open={redeemAlert}
                        onSuccess={() => setRedeemAlert(false)}
                        title={'Attention'}
                        content={new Array('Cannot Redeem')}
                        yesButton={'OK'}
                    />
                }
                {error &&
                    <DialogAlert
                        open={error}
                        onCancel={() => {
                            dispatch(bookRateDimissMessage());
                            setSubmitClicked(false);
                        }}
                        title={'Attention'}
                        content={new Array(message)}
                        yesButton={'OK'}
                    />
                }
                {expired &&
                    <DialogAlert
                        open={expired}
                        onCancel={() => {
                            dispatch(bookRateDimissMessage());
                            setSubmitClicked(false);
                        }}
                        onSuccess={() => {
                            dispatch(bookRateDimissMessage());
                            navigate('/upgradepassport');
                        }}
                        title={'Attention'}
                        content={new Array(message)}
                        yesButton={'Update'}
                        cancelButton={'Cancel'}
                    />
                }
                {showMessage &&
                    <DialogAlert
                        open={showMessage}
                        onCancel={async () => {
                            dispatch(bookRateDimissMessage());
                            navigate('/');
                        }}
                        onSuccess={async () => {
                            dispatch(bookRateDimissMessage());
                            navigate('/kirim/paynow', { state: {bookRateResp: bookRateResp} })
                        }}
                        title={'Confirm'}
                        content={new Array(message)}
                        yesButton={'YES'}
                        cancelButton={'NO'}
                    />
                }
            </Box>            
        </Box>
    )
}
  
const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        m: 2,
        borderRadius: 3,
        px: 1
    }
})

export default BookRateConfirmation
