import { Box, Button, Card, CardContent, Grid, IconButton, TextField, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { useState } from "react";

const ProductDetails = () => {
    const {state} = useLocation();
    const product: any = state?.product;
    console.log(product)
    const [quantity, setQuantity] = useState(product?.min_order_quantity); 
    const { user } = useSelector((state: RootState) => state.user);

    const navigate = useNavigate();

    const handleIncreaseQuantity = () => {
        if (quantity < product.product_stock)
            setQuantity(parseInt(quantity) + 1);
    };
    
    const handleDecreaseQuantity = () => {
        if (quantity > product.min_order_quantity) {
            setQuantity(parseInt(quantity) - 1);
        }
    };

    const handleChangeQuantity = (event: any) => {
        const parsedInput = parseInt(event.target.value, 10);  
        if (isNaN(parsedInput) || parsedInput < product.min_order_quantity) {
            setQuantity(product.min_order_quantity);
        } else if (parsedInput > product.product_stock) {
            setQuantity(product.product_stock);
        } else {
            setQuantity(parsedInput);
        }
    };

    return (
        <Box m="20px">
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Card elevation={15}>
                        <CardContent>
                        <IconButton onClick={() => navigate(-1)} aria-label="back">
                            <ArrowBackIcon />
                        </IconButton>
                            <Box sx={styles.imageContainer}>
                                <img
                                    src={product?.image?.[0]?.url ?? ''}
                                    style={{
                                        maxWidth: '250px', 
                                        maxHeight: '250px', 
                                        width: 'auto',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Box>
                            <Typography variant="h5" sx={styles.titleStyle}>
                                {product.title}
                            </Typography>
                            <Typography variant="h6">
                                RM {product.price.toFixed(2)}
                            </Typography>
                            <Box sx={styles.descriptionBox}>
                                <Typography variant="subtitle1" sx={styles.subtitleStyle}>Product Description:</Typography>
                                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: product.detail_description }} />
                            </Box>

                            <Box sx={styles.infoBox}>
                                <Typography variant="subtitle1" sx={styles.subtitleStyle}>Minimum Order Quantity:</Typography>
                                <Typography variant="body1">{product.min_order_quantity} units</Typography>
                            </Box>
                            <Box sx={styles.infoBox}>
                                <Typography variant="subtitle1" sx={styles.subtitleStyle}>{user?.community?.name} Credits earned per Unit:</Typography>
                                <Typography variant="body1">{product.bc_per_uom.toFixed(2)}</Typography>
                            </Box>        
                            {/* <Box sx={styles.quantityBox}>
                                <Box sx={styles.quantityLabelAndControls}>
                                    <Typography variant="subtitle1" sx={styles.subtitleStyle}>Quantity:</Typography>
                                    <Box sx={styles.quantityControls}>
                                        <Button onClick={handleDecreaseQuantity} sx={styles.buttonStyle}>-</Button>
                                        <TextField
                                            value={quantity.toString()}
                                            onChange={handleChangeQuantity}
                                            type="number"
                                            sx={{ width: '100px', mx: 1 }} 
                                        />
                                        <Button onClick={handleIncreaseQuantity} sx={styles.buttonStyle}>+</Button>
                                    </Box>
                                </Box>
                            </Box> */}
                        </CardContent>          
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

const styles = {
    titleStyle: {
        fontWeight: 'bold',
        mt: 2,
        mb: 1,
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    descriptionBox: {
        mt: 2,
    },
    subtitleStyle: {
        fontWeight: 'bold',
    },
    infoBox: {
        mt: 1,
    },
    quantityBox: {
        display: 'flex',
        alignItems: 'center',
    },
    quantityLabelAndControls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    quantityControls: {
        display: 'flex',
        alignItems: 'center',
        ml: 2, 
    },
    buttonStyle: {
        minWidth: '40px',
        height: '40px',
        fontSize: '30px', 
    },
};

export default ProductDetails;