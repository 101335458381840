import { useSelector } from "react-redux";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { RootState, useAppDispatch } from "../../store/store";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import Header from "../../components/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import { getLabelValue, getLastUpdatedTime } from "../../utils";
import { useEffect, useRef, useState } from "react";
import { getRedeemedList } from "../../actions/RedeemAction";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import moment from "moment-timezone";
import KitaHeader from "../../components/KitaHeader";

const RedeemHistory = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [expandedPanels, setExpandedPanels] = useState<number[]>([]);
    const [tabValues, setTabValues] = useState<any>({});
    const [voucherToUsePage, setVoucherToUsePage] = useState(0);
    const [voucherToUseRowsPerPage, setVoucherToUseRowsPerPage] = useState(5);
    const [voucherPage, setVoucherPage] = useState(0);
    const [voucherRowsPerPage, setVoucherRowsPerPage] = useState(5);
    const [waterPage, setWaterPage] = useState(0);
    const [waterRowsPerPage, setWaterRowsPerPage] = useState(5);
    const [prepaidPage, setPrepaidPage] = useState(0);
    const [prepaidRowsPerPage, setPrepaidRowsPerPage] = useState(5);
    const { loading, redeemed_list } = useSelector((state: RootState) => state.redeem);
    const { lang, user } = useSelector((state: RootState) => state.user);

    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);
    
    const temp_lang = [getLabelValue(lang, 'kita.voucher'), getLabelValue(lang, 'kita.water'),'Prepaid']

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(getRedeemedList(user, lang));
           
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])

    const getPin = (item: any) => {
        try {  
            const json = JSON.parse(item);  
            return json.pin;  
        } catch (e) {  
        }
    };

    const handleChange = (panelIndex: number) => () => {
        const isExpanded = expandedPanels.includes(panelIndex);
        if (isExpanded) {
            setExpandedPanels(expandedPanels.filter((index) => index !== panelIndex));
        } else {
            setExpandedPanels([...expandedPanels, panelIndex]);
        }
    };
    
    const handleChangeTab = (categoryIndex: any, newValue: any) => {
        setTabValues({ ...tabValues, [categoryIndex]: newValue });
    };

    const handleChangeVoucherToUsePage = (event: any, newPage: number) => {
        setVoucherToUsePage(newPage);
    };

    const handleChangeVoucherToUseRowsPerPage = (event: any) => {
        setVoucherToUseRowsPerPage(parseInt(event.target.value, 10));
        setVoucherToUsePage(0);
    };

    const handleChangeVoucherPage = (event: any, newPage: number) => {
        setVoucherPage(newPage);
    };

    const handleChangeVoucherRowsPerPage = (event: any) => {
        setVoucherRowsPerPage(parseInt(event.target.value, 10));
        setVoucherPage(0);
    };

    const handleChangeWaterPage = (event: any, newPage: number) => {
        setWaterPage(newPage);
    };
    
    const handleChangeWaterRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWaterRowsPerPage(parseInt(event.target.value, 10));
        setWaterPage(0);
    };

    const handleChangePrepaidPage = (event: any, newPage: any) => {
        setPrepaidPage(newPage);
    };
    
    const handleChangePrepaidRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrepaidRowsPerPage(parseInt(event.target.value, 10));
        setPrepaidPage(0);
    };

    const getRedeemedItems = (categoryName: any , status: any, page: any, rowsPerPage: any) => {
        const category = redeemed_list.find((c: any) => c.redeem_category === categoryName);
        if (!category || !category.products) return [];
        return category.products
            .filter((product: any) => product.collect_status === status)
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((product: any, index: any) => (
                <TableRow key={index}>
                    <TableCell>{moment(product.redeem_datetime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell>
                        {product.product_images && product.product_images.length > 0 ? (
                            <img src={product.product_images[0].img_url} alt={product.product_images[0].img_name} style={styles(isMobile).img} />
                        ) : null}
                    </TableCell>
                    <TableCell>{product.product_name}</TableCell>
                    {(categoryName === 'voucher') && <TableCell>{`${getLabelValue(lang, 'kita.history.voucher_code')}: ${product.voucher_code}`}</TableCell>}
                    {(categoryName === 'prepaid') && <TableCell>{`PIN : ${getPin(product.action_description)}`}</TableCell>}
                    <TableCell sx={{ color: 'red' }}>{`-${product.product_total_redeem_points} ${getLabelValue(lang, 'kita.points')}`}</TableCell>
                </TableRow>
            ));
    };

    const voucherToUseRows = getRedeemedItems('voucher', 0, voucherToUsePage, voucherToUseRowsPerPage);
    const voucherUsedRows = getRedeemedItems('voucher', 1, voucherPage, voucherRowsPerPage);
    const waterUsedRows = getRedeemedItems('water', 1, waterPage, waterRowsPerPage);
    const prepaidUsedRows = getRedeemedItems('prepaid', 1, prepaidPage, prepaidRowsPerPage);

    const getTotalCount = (categoryName: any, status: any) => {
        const category = redeemed_list.find((c: any) => c.redeem_category === categoryName);
        return category?.products?.filter((product: any) => product.collect_status === status).length || 0;
    };
    
    const totalVoucherToUseCount = getTotalCount('voucher', 0);
    const totalVoucherUsedCount = getTotalCount('voucher', 1);
    const totalWaterUsedCount = getTotalCount('water', 1);
    const totalPrepaidUsedCount = getTotalCount('prepaid', 1);

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'kita.history.header')} subtitle={getLastUpdatedTime(lang)} />
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <KitaHeader />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            {redeemed_list.map((category: any, index: number) => (
                                <Accordion key={category.title} expanded={expandedPanels.includes(index)} onChange={handleChange(index)} sx={{
                                    boxShadow: 2,
                                    '&.Mui-expanded': {
                                        backgroundColor: 'white',
                                    },
                                }}>
                                    <AccordionSummary sx={{ minHeight: "40px" }}
                                        expandIcon={<ExpandMoreIcon />}
                                        id={`category-${index}`}
                                    >
                                        <Typography variant='h5'>
                                            {category.redeem_category === undefined ? '' :   temp_lang[index]}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TabContext value={tabValues[index] || (category.redeem_category === 'voucher' ? '1' : '2')}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            {(redeemed_list == '' || !redeemed_list || redeemed_list.length === 0) ? (
                                                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                                                    {getLabelValue(lang, 'kita.point.empty')}
                                                </Typography>     
                                            ) : (
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList onChange={(e: any, newValue: any) => handleChangeTab(index, newValue)} variant="scrollable" scrollButtons="auto">
                                                        {category.redeem_category == 'voucher' ? (
                                                        <Tab 
                                                            label={getLabelValue(lang, 'kita.history.toptab1')} 
                                                            value="1"
                                                            sx={{ flexGrow: 1, textAlign: 'center', maxWidth: 'none',  color: 'green' }} 
                                                        />
                                                        ): null}
                                                        <Tab 
                                                            label={getLabelValue(lang, 'kita.history.toptab2')} 
                                                            value="2"
                                                            sx={{ flexGrow: 1, textAlign: 'center', maxWidth: 'none', color: 'red' }} 
                                                        />
                                                    </TabList>
                                                </Box>
                                            )}
                                        </Box>
                                        <TabPanel value="1">
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                    {!loading && (!voucherToUseRows || voucherToUseRows.length === 0) && (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center">{getLabelValue(lang, 'kita.point.empty')}</TableCell>
                                                        </TableRow>                                     
                                                    )}
                                                    {loading && (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center">
                                                                <CircularProgress color="primary" />
                                                            </TableCell>
                                                        </TableRow>                                               
                                                    )}       
                                                    {voucherToUseRows}
                                                    </TableBody>
                                                </Table>
                                                <TablePagination
                                                    component="div"
                                                    count={totalVoucherToUseCount}
                                                    rowsPerPage={voucherRowsPerPage}
                                                    page={voucherPage}
                                                    onPageChange={handleChangeVoucherToUsePage}
                                                    onRowsPerPageChange={handleChangeVoucherToUseRowsPerPage}
                                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                />
                                            </TableContainer>                            
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                    {category.redeem_category === 'voucher' && voucherUsedRows.length === 0 ? (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center">{getLabelValue(lang, 'kita.point.empty')}</TableCell>
                                                        </TableRow>
                                                    ) : null}
                                                    {category.redeem_category === 'water' && waterUsedRows.length === 0 ? (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center">{getLabelValue(lang, 'kita.point.empty')}</TableCell>
                                                        </TableRow>
                                                    ) : null}
                                                    {category.redeem_category === 'prepaid' && prepaidUsedRows.length === 0 ? (
                                                        <TableRow>
                                                            <TableCell colSpan={3} align="center">{getLabelValue(lang, 'kita.point.empty')}</TableCell>
                                                        </TableRow>
                                                    ) : null}
                                                    {
                                                        category.redeem_category === 'voucher' ? voucherUsedRows :
                                                        category.redeem_category === 'water' ? waterUsedRows :
                                                        prepaidUsedRows
                                                    }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            {category.redeem_category === 'voucher' &&
                                                <TablePagination
                                                    component="div"
                                                    count={totalVoucherUsedCount}
                                                    rowsPerPage={voucherRowsPerPage}
                                                    page={voucherPage}
                                                    onPageChange={handleChangeVoucherPage}
                                                    onRowsPerPageChange={handleChangeVoucherRowsPerPage}
                                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                />}
                                                {category.redeem_category === 'water' &&
                                                <TablePagination
                                                    component="div"
                                                    count={totalWaterUsedCount}
                                                    rowsPerPage={waterRowsPerPage}
                                                    page={waterPage}
                                                    onPageChange={handleChangeWaterPage}
                                                    onRowsPerPageChange={handleChangeWaterRowsPerPage}
                                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                />}
                                                {category.redeem_category === 'prepaid' &&
                                                <TablePagination
                                                    component="div"
                                                    count={totalPrepaidUsedCount}
                                                    rowsPerPage={prepaidRowsPerPage}
                                                    page={prepaidPage}
                                                    onPageChange={handleChangePrepaidPage}
                                                    onRowsPerPageChange={handleChangePrepaidRowsPerPage}
                                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                />}                                
                                        </TabPanel>
                                        </TabContext>                                        
                                    </AccordionDetails>
                                </Accordion>
                            ))}                         
                        </Card>
                    </Grid2>
                </Grid2> 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    },
    img: {
        width: 45,
        height: 38,
    }
});

export default RedeemHistory;

