import {Box, Button, Card, CardContent, CircularProgress, FormControl, List, MenuItem, Select, Typography} from "@mui/material";
import Grid from '@mui/material/Grid';
import React, { useEffect, useRef, useState } from "react";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../../utils";
import { CONFIG } from "../../../config";
import _ from "lodash";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import BeneficiaryCard from "../../../components/BeneficiaryCard";
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import Grid2 from "@mui/material/Unstable_Grid2";
import DialogAlert from "../../../components/DialogAlert";
import useCheckIsMobile from "../../../hooks/useCheckIsMobile";
import { BOOK_RATE_CHANGE_BENE } from "../../../actions/type";

const colors = tokens();

const Beneficiary = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState<undefined | string>(undefined);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [unfilteredBeneficiaries, setUnFilteredBeneficiaries] = useState([]);
    const [beneficiary, setBeneficiary] = useState(undefined);
    const [isSelectedBene, setIsSelectedBene] = useState(-1);
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ error: false, message: '' });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const shouldLockEffect = useRef(false);
    
    useEffect(() => {
        const initialize = async (): Promise<void> => {
            fetchAllBeneList();
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        } 
    },[]);
    
    const fetchAllBeneList = async () : Promise<void> => {
        try{
            setLoading(true);
            let input : any = {
                ver: CONFIG.ver,
                act: 30001,
                m1: 'Get Beneficiary Data',
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error: true, message: '001: Something went wrong, try again'});
            }
            if (resultsFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: '002: Something went wrong, try again'});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error: true, message: '003: Something went wrong, try again'});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0){
                    setLoading(false);
                    return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}` });
                }
                if (results.hasOwnProperty("providers")){
                    setBeneficiaries(results.providers);
                    setUnFilteredBeneficiaries(results.providers);
                    let countriesList = results.providers.map((i: { label: any; value: any; }) => {
                      return { label: i?.label, value: i?.value }
                    })
                    let uniqCountriesList : any = [
                      ...[{label: 'All Countries', value: 'All Countries'}],
                      ..._.uniqBy(countriesList, (x: { value: any; }) => x.value)
                    ];
                    setCountries(uniqCountriesList);
                    setCountry(uniqCountriesList[0]?.value)
                }
                return setLoading(false);
            }
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: '99: OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER'})
        }
    }

    const filterBeneListByCountry = (country : any) => {
        if (country === 'All Countries')
        return setBeneficiaries(unfilteredBeneficiaries);
        return setBeneficiaries(unfilteredBeneficiaries.filter((i: { value: any;}) => i?.value === country));
    }

    const handleSubmitClick = (item : any) => {
        if (!beneficiary) {
            return setError({ error: true,message: "Please select a beneficiary"});
        }
        dispatch({ type: BOOK_RATE_CHANGE_BENE, payload: item?.card_id });
        navigate('/kirim/bookrate', { state: { bene_country: item?.country_name, bene_name: item?.ic_acc_name, pickup_method_value : item?.pickup_method_value }}); 
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'kirim.beneficiary.bene')} subtitle={""} />
                </Box>
                <Grid2 container>
                    <Grid2 xs={12} md={6}>
                    <Card
                        sx={styles(isMobile).cardStyle}
                        elevation={3}
                    >
                        <CardContent>
                            <Box 
                                sx={{ textAlign: 'left'  }}
                                mt="5px"
                                mb="10px"
                                ml="5px" 
                                mr="5px"
                            >
                                {getLabelValue(lang, 'kirim.beneficiary.benecountry')}
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                value={country || ''}
                                onChange={(e) => { 
                                    const itemValue = e.target.value; 
                                    setCountry(itemValue);
                                    filterBeneListByCountry(itemValue);
                                }}
                                >
                                {countries.map((country : any, index) => (
                                    <MenuItem key={index} value={country.value}>
                                    {country.value}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                    <Card
                        sx={styles(isMobile).cardStyle}
                        elevation={3}
                    >
                        <CardContent>
                            <Box 
                                sx={{ display: 'flex', alignItems: 'center' }}
                                mt="5px"
                                mb="10px"
                                ml="5px" 
                                mr="5px"
                            >
                                <Typography variant="h5">
                                    {getLabelValue(lang, 'kirim.bookrate.selectbene.title')}
                                </Typography>
                                <Box sx={{ marginLeft: 'auto' }}>
                                    <Tooltip title={getLabelValue(lang, 'kirim.beneficiary.title')}>
                                        <IconButton
                                            onClick={() => navigate('/kirim/addbeneficiary')}
                                        >
                                            <AddIcon sx={{ fontSize: '30px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <List component="nav" sx={{ flexGrow: 1, overflowY: 'auto', alignItems: 'center' }}>
                                {beneficiaries.map((item, index) => (
                                <BeneficiaryCard
                                    beneficiary={item}
                                    selected={isSelectedBene}
                                    onSelect={(index) => {
                                        setIsSelectedBene(index);
                                        setBeneficiary(beneficiaries[index])
                                    }}
                                    index={index}
                                />
                                ))}             
                                {beneficiaries.length === 0 && (
                                <Box sx={{ textAlign: 'center', p: 3 }}>
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                    {getLabelValue(lang, 'kirim.bookrate.noBene')}
                                    </Typography>
                                    <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/kirim/addbeneficiary')}
                                    >
                                    {getLabelValue(lang, 'kirim.beneficiary.title')}
                                    </Button>
                                </Box>
                                )}
                            </List>
                            {error.error && (
                                <Grid
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                >
                                <Typography textAlign="center" color={colors.primary[500]}>
                                    {error.message || ''}
                                </Typography>
                                </Grid>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                onClick={() => handleSubmitClick(beneficiary)}
                                variant="contained"
                                color="primary"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loading}
                                endIcon={loading ? <CircularProgress size={20} /> : null}
                            >
                                {getLabelValue(lang, 'kirim.bookrate.selectbene.button')}
                            </Button>          
                        </CardContent>
                    </Card>
                    </Grid2>
                </Grid2>
                {error.error && (
                    <DialogAlert
                    open={error.error}
                    onSuccess={() => setError({ error: false, message: "" })}
                    title={"Attention"}
                    content={new Array(error.message)}
                    yesButton={"OK"}
                    />
                )}
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        m: 2,
        borderRadius: 3,
        px: 1
    }
})

export default Beneficiary;
