import { FC, useEffect, useRef, useState } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import { getPayBillBillers } from "../../actions/PayBillAction";
import { useSelector } from "react-redux";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CircularProgress, Container, Grid, TextField, Typography } from "@mui/material";
import Header from "../../components/Header";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../utils";
import Grid2 from "@mui/material/Unstable_Grid2";
import { CONFIG } from "../../config";
import { IError, RouteComponent } from "../../type";
import PinInput from "../../components/PinInput";
import { HOME_UPDATE_WALLET_BALANCE } from "../../actions/type";
import DialogAlert from "../../components/DialogAlert";
import { useNavigate } from "react-router-dom";

interface Props extends RouteComponent{
    logOut: () => void;
}

const Bill: FC<Props> = (props) => {
    const { logOut } = props;
    const isMobile: boolean = useCheckIsMobile();
    const [expandedPanels, setExpandedPanels] = useState<number[]>([]);
    const { payBillBillers } = useSelector((state: RootState) =>state.bill);
    const [selectedBiller, setSelectedBiller] = useState<any>('');
    const [billerInputs, setBillerInputs] = useState<any>('');
    const [showPin, setShowPin] = useState(false);
    const [pinNumber, setPinNumber] = useState<string>('');
    const { lang, user } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<IError>({ error: false, message: "", forgot_pin: 0});
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(getPayBillBillers(lang));
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])
    
    const handleChange = (panelIndex: number) => () => {
        const isExpanded = expandedPanels.includes(panelIndex);
        if (isExpanded) {
            setExpandedPanels(expandedPanels.filter((index) => index !== panelIndex));
        } else {
            setExpandedPanels([...expandedPanels, panelIndex]);
        }
    };
    
    const handleInputChange = (val: any, index: any) => {
        if (billerInputs === undefined) return null;
        const updatedBillerInputs = billerInputs.map((i: any, v: any) => {
            if (index === v)
                return { ...i, value: val }
            else
                return i;
        });
        setBillerInputs(updatedBillerInputs);
    }

    const handleSubmitButton = () => {
        try{
            if (!billerInputs || billerInputs.length === 0)
                return setError({error: true, message: lang === 'en' ? 'No input data' : 'Tiada data input'});
      
            for (let i = 0; i < billerInputs.length; i++){
                if (
                    billerInputs[i].hasOwnProperty("required")
                    && billerInputs[i].required === "true"
                ){
                    if (billerInputs[i].value === '')
                    return setError({error: true, message: `${billerInputs[i].label || 'Input'} is required`});
                }
            }
      
            setError({error: false, message: ''})
            setShowPin(true);
        }
        catch (e) {
            console.log(e);
            setError({error: true, message: 'OOPS SOMETHING WENT WRONG'})
        }
    }

    const createPayBillTransaction = async () => {
        try{
            setLoading(true);
            if (!pinNumber || pinNumber === ''){
                setShowPin(false);
                setLoading(false);
                return setError({ error: true, message: "Please enter Pin" });
            }
            let input: any = {
                ver: CONFIG.ver,
                act: selectedBiller.act_id,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: pinNumber,
                m1: 'PayBill',
                m2: selectedBiller.name || '',
                m3: selectedBiller.payee_code || '',
                lg: lang
            };
            for (let i = 0; i < billerInputs.length; i++){
                input[`p${i + 2}`] = billerInputs[i].value || '';
            }
            input = addUserObjectToRequest(input);
            console.log(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                setLoading(false);
                setPinNumber('');
                return setError({error: true, message: '001: Something went wrong, try again'});
            }
            if (resultsFromAPI.error !== undefined){
                setLoading(false);
                setPinNumber('');
                return setError({error: true, message: '002: Something went wrong, please check your Internet Connection and try again'});
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                setLoading(false);
                setPinNumber('');
                return setError({error: true, message: '003: Something went wrong, try again'});
            }
            let results = resultsFromAPI.results;
        
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0){
                setLoading(false);
                setPinNumber('');
                if (results?.renderForgotPin)
                    return setError({error: true, message: `[${results.code}] : ${results.message || ""}`, forgot_pin: results.renderForgotPin});
                }
                if (results.hasOwnProperty("balance") && results.balance)
                dispatch({
                    type: HOME_UPDATE_WALLET_BALANCE,
                    payload: { balance: results.balance,pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null }
                })
        
                if (results.code === 0){
                    return navigate('/receipt', { state : { receiptInfo: results.receipt, messageKey: 'prepaidselected.receipt.subTitle' } });         
                }

                return setError({error: true, message: `[${results.code}] : ${results.message || ""}`});
            }

            setLoading(false);
            setPinNumber('');
            return setError({error: true, message: '004: Something went wrong, try again'});
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setPinNumber('');
            setError({error: true, message: 'OOPS SOMETHING WENT WRONG'})
        }
    }

    return (
        <Box m="20px">
            <Box
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'home.mmproducts.product2')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                {selectedBiller ? (
                                    <>
                                        <Box display='flex' justifyContent={'center'} alignItems={'center'}>
                                            <img
                                                src={selectedBiller.icon}
                                                style={{
                                                    width: '100px',  
                                                    height: '100px', 
                                                    objectFit: 'contain'
                                                }}                                    
                                            />
                                        </Box>
                                        <Typography variant='h6' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                                            {selectedBiller.name}
                                        </Typography>    
                                        <Container>
                                            <Box 
                                                sx={{ textAlign: 'left'  }}
                                                mt="10px"
                                                ml="5px" 
                                                mr="5px"
                                            >
                                                {getLabelValue(lang, 'paybill.description')}
                                            </Box>
                                            {billerInputs ? (
                                                billerInputs.map((input: any, index: React.Key) => (
                                                    <TextField
                                                        key={index}
                                                        label={input.label}
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        value={input.value || ''}
                                                        onChange={(e) => handleInputChange(e.target.value, index)}
                                                        type={input.type}
                                                    />
                                                ))
                                            ):(
                                                null
                                            )}
                                        </Container>  
                                        <Container sx={{ mt: 2, mb: 2}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        color="success"
                                                        onClick={() => {
                                                            setSelectedBiller('');
                                                            setBillerInputs('');
                                                            setPinNumber('');
                                                            setError({ error: false, message: '' });
                                                        }}
                                                        disabled={loading} 
                                                    >
                                                        {getLabelValue(lang, 'login.account.button.goback')}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Button
                                                    type="submit"
                                                    fullWidth
                                                    onClick={() => {handleSubmitButton()}}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={loading}
                                                    endIcon={loading ? <CircularProgress size={20} /> : null}
                                                    >
                                                        {getLabelValue(lang, 'paybill.proceed')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Container>                               
                                    </>
                                ):(
                                <Box sx={{ m:2 }}>
                                    <Typography variant={"h3"} 
                                        sx={{ wordWrap: 'break-word', textDecoration: 'underline', textDecorationThickness: '1px', fontWeight: '600' }}>
                                        Biller List
                                    </Typography>
                                    {payBillBillers.map((category: any, index: number) => (
                                        <Accordion key={category.title} expanded={expandedPanels.includes(index)} onChange={handleChange(index)} sx={{
                                            boxShadow: 2,
                                            marginTop: 1,
                                            '&.Mui-expanded': {
                                                backgroundColor: 'white',
                                            },
                                        }}>
                                            <AccordionSummary sx={{ marginTop: 3, minHeight: "40px" }}
                                                expandIcon={<ExpandMoreIcon />}
                                                id={`category-${index}`}
                                            >
                                                <Typography variant='h5'>
                                                    {category.title}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                                    {category.data.map((item: any, index: React.Key) => (
                                                    <Grid xs={2} sm={4} md={4} key={index}>
                                                        <Button
                                                            fullWidth
                                                            onClick={() => {
                                                                if(item.hasOwnProperty("navigateTo") && item.navigateTo != null){
                                                                    navigate(`${item.navigateTo}`, { state: { item: item } });
                                                                }
                                                                setSelectedBiller(item);
                                                                setBillerInputs(item.inputs);
                                                            }} 
                                                            sx={{
                                                                mt: '10px',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                padding: '10px',
                                                                height: isMobile ? '80%' : '100%',
                                                            }}
                                                        >
                                                            <img
                                                                src={item.icon}
                                                                style={{ maxWidth: isMobile ? '70%' : '50%', maxHeight: isMobile ? '70%' : '50%' }}
                                                            />
                                                            <Typography variant='h6' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                                                                {item.name}
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    ))}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Box>
                                )}
                            </CardContent>    
                        </Card>        
                    </Grid2>
                </Grid2>
                {showPin && 
                    <PinInput
                        open={showPin}
                        onCancel={() => setShowPin(false)}
                        onSuccess={async () => {
                            setShowPin(false);
                            await createPayBillTransaction();
                        }}
                        yesButton={'Submit'}
                        cancelButton={'Cancel'}
                        pinNumber ={pinNumber}
                        onPinChange={(e: any)=>{
                            setPinNumber(e.target.value)
                        }}
                        lang={lang}
                    />
                }
                {error.error && (
                    <DialogAlert
                        open={error.error}
                        onCancel={() => {
                            setError({ error: false, message: '', forgot_pin: 0 });
                            logOut();
                            navigate('/forgotpin', { state: { user: user } });
                        }}
                        onSuccess={() => {
                            setError({ error: false, message: '', forgot_pin: 0 });
                            setPinNumber('');
                        }}
                        title={'Attention'}
                        content={new Array(error.message)}
                        yesButton={'OK'}
                        cancelButton={error.forgot_pin === 1 ? (lang === 'en' ? 'FORGOT PIN' : 'LUPA PIN') : ''}
                        reportIssue={error.reportIssue?.show}
                        req_idx={error.reportIssue?.idx}
                        onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
                        reportTitle={error?.title}
                        reportDetails={error?.details}
                        trans_id={error?.trans_id}
                        ticket_type={error?.ticket_type}
                    />
                )}  
            </Box>                 
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    },
})

export default Bill;
