import { Box, Button, Card, CardContent, CardMedia, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { RootState } from "../../store/store";
import { CONFIG } from "../../config";
import { addUserObjectToRequest, callBackendAPI } from "../../utils";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { tokens } from "../../theme";
import zIndex from "@mui/material/styles/zIndex";

const colors =  tokens();

const ProductListing = () => {
    const {state} = useLocation();
    const isMobile: boolean = useCheckIsMobile();
    const category = state?.category;
    const subCategory = state?.subCategory;
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            fetchProducts();
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])

    const fetchProducts = async () => {
        try {
            setLoading(true);
            let input: any = {
                ver: CONFIG.ver,
                act: 60220,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                lg: lang,
                community: user?.community,
                m1: "Fetch Borneo Products",
                products_action_name: "read",
                category: category,
                subcategory: subCategory,
            };
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            setLoading(false);
            if (!resultsFromAPI) {
                return;
            }
            if (resultsFromAPI.error !== undefined) {
                return;
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return;
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0) {
                    return;
                }
                setProducts(results?.data);
                return;
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >   
                <Box display="flex" alignItems="center" sx={{ mb: '20px' }}>
                    <IconButton onClick={() => navigate(-1)} aria-label="back">  
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography                 
                        variant="h3"
                        color={colors.black[500]}
                        fontWeight="bold"
                    >
                        Products
                    </Typography>
                </Box>
                {loading ? (
                    <Box
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <CircularProgress size={60} color="primary" />
                    </Box>                    
                ) : (
                    products.length > 0 ? (
                        <Grid container spacing={2} alignItems="stretch">
                        {products.map((product: any) => (
                            <Grid item xs={6} sm={6} lg={3} key={product.id}>
                                <Card sx={styles(isMobile).cardStyle}>
                                    <CardMedia
                                        component="img"
                                        width="100%"
                                        height={isMobile ? '100' : '200'}
                                        image={product.image[0]?.url}
                                    />
                                    <CardContent sx={styles(isMobile).cardContentStyle}>
                                        <Typography 
                                            variant="body1" 
                                            sx={{ fontWeight: 'bold' }} 
                                            align="center"
                                        >
                                            {product.title}
                                        </Typography>
                                        <Typography 
                                            variant="body1" 
                                            align="center"
                                        >
                                            RM {product.price.toFixed(2)}
                                        </Typography>
                                        <Box sx={styles(isMobile).buttonContainerStyle}>
                                            <Button 
                                                variant="contained"
                                                onClick={() => {navigate('/borneokaki/productdetails', { state: { product: product }} )}}
                                            >
                                                View Product
                                            </Button>
                                        </Box>                              
                                    </CardContent>
                                </Card>
                            </Grid> 
                        ))}
                        </Grid>
                    ) : (
                        <Box
                            style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: -1
                            }}
                        >
                            <Typography variant="h4">
                                Product List is Empty
                            </Typography>  
                        </Box>  
                    )
                )}
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%', 
        borderRadius: 2,
    },
    cardContentStyle: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between',
        flexGrow: 1,
    },
    buttonContainerStyle: {
        display: 'flex',
        justifyContent: 'center',
        mt: 2,
        flexGrow: 0, 
    },
})

export default ProductListing;
