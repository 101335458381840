import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { FC, useState } from "react";
import { getLabelValue } from "../utils";

interface PROPS {
    open: boolean
    cancelButton?: string;
    onCancel?: () => void;
    yesButton?: string;
    onSuccess?: () => void;
    pinNumber: string;
    onPinChange: any;
    lang: string;
}

const PinInput: FC<PROPS> = (props) => {
    const {
        open,
        cancelButton = '',
        onCancel,
        yesButton,
        onSuccess,
        pinNumber,
        onPinChange,
        lang,
    } = props;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <Box
                sx={{ mt: 1 }}>
                <Box
                sx={{ textAlign: 'left' }}
                mt="10px"
                mb="5px"
                ml="5px"
                mr="5px"
                >
                {getLabelValue(lang, 'login.account.pin.title')}
                </Box>
                <TextField
                margin="none"
                required
                fullWidth
                name="pin"
                placeholder=""
                type='password'
                id="pin"
                autoFocus
                value={pinNumber}
                onChange={onPinChange}
                inputProps={{
                    maxLength: 6,
                    style: { textAlign: 'center' }, 
                }}                    
                />
            </Box>
            </DialogContent>
            <DialogActions>
                {cancelButton !== '' ? (
                    <Button onClick={onCancel}>{cancelButton}</Button>
                ) : null}
                {yesButton !== '' ? (
                    <Button onClick={onSuccess} autoFocus>
                        {yesButton}
                    </Button>
                ) : null}
            </DialogActions>
        </Dialog>        
    )
}

export default PinInput;