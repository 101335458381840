import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { BENEFICIARY_ADD, BENEFICIARY_ADD_BANK_FETCH, BENEFICIARY_ADD_BANK_FETCH_FAILED, BENEFICIARY_ADD_BANK_FETCH_SUCCESS, BENEFICIARY_ADD_DISMISS_MESSAGE, BENEFICIARY_ADD_FAILED, BENEFICIARY_ADD_SETTING_FETCH, BENEFICIARY_ADD_SETTING_FETCH_FAILED, BENEFICIARY_ADD_SETTING_FETCH_SUCCESS, BENEFICIARY_ADD_SUCCESS, BENEFICIARY_CHANGE_COUNTRY, BENEFICIARY_CHECK, BENEFICIARY_CHECK_DISMISS_MESSAGE, BENEFICIARY_CHECK_FAILED, BENEFICIARY_CHECK_SUCCESS, BENEFICIARY_FETCH, BENEFICIARY_FETCH_FAILED, BENEFICIARY_FETCH_SUCCESS, BENEFICIARY_FILTER, BENEFICIARY_RENDER_COMPONENT, CHECK_IFSCCODE, CHECK_IFSCCODE_DISMISS_MESSAGE, CHECK_IFSCCODE_FAILED, CHECK_IFSCCODE_SUCCESS } from "./type";

export const checkifsc = (lang : any, code : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: CHECK_IFSCCODE });
            if (code === undefined)
                return dispatch({ type:  CHECK_IFSCCODE_FAILED, payload: "002: Oops Something went wrong, Login in again"})
            if (!code.hasOwnProperty("act_id"))
                return dispatch({ type: CHECK_IFSCCODE_FAILED, payload: "003: Oops Something went wrong, Login in again"})

            let act_id = code.act_id
            let input : any = {
                ver: CONFIG.ver,
                act: String(act_id),
                p1: code.ifsc,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: CHECK_IFSCCODE_FAILED, payload: "005: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: CHECK_IFSCCODE_FAILED, payload: "006: Something went wrong, try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: CHECK_IFSCCODE_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0) {
                    return dispatch({ type: CHECK_IFSCCODE_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                }
                if (results.hasOwnProperty("ifsc")) {
                    return dispatch({ type: CHECK_IFSCCODE_SUCCESS, payload: results.ifsc});
                }        
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: CHECK_IFSCCODE_FAILED, payload: "Oops Something went wrong"})
        }
    }
}

export const dismissCheck = () =>{
    return {type:BENEFICIARY_CHECK_DISMISS_MESSAGE}
}

export const dismissCheckifscMessage = ()=>{
    return { type: CHECK_IFSCCODE_DISMISS_MESSAGE}
}

export const beneficiaryListFetch = (lang : any, params : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: BENEFICIARY_FETCH });
            if (params === undefined)
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "002: Oops Something went wrong, Login in again"})
            if (!params.hasOwnProperty("act_id"))
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "003: Oops Something went wrong, Login in again"})

            let act_id = params.act_id,
                m1 = params.name || '';
            let input : any = {
              ver: CONFIG.ver,
              act: String(act_id),
              m1: m1,
              lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "005: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "006: Something went wrong, try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                if (results.hasOwnProperty("providers")){
                    let countryArray : any = [];
                    countryArray.push({label: "All Country"});
                    if (results.providers.length === 0){
                        return dispatch({ type: BENEFICIARY_FETCH_SUCCESS, payload: results.providers, payload2: countryArray })
                    }
                    results.providers.forEach((obj: { label: any; }) => {
                        if (!countryArray.some((o: { label: any; }) => o.label === obj.label)) {
                            countryArray.push({ ...obj })
                        }
                        return dispatch({ type: BENEFICIARY_FETCH_SUCCESS, payload: results.providers, payload2: countryArray })
                    });
                }
            }
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "Oops Something went wrong"})
        }
    }
}

export const changeBeneCountry = (selectedCountry : any) => {
    return { type: BENEFICIARY_CHANGE_COUNTRY, payload: selectedCountry }
}

export const beneficiaryAddBeneSettingListFetch = (lang : any, params : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH });
            if (params === undefined)
                return dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH_FAILED, payload: "002: Oops Something went wrong, Login in again"})
            if (!params.hasOwnProperty("act_id"))
                return dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH_FAILED, payload: "003: Oops Something went wrong, Login in again"})

            let act_id = params.act_id,
                m1 = params.name || '';
            let input : any = {
              ver: CONFIG.ver,
              act: String(act_id),
              m1: m1,
              lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH_FAILED, payload: "005: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH_FAILED, payload: "006: Something went wrong, try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                if (!results.hasOwnProperty("providers"))
                    return dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH_FAILED, payload: "008: Something went wrong, try again"});
                let resultsProviders = results.providers;
                if (resultsProviders.hasOwnProperty("relationshiplist") && resultsProviders.hasOwnProperty("countrylist")) {
                    return dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH_SUCCESS, payload: resultsProviders.relationshiplist, payload2: resultsProviders.countrylist, payload3: resultsProviders.purposelist, payload4: resultsProviders.cashorbanklist , payload5:resultsProviders.bangbanklist ,payload6:resultsProviders.ifsclist})
                }
            }       
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BENEFICIARY_ADD_SETTING_FETCH_FAILED, payload: "Oops Something went wrong"})
        }
    }
}

export const beneficiaryAddBeneGetBank = (lang : any, params : any, country : any, bankname : any, branchname : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: BENEFICIARY_ADD_BANK_FETCH });
            if (params === undefined)
                return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_FAILED, payload: "002: Oops Something went wrong, Login in again"})
            if (!params.hasOwnProperty("act_id"))
                return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_FAILED, payload: "002: Oops Something went wrong, Login in again"})

            let act_id = params.act_id,
                m1 = params.name || '';
            let input : any = {
              ver: CONFIG.ver,
              act: String(act_id),
              p1: country,
              p2: bankname,
              p3: branchname,
              m1: m1,
              lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_FAILED, payload: "005: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_FAILED, payload: "006: Something went wrong, try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                if (!results.hasOwnProperty("providers"))
                    return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_FAILED, payload: "008: Something went wrong, try again"});
                let resultsProviders = results.providers;
                if (resultsProviders.hasOwnProperty("banklist")) {
                    resultsProviders.banklist.sort((a: { bankValue: number; }, b: { bankValue: number; }) => a.bankValue - b.bankValue);
                    // resultsProviders.banklist.sort((a: { bankName: string; }, b: { bankName: any; }) => a.bankName.localeCompare(b.bankName));
                    if (resultsProviders.hasOwnProperty("bankpinlist")) {
                        return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_SUCCESS, payload: resultsProviders.banklist, payload2: resultsProviders.branchlist, payload4: resultsProviders.bankpinlist })
                    }
                    if (resultsProviders.hasOwnProperty("branchlist")) {
                        return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_SUCCESS, payload: resultsProviders.banklist, payload2: resultsProviders.branchlist, payload3: resultsProviders.ifsc })
                    }
                    return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_SUCCESS, payload: resultsProviders.banklist })
                }
            }       
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BENEFICIARY_ADD_BANK_FETCH_FAILED, payload: "Oops Something went wrong"})
        }
    }
}

export const checkBank = (lang : any, params : any, acc_no : any, bank_name : any, navigation : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: BENEFICIARY_CHECK });
            if (params === undefined)
                return dispatch({ type: BENEFICIARY_CHECK_FAILED, payload: "002: Oops Something went wrong, Login in again"})
            if (!params.hasOwnProperty("act_id"))
                return dispatch({ type: BENEFICIARY_CHECK_FAILED, payload: "003: Oops Something went wrong, Login in again"})

            let act_id = params.act_id
            let input : any = {
              ver: CONFIG.ver,
              act: String(act_id),
              p1: acc_no,
              p2: bank_name,
              lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BENEFICIARY_CHECK_FAILED, payload: "005: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BENEFICIARY_CHECK_FAILED, payload: "006: Something went wrong, try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BENEFICIARY_CHECK_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: BENEFICIARY_CHECK_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                if (results.hasOwnProperty("acc_name")) {
                    return dispatch({ type: BENEFICIARY_CHECK_SUCCESS, payload: results});
                }
            }       
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BENEFICIARY_CHECK_FAILED, payload: "Oops Something went wrong"})
        }
    }
}

export const beneficiaryAddBene = (lang : any, params : any, addbeneparams : any) => {
    return async (dispatch : any) => {
        try{
            dispatch({ type: BENEFICIARY_ADD });
            if (params === undefined)
                return dispatch({ type: BENEFICIARY_ADD_FAILED, payload: "002: Oops Something went wrong, Login in again"})
            if (!params.hasOwnProperty("act_id"))
                return dispatch({ type: BENEFICIARY_ADD_FAILED, payload: "003: Oops Something went wrong, Login in again"})

            let act_id = params.act_id
            let input : any = {
                ver: CONFIG.ver,
                act: String(act_id),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: addbeneparams,
                lang: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BENEFICIARY_ADD_FAILED, payload: "005: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BENEFICIARY_ADD_FAILED, payload: "006: Something went wrong, try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BENEFICIARY_ADD_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: BENEFICIARY_ADD_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                if (results.hasOwnProperty("message")) {
                    return dispatch({ type: BENEFICIARY_ADD_SUCCESS, payload: {message: `${results.message || ""}`, title: results?.title  }});
                }
            }       
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BENEFICIARY_ADD_FAILED, payload: "Oops Something went wrong"})
        }
    }
}

export const beneficiaryRenderComponent = (component : any) => {
    return { type: BENEFICIARY_RENDER_COMPONENT, payload: component };
}

export const addBeneDimissMessage = () => {
    return { type: BENEFICIARY_ADD_DISMISS_MESSAGE }
}

export const filterBeneWithCountry = (beneficiaries: { label: string; value: string }[], selectedCountry: string) => {
    const filteredCountry = [selectedCountry];
    const filteredBene = beneficiaries.filter(({ label }) => filteredCountry.includes(label));

    return { type: BENEFICIARY_FILTER, payload: filteredBene };
}

export const beneficiaryListFetchWithCountry = (lang : any, params : any, selectedCountry : any) => {
    const filteredCountry = [selectedCountry];
    return async (dispatch : any) => {
        try{
            dispatch({ type: BENEFICIARY_FETCH });
            if (params === undefined)
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "002: Oops Something went wrong, Login in again"})
            if (!params.hasOwnProperty("act_id"))
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "003: Oops Something went wrong, Login in again"})

            let act_id = params.act_id,
                m1 = params.name || '';
            let input : any = {
              ver: CONFIG.ver,
              act: String(act_id),
              m1: m1,
              lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "005: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "006: Something went wrong, try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "007: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0)
                    return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
                if (results.hasOwnProperty("providers")){
                    let countryArray : any = [];
                    countryArray.push({label: "All Country"});
                    if (results.providers.length === 0){
                        dispatch({ type: BENEFICIARY_FETCH_SUCCESS, payload: results.providers, payload2: countryArray })
                        return dispatch({ type: BENEFICIARY_FILTER, payload: [] });
                    }
                    results.providers.forEach((obj: { label: any; }) => {
                        if (!countryArray.some((o: { label: any; }) => o.label === obj.label)) {
                            countryArray.push({ ...obj })
                        }
                        dispatch({ type: BENEFICIARY_FETCH_SUCCESS, payload: results.providers, payload2: countryArray })
                        const filteredBene = results.providers.filter(({ label }: { label: string }) => filteredCountry.includes(label));
                        return dispatch({ type: BENEFICIARY_FILTER, payload: filteredBene });
                    });
                }
            }       
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: BENEFICIARY_FETCH_FAILED, payload: "Oops Something went wrong"})
        }
    }
}