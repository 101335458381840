import {BM_LANG_CONFIG, BN_LANG_CONFIG, EN_LANG_CONFIG, NE_LANG_CONFIG} from "./lang";
import {CONFIG} from './config';
import {BackendResponse, TUser} from "./type";
import moment from "moment";

let apiUrl : string = CONFIG.env === "test" ? CONFIG.apiUrlTest : CONFIG.apiUrlLive;

/**
 * Function to get the label value depending on the
 * users preferred language.
 *
 * Inputs: language: string, key: string
 * returns: a string
 */

const getLabelValue = (language: string = 'en', key: string = '') => {
    if (language === '') return '';
    if (key === '') return '';
    if (language === 'en') return EN_LANG_CONFIG[key];
    if (language === 'bm') return BM_LANG_CONFIG[key];
    if (language === 'bn') return BN_LANG_CONFIG[key];
    if (language === 'ne') return NE_LANG_CONFIG[key];
    return 'hello'
}

const addUserObjectToRequest = (input: { [key: string]: any }): { [key: string]: any } => {
    try {
        if (!input) return input;

        let user = input.hasOwnProperty('user') ? { ...input.user } : {};
        let storedUser: TUser | undefined = getStoredUser('user');

        if (storedUser) {
            user = {
                ...user,
                ...('mobile' in storedUser) && { mobile: storedUser.mobile },
                ...('aa_uuid' in storedUser) && { aa_uuid: storedUser.aa_uuid },
                ...('aa_notiphoneid' in storedUser) && { aa_notiphoneid: storedUser.aa_notiphoneid },
                ...('aa_emailid' in storedUser) && { aa_emailid: storedUser.aa_emailid },
                ...('aa_addressid' in storedUser) && { aa_addressid: storedUser.aa_addressid },
                ...('aa_contactpersonid' in storedUser) && { aa_contactpersonid: storedUser.aa_contactpersonid },
                ...('wallet_acctno_mmp_id' in storedUser) && { wallet_acctno_mmp_id: storedUser.wallet_acctno_mmp_id },
                ...('isShinYang' in storedUser) && { isShinYang: storedUser.isShinYang },
                ...('membership' in storedUser) && { membership: storedUser.membership },
            };
        }
        
        return {
            ...input,
            user: user
        };
    }
    catch (e) {
        logError('Error addUserObjectToInput', e);
        return input;
    }
}

const callBackendAPI = async (
    route: string = '', method: string = 'POST', headers: HeadersInit, data: any
    ): Promise<BackendResponse | undefined> => {
    try {
        if (route === '') return { error: 'Missing route in call'};
        let options: RequestInit = {
            method: method,
            credentials: 'include' // this is needed to attach a cookie
        };
        if (headers)
            options['headers'] = headers;

        if (data) {
            if (!data.hasOwnProperty("platform"))
                data.platform = 'WebApp';
            data['seq'] = Date.now();
            data['ver'] = CONFIG?.ver || '';
            options['body'] = JSON.stringify(data);
        }

        let response: Response = await fetch(new URL(`${apiUrl}/${route}`), options);
        let results: any = await response.json();
        if (CONFIG.env === "test"){
            console.log ('result', results)
        }
        return { error: undefined, results }
    }
    catch (e: any) {
        logError('Error callBackendAPI', e);
        return { error: e.toString() }
    }
}

const getItemFromLS = (key: string): string | null | undefined => {
    if (key === '') return '';
    try {
        return localStorage.getItem(key);
    }
    catch (e) {
        logError('getItemFromLS', e);
        return undefined;
    }
}

const setItemFromLS = (key: string, value: any): boolean => {
    try {
        localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value.toString());
        return true;
    }
    catch (e) {
        logError('setItemFromLS', e);
        return false;
    }
}

const removeItemFromLS = (key: string): void => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        logError('removeItemFromLS', e);
    }
}

const getStoredUser = (key: string): TUser | undefined => {
    const userString: any = getItemFromLS(key);
    try {
        // try to parse the string
        return JSON.parse(userString);
    }
    catch (e) {
        logError('getUsername: Failed to parse json', e);
        return undefined;
    }
}

// function to only console log in test environment.
// in production we shouldnt console log errors
const logError = (title: string, error: any): void => {
    if (CONFIG.env === "test")
        console.log(`${title} :: `, error);
}

const getOriginalPath = (location: Location): string => {
    if (location === undefined) return '';
    const pathname = location.pathname || '';
    if (pathname === '') return '';
    const search = location.search || '';
    return `${pathname}${search}`;
}

const validateEmail = (email: string = ''): boolean => {
    if (email === '') return false;
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.trim(),
    );
}

const validatePostCode = (postcode: string =''): boolean => {
    if (postcode.length !== 5){
        return false;
    }else{
        return true;
    }
}

const navigateToExternalUrl = (url: string): string => {
    return window.location.href = url;
}

const numberWithCommas = (x = '') => {
    // let parts = isNaN(x) ? x.split('.') : x.toString().split('.');
    // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // return parts.join('.');
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

const getConversionString = (rates: any, country: any, provider: any) => {
    try {
        if (rates === undefined || rates === null || rates.length === 0) {
        return '';
        }
        if (!rates[country].hasOwnProperty('providers')) {
        return '';
        }
        if (!rates[country].providers[provider].hasOwnProperty('rate')) {
        return '';
        }
        if (!rates[country].providers[provider].hasOwnProperty('currencyprefix')) {
        return '';
        }
        let {rate} = rates[country].providers[provider];
        return rate || '';
    } catch (e) {
        return '';
    }
}

const getConvertedAmount = (rates: any, country: any, provider: any, amountInMYR: any) => {
    try {
        if (amountInMYR === 0) {
        return 0.0;
        }
        if (rates === undefined || rates === null || rates.length === 0) {
        return 0.0;
        }
        if (!rates[country].hasOwnProperty('providers')) {
        return 0.0;
        }
        if (!rates[country].providers[provider].hasOwnProperty('rate_multiple')) {
        return 0.0;
        }
        let finalAmount =
        amountInMYR * rates[country].providers[provider].rate_multiple;

        return Math.round(finalAmount);
    } catch (e) {
        console.log(e);
        return 0.0;
    }
}

const extendedAmountTextInput = (value: any) => {
    value = value.replace(/[^\d.]/g, '');
    if (value === '') {
        return '0.00';
    }
    const [integerPart, decimalPart] = value.split('.');
    const formattedIntegerPart = integerPart || '0';
    const formattedDecimalPart = (decimalPart || '00').padEnd(2, '0');
    return `${formattedIntegerPart}.${formattedDecimalPart}`;
}

const extendedAmountTextInputv2 = (value: any) => {
    value = value.replace(/[^\d.]/g, '');
    if (value === '') {
        return '0.00';
    }
    const [integerPart, decimalPart] = value.split('.');
    const formattedIntegerPart = integerPart || '0';
    const formattedDecimalPart = (decimalPart || '00').padEnd(2, '0');
    return `${formattedIntegerPart}.${formattedDecimalPart}`;
}

const sendReportIssueRequest = async (
    idx: number = -1,
    title: string,
    details: string,
    trans_id: number = -1,
    ticket_type: number = 0
    ): Promise<string | undefined>  => {
    try {
        let storedUser: TUser | undefined = getStoredUser('user');
        let input: { [key: string]: any } = {
            data: {
                req_source: 6,
                req_refid: storedUser?.sc_code || '',
                req_transid: trans_id,
                priority: 1,
                isSendTG: 1,
                ticket_title: title,
                ticket_details: details,
                ticket_type: ticket_type
            }
        }
        input = addUserObjectToRequest(input);
        console.log(input)

        let headers: HeadersInit = {
            'Content-Type': 'application/json'
        }
        let resultFromAPI: BackendResponse | undefined = await callBackendAPI(
            'api/ats/createticket',
            'POST',
            headers,
            input
        );
        if (resultFromAPI === undefined)
            return '001: Error: No results'

        if (resultFromAPI?.error !== undefined)
            return '002: Error: No results'

        if (resultFromAPI?.results) {
            let results: any = resultFromAPI.results;
            if (results?.code && results.code !== 0)
                return results?.message || '003: Error: No results'

            const result = resultFromAPI.results;
            console.log(result)
            if (Array.isArray(result)) {
                if (result[0]?.statusCode === 0)
                    return undefined;

                return '004: Error creating ticket'
            }
            console.log(result)
            return result?.title || '005: Error creating ticket';
        }
        return '004: Error: No results'
    }
    catch (e) {
        logError('sendReportIssueRequest', e);
        return e?.toString() || 'Exception, please try later'
    }
}

const stripCharactersAndTrim = (value: string, searchValue: string, replaceValue: string): string => {
    if (value === '') return value;
    return value.replace(searchValue, replaceValue).trim();
}

const formatCardNumberWithDashes = (card: string = ''): string => {
    try {
        let formatted: string = "";
        for (let i: number = 0; i < card.length; i++) {
            formatted += card[i];
            if ((i + 1) % 4 === 0 && i !== card.length - 1) {
                formatted += "-";
            }
        }
        return formatted;
    } catch (e) {
        logError('formatCardNumberWithDashes', e);
        return card;
    }
}

const getImage = async (user: TUser | undefined) => {
    try {
        let path = (user?.selected_image || '').replace(/\\/g, '/');
        path = path.replace('/RemittanceRegistrationData', '');
        let getImageUrl = '/RemittanceRegistrationData';
        let resultFromAPI = await fetch(`${getImageUrl}${path}`);
        if (resultFromAPI === undefined) {
            return '001: Error: No results' 
        }      
        if (resultFromAPI.ok) {
            let results: any = await resultFromAPI.json();
            if (!results.hasOwnProperty("status")) {
                return '002: Error: No results'   
            }
            if (results.status !== 0) {
                return '003: Error: No results'    
            }
            return results.url || ''
        } 
    }
    catch (e) {
        logError('getImage', e);
        return e?.toString() || 'Exception, please try later'
    }
}  

const formatMoneyAsK = (money = '') => {
    try {
        if (money.substr(money.length - 3) === '000') {
            return money.substring(0, money.length - 3) + 'K';
        } else {
            return money;
        }
    } catch (e) {
        console.log(e);
        return money;
    }
}

const getLastUpdatedTime = (lang: string) => {
    return (getLabelValue(lang, 'kita.date') + ' ' + moment().tz('Asia/Singapore').format('MMMM Do YYYY, h:mm a'))
}


const isUserSubscribedToCommunity = (codes: any, referralCode: any) => {
    try {
        if (!codes || !referralCode)
            return false;
        return codes.find((code: { name: any; }) => code.name === referralCode) !== undefined;
    } catch (e) {
        console.log(e);
        return false;
    }
}

const validateDate = (dateStr = '', action = '') => {
    let message = 'Date is incorrect, please enter a valid date';
    try {
        // lets assure date is of format DD/MM/YYYY
        if (dateStr === '')
            return {valid: false, message };
        let date_split = dateStr.split('/');
        if (date_split.length !== 3)
            return {valid: false, message: `001: ${message}` };
    
        // verify month
        let month = Number(date_split[1]);
        if (isNaN(month))
            return {valid: false, message: `002: ${message}` };
        if (month < 1 || month > 12)
            return {valid: false, message: `003: ${message}` };
    
        // verify date
        let date = Number(date_split[0]);
        if (isNaN(date))
            return {valid: false, message: `004: ${message}` };
        if (date < 1 || date > 31)
            return {valid: false, message: `005: ${message}` };
        if (month === 2) { // check for Feb
            if (date > 29)
            return {valid: false, message: `006: ${message}` };
        }
    
        // verify year
        let year = Number(date_split[2]);
        if (isNaN(year))
            return {valid: false, message };
        let currentDate = new Date();
        let current_year = currentDate.getFullYear();
        let current_month = currentDate.getMonth() + 1;
        let current_date = currentDate.getDate();
    
        if (action === 'EXPIRY') { // expiry year should be greater or equal to current yeear at least
            if (current_year > year)
            return {valid: false, message: '007: Expiry date has to be greater or equal to the current date.'}
            else if (current_year === year) {
            if (current_month > month)
                return { valid: false, message: '008: Expiry date has to be greater or equal to the current date.' }
            else if (current_month === month) {
                if (current_date > date)
                return { valid: false, message: '009: Expiry date has to be greater or equal to the current date.' }
            }
            }
        } else if (action === 'ISSUE') { // ISSUE year should be at least less or equal to current year.
            if (year > current_year)
            return {valid: false, message: '010: Issue date is out of range' };
            else if (year === current_year) {
            if (month > current_month)
                return {valid: false, message: '011: Issue date is out of range' };
            else if (month === current_month) {
                if (date > current_date)
                return {valid: false, message: '012: Issue date is out of range' };
            }
            } else {
            if ((current_year - year) > 20) { // cut off of
                return {valid: false, message: '013: Issue date is out of range' };
            }
            }
        }
    
        return {valid: true, message: ''}
    } catch (e) {
        console.log(e);
        return {valid: false, message: `99: ${message}` };
    }
}

export { 
    getLabelValue, 
    callBackendAPI, 
    addUserObjectToRequest,  
    getItemFromLS, 
    setItemFromLS, 
    removeItemFromLS, 
    getStoredUser,
    getOriginalPath, 
    logError,
    validateEmail, 
    validatePostCode, 
    navigateToExternalUrl,
    getConversionString, 
    getConvertedAmount,
    numberWithCommas, 
    extendedAmountTextInput,
    extendedAmountTextInputv2, 
    sendReportIssueRequest,
    stripCharactersAndTrim, 
    formatCardNumberWithDashes,
    getImage, 
    formatMoneyAsK, 
    getLastUpdatedTime,
    isUserSubscribedToCommunity,
    validateDate
};
