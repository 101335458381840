import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { tokens } from '../../theme';
import { callBackendAPI, logError } from "../../utils";
import { BackendResponse, Error } from "../../type";
import { CONFIG } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANTS } from '../../constants/data';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useRegister } from '../../actions/RegisterAction';
import Footer from '../../components/Footer';

const colors = tokens();

const RegisterOTP = () => {
    const { state } = useLocation();
    const userinfo: any = state.userinfo;
    const userinput: any = state.userinput;
    const [, , , , tnc_version_idx] = useRegister();
    const [otp, setOtp] = useState("");
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [otpCounter, setOtpCounter] = useState<number>(CONSTANTS.OTP_COUNTER);
    const [resendOtp, setResendOtp] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ error: false, message: "" });
    const navigate = useNavigate();

    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            if (state?.shouldSendOtp === true) {
                handleOTPResend();
            }
        }
        if (shouldLockEffect?.current === false) {
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, []);

    useEffect(() => {
        let interval: string | number | NodeJS.Timer | undefined;
        const runInternal = (value: number, delay: number) => {
            return setInterval(() => {
                setOtpCounter(value);
            }, delay);
        }

        if (otpCounter > 0)
            interval = runInternal(otpCounter - 1, 1000);
        else
            setResendOtp(true);

        return () => {
            clearInterval(interval);
        }
    }, [otpCounter])

    const handleOTPVerify = async (): Promise<void> => {
        try {
            if (otp === undefined || otp.length < 6)
                return setError({ error: true, message: lang === 'bm' ? "OTP tidak sah, Minta lagi" : "Invalid OTP , Request again" });
            if (error.error)
                setError({ error: false, message: '' });

            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let input: any = {
                ver: CONFIG?.ver,
                act: 902,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: userinput?.phoneNumber.trim(),
                p2: userinput?.idType,
                p3: userinput?.idNumber.trim(),
                p4: userinput?.nationality,
                p5: userinfo?.name.trim(),
                p6: userinfo?.email.trim(),
                p7: userinfo?.address.trim(),
                p8: userinfo?.state.trim(),
                p9: userinfo?.city.trim(),
                p10: otp ? otp.trim() : '',
                p11: userinfo?.aa_uuid ? userinfo?.aa_uuid.trim() || '' : '',
                p12: userinfo?.aa_notiphoneid ? userinfo?.aa_notiphoneid.trim() || 0 : 0,
                p13: '',
                p14: userinput?.referralCode.trim() || '',
                p15: 0,
                p16: userinfo?.lotno ? userinfo?.lotno.trim() || '' : '',
                p17: userinfo?.address2 ? userinfo?.address2.trim() : '',
                p18: userinfo?.postcode ? userinfo?.postcode.trim() || '' : '',
                p19: tnc_version_idx ? tnc_version_idx : 0,
                m1: "Register",
                m2: "",
                platform: 'WebApp',
                lg: lang
            }
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('register', 'POST', headers, input);
            if (resultFromAPI === undefined) {
                setLoading(false);
                return setError({ error: true, message: "001: Something went wrong, please try again." });
            }
            if (resultFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again." });
            }
            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results?.code && results.code !== 0) {
                    setLoading(false);
                    return setError({ error: true, message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}` });
                }
                return navigate('/registerenterpin', {
                    state: {
                        user: results.user
                    },
                });
            }
            setLoading(false);
            return setError({ error: false, message: "004: Something went wrong, please try again." })
        }
        catch (e) {
            logError('handleOTPVerify', e);
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN" });
        }
    }

    const handleOTPVerifyOnly = async (): Promise<void> => {
        try {
            if (otp === undefined || otp.length < 6)
                setError({ error: true, message: lang === 'bm' ? "OTP tidak sah, Minta lagi" : "Invalid OTP , Request again" });
            if (error.error)
                setError({ error: false, message: '' });

            setLoading(true);
            let input: any = {
                ver: CONFIG.ver,
                act: 940,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: userinput?.phoneNumber.trim(),
                p2: otp ? otp.trim() : '',
                p3: userinput?.referralCode || '',
                m1: "Register Verify OTP Only",
                m2: "",
                lg: lang
            }
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('register', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error: true, message: "002: Something went wrong, try again" });
            }
            if (resultsFromAPI.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: "004: Something went wrong, please check your Internet Connection and try again" });
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error: true, message: "005: Something went wrong, try again" });
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0) {
                    setLoading(false);
                    return setError({
                        error: true, message: `[${results.code}] : ${results.message || ""}`
                    });
                }

                setLoading(false);
                if (results.hasOwnProperty("referralCode")) {
                    return navigate('/registerexistuser', {
                        state: {
                            user: state.user,
                            otp: otp,
                            referralCode: results.referralCode || ''
                        },
                    });
                }
                return navigate('/registerexistuser', {
                    state: {
                        user: state.user,
                        otp: otp
                    },
                });
            }
            setLoading(false);
            return setError({ error: true, message: "005: Something went wrong, try again" });
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: "Oops something went wrong!" });
        }
    }

    const handleOTPResend = async (): Promise<void> => {
        try {
            setResendOtp(false);
            setOtp("");
            setError({ error: false, message: '' })

            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let input = {
                ver: CONFIG?.ver,
                mobileno: userinput.phoneNumber.trim(),
                purpose: "MM App Registration OTP",
            }
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('sendotp', 'POST', headers, input);
            if (resultFromAPI === undefined) {
                setLoading(false);
                return setError({ error: true, message: "001: Something went wrong, please try again." });
            }

            if (resultFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again." });
            }

            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results?.code && results.code !== 0) {
                    setLoading(false);
                    return setError({
                        error: true,
                        message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`
                    });
                }

                setLoading(false);
                setError({ error: false, message: '' })
                return setOtpCounter(CONSTANTS.OTP_COUNTER);
            }

            setLoading(false);
            return setError({ error: false, message: "002: Something went wrong, please try again." });
        }
        catch (e) {
            logError('handleResendOtp', e);
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN" });
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        py: 4,
                        marginTop: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box mt="5px" mb="25px" ml="5px">
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="10px"
                            overflow="hidden"
                            boxShadow={2}
                        >
                            <img
                                alt="logo"
                                width="230px"
                                height="60px"
                                src={'../../assets/mmlogo.png'}
                                style={{ cursor: "pointer" }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{ fontStyle: 'italic', textAlign: 'center' }}
                        mb="5px"
                        ml="50px"
                        mr="50px"
                    >
                        One-Time Passowrd(OTP) for login has been sent to
                    </Box>
                    <Box
                        sx={{ mt: 3 }}>
                        <Box
                            sx={{ fontStyle: 'normal', textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}
                            mb="5px"
                            ml="50px"
                            mr="50px"
                        >
                            {userinput.phoneNumber}
                        </Box>
                        <Box
                            sx={{ textAlign: 'left', mt: 3 }}
                            mt="10px"
                            mb="5px"
                            ml="5px"
                            mr="5px"
                        >
                            Please find the OTP in the SMS and enter it in the space below
                        </Box>
                        <TextField
                            margin="none"
                            required
                            fullWidth
                            name="pin"
                            placeholder=""
                            id="login-tac"
                            value={otp}
                            autoFocus
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                if (event.target.value === ' ') return;
                                if (event?.target?.value.substring(0, 1) === '0') {
                                    return setOtp(event.target.value);
                                } else
                                    if (!isNaN(Number(event.target.value)))
                                        return setOtp(event.target.value)
                            }}
                            inputProps={{ maxLength: 6, inputMode: 'numeric' }}
                            sx={{
                                '& input': {
                                    textAlign: 'center',
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            onClick={async () => {
                                if (state.hasOwnProperty("rba") && state.rba === true)
                                    await handleOTPVerifyOnly();
                                else
                                    await handleOTPVerify();
                            }}
                            variant="contained"
                            color="success"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading}
                            endIcon={loading ? <CircularProgress size={20} /> : null}
                        >
                            Proceed
                        </Button>
                        <Box
                            sx={{ fontStyle: 'normal', textAlign: 'center', fontSize: '13px' }}
                            mt="5px"
                            mb="5px"
                            ml="50px"
                            mr="50px"
                        >
                            SMS not received? Request again
                        </Box>
                        {resendOtp ? (
                            <Button
                                type="submit"
                                onClick={async () => await handleOTPResend()}
                                fullWidth
                                variant="text"
                                color="primary"
                                sx={{ mt: 2, mb: 0, textDecoration: "underline" }}
                            >
                                Request another OTP
                            </Button>
                        ) : (
                            <Box
                                sx={{ textAlign: 'center' }}
                                mt="10px"
                                mb="5px"
                                ml="50px"
                                mr="50px"
                            >
                                <Typography variant="h5" component="h6" color={colors.black}>
                                    {otpCounter}
                                </Typography>
                            </Box>
                        )}
                        {error.error && (
                            <Typography
                                sx={{
                                    mt: '10px',
                                    textAlign: 'center',
                                    color: colors.primary[500],
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {error.message || ''}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Container>
            <Footer />
        </ThemeProvider>
    );
}

const theme = createTheme({
    palette: {
        secondary: {
            //MMApp signature red
            main: '#ef233e',
        },
        primary: {
            main: '#212121',
        }
    },
});

export default RegisterOTP;
