import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Checkbox, CircularProgress, FormControlLabel, Grid, Typography} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { tokens } from '../../theme';
import {callBackendAPI, getImage, getLabelValue, logError, setItemFromLS} from "../../utils";
import {BackendResponse, Error, RouteComponent, TUser} from "../../type";
import {CONFIG} from "../../config";
import {useLocation, useNavigate} from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Footer from '../../components/Footer';

const colors =  tokens();
interface Props extends RouteComponent{
    onLoggedInChange (
        user: TUser | undefined,
        userImage: string | undefined
    ): void;
}

const LoginPin: FC<Props> = (props) => {
    const {onLoggedInChange} = props;
    const {state} = useLocation();
    const user: TUser | undefined = state?.user;
    const [pinNumber, setPinNumber] = useState<string>("");
    const [photoVerification, setPhotoVerification] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [imageLoading, setImageLoading] = useState<boolean>(true);
    const {lang} = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ error: false, message: ""});

    const navigate = useNavigate();
    const shouldLockEffect = useRef(false);

    // useEffect(() => {
    //     const initialize = async (): Promise<void> => {
    //         let image : string | undefined = await getImage(user);
    //         setImageUrl(image);
    //         setImageLoading(false);
    //     }
    //     if (shouldLockEffect?.current === false){
    //         initialize();
    //     }
    //     return () => {
    //         shouldLockEffect.current = true;
    //     }
    // },[]);
    
  const handlePinSubmit = async (): Promise<void> => {
        try {
            if (!photoVerification)
                return setError({ error: true, message: 'You must check the Photo Verification checkbox to proceed'});
            if (pinNumber === '')
                return setError({ error: true, message: '001: PIN NUMBER CANNOT BE EMPTY'});
            if (user === undefined)
                return setError({ error: true, message: '002: FAILED TO VALIDATE PIN, PLEASE TRY AGAIN'});
            if (error.error)
                setError({ error: false, message: ''});

            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let input: any = {
                ver: CONFIG?.ver,
                act: 905,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: pinNumber.trim(),
                m1: 'VerifyPIN',
                m2: '',
                lg: lang,
                user: user
            }
            let resultsFromAPI: BackendResponse | undefined = await callBackendAPI('verifypin', 'POST', headers, input);
            if (resultsFromAPI === undefined) {
                setLoading(false);
                return setError({ error: true, message: "001: Something went wrong, please try again."});
            }

            if (resultsFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: resultsFromAPI.error || "002: Something went wrong, please try again."});
            }

            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results?.code && results.code !== 0) {
                    setLoading(false);
                    return setError({
                        error: true,
                        message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`});
                }
                return onLoggedInChange(user, imageUrl);
            }

            return setError({ error: false, message: "004: Something went wrong, please try again."})
        }
        catch (e) {
            logError('handleLoginPinSubmit', e);
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    const handleForgotPin = async (): Promise<void> => {
        try {
            if (error.error)
                setError({ error: false, message: ''});

            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let input = {
                ver: CONFIG?.ver,
                mobileno: user?.mobile?.trim(),
                purpose: "MM App FORGOT PIN",
            }
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('sendotp', 'POST', headers, input);
            if (resultFromAPI === undefined) {
                setLoading(false);
                return setError({ error: true, message: "001: Something went wrong, please try again."});
            }

            if (resultFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
            }

            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results?.code && results.code !== 0) {
                setLoading(false);
                return setError({
                    error: true,
                    message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`});
                }

                setLoading(false);
                setError({ error: false, message: ''})
                return navigate('/forgotpin', { state: { user: user} })
            }

            setLoading(false);
            return setError({ error: false, message: "002: Something went wrong, please try again."});
        }
        catch (e) {
            logError('handleResendOtp', e);
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
            sx={{
                boxShadow: 3,
                borderRadius: 2,
                px: 4,
                py: 4,
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            >
            <Box mt="5px" mb="25px" ml="5px">
                <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="10px"
                overflow="hidden"
                boxShadow={2}
                >
                    <img
                        alt="logo"
                        width="230px"
                        height="60px"
                        src={'../../assets/mmlogo.png'}
                        style={{ cursor: "pointer"}}
                    />
                </Box>
            </Box>
            <Box sx={{
                backgroundColor: colors.primary[500],
                color: colors.white[100],
                display: 'inline-block',
                padding: '15px 25px',
                fontWeight: 'bold',
                mb: 2,
            }}>
                {getLabelValue(lang, 'login.account.warning.title')}
            </Box>
            <Box
                sx={{ fontStyle: 'italic', textAlign: 'center' }}
                mb="5px"
                ml="50px"
                mr="50px"
            >
                {getLabelValue(lang, 'login.account.warning.subTitle1')}
                {getLabelValue(lang, 'login.account.warning.subTitle2')}
            </Box>
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Avatar variant="rounded" sx={{ width: 100, height: 100 }} src={user?.selected_image || imageUrl || ''}>
                {/* {imageLoading && <CircularProgress />} */}
                </Avatar>
            </Stack>
            <FormControlLabel
                sx={{ mt: 2 }}
                control={
                <Checkbox
                checked={photoVerification}
                onChange={(e) => setPhotoVerification(e.target.checked)}
                />}
                label= {getLabelValue(lang, 'login.account.photo.confirm')}
                id="photoVerification"
            />
            <Box
                sx={{ mt: 1 }}>
                <Box
                sx={{ textAlign: 'left' }}
                mt="10px"
                mb="5px"
                ml="5px"
                mr="5px"
                >
                {getLabelValue(lang, 'login.account.pin.title')}
                </Box>
                <TextField
                margin="none"
                required
                fullWidth
                name="pin"
                placeholder=""
                type='password'
                id="pin"
                autoFocus
                value={pinNumber}
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => {
                    if (error.error)
                    setError({ error: false, message: ''});
                    setPinNumber(e.target.value);
                }}
                inputProps={{
                    maxLength: 6,
                    style: { textAlign: 'center' }, 
                }}                    
                />
                <Button
                type="submit"
                fullWidth
                onClick={async () => await handleForgotPin()}
                variant="text"
                color="primary"
                sx={{ mt: 2, mb: 0, textDecoration: "underline" }}
                >
                {getLabelValue(lang, 'login.acoount.forgotpassword')}
                </Button>
                {error.error && (
                    <Grid
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                    <Typography textAlign="center" color={colors.primary[500]}>
                        {error.message || ''}
                    </Typography>
                    </Grid>
                )}
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    onClick={async () => await handlePinSubmit()}
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                    sx={{ mt: 3, mb: 1 }}
                    >
                    {getLabelValue(lang, 'login.button.login')}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                    type="submit"
                    fullWidth
                    onClick={() => navigate('/login')}
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 3, mb: 1 }}
                    >
                    {getLabelValue(lang, 'login.account.button.goback')}
                    </Button>
                </Grid>
                </Grid>
            </Box>
            </Box>
        </Container>
        <Footer />
        </ThemeProvider>
    );
}

const theme = createTheme({
    palette: {
        secondary: {
        //MMApp signature red
        main: '#ef233e',
        },
        primary: {
        main: '#212121',
        }
    },
});

export default LoginPin;
