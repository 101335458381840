import { Box, Button, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import useCheckIsMobile from "../hooks/useCheckIsMobile";
import { RootState } from "../store/store";
import { addUserObjectToRequest, callBackendAPI, getLabelValue, validateDate } from "../utils";
import { useRef, useState } from "react";
import UploadPicSVG from "../assets/icons/uploadpic.svg";
import { COLORS, FONTS } from "../constants/data";
import PinInput from "../components/PinInput";
import { CONFIG } from "../config";
import DialogAlert from "../components/DialogAlert";
import Webcam from 'react-webcam';

const UpdatePassport = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [newIC, setNewIC] = useState("");
    const [newICIssueDate, setNewICIssueDate] = useState("");
    const [newICExpiryDate, setNewICExpiryDate] = useState("");
    const [showWebcam, setShowWebcam] = useState(false);
    const [image, setImage] = useState<any>(undefined);
    const [showPin, setShowPin] = useState(false);
    const [pinNumber, setPinNumber] = useState<string>('');
    const [error, setError] = useState({ error: false, message: '' });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState({ success: false, message: '' });
    const { lang, user } = useSelector((state: RootState) => state.user);

    const webcamRef = useRef<any>(undefined);

    const handleOpenWebcam = () => {
        setShowWebcam(true);
    };

    const handleCloseWebcam = () => {
        setShowWebcam(false);
    };

    const handleCapture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc);
        setShowWebcam(false);
    };

    const handleRetakePhoto = () => {
        setImage(null);
        handleOpenWebcam();
    };

    const renderHeader = () => {
        return (
            <Box>
                <Box sx={{ marginBottom: '16px' }}>
                    <Typography variant="body1">
                        {getLabelValue(lang, 'profile.upgrade.ic.oldid')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={user?.idNumber || ''}
                        disabled
                    />
                </Box>
                <Box sx={{ margin: '16px 0' }}>
                    <Typography variant="body1">
                        {getLabelValue(lang, 'profile.upgrade.ic.expired_dt')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={user?.ICExpiredDt || ''}
                        disabled
                    />
                </Box>
                <Box sx={{ margin: '16px 0' }}>
                    <Typography variant="body1">
                        {getLabelValue(lang, 'profile.upgrade.ic.newid')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={newIC}
                        onChange={(e) => setNewIC(e.target.value)}
                    />
                </Box>
                <Box sx={{ margin: '16px 0' }}>
                    <Typography variant="body1">
                        {getLabelValue(lang, 'profile.upgrade.ic.newidissuedt')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={newICIssueDate}
                        onChange={(e) => setNewICIssueDate(e.target.value)}
                    />
                </Box>
                <Box sx={{ margin: '16px 0' }}>
                    <Typography variant="body1">
                        {getLabelValue(lang, 'profile.upgrade.ic.newidexpirydt')}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={newICExpiryDate}
                        onChange={(e) => setNewICExpiryDate(e.target.value)}
                    />
                </Box>
            </Box>
        )
    }

    const renderMiddleView = () => {
        return (
            <Box>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h5" sx={styles(isMobile).uploadDescription}>
                        Click below to upload your new Passport
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {image ? (
                        <Box sx={{ textAlign: 'center' }}>
                            <img
                                style={{ width: image?.width || 200, height: image?.height || 200 }}
                                src={image}
                                alt="Uploaded Passport"
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button onClick={handleRetakePhoto}>Retake Photo</Button>
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            {!showWebcam ? (
                                <Button onClick={handleOpenWebcam}>
                                    <img src={UploadPicSVG} alt="svg" style={{ height: 250, width: 250 }} />
                                </Button>
                            ) : (
                                <Box sx={{ textAlign: 'center' }}>
                                    <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        style={{ width: 250, height: 250 }}
                                        videoConstraints={{
                                            facingMode: isMobile ? 'environment' : 'user'
                                        }}
                                    />
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button onClick={handleCapture}>Capture</Button>
                                        <Button onClick={handleCloseWebcam}>Close</Button>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }

    const renderFooter = () => {
        return (
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button onClick={() => verifyEnteredData()} variant="contained" sx={{ width: '200px' }} disabled={loading}>
                    <Typography variant="body1">
                        {getLabelValue(lang, 'profile.upgrade.ic.submit').toUpperCase()}
                    </Typography>
                </Button>
            </Box>
        )
    }

    const verifyEnteredData = () => {
        if (newIC === "")
            return setError({ error: true, message: getLabelValue(lang, 'profile.upgrade.ic.errornewid') });
        if (newICIssueDate === "")
            return setError({ error: true, message: getLabelValue(lang, 'profile.upgrade.ic.errornewidissuedt') });
        if (newICExpiryDate === "")
            return setError({ error: true, message: getLabelValue(lang, 'profile.upgrade.ic.errornewidexpirydt') });

        const validateIssueDt = validateDate(newICIssueDate, 'ISSUE');
        if (!validateIssueDt.valid)
            return setError({ error: true, message: validateIssueDt.message });
        const validateExpiryDt = validateDate(newICExpiryDate, 'EXPIRY');
        if (!validateExpiryDt.valid)
            return setError({ error: true, message: validateExpiryDt.message });

        if (image === undefined)
            return setError({ error: true, message: getLabelValue(lang, 'profile.upgrade.ic.errorimage') })

        return setShowPin(true)
    }

    const handleImageUpload = async () => {
        try {
            if (!image) {
                setShowPin(false);
                return setError({ error: true, message: "Failed to create appropriate type from the image. Please try another image" })
            }
            setLoading(true);
            let input: any = {
                photo: image
            }
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('imageUpload', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error: true, message: '002: Something went wrong, try again' });
            }
            if (resultsFromAPI.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: '003: Something went wrong, please check your Internet Connection and try again' });
            }

            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error: true, message: '004: Something went wrong, try again' });
            }
            let results = resultsFromAPI.results;

            setShowPin(false);
            return updateIC(results?.filePath || '');
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setShowPin(false);
            return setError({ error: true, message: 'OOPS SOMETHING WENT WRONG' })
        }
    }

    const updateIC = async (imageUrl: any) => {
        try {
            if (pinNumber === '') {
                setShowPin(false);
                return setError({ error: false, message: "Please enter a PIN" });
            }
            if (user === undefined)
                return setError({ error: false, message: "001: Oops something went wrong, Please log out and log back in" });
            let input: any = {
                ver: CONFIG.ver,
                act: String(960),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: user?.idNumber || '',
                p2: newIC,
                p3: pinNumber,
                p4: image || '',
                p5: imageUrl || '',
                p6: newICIssueDate || '',
                p7: newICExpiryDate || '',
                m1: "UpdatePassport",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error: true, message: '002: Something went wrong, try again' });
            }
            if (resultsFromAPI.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: '003: Something went wrong, please check your Internet Connection and try again' });
            }

            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error: true, message: '004: Something went wrong, try again' });
            }
            let results = resultsFromAPI.results;

            if (results.hasOwnProperty("code")) {
                setLoading(false);
                if (results.code !== 0) {
                    return setError({ error: true, message: `[${results.code}] : ${results.message || ""}` });
                }

                return setSuccess({ success: true, message: results?.message || '' });
            }

            setLoading(false);
            return setError({ error: true, message: "005: Something went wrong, try again" });
        } catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: 'OOPS SOMETHING WENT WRONG' });
        }
    }

    return (
        <Box m="20px">
            <Box
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'profile.upgrade.ic')} subtitle={getLabelValue(lang, 'profile.upgrade.ic.header')} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={4}
                    >
                        {renderHeader()}
                        {renderMiddleView()}
                        {renderFooter()}
                    </Grid2>
                </Grid2>
                {showPin &&
                    <PinInput
                        open={showPin}
                        onCancel={() => setShowPin(false)}
                        onSuccess={async () => {
                            await handleImageUpload()
                        }}
                        yesButton={'Submit'}
                        cancelButton={'Cancel'}
                        pinNumber={pinNumber}
                        onPinChange={(e: any) => {
                            setPinNumber(e.target.value)
                        }}
                        lang={lang}
                    />
                }
                {success.success &&
                    <DialogAlert
                        open={success.success}
                        onSuccess={() => setSuccess({ success: false, message: '' })}
                        title={'Attention'}
                        content={new Array(success.message)}
                        yesButton={'OK'}
                    />
                }
                {error.error && (
                    <DialogAlert
                        open={error.error}
                        onSuccess={() => setError({ error: false, message: "" })}
                        title={"Attention"}
                        content={new Array(error.message)}
                        yesButton={"OK"}
                    />
                )}
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) => ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)',
        overflow: 'auto'
    },
    uploadDescription: {
        color: COLORS.BLACK,
        fontFamily: FONTS.medium,
    },
})

export default UpdatePassport;