import {Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, List, ListItem, ListItemText, MenuItem, Select, Typography} from "@mui/material";
import Header from "../../components/Header";
import React, { FC, useEffect, useRef, useState } from "react";
import { getLabelValue } from "../../utils";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { changeQuerytransactionHistoryFetch, kirimDoCancel, kirimDoRefund, transactionHistoryFetch, transactionHistoryRenderComponenet } from "../../actions/TransactionHistoryAction";
import { BOOKING_STATUS, DATE_RANGES, KIRIM_MENU, TRANSACTION_STATUS } from "../../constants/data";
import { useNavigate } from "react-router-dom";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import Grid2 from "@mui/material/Unstable_Grid2";
import DialogAlert from "../../components/DialogAlert";

const TransactionStatus = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [date, setDate] = useState("");
    const [bookingStatus, setBookingStatus] = useState("");
    const [transactionStatus, setTransactionStatus] = useState("");
    const [refundAlert, setRefundAlert] = useState<{ open: boolean; item: any }>({ open: false, item: null });
    const [confirmation, setConfirmation] = useState<{ open: boolean; item: any }>({ open: false, item: null });
    const { lang } = useSelector((state: RootState) => state.user);
    const { loading, error, message, transactions,　showMessage, queryDate, booking_status, transaction_status } = useSelector((state: RootState) => state.transaction);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    let params : any = {item: KIRIM_MENU[3]};

    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(transactionHistoryFetch(lang, params, queryDate, booking_status, transaction_status));
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    },[]);    

    const renderTransaction = (item: any, index: any) => {
        return (
            <ListItem key={index}>   
                <ListItemText 
                    primary={
                        <React.Fragment>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Transaction Status: {item ? item.pin_status_en || '' : ''}
                            </Typography>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Booking Status: {item ? item.booking_status || '' : ''} {item ? item.paid_method ==='' ? '' :'(' + item.paid_method + ')' :" "}
                            </Typography>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Booking ID: {item ? item.booking_id || '' : ''}
                            </Typography>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {getLabelValue(lang, 'kirim.transaction.transid')}
                                </Typography>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {item ? item.remit_ref_trans_id || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Pin Number
                                </Typography>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {item ? item.pin_number || 0 : 0}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {getLabelValue(lang, 'kirim.transaction.customerhp')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.customerhp || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {getLabelValue(lang, 'kirim.transaction.beneficiary')}
                                </Typography>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {item ? item.bene_name || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {getLabelValue(lang, 'kirim.transaction.cardno')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.bene_card_no || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {getLabelValue(lang, 'kirim.transaction.country')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.country || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {getLabelValue(lang, 'kirim.transaction.provider')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.providerlabel || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Transfer Type
                                </Typography>
                                <Typography variant="subtitle1">
                                    {(item.pickup_method === 0) ? 'Cash pick-up' : (item.pickup_method ===1) ? 'Bank Transfer' : ' '}
                                </Typography>
                            </div> 
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {getLabelValue(lang, 'kirim.transaction.booktime')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.booking_created || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {getLabelValue(lang, 'kirim.transaction.rate')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.remit_ex_rate || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {'Send Amount in ' + (item ? item.remit_foreign_currency || '' : '')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.remit_foreign_amt || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {'Send Amount in RM'}
                                </Typography>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {item ? item.remit_rm_amt || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {'Service Charge in RM'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.service_charge || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {'Total Discount in RM'}
                                </Typography>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {`(${item ? item.kita_discount_amt || '' : ''})`}
                                </Typography>
                            </div>  
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {'Total Charge in RM'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.total_charge || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {getLabelValue(lang, "kirim.transaction.date")}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {item ? item.booking_created || '' : ''}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '20px'}}>
                            {item.receipt_url === 1 ? (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        window.open(item ? item.receipt_url || '' : '');
                                    }}
                                >
                                    {getLabelValue(lang, "kirim.transaction.receipt")}
                                </Button>
                            ) : null}
                            {item.allow_refund === '1' && (item.pay_method !==2 && item.pay_method !==3) ? (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if (item.pay_method === 4) {
                                            setRefundAlert({ open: true, item })
                                        } else {
                                            navigate('/kirim/transactionrefund', { state: { item } })
                                        }
                                    }}
                                >
                                    {getLabelValue(lang, "kirim.transaction.refund")}
                                </Button>
                            ) : <></>}
                            {item.allow_cancel === '1' ? (
                                <Button
                                    variant="contained"
                                    onClick={() => setConfirmation({ open: true, item })}>
                                    {getLabelValue(lang, "kirim.transaction.cancel")}
                                </Button>
                            ) : null}
                            </div>                                
                        </React.Fragment>
                    }
                    sx={{
                        backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5' 
                    }}
                />
            </ListItem>
        )
    }

    const handleOkButton = (item : any) => {
        if(refundAlert.open === true){
            setRefundAlert({open: false, item: ''});
            dispatch(kirimDoRefund(lang, item.remit_ref_trans_id, '', '', ''))
            dispatch(transactionHistoryRenderComponenet());
        }
        if(confirmation.open === true){
            setConfirmation({open: false, item: ''});
            dispatch(kirimDoCancel(lang, item.remit_ref_trans_id));
            dispatch(transactionHistoryRenderComponenet());
        }
    }
    
    type DialogProps = {
        open: boolean;
        item: any; 
        onClose: (item: any) => void;
    }
    
    const RefundAlertDialog: FC<DialogProps> = ({ open, item, onClose }) => {
        const handleCancelClick = () => {
            setRefundAlert({open: false, item: ''});
        };
        
        const handleOkClick = () => {
            onClose(item);
        };

        return(
            <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
                {getLabelValue(lang, 'kirim.transaction.refund')}
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">
                    Are you sure that you want to perform refund ?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick} >CANCEL</Button>
                <Button onClick={handleOkClick} >OK</Button>
            </DialogActions>
          </Dialog>
        );
    }

    const ConfirmDialog: FC<DialogProps> = ({ open, item, onClose }) => {
        const handleCancelClick = () => {
            setConfirmation({open: false, item: ''});
        };
        
        const handleOkClick = () => {
            onClose(item);
        };

        return(
            <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
                {getLabelValue(lang, 'kirim.transaction.cancel')}
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">
                    Are you sure that you want to perform Cancel transaction ?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick} >CANCEL</Button>
                <Button onClick={handleOkClick} >OK</Button>
            </DialogActions>
          </Dialog>
        );
    }

    type MessageDialogProps = {
        open: boolean;
    }

    const MessageDialog: FC<MessageDialogProps> = ({ open }) => {
        const handleOkClick = () => {
            dispatch(transactionHistoryRenderComponenet());
            dispatch(transactionHistoryFetch(lang, params, queryDate, booking_status, transaction_status));
        }

        return(
            <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
                {getLabelValue(lang, 'home.transaction.status.success')}
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOkClick} color="primary">
                    OK
                </Button>
            </DialogActions>
          </Dialog>
        );
    }
    
    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'home.header.button.transhistory')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <Select
                                value={date || '7'}
                                onChange={(event) => {
                                    setDate(event.target.value);                                  
                                    dispatch(changeQuerytransactionHistoryFetch(Number(event.target.value), booking_status, transaction_status, params, lang));
                                }}
                                >
                                {(DATE_RANGES[lang as keyof typeof DATE_RANGES] || []).map((date: any, index: React.Key) => (
                                    <MenuItem key={index} value={date.value}>
                                        {date.label}
                                    </MenuItem> 
                                ))}
                                </Select>
                            </FormControl>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                        <Select
                                        value={bookingStatus || 'ALL'}
                                        onChange={(event) => {
                                            setBookingStatus(event.target.value);                                  
                                            dispatch(changeQuerytransactionHistoryFetch(queryDate, event.target.value, transaction_status, params, lang));
                                        }}
                                        >
                                        {(BOOKING_STATUS[lang as keyof typeof DATE_RANGES] || []).map((booking: any, index: React.Key) => (
                                            <MenuItem key={index} value={booking.value}>
                                                {booking.label}
                                            </MenuItem> 
                                        ))}
                                        </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                        <Select
                                        value={transactionStatus || 'ALL'}
                                        onChange={(event) => {
                                            setTransactionStatus(event.target.value);                                  
                                            dispatch(changeQuerytransactionHistoryFetch(queryDate, booking_status, event.target.value, params, lang));
                                        }}                                        
                                        >
                                        {(TRANSACTION_STATUS[lang as keyof typeof DATE_RANGES] || []).map((transaction: any, index: React.Key) => (
                                            <MenuItem key={index} value={transaction.value}>
                                                {transaction.label}
                                            </MenuItem> 
                                        ))}
                                        </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>                            
                            </CardContent>

                        </Card>
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <List>
                                {loading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                                    <CircularProgress />
                                    </Box>
                                ) : transactions.length === 0 ? (
                                    <Typography variant="subtitle1" align="center">
                                        No Transactions.
                                    </Typography>
                                ) : (
                                    transactions.map((item : any, index : any ) => renderTransaction(item, index))
                                )}
                                </List>   
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
                {refundAlert.open && (
                    <RefundAlertDialog
                        open={refundAlert.open}
                        item={refundAlert.item}
                        onClose={(item) => handleOkButton(item)}
                    />
                )}
                {confirmation.open && (
                    <ConfirmDialog
                        open={confirmation.open}
                        item={confirmation.item}
                        onClose={(item) => handleOkButton(item)}
                    />
                )}
                {showMessage && <MessageDialog open={showMessage} />}
                {error && (
                    <DialogAlert
                        open={error}
                        onSuccess={() => dispatch(transactionHistoryRenderComponenet())}
                        title={"Attention"}
                        content={new Array(message)}
                        yesButton={"OK"}
                    />
                )}
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default TransactionStatus;
