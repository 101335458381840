import { useSelector } from "react-redux";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { RootState, useAppDispatch } from "../../store/store";
import { Box, Card, CardContent, CircularProgress, FormControl, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import Header from "../../components/Header";
import { getLabelValue } from "../../utils";
import { useRef, useEffect, useState } from "react";
import { changeQueryDateAndFetchNewTransactions, receiptTransactionsFetch, unMountReceiptScreen } from "../../actions/ReceiptAction";
import { useNavigate } from "react-router-dom";
import { DATE_RANGES } from "../../constants/data";

const Receipt = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const { loading, error, message, transactions, queryDate } = useSelector((state: RootState) => state.receipt);

    const navigate = useNavigate();
    
    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(receiptTransactionsFetch(lang, user, 20010, queryDate));
        }   
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            dispatch(unMountReceiptScreen());
            shouldLockEffect.current = true;
        }
    }, [])

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const transactionsRows = transactions
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    .map((item: any, index: any) => {
        return (
            <TableRow 
                key={index} 
                onClick={() =>　navigate('/retrievedreceipt', { state: { item } })}
                style={{ 
                    cursor: 'pointer',
                    fontWeight: 'bold' 
                }}
            >
                <TableCell sx={{ fontWeight: 'bold' }}>{item ? item.name : ""}</TableCell>
                <TableCell>{item ? (item.id ? "TXN ID: "+ item.id : "") : ""}</TableCell>
                <TableCell sx={{ color: item && item.type === "add" ? "green" : "red" }}>
                    {item ? item.amount : ""}
                </TableCell>
                <TableCell>{item ? item.date : ""}</TableCell>
            </TableRow>
        );
    });

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'navigation.tabs.receipt.title')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={12} lg={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <FormControl fullWidth sx={{ mb: 1}}>
                                    <Select
                                        value={queryDate}
                                        onChange={(event) => {
                                            dispatch(changeQueryDateAndFetchNewTransactions(event.target.value, user, 20010,lang))
                                        }}
                                    >
                                    {(DATE_RANGES[lang as keyof typeof DATE_RANGES] || []).map((date: any, index: any) => (
                                        <MenuItem key={index} value={date.value}>
                                            {date.label}
                                        </MenuItem> 
                                    ))}                                        
                                    </Select>
                                </FormControl>                                 
                                <TableContainer>
                                    <Table stickyHeader>
                                    <TableBody>
                                    {loading && (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <CircularProgress color="primary" />
                                            </TableCell>
                                        </TableRow>                                               
                                    )} 
                                    {(transactions.length === 0 && !loading) && 
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">{'No Transactions.'}</TableCell>
                                        </TableRow> 
                                    }      
                                    {transactionsRows}
                                    </TableBody>
                                    </Table>
                                </TableContainer>      
                                <TablePagination
                                    component="div"
                                    count={transactions.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default Receipt;
