import { CONFIG } from "./config";
import { useEffect, useRef, useState } from "react";
import { theme } from "./theme";
import { Box, CircularProgress, CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "./routes";
import Register from "./screens/register/Register";
import Login from "./screens/login/Login";
import LoginPin from "./screens/login/LoginPin";
import ForgotPin from "./screens/login/ForgotPin";
import SidebarComponent from "./components/SidebarComponent";
import TopBar from "./components/TopBar";
import RegisterPageTwo from "./screens/register/RegisterPageTwo";
import { RootState, useAppDispatch } from "./store/store";
import { addUserObjectToRequest, callBackendAPI, getOriginalPath, logError, removeItemFromLS, setItemFromLS } from "./utils";
import { BackendResponse, RouteComponent, TUser } from "./type";
import { useNavigate, useLocation, Location } from "react-router-dom";
import RegisterOTP from "./screens/register/RegisterOTP";
import RegisterEnterPin from "./screens/register/RegisterEnterPin";
import RegisterProfilePic from "./screens/register/RegisterProfilePic";
import RegisterSuccess from "./screens/register/RegisterSuccess";
import ResetPin from "./screens/login/ResetPin";
import RegisterEkycStatus from "./screens/register/RegisterEkycStatus";
import AddBeneficiary from "./screens/kirim/Beneficiary/AddBeneficiary";
import TransactionRefund from "./screens/kirim/TransactionRefund";
import { useSelector } from "react-redux";
import { logOutUser, loginUser, updateLoggedInUser } from "./screens/login/userSlice";
import Notification from "./screens/Notification";
import SelectBeneficiary from "./screens/kirim/BookRate/SelectBeneficiary";
import BookRateConfirmation from "./screens/kirim/BookRate/BookRateConfirmation";
import PLN from "./screens/bill/PLN";
import Pulsa from "./screens/bill/Pulsa";
import RetrievedReceipt from "./screens/receipt/RetrievedReceipt";
import ReceiptPage from "./screens/ReceiptPage";
import FPXResponse from "./screens/topup/FPXResponse";
import CommunityDetails from "./screens/communities/communityDetails";
import ScanCorporateID from "./screens/account/ScanCorporateID";
import QRCodePage from "./screens/account/QRCodePage";
import PayFPXResponse from "./screens/kirim/PayFPXResponse";
import BorneoLanding from "./screens/borneokaki/BorneoLandingPage";
import SubCategoryPage from "./screens/borneokaki/SubcategoryPage";
import ProductListing from "./screens/borneokaki/ProductListing";
import RegisterExistUser from "./screens/register/RegisterExistUser";
import ProductDetails from "./screens/borneokaki/ProductDetails";
import WhatsAppBooking from "./screens/whatsapp/whatsAppBooking";

const App = () => {
    const location: Location = useLocation();
    // @ts-ignore
    const [originalPathname] = useState<string>(getOriginalPath(location));
    const [loading, setLoading] = useState<boolean>(false);
    const { isLoggedIn } = useSelector((state: RootState) => state.user);

    const dispatch = useAppDispatch();

    const logOut = () => {
        removeItemFromLS('mmapp_token');
        removeItemFromLS('user');
        dispatch(logOutUser());
        return navigate('/');
    }

    let params: RouteComponent = {
        logOut
    }

    const navigate = useNavigate();
    // add this to put in place code that will prevent the func in
    // useeffect to run twice as per React 18
    // https://react.dev/blog/2022/03/29/react-v18
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        if (location.pathname.includes("booking")) {
            return navigate(originalPathname);
        }
        const initialize = async (): Promise<void> => {
            try {
                setLoading(true);
                let headers: HeadersInit = {
                    'Content-Type': 'application/json'
                }
                let input: any = {
                    ver: CONFIG.ver,
                    act: 9003,
                    user_agent: navigator.userAgent,
                    app_id: CONFIG.app_id
                }
                input = addUserObjectToRequest(input)
                let resultFromAPI: BackendResponse | undefined = await callBackendAPI('init', 'POST', headers, input);
                if (resultFromAPI === undefined)
                    return setLoading(false);

                if (resultFromAPI?.error !== undefined)
                    return setLoading(false);

                if (resultFromAPI?.results) {
                    let results: any = resultFromAPI.results;
                    if (results?.code && results.code !== 0)
                        return setLoading(false);

                    setLoading(false);
                    dispatch(updateLoggedInUser(results.user));
                    dispatch(loginUser());
                    if (originalPathname.startsWith('/borneokaki')) {
                        return navigate('/borneokaki');
                    }
                    if (['/', '/login', '/loginpin'].includes(originalPathname)) {
                        return navigate('/');
                    }
                    else {
                        return navigate(originalPathname);
                    }
                }

                return setLoading(false);
            }
            catch (e) {
                logError('Failed to initialize', e);
                return setLoading(false);
            }
        }
        if (shouldLockEffect?.current === false) {
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [dispatch, navigate, originalPathname, location.pathname]);

    const onLoggedInChange = (
        user: TUser | undefined
    ) => {
        setItemFromLS('user', user);
        dispatch(updateLoggedInUser(user));
        dispatch(loginUser());
        if (user?.hasOwnProperty("community") && user.community.community_id === 1) {
            return navigate('/borneokaki');
        }
        else
            return navigate('/');
    };

    if (loading) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: '100vh' }}
                >
                    <CircularProgress size={30} color="secondary" />
                </Box>
            </ThemeProvider>
        );
    }

    if (!isLoggedIn)
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/loginpin" element={<LoginPin {...params} onLoggedInChange={onLoggedInChange} />} />
                    <Route path="/forgotpin" element={<ForgotPin {...params} />} />
                    <Route path="/resetpin" element={<ResetPin {...params} />} />
                    <Route path="/register" element={<Register  {...params} />} />
                    <Route path="/registerpagetwo" element={<RegisterPageTwo {...params} />} />
                    <Route path="/registerexistuser" element={<RegisterExistUser {...params} />} />
                    <Route path="/registerotp" element={<RegisterOTP {...params} />} />
                    <Route path="/registerekycstatus" element={<RegisterEkycStatus />} />
                    <Route path="/registerenterpin" element={<RegisterEnterPin  {...params} />} />
                    <Route path="/registerprofilepic" element={<RegisterProfilePic {...params} />} />
                    <Route path="/registersuccess" element={<RegisterSuccess />} />
                    <Route path="/booking" element={<WhatsAppBooking />} />
                    <Route path="*" element={<Navigate to={'/login'} replace />} />
                </Routes>
            </ThemeProvider>
        )

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
                <SidebarComponent
                    routes={routes}
                />
                <main className="content">
                    <TopBar logOut={logOut} />
                    <Routes>
                        {routes.map(({ path, name, component: Component, submenus }, v) => {
                            if (submenus === undefined)
                                return (
                                    <Route
                                        key={v}
                                        path={path}
                                        element={
                                            <Component
                                                key={path}
                                                {...params}
                                                logOut={logOut}
                                            />}
                                    />
                                )
                            else {
                                if (!Array.isArray(submenus)) return null;
                                return submenus.map(({ path, name, component: Component }, v) => {
                                    return (
                                        <Route
                                            key={v}
                                            path={path}
                                            element={
                                                <Component
                                                    key={path}
                                                    {...params}
                                                    logOut={logOut}
                                                />}
                                        />
                                    )
                                })
                            }
                        })}
                        <Route path="/qrcode" element={<QRCodePage {...params} />} />
                        <Route path="/scanCorporateID" element={<ScanCorporateID {...params} />} />
                        <Route path="/notification" element={<Notification {...params} />} />
                        <Route path="/kirim/bookrate/selectbeneficiary" element={<SelectBeneficiary {...params} />} />
                        <Route path="/kirim/bookrate/bookrateconfirmation" element={<BookRateConfirmation {...params} />} />
                        <Route path="/kirim/addbeneficiary" element={<AddBeneficiary {...params} />} />
                        <Route path="/kirim/transactionrefund" element={<TransactionRefund {...params} />} />
                        <Route path="/bill/pln" element={<PLN logOut={logOut} {...params} />} />
                        <Route path="/bill/pulsa" element={<Pulsa logOut={logOut} {...params} />} />
                        <Route path="/payfpxresponse" element={<PayFPXResponse {...params} />} />
                        <Route path="/fpxresponse" element={<FPXResponse {...params} />} />
                        <Route path="/receipt" element={<ReceiptPage {...params} />} />
                        <Route path="/retrievedreceipt" element={<RetrievedReceipt {...params} />} />
                        <Route path="/communityDetails" element={<CommunityDetails {...params} />} />
                        <Route path="/borneokaki" element={<BorneoLanding {...params} />} />
                        <Route path="/borneokaki/subcategory" element={<SubCategoryPage {...params} />} />
                        <Route path="/borneokaki/product" element={<ProductListing {...params} />} />
                        <Route path="/borneokaki/productdetails" element={<ProductDetails {...params} />} />
                    </Routes>
                </main>
            </div>
        </ThemeProvider>
    )
}

export default App;
