import { Box, Card, CardContent, Typography, FormControl, Select, MenuItem, Grid, Button, CircularProgress, IconButton, FormGroup, FormControlLabel, Checkbox, Link, Container, Paper } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { RootState } from "../../store/store";
import { IError } from "../../type";
import { useLocation, useNavigate } from "react-router-dom";
import { CONFIG } from "../../config";
import { addUserObjectToRequest, callBackendAPI, isUserSubscribedToCommunity } from "../../utils";
import { tokens } from "../../theme";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import DialogAlert from "../../components/DialogAlert";
import QrReader from 'react-qr-reader'

const colors = tokens();
const CommunityDetails = () => {
    const {state} = useLocation();
    const item: any = state.item;
    const isMobile: boolean = useCheckIsMobile();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<IError>({ error: false, message: ""　});
    const [success, setSuccess] = useState<IError>({ error: false, message: ""});
    const { lang, user } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    const isUserSubscribed = isUserSubscribedToCommunity(item?.codes, user?.referralCode);
    
    const [code, setCode] = useState(isUserSubscribed ? user?.referralCode || '' : item.codes[0].name);
    const [checked, setChecked] = useState(isUserSubscribed);
    const [salesManId, setSalesManId] = useState(user?.community?.salesman_uid || '');
    const [scanQR, setScanQR] = useState(false);

    const [selected, setSelected] = useState<"environment" | "user">("environment");

    const subscribeOrUnsubscribe = async () => {
        try {
            if (!isUserSubscribed) {
                if (!checked)
                    return setError({ error: true, message: 'Please agree to the terms and conditions.'});
                if (item.enableSalesmanId && salesManId === '')
                    return setError({ error: true, message: 'Please scan salesman ID to continue.'});
            }
            if (item?.enableSalesmanId)
            setLoading(true);
            let input: any = {
                ver: CONFIG.ver,
                act: String(60233),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: isUserSubscribed ? 0 : 1,
                p2: item.communityIdx || -1,
                p3: code,
                p4: salesManId,
                p5: item.mmpid,
                lg: lang,
                m1: isUserSubscribed ? 'Unsubscribe' : 'subscribe'
            };
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                setLoading(false);
                return setError({error: true, message: '001: Something went wrong, please try again'});
            }
            if (resultsFromAPI.error !== undefined){
                setLoading(false);
                return setError({error: true, message: '002: Something went wrong, please check your Internet Connection and try again'});
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                return setError({error: true, message: '003: Something went wrong, try again'});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0) {
                setLoading(false);
                return setError({ error: true, message: `[${results.code}] : ${results.message || ""}` });
                }

                setLoading(false)
                console.log(results)
                return setSuccess({error: true, message: results.message});
            }
            setLoading(false);
            return setError({error: true, message: '004: Something went wrong, try again'});
        } catch (e) {
            console.log(e);
            setLoading(false);
            return setError({error: true, message: 'Oops Something went wrong'});
        }
    }

    const showQRScanIconForSalesman = () => {
        try {
            if (item.enableSalesmanId === false)
                return false;
            if (isUserSubscribed)
                return false;
            return !(user?.community?.salesman_uid?.length <= 1);
        } catch (e) {
            console.log(e);
            return false;
        }
    }
    
    const showSalesmanID = () => {
        if (salesManId !== '')
            return (
                <Box
                    sx={{ textAlign: 'left' }}
                    mt="5px"
                >                     
                    <Typography variant='h5' sx={{ display: 'flex' }}>
                        {`Salesman ID: ${salesManId}`}
                    </Typography>   
                </Box> 
            )
        return null;
    }

    const renderTNC = () => {
        if (!item?.hasOwnProperty("tncUrl"))
            return null;
        if (item.tncUrl === '')
            return null;
        return (
        <Grid container  sx={{ display: 'flex', alignItems: "flex-start" }}>
            <FormGroup sx={{ marginLeft: 1 }}>
                <FormControlLabel 
                style={{ display: 'flex' }}
                control={
                    <Checkbox 
                        checked={checked}
                        onChange={(event) => {
                            setChecked(event.target.checked);
                        }}
                        disabled={isUserSubscribed}
                        style={{ marginRight: '-10px' }}
                    />} 
                label= "" />
            </FormGroup> 
            <Typography variant="h6" sx={{fontSize: '13px', fontStyle: 'italic', textAlign: 'justify', marginTop: '10px' }}>
                I agree to the{' '} <Link href={item.tncUrl} target="_blank" rel="noopener noreferrer" sx={{ fontWeight: 'bold' }}>Terms and Conditions</Link>
            </Typography>
        </Grid>
        )
    }
     
    if(scanQR){
        return(
            <Box m="20px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={'Community Details'} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6} lg={4}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                            <QrReader
                                facingMode={selected}
                                delay={1000}
                                onError={() => setError({ error: false, message: 'QRCode Error'})}
                                onScan={(data) => {
                                    if (data) {
                                        setSalesManId(data);
                                        setScanQR(false);
                                    }
                                }}
                            />
                            <Grid container spacing={2} alignItems="center" justifyContent="center" mt={1}>
                            <Grid item xs={6} md={6} lg={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <FormControl variant="outlined" sx={{ width: '100%' }}>
                                    <Select
                                    value={selected}
                                    onChange={(e) => setSelected(e.target.value as "environment" | "user")}
                                    >
                                    <MenuItem value={"environment"}>Back Camera</MenuItem>
                                    <MenuItem value={"user"}>Front Camera</MenuItem>
                                    </Select>
                                </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setScanQR(false)}
                                    sx={{ width: '100%' }}
                                >
                                    Stop Scan
                                </Button>
                                </Box>
                            </Grid>
                            </Grid>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
            </Box>
        )
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={'Community Details'} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}
                                >
                                    <Typography variant='h4' sx={{ mt: 2, display: 'flex', fontWeight: 'bold' }}>
                                        {item.name}
                                    </Typography>                                 
                                </Box> 
                                <Box display='flex' justifyContent={'center'} alignItems={'center'}>
                                    <img
                                        src={item.banner}
                                        style={{ width: '50%', height: 'auto' }}                
                                    />
                                </Box> 
                                {item.codes.length > 0 && (
                                <>
                                <Box
                                    sx={{ textAlign: 'left' }}
                                    mt="15px"
                                    mb="5px"
                                >                     
                                    <Typography variant='h5' sx={{ mt: 2, display: 'flex' }}>
                                        Select your preference
                                    </Typography>   
                                </Box>                
                                <FormControl fullWidth>
                                    <Select
                                        value={code || ''}
                                        onChange={(e) => setCode(e.target.value)}
                                        disabled={isUserSubscribed}
                                    >
                                    {item.codes.map((codes: any, index: React.Key) => (
                                        <MenuItem key={index} value={codes.name}>
                                            {codes.code}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                                </>                                
                                )}   
                                <Grid container spacing={2} alignItems="center" sx={{ mt: 0 }}>
                                <Grid item xs={6} lg={6}>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                        {`${item.memberCount || '0'} ${item.memberCount > 1 ? 'members' : 'member'}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {showQRScanIconForSalesman() && (
                                    <IconButton
                                        onClick={() => setScanQR(true)}
                                        sx={{ marginRight: 1, color: 'black' }} 
                                    >
                                        <QrCodeScannerIcon sx={{ fontSize: '1.5rem' }} />
                                    </IconButton>
                                    )}
                                    <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        backgroundColor: colors.primary[500],
                                        color: 'white',
                                        padding: '8px 16px',
                                        '&:hover': {
                                        backgroundColor: colors.primary[700],
                                        },
                                        flex: loading ? 0.5 : 0.4,
                                        minWidth: 120,
                                    }}
                                    onClick={async () => await subscribeOrUnsubscribe()}
                                    disabled={loading}
                                    >
                                    {isUserSubscribed ? 'Unsubscribe' : 'Subscribe'}
                                    {loading && <CircularProgress size={20} />}
                                    </Button>
                                </Grid>
                                </Grid>
                                {showSalesmanID()}
                                {renderTNC()}
                                <Box paddingTop={1}>
                                    <Typography variant="h6" sx={{ fontWeight:'bold' }}>Community Info</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1">{item.description || ''}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
                {error.error &&
                    <DialogAlert
                        open={error.error}
                        onSuccess={() => setError({ error: false, message: ''})}
                        title={'Attention'}
                        content={new Array(error.message)}
                        yesButton={'OK'}
                    />
                }
                {success.error &&
                    <DialogAlert
                        open={success.error}
                        onSuccess={() => {
                            setSuccess({ error: false, message: ''});
                            navigate('/');
                        }}
                        title={'Successfully'}
                        content={new Array(success.message)}
                        yesButton={'OK'}
                    />
                }      
            </Box>      
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default CommunityDetails;
