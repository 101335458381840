import {FC, useEffect, useState} from "react";
import {Sidebar, Menu, MenuItem, sidebarClasses, menuClasses, useProSidebar, SubMenu} from "react-pro-sidebar";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,　IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../theme";
import { MenuInterface } from "../type";  
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store/store";
import { checkUserEkycStatus, ekycUnmount } from "../actions/EkycAction";
import { CONFIG } from "../config";

interface Props {
    routes: MenuInterface[]
}

interface MenuItemProps extends MenuInterface{
    index: number
}

let EKYC_URL : string = CONFIG.env === "test" ? CONFIG.ekycUrl : CONFIG.ekycUrlLive;

const colors = tokens();

const SidebarComponent:FC <Props> = (props) => {
    const {
        routes
    } = props;
    
    const { collapseSidebar, collapsed, broken, toggleSidebar } = useProSidebar();
    const [selected, setSelected] = useState<string>("Account"); 
    const { ekycLoader, ekycError, ekycMessage, ekycVerified, ekycShowPage, ekycShowVideo } = useSelector((state: RootState) => state.ekyc);
    const [isKirimSubMenuExpanded, setKirimSubMenuExpanded] = useState<boolean>(false);
    const [isAlertShown, setIsAlertShown] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [renderEKYC, setRenderEKYC] = useState(false);
    const [renderVKYC, setRenderVKYC] = useState(false);
    const { user } = useSelector((state: RootState) => state.user);
    
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (ekycVerified) {
            setKirimSubMenuExpanded(ekycVerified); 
        }
        if (ekycError && !isAlertShown) {
            setIsAlertShown(true);
            handleOpenDialog();
        }
    }, [ekycVerified, ekycError, isAlertShown]);

    useEffect(() => {
        if (renderEKYC) {
            window.open(
            `${EKYC_URL}?id=2&uuid=${user?.hasOwnProperty("aa_uuid")
              ? user.aa_uuid
              : ""}&hp=${user?.hasOwnProperty("mobile") ? user?.mobile : ""}&lang=${'en'}`,
            "_blank"
            );
            setRenderEKYC(false);
        }
    }, [renderEKYC, renderVKYC, user]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setIsAlertShown(false);
    };

    const handleKirimButtonClick = async () => {
        if (isKirimSubMenuExpanded) {
            setKirimSubMenuExpanded(false);
        } else {
            await dispatch(checkUserEkycStatus(user));
        }
    };

    type AlertDialogProps = {
        open: boolean;
        onClose: () => void;
    };

    const AlertDialog: FC<AlertDialogProps> = ({ open, onClose }) => {
        const handleCancelClick = () => {
            dispatch(ekycUnmount());
            if (renderEKYC) {
                setRenderEKYC(false);
            }
            onClose();
        };
      
        const handleOkClick = () => {
            dispatch(ekycUnmount());
            if (ekycShowVideo) {
                setRenderVKYC(true);
            }
            else if (ekycShowPage) {
                setRenderEKYC(true);
            }
            onClose();
        };
      
        return (
          <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"ATTENTION"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {ekycMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClick}>CANCEL</Button>
              <Button onClick={handleOkClick} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        );
    };
      
    const renderMenuItem = (item: MenuItemProps) => {
        const {
            index, name, path, icon: Icon, submenus, visible
        } = item;
        if (!visible) return null;
        if (submenus === undefined) {
            return (
                <MenuItem
                    rootStyles={{
                        [`.${menuClasses.label}`]: {
                            color: colors.white[500],
                            marginLeft: collapsed ? 0 : -10
                        },
                        [`.${menuClasses.icon}`]: {
                            color: colors.white[500],
                            marginLeft: collapsed ? 0 : -10
                        },
                    }}
                    active={selected === name}
                    onClick={() => {
                        setSelected(name);
                        if (broken)
                            toggleSidebar();
                    }}
                    key={index}
                    component={<Link to={path || '/'} />}
                    icon={<Icon />}
                >
                    <Typography
                        variant={"h6"}
                        sx={{ fontWeight: selected === name ? "bold" : "normal", fontSize: 12}}
                    >
                        {name || ''}
                    </Typography>
                </MenuItem>
            )
        }
        else if (name === "Kirim") {
            if (ekycError && isAlertShown) {
                return;
            } 
            return (
                <SubMenu
                    key={index}
                    label={name}
                    rootStyles={{
                        [`.${menuClasses.label}`]: {
                            color: colors.white[500],
                            marginLeft: collapsed ? 0 : -10,
                        },
                        [`.${menuClasses.icon}`]: {
                            color: colors.white[500],
                            marginLeft: collapsed ? 0 : -10
                        },
                        [`.${menuClasses.active}`]: {
                            backgroundColor: colors.primary[700],
                        },
                    }}
                    icon={<Icon />}
                    onClick={handleKirimButtonClick}
                >
                    {isKirimSubMenuExpanded &&
                                submenus.map((i, v) => {
                                if (i?.visible)
                                    return (
                                    <MenuItem
                                    rootStyles={{
                                        [`.${menuClasses.label}`]: {
                                            color: colors.white[500]
                                        },
                                        [`.${menuClasses.icon}`]: {
                                            color: colors.white[500],
                                        },
                                        [`.${menuClasses.button}`]: {
                                            backgroundColor: colors.primary[600],
                                            '&:hover': {
                                                backgroundColor: selected === i?.name ? colors.primary[700] : colors.primary[300],
                                            },
                                        }
                                    }}
                                    active={selected === i?.name}
                                    onClick={() => {
                                        setSelected(i?.name);
                                        if (broken)
                                            toggleSidebar();
                                    }}
                                    key={v}
                                    component={<Link to={i?.path || '/'} />}
                                >
                                    <Typography
                                        variant={"h6"}
                                        sx={{ fontWeight: selected === i?.name ? "bold" : "normal", fontSize: 12 }}
                                    >
                                        {i?.name || ''}
                                    </Typography>
                                </MenuItem>
                            )
                        else return null
                    })}
                </SubMenu>
            )
        }
        else {
            return (
                <SubMenu
                    key={index}
                    label={name}
                    rootStyles={{
                        [`.${menuClasses.label}`]: {
                            color: colors.white[500],
                            marginLeft: collapsed ? 0 : -10,
                        },
                        [`.${menuClasses.icon}`]: {
                            color: colors.white[500],
                            marginLeft: collapsed ? 0 : -10
                        },
                        [`.${menuClasses.active}`]: {
                            backgroundColor: colors.primary[700],
                        },
                    }}
                    icon={<Icon />}
                >
                    {submenus.map((i,v) => {
                        if (i?.visible)
                            return (
                                <MenuItem
                                    rootStyles={{
                                        [`.${menuClasses.label}`]: {
                                            color: colors.white[500]
                                        },
                                        [`.${menuClasses.icon}`]: {
                                            color: colors.white[500],
                                        },
                                        [`.${menuClasses.button}`]: {
                                            backgroundColor: colors.primary[600],
                                            '&:hover': {
                                                backgroundColor: selected === i?.name ? colors.primary[700] : colors.primary[300],
                                            },
                                        }
                                    }}
                                    active={selected === i?.name}
                                    onClick={() => {
                                        setSelected(i?.name);
                                        if (broken)
                                            toggleSidebar();
                                    }}
                                    key={v}
                                    component={<Link to={i?.path || '/'} />}
                                >
                                    <Typography
                                        variant={"h6"}
                                        sx={{ fontWeight: selected === i?.name ? "bold" : "normal", fontSize: 12}}
                                    >
                                        {i?.name || ''}
                                    </Typography>
                                </MenuItem>
                            )
                        else return null
                    })}
                </SubMenu>
            )
        }
    }

    return (
        <Sidebar
            breakPoint="xs"
            rootStyles={{
                [`.${sidebarClasses.container}`]: {
                    backgroundColor: colors.primary[500],
                },
            }}
        >
            <Menu
                rootStyles={{
                    [`.${menuClasses.active}`]: {
                        backgroundColor: colors.primary[700],
                    },
                }}
                menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                        // only apply styles on first level elements of the tree
                        return {
                            color: colors.white[500],
                            '&:hover': {
                                backgroundColor: colors.primary[500]
                            },
                        }
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        if (broken)
                            toggleSidebar();
                        else
                            collapseSidebar();
                    }}
                    icon={collapsed ? <MenuOutlinedIcon /> : undefined}
                    style={{
                        margin: "10px 0 20px 0",
                        color: colors.gray[100]
                    }}
                >
                    {!collapsed && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center" ml="15px"

                        >
                            <Typography variant="h3" color={colors.white[500]}>
                                MMApp Web
                            </Typography>
                            <IconButton>
                                <MenuOutlinedIcon />
                            </IconButton>
                        </Box>
                    )}
                </MenuItem>
            </Menu>
            {!collapsed && (
                <Box mt="25px" mb="25px">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <img
                            alt="logo"
                            width="230px"
                            height="60px"
                            src={'../../assets/mmlogo.png'}
                            style={{ cursor: "pointer"}}
                        />
                    </Box>
                </Box>
            )}
            <Menu
                menuItemStyles={{
                    button: ({ level, active, disabled }) => {
                        // only apply styles on first level elements of the tree
                       return {
                           color: colors.white[500],
                           '&:hover': {
                               backgroundColor: active ? colors.primary[700] : colors.primary[300],
                           },
                       }
                    },
                }}
                rootStyles={{
                    [`.${menuClasses.active}`]: {
                        backgroundColor: colors.primary[700],
                    },
                }}
                closeOnClick={true}
                style={{ marginBottom: 20}}
            >
                <Box paddingLeft={collapsed ? undefined : "3%"}
                     paddingRight={collapsed ? undefined : "3%"}
                >
                    {routes.map((i,index) => {
                        const { name, path, icon, submenus, component, visible } = i;
                        return renderMenuItem({index, name, path, icon, submenus, component, visible});
                    })}
                </Box>
            </Menu>
            {openDialog && <AlertDialog open={openDialog} onClose={handleCloseDialog} />}
            {ekycLoader && (
            <Box
                style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
            >
                <CircularProgress size={60} color="primary" />
            </Box>
            )}
        </Sidebar>
    )
}

export default SidebarComponent;
