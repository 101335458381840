import React, {FC, useState} from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    FormLabel,
    TextField,
    Typography
} from "@mui/material";
import {BackendResponse, IError, IReceipt, TRedeemDetails, ISuccess, IConfirm} from "../type";
import {tokens} from "../theme";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {jsx} from "@emotion/react";
import JSX = jsx.JSX;
import {addUserObjectToRequest, callBackendAPI, formatCardNumberWithDashes, logError} from "../utils";
import DialogAlert from "./DialogAlert";
import { useAppDispatch } from "../store/store";
import { HOME_UPDATE_WALLET_BALANCE } from "../actions/type";

interface PROPS extends ISuccess {
    data?: IReceipt | undefined;
    print?: boolean;
    onPrintClick?: () => void;
    onOKClick?: () => void;
    redeem?: boolean;
    bankAccount?: string | undefined;
    redeemDetails?: TRedeemDetails | undefined;
    onSuccess?: () => void;
    successTitle?: string;
}
type IRSuccess = {
    success: boolean;
    message: string[];
}

const colors = tokens();
const Receipt: FC<PROPS> = (props) => {
    const {
        data,
        print,
        onOKClick,
        onPrintClick,
        redeem = false,
        bankAccount,
        redeemDetails,
        onSuccess,
        successTitle
    } = props;
    const [userBankAccount, setUserBankAccount] = useState<string | undefined>(bankAccount);

    const [redeemLoading, setRedeemLoading] = useState<boolean>(false);
    const [error, setError] = useState<IError>({ error: false, message: ''});
    const [success, setSuccess] = useState<IRSuccess>({success: false, message: []});
    const [showConfirmation, setShowConfirmation] = useState<IConfirm>({confirm: false, content: []});
    const dispatch = useAppDispatch();

    const renderReceiptData = (): JSX.Element[] | null => {
        if (data?.content === undefined) return null;
        if (!Array.isArray(data?.content)) return null;

        return data.content.map((i: any, v: any) => {
            return(
                <Grid2
                    key={v}
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ m: 2, my: 1}}
                >
                    <Grid2
                        xs={6} md={5}
                        sx={{ py: 0.5, display: 'flex', justifyContent: 'flex-start'}}
                        xsOffset={1} mdOffset={1}
                    >
                        <Typography variant="h6" sx={styles.labelStyle}>
                            {i?.label || ''}
                        </Typography>
                    </Grid2>
                    <Grid2
                        xs={5} md={6}
                        sx={{ py: 0.5, display: 'flex', justifyContent: 'flex-end'}}
                    >
                        <Typography variant="h6" sx={styles.valueStyle}>
                            {i?.value || ''}
                        </Typography>
                    </Grid2>
                </Grid2>
            )
        })
    }

    const renderRedeemComponent = (): JSX.Element | null => {
        if (!redeem) {
            return null;
        }
        return (
            <Grid2
                container
                mt={2}
            >
                <Grid2
                    xs={12} md={12}
                >
                    <FormControl fullWidth>
                        <FormLabel sx={{ mb: 1, fontSize: 12, color: colors.black[500]}}>
                            Bank Account
                        </FormLabel>
                        <TextField
                            fullWidth
                            id={'kirim-bankaccount'}
                            variant="outlined"
                            value={userBankAccount}
                            onChange={e => {
                                if (!isNaN(Number(e.target.value))) {
                                    setUserBankAccount(e.target.value);
                                }
                            }}
                            inputProps={{
                                inputMode: 'numeric',
                            }}
                            FormHelperTextProps={{
                                sx: {
                                    color: colors.blue[900],
                                    fontSize: 10,
                                }
                            }}
                        />
                    </FormControl>
                </Grid2>
            </Grid2>
        )
    }

    const handleRedeemSubmit = () => {
        try {
            let content: string[] = ["Confirm the details below?", "br"];
            if (userBankAccount === '')
                return setError({ error: true, message: 'Bank Account cannot be empty'});
            else
                content.push(`Bank Account: ${userBankAccount}`)
            if (redeemDetails?.phoneNumber === '') {
                return setError({ error: true, message: 'Oops something went wrong, phone number not found'});
            }
            if (redeemDetails?.card_id === '') {
                return setError({ error: true, message: 'Oops something went wrong, Beneficiary card id not found'});
            } else {
                content.push(`Card ID: ${formatCardNumberWithDashes(redeemDetails?.card_id)}`);
            }
            if (redeemDetails?.trans_id === 0) {
                return setError({ error: true, message: 'Oops something went wrong, Transaction id not found'});
            }


            return setShowConfirmation({
                confirm: true,
                content: content
            })
        } catch (e) {
            logError('Redeem handleRedeemSubmit', e);
            return setError({ error: true, message: 'OOPS, Something went wrong. Please try again'})
        }
    }

    const handleRedeem = async (): Promise<void> => {
        try {
            setShowConfirmation({ confirm: false, content: []});
            setRedeemLoading(true);
            let headers: HeadersInit = {
                'Content-Type': 'application/json'
            }
            let inputs: any = {
                act: 20009,
                p1: userBankAccount?.trim() || '',
                p2: redeemDetails?.phoneNumber,
                p3: redeemDetails?.card_id,
                p4: redeemDetails?.trans_id,
                m1: 'Redeem Bank Transfer',
            }
            inputs = addUserObjectToRequest(inputs);
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI(
                'api/request',
                'POST',
                headers,
                inputs
            );
            if (resultFromAPI === undefined) {
                setRedeemLoading(false);
                return setError({ error: true, message: '001: Failed to Make Bank payment, please try again later'});
            }

            if (resultFromAPI?.error !== undefined) {
                setRedeemLoading(false);
                return setError({ error: true, message: '002: Failed to make Bank Payment, please try again later'})
            }
            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results?.code && results.code !== 0) {
                    setRedeemLoading(false);
                    return setError({
                        error: true,
                        message: `${results?.message || "003: Something went wrong, please try again."}`,
                        reportIssue: {show: results?.reportIssue?.show || false, idx: results?.reportIssue?.idx},
                        title: "Redeem Bank Transfer",
                        details: `Phone no : ${redeemDetails?.phoneNumber || ''}\n` +
                            `Card id : ${redeemDetails?.card_id || ''}\n` +
                            `Trans ID: ${redeemDetails?.trans_id || ''}\n` +
                            `Bank A/C: ${userBankAccount || ''}\n` +
                            `Error: ${results?.message || ''}`,
                        trans_id: results?.trans_id,
                        ticket_type: results?.ticket_type
                    });
                }

                // update balance if its present in the result
                // if (results.hasOwnProperty("balance"))
                //     dispatch(updateBalance(results?.balance));
                if (results.hasOwnProperty("balance") && results.balance)
                    dispatch({ type: HOME_UPDATE_WALLET_BALANCE,
                    payload: {balance: results.balance, pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null }});

                setRedeemLoading(false);
                return setSuccess({success: true, message: [results?.message]})
            }
            setRedeemLoading(false);
            return setError({ error: true, message: "003: Something went wrong, please try again."});
        } catch (e) {
            logError('Bank Transfer handleBeneSearch', e);
            setRedeemLoading(false);
            return setError({ error: true, message: 'OOPS, Something went wrong. Please try again'})
        }
    }

    return (
        <Box m="20px">
            <Grid2
                container
            >
                <Grid2
                    xs={12} md={5}
                    mdOffset={3}
                >
                    <Card
                        sx={styles.cardStyle}
                        elevation={15}>
                        <CardContent>
                            <Grid2
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CheckCircleIcon
                                    sx={{
                                        color: colors.green[700],
                                        fontSize: 70
                                    }}
                                />
                            </Grid2>
                            {data?.amount ? (
                                <Grid2
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{mt: 2}}
                                >
                                    <Typography variant="h2" color={colors.black[900]} sx={{ fontWeight: 600}}>
                                        <span style={{ color: colors.black[500], fontWeight: 100 }}>RM</span> {data?.amount }
                                    </Typography>
                                </Grid2>
                            ) : null}
                            <Grid2
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{my: 2}}
                            >
                                <Typography variant="h6" color={colors.gray[300]} textAlign="center">
                                    {data?.title || 'Your transaction is successful' }
                                </Typography>
                            </Grid2>
                            {renderReceiptData()}
                            {renderRedeemComponent()}
                            <Grid2
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                container
                                sx={{ my: 5}}
                            >
                                {print ? (
                                    <>
                                        <Grid2
                                            xs={6} md={6}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Button
                                                variant="contained"
                                                sx={[styles.submitButtonStyle, {px: 5, }]}
                                                onClick={onPrintClick}
                                            >
                                                PRINT
                                            </Button>
                                        </Grid2>
                                        <Grid2
                                            xs={6} md={6}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Button
                                                variant="contained"
                                                sx={[styles.submitButtonStyle, {backgroundColor: colors.green[700]}]}
                                                disabled={redeem ? redeemLoading : false}
                                                endIcon={redeem ? (redeemLoading ? <CircularProgress size={10} /> : null) : null}
                                                onClick={() => {
                                                    if (redeem) {
                                                        return handleRedeemSubmit();
                                                    } else {
                                                        if (onOKClick) {
                                                            return onOKClick()
                                                        }
                                                    }
                                                }}
                                            >
                                                {redeem ? 'Redeem' : 'Done'}
                                            </Button>
                                        </Grid2>
                                    </>
                                ) : (
                                    <Grid2
                                        xs={6} md={4}
                                    >
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            sx={[styles.submitButtonStyle, {backgroundColor: colors.green[700]}]}
                                            onClick={onOKClick}
                                        >
                                            Done
                                        </Button>
                                    </Grid2>
                                )}
                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
            </Grid2>
            {error.error &&
                <DialogAlert
                    open={error.error}
                    onCancel={() => setError({ error: false, message: ''})}
                    onSuccess={() => setError({ error: false, message: ''})}
                    title={'Attention'}
                    content={new Array(error.message)}
                    yesButton={'OK'}
                    reportIssue={error.reportIssue?.show}
                    req_idx={error.reportIssue?.idx}
                    onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
                    reportTitle={error?.title}
                    reportDetails={error?.details}
                    trans_id={error?.trans_id}
                    ticket_type={error?.ticket_type}
                />
            }
            {success.success &&
                <DialogAlert
                    open={success.success}
                    onSuccess={onSuccess}
                    title={successTitle}
                    content={success.message}
                    yesButton={'OK'}
                />
            }
            {showConfirmation &&
                <DialogAlert
                    open={showConfirmation.confirm}
                    onCancel={() => setShowConfirmation({confirm: false, content: []})}
                    onSuccess={async () => await handleRedeem()}
                    title={'Confirm'}
                    content={showConfirmation.content}
                    yesButton={'YES'}
                    cancelButton={'NO'}
                />
            }
        </Box>
    )
}

const styles = {
    cardStyle: {
        mt: 2,
        borderRadius: 2,
    },
    labelStyle: {
        color: colors.gray[300],
        fontWeight: 400,
        fontSize: 13
    },
    valueStyle: {
        colors: colors.black[900],
        fontWeight: 600,
        fontSize: 13
    },
    submitButtonStyle: {
        px: 4,
        py: 0.9,
        mt: 2,
        fontWeight: 'bold'
    }
};

export default Receipt;
