import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { BackendResponse, Error } from "../../type";
import { callBackendAPI, getLabelValue, logError } from "../../utils";
import { CircularProgress, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { CONFIG } from "../../config";
import { useNavigate } from "react-router-dom";
import { tokens } from '../../theme';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { LANGUAGES } from '../../constants/data';
import { updateLanguage } from './userSlice';
import Footer from '../../components/Footer';

const colors = tokens();

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [idNumber, setIDNumber] = useState<string>("");
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ error: false, message: "" });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (): Promise<void> => {
        try {
            if (phoneNumber === '')
                return setError({ error: true, message: 'Handphone No. Is Required' });
            if (idNumber === '')
                return setError({ error: true, message: 'ID Number Is Required' });
            if (error.error)
                setError({ error: false, message: '' });

            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let input: any = {
                ver: CONFIG?.ver,
                act: 900,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: phoneNumber.trim(),
                p2: idNumber.trim(),
                m1: 'Login',
                m2: '',
                lg: lang
            }
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('login', 'POST', headers, input);
            if (resultFromAPI === undefined) {
                setLoading(false);
                return setError({ error: true, message: "001: Something went wrong, please try again." });
            }

            if (resultFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again." });
            }

            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results?.code && results.code !== 0) {
                    setLoading(false);
                    return setError({
                        error: true,
                        message: `${results?.message || "003: Something went wrong, please try again."}`
                    });
                }
                if (results.hasOwnProperty("rba"))
                    return navigate('/registerotp', {
                        state: {
                            user: results.user,
                            userinput: { referralCode: results.referralCode, phoneNumber: phoneNumber },
                            rba: true,
                            shouldSendOtp: true
                        },
                    });

                if (results.hasOwnProperty("wa"))
                    return navigate('/registerexistuser', {
                        state: {
                            user: results.user,
                            wa: true
                        },
                    });

                return navigate('/loginpin', { state: { user: results.user } })
            }

            setLoading(false);
            return setError({ error: false, message: "002: Something went wrong, please try again." })
        }
        catch (e) {
            logError('handleLoginSubmit', e);
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN" });
        }
    }

    const handleSelectLanguage = (event: SelectChangeEvent) => {
        dispatch(updateLanguage(event.target.value));
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        py: 4,
                        marginTop: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box mt="5px" mb="25px" ml="5px">
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="10px"
                            overflow="hidden"
                            boxShadow={2}
                        >
                            <img
                                alt="logo"
                                width="230px"
                                height="60px"
                                src={'../../assets/mmlogo.png'}
                                style={{ cursor: "pointer" }}
                            />
                        </Box>
                    </Box>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }} color='#ef233e'>
                        {getLabelValue(lang, 'login.account.login.title')}
                    </Typography>
                    <Box
                        sx={{ textAlign: 'left' }}
                        mt="15px"
                        mb="5px"
                        ml="5px"
                        mr="5px"
                    >
                        {getLabelValue(lang, 'login.account.title')}
                    </Box>
                    <Box sx={{ mt: 1 }} minWidth={'100%'}>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="20px"
                            mb="5px"
                            ml="5px"
                            mr="5px"
                        >
                            {getLabelValue(lang, 'login.account.handphone')}
                        </Box>
                        <TextField
                            margin="none"
                            required
                            fullWidth
                            name="phone"
                            type='text'
                            id="phone"
                            autoFocus
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="20px"
                            mb="5px"
                            ml="5px"
                            mr="5px"
                        >
                            {getLabelValue(lang, 'login.account.id')}
                        </Box>
                        <TextField
                            margin="none"
                            required
                            fullWidth
                            name="id"
                            type="text"
                            id="id"
                            value={idNumber}
                            onChange={(e) => setIDNumber(e.target.value)}
                        />
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="15px"
                            mb="5px"
                            ml="5px"
                            mr="5px"
                        >
                            {getLabelValue(lang, 'login.title')}
                        </Box>
                        <FormControl sx={{ width: '100%', borderRadius: '20px' }}>
                            <Select
                                labelId="language"
                                id="language"
                                name="language"
                                value={lang}
                                onChange={handleSelectLanguage}
                            >
                                {LANGUAGES.map((i, v) => {
                                    return (
                                        <MenuItem key={v} value={i?.value || ''}>{i?.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {error.error && (
                            <Typography
                                sx={{
                                    mt: '10px',
                                    textAlign: 'center',
                                    color: colors.primary[500],
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {error.message || ''}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            onClick={async () => await handleSubmit()}
                            variant="contained"
                            color="secondary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading}
                            endIcon={loading ? <CircularProgress size={20} /> : null}
                        >
                            {getLabelValue(lang, 'login.button.login')}
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            onClick={() => navigate('/register')}
                            variant="outlined"
                            color="secondary"
                            sx={{ mt: 1, mb: 1 }}
                        >
                            {getLabelValue(lang, 'login.button.newuser')}
                        </Button>
                    </Box>

                </Box>
            </Container>
            <Footer />
        </ThemeProvider>
    )
}

const theme = createTheme({
    palette: {
        secondary: {
            //MMApp signature red
            main: '#ef233e',
        },
        primary: {
            main: '#212121',
        }
    },
});

export default Login;
