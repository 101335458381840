import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Alert } from "@mui/material";
import { FC, useState, useEffect } from "react";

interface PROPS {
    open: boolean;
    title?: string;
    content?: string[] | undefined;
    cancelButton?: string;
    onCancel?: () => void;
    yesButton?: string;
    onSuccess?: () => void;
}

const ConfirmDialog: FC<PROPS> = (props) => {
    const {
        open,
        onSuccess,
        onCancel,
        title = "",
        content = [],
        cancelButton = "",
        yesButton,
    } = props;

  // Handle the Escape key event
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && onCancel) {
                event.preventDefault();
                onCancel();
            }
        };
        if (open) {
            window.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [open, onCancel]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest(".MuiDialog-root") && onCancel) {
                onCancel();
            }
        };

        if (open) {
            window.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, onCancel]);

    return (
        <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            {content.map((i: string, v) => {
                if (i === "br") return <br key={v} />;
                    return (
                        <Typography key={v} variant="body1">
                            {i}
                        </Typography>
                    );
            })}
        </DialogContent>
        <DialogActions>
            {cancelButton !== "" ? (
            <Button onClick={onCancel}>{cancelButton}</Button>
            ) : null}
            {yesButton !== "" ? (
            <Button onClick={onSuccess} autoFocus>
                {yesButton}
            </Button>
            ) : null}
        </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
