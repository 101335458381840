// screens
import Account from "./screens/account/Account";
import Prepaid from "./screens/prepaid/Prepaid";
import Pay from "./screens/pay/Pay";
import Transfer from "./screens/transfer/Transfer";
import Kita from "./screens/kita/Kita";
import Receipt from "./screens/receipt/Receipt";
import Report from "./screens/report/Report";
import Settings from "./screens/settings/Settings";
import Bill from "./screens/bill/Bill";
import CashOut from "./screens/cashout/CashOut";
import Beneficiary from "./screens/kirim/Beneficiary/Beneficiary";
import Membership from "./screens/account/Membership";
import TopUp from "./screens/topup/TopUp";
import TopUpFPX from "./screens/topup/TopUpFPX";
import BookRate from "./screens/kirim/BookRate/BookRate";
import Documents from "./screens/kirim/Documents";
import PayNow from "./screens/kirim/PayNow";
import TransactionStatus from "./screens/kirim/TransactionStatus";
import VerifyBankAccount from "./screens/kirim/VerifyBankAccount";
import RedeemHistory from "./screens/kita/RedeemHistory";
import PointsHistory from "./screens/kita/PointsHistory";
import Communities from "./screens/communities/communities";
import ClaimWater from "./screens/kita/ClaimWater";
import EarnPoints from "./screens/kita/EarnPoints";
import CashVoucher from "./screens/kita/CashVoucher";
import EStamp from "./screens/kita/EStamp";
import ClaimItem from "./screens/kita/ClaimItem";
import UpdatePassport from "./screens/UpdatePassport";

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SendIcon from '@mui/icons-material/Send';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import StarsIcon from '@mui/icons-material/Stars';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import PeopleIcon from '@mui/icons-material/People';
import ClaimItemPrepaid from "./screens/kita/ClaimItemPrepaid";



export const routes = [
    {
        name: "Account",
        path: "/",
        component: Account,
        icon: AccountCircleIcon,
        submenus: undefined,
        visible: true
    },
    {
        name: "Communities",
        path: "/communities",
        component: Communities,
        icon: PeopleIcon,
        submenus: undefined,
        visible: true
    },
    {
        name: "Upgrade Membership",
        path: "/membership",
        component: Membership,
        icon: undefined,
        submenus: undefined,
        visible: false
    },
    {
        name: "Upgrade IC",
        path: "/upgradepassport",
        component: UpdatePassport,
        icon: undefined,
        submenus: undefined,
        visible: false
    },
    {
        name: "Top Up",
        path: "/topup",
        component: TopUp,
        icon: undefined,
        submenus: undefined,
        visible: false
    },
    {
        name: "Top Up FPX",
        path: "/topupfpx",
        component: TopUpFPX,
        icon: undefined,
        submenus: undefined,
        visible: false,
    },
    {
        name: "Kirim",
        path: "/kirim",
        component: undefined,
        icon: SendIcon,
        submenus: [
            {
                name: "Book Rate",
                path: "/kirim/bookrate",
                component: BookRate,
                icon: undefined,
                visible: true,
            },
            {
                name: "Beneficiary",
                path: "/kirim/beneficiary",
                component: Beneficiary,
                icon: undefined,
                visible: true,
            },
            {
                name: "Pay Now",
                path: "/kirim/paynow",
                component: PayNow,
                icon: undefined,
                visible: true
            },
            {
                name: "Transaction Status",
                path: "/kirim/transaction",
                component: TransactionStatus,
                icon: undefined,
                visible: true
            },
            {
                name: "Documents for MM",
                path: "/kirim/documents",
                component: Documents,
                icon: undefined,
                visible: false
            },
            {
                name: "Verify Bank Account",
                path: "/kirim/verify",
                component: VerifyBankAccount,
                icon: undefined,
                visible: true
            }
        ],
        visible: true
    },
    {
        name: "Bill",
        path: "/bill",
        component: Bill,
        icon: RequestQuoteRoundedIcon,
        submenus: undefined,
        visible: true
    },
    {
        name: "Prepaid",
        path: "/prepaid",
        component: Prepaid,
        icon: SmartphoneIcon,
        submenus: undefined,
        visible: true
    },
    {
        name: "Cash Out",
        path: "/cashout",
        component: CashOut,
        icon: LocalAtmRoundedIcon,
        submenus: undefined,
        visible: true
    },
    {
        name: "Pay",
        path: "/pay",
        component: Pay,
        icon: QrCodeScannerRoundedIcon,
        submenus: undefined,
        visible: true
    },
    {
        name: "Transfer",
        path: "/transfer",
        component: Transfer,
        icon: AddToHomeScreenIcon,
        submenus: undefined,
        visible: true
    },
    {
        name: "KITA",
        path: "/kita",
        component: Kita,
        icon: StarsIcon,
        submenus: [
            {
                name: "Point History",
                path: "/kita/history",
                component: PointsHistory,
                icon: undefined,
                visible: true
            },
            {
                name: "Redemption History",
                path: "/kita/redeemhistory",
                component: RedeemHistory,
                icon: undefined,
                visible: true
            },
            {
                name: "Claim Prepaid",
                path: "/kita/claimprepaid",
                component: ClaimItemPrepaid,
                icon: undefined,
                visible: true
            },
            // {
            //   name: "Claim Water",
            //   path: "/kita/claimwater",
            //   component: ClaimWater,
            //   icon: undefined,
            //   visible: true
            // },
            // {
            //   name: "Earn Points",
            //   path: "/kita/earnpoints",
            //   component: EarnPoints,
            //   icon: undefined,
            //   visible: true
            // },
            // {
            //   name: "Cash Voucher",
            //   path: "/kita/cashvoucher",
            //   component: CashVoucher,
            //   icon: undefined,
            //   visible: true
            // },
            // {
            //   name: "E-Stamp",
            //   path: "/kita/estamp",
            //   component: EStamp,
            //   icon: undefined,
            //   visible: true
            // }
        ],
        visible: true
    },
    {
        name: "Receipt",
        path: "/receipthistory",
        component: Receipt,
        icon: ReceiptRoundedIcon,
        submenus: undefined,
        visible: true
    },
    {
        name: "Report a Problem",
        path: "/report",
        component: Report,
        icon: AssessmentIcon,
        submenus: undefined,
        visible: false
    },
    {
        name: "Settings",
        path: "/settings",
        component: Settings,
        icon: SettingsIcon,
        submenus: undefined,
        visible: true
    },
]
