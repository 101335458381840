import {Box} from "@mui/material";
import Header from "../../components/Header";
import React from "react";

const Report = () => {
    return (
        <Box sx={{ mt: 3, ml: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={"Report"} subtitle={"Report vibes"} />
            </Box>
        </Box>
    )
}

export default Report;
