import { Box, Card, CardContent, CircularProgress, Divider, Grid, IconButton, List, ListItemButton, Typography } from "@mui/material";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import Header from "../../components/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { IError } from "../../type";
import { CONFIG } from "../../config";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../utils";
import { useNavigate } from "react-router-dom";
import DialogAlert from "../../components/DialogAlert";

const Communities = () => {
    const [communities, setCommunities] = useState<any>([]);
    const isMobile: boolean = useCheckIsMobile();
    const { lang, user } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<IError>({ error: false, message: ""　});
    const navigate = useNavigate();

    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            fetchCommunities();
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])

    const fetchCommunities = async () => {
        try {
            setLoading(true);
            let input: any = {
                ver: CONFIG.ver,
                act: String(60231),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                lg: lang,
                m1: 'Get promotions'
            };
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                setLoading(false);
                return setError({error: true, message: '001: Something went wrong, please try again'});
            }
            if (resultsFromAPI.error !== undefined){
                setLoading(false);
                return setError({error: true, message: '002: Something went wrong, please check your Internet Connection and try again'});
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                return setError({error: true, message: '003: Something went wrong, try again'});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0) {
                setLoading(false);
                    return setError({ error: true, message: `[${results.code}] : ${results.message || ""}` });
                }
        
                setLoading(false);
                return setCommunities(results.results[0]?.data);
            }
            setLoading(false);
            return setError({error: true, message: '004: Something went wrong, try again'});
        } catch (e) {
            console.log(e);
            setLoading(false);
            return setError({error: true, message: 'Oops Something went wrong'});
        }
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={"Communities"} subtitle={""} />
                </Box>
                <Grid2 container>
                    <Grid2 xs={12} md={6}>
                    <Card
                        sx={styles(isMobile).cardStyle}
                        elevation={3}
                    >
                        <CardContent>
                        <Box sx={{ m: 2 }}>
                            {user?.community &&
                                <>
                                    <Typography
                                        variant={"h5"}
                                        sx={{
                                            wordWrap: "break-word",
                                            textDecoration: "underline",
                                            textDecorationThickness: "1px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {'Community'}
                                    </Typography>                                    
                                    <Box>
                                    <IconButton
                                        onClick={() => navigate('/borneokaki')}
                                        sx={{ mb: 2 }}
                                    >
                                        <img
                                            src={user.community.icon}
                                            alt={user.community.name}
                                            style={{ width: 75, height: 75 }}
                                        />
                                    </IconButton>
                                    </Box>                                    
                                </>
                            }                            
                            <Typography
                                variant={"h5"}
                                sx={{
                                    wordWrap: "break-word",
                                    textDecoration: "underline",
                                    textDecorationThickness: "1px",
                                    fontWeight: "bold",
                                    mb: "25px",
                                }}
                            >
                                {getLabelValue(lang, "profile.community.header")}
                            </Typography>
                            {loading ? (
                            <Box
                                sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "80%",
                                }}
                            >
                                <CircularProgress size={30} color="primary" />
                            </Box>
                            ) : (
                            <>
                                {communities && communities.length > 0 ? (
                                <List>
                                    {communities.map((item: any, index: React.Key) => (
                                    <ListItemButton
                                        key={index}
                                        sx={{ display: "flex", alignItems: "center" }}
                                        onClick={() => {
                                            navigate("/communityDetails", {
                                                state: { item: item },
                                            });
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                        <Grid item xs={12} sm={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img
                                                src={item.banner}
                                                style={{ maxWidth: '75%', height: 'auto' }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                            variant="h4"
                                            sx={{ color: "black", fontWeight: "bold" }}
                                            >
                                            {item.name || ""}
                                            </Typography>
                                            <Typography variant="body1">
                                            {`${item.memberCount || "0"} ${
                                                item.memberCount > 1
                                                ? "members"
                                                : "member"
                                            }`}
                                            </Typography>
                                        </Grid>
                                        </Grid>
                                        <Divider sx={{ mt: 2 }} />
                                    </ListItemButton>
                                    ))}
                                </List>
                                ) : (
                                <Typography
                                    variant="body1"
                                    sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "80%",
                                    }}
                                >
                                    No communities found.
                                </Typography>
                                )}
                            </>
                            )}
                        </Box>
                        </CardContent>
                    </Card>
                    </Grid2>
                </Grid2>
                {error.error && (
                    <DialogAlert
                    open={error.error}
                    onSuccess={() => setError({ error: false, message: "" })}
                    title={"Attention"}
                    content={new Array(error.message)}
                    yesButton={"OK"}
                    />
                )}
            </Box>
        </Box>
    );
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default Communities;
