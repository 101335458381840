import { Box, CircularProgress } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import DialogAlert from "../../components/DialogAlert";
import { getKirimFPXTransaction, payNowDismissError } from "../../actions/PayNowAction";

const PayFPXResponse = () => {
    const [search] = useSearchParams();
    const request_id: any = search === undefined ? undefined : search.get('requestId');
    const { lang } = useSelector((state: RootState) => state.user);
    const { loading, error, message } = useSelector((state: RootState) => state.paynow);
    const navigate = useNavigate();
    
    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(getKirimFPXTransaction(navigate, request_id, lang));
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])
    
    return (
        <Box m="20px">
            {loading ? (
                <Grid2
                    container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: '80%'}}
                >
                    <Grid2
                        xs={12} md={8}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress size={30} color="primary" />
                    </Grid2>
                </Grid2>
            ) : (
                null
            )}
            {error &&
                <DialogAlert
                    open={error}
                    onSuccess={() => {
                        dispatch(payNowDismissError());
                        navigate('/');
                    }}
                    title={'Attention'}
                    content={new Array(message)}
                    yesButton={'OK'}
                    // reportIssue={error.reportIssue?.show}
                    // req_idx={error.reportIssue?.idx}
                    // onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
                />
            }
        </Box>
    )
}

export default PayFPXResponse;