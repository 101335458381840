import { Typography } from "@mui/material";
import { tokens } from "../theme";
import Grid2 from "@mui/material/Unstable_Grid2";
import { CONFIG } from "../config";

const colors = tokens();

const Footer = () => {
    return (
        <Grid2
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ my: 3, mx: 1 }}
        >
            <Grid2>
                <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize={10}
                    color={colors.black[900]}
                >
                    Mobile Money International Sdn Bhd Lot 23-24, 2nd Floor, IOI Business Park, 47100 Puchong, Selangor, Malaysia. Tel: 03-80730200
                </Typography>
            </Grid2>

            <Grid2 sx={{ pt: 1 }}>
                <Typography
                    variant="body1"
                    textAlign="center"
                    fontSize={10}
                    color={colors.gray[500]}
                >
                    <span>© {new Date().getFullYear()}</span> Mobile Money All rights reserved
                </Typography>
            </Grid2>

            <Grid2 sx={{ pt: 1 , pb: 2}}>
                <Typography
                    variant="h3"
                    textAlign="center"
                    fontSize={12}
                    fontWeight="bold"
                    color={colors.black[900]}
                >
                    {CONFIG.ver}
                </Typography>
            </Grid2>
        </Grid2>
    );
}

export default Footer;
