import { Box, Card, CardContent } from "@mui/material";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import Header from "../../components/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import { getLabelValue } from "../../utils";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

const Kita = () => {
    const isMobile: boolean = useCheckIsMobile();
    const { lang } = useSelector((state: RootState) => state.user);

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={getLabelValue(lang, 'home.mmproducts.product6')} subtitle={""} />
            </Box>
            <Grid2
                container
            >
                <Grid2
                    xs={12} md={6}
                >
                    <Card
                        sx={styles(isMobile).cardStyle}
                        elevation={3}
                    >
                        <CardContent>
                            f
                        </CardContent>
                    </Card>
                </Grid2>
            </Grid2>
        </Box>
    )
}

const styles = (isMobile: boolean) => ({
    cardStyle: {
        mt: 2,
        borderRadius: 2,
        maxHeight: isMobile ? 'auto' : '70vh',
        overflow: 'auto'
    }
})

export default Kita;
