export const BM_LANG_CONFIG: { [key: string]: any } = {
 /*  APP NAVIGATOR */
 'navigation.tabs.home.title': 'Home',
 'navigation.tabs.receipt.title': 'Resit',
 'navigation.tabs.profile.title': 'Peribadi',
 'navigation.tabs.kita.title': 'Kita',

 /* LOGIN PAGE */
 'login.title': 'Sila pilih bahasa',
 'login.newuser': 'Kali pertama?',
 'login.button.newuser': 'DAFTAR BARU',
 'login.button.login': 'Log Masuk',
 'login.account.login.title': 'Log Masuk',
 'login.account.login': 'Belum ada akaun?',
 'login.account.button.next': 'Seterusnya',
 'login.account.button.submit': 'Teruskan',
 'login.account.button.goback': 'Kembali',
 'login.account.button.newuser': 'Daftar Baru',
 'login.account.title': 'Sila lengkapkan maklumat di bawah untuk masuk ke halaman profil anda.',
 'login.account.handphone': 'Nombor Handphone',
 'login.account.id': 'Nombor ID',
 'login.acoount.forgotpassword': 'Terlupa PIN',
 'login.account.warning.title': 'Amaran!',
 'login.account.warning.subTitle1': 'Jangan masukkan PIN anda jika ini',
 'login.account.warning.subTitle2': 'bukan foto profile anda.',
 'login.account.photo.confirm': 'Ya, ini foto saya.',
 'login.account.pin.title': 'Masukkan nombor 6 angka PIN anda',
 'login.scan.instructions': 'Sila imbas Kod QR ID Akaun sekarang',

 /* REGISTER PAGE */
 'register.title': 'DAFTAR BARU',
 'register.account.title': 'Sila lengkapkan maklumat di bawah untuk mendaftar akaun baru.',
 'register.account.handphone': 'Nombor Handphone',
 'register.account.idType': 'Pilih jenis ID',
 'register.account.id': 'Nombor ID',
 'register.account.nationality': 'Pilih jenis Nationality',
 'register.account.rfCode': 'Kod Referral (jika ada)',
 'register.account.name': 'Full Name',
 'register.account.name.helpertext': '** Sila masukkan nama penuh anda seperti dalam IC / Passport **',
 'register.account.email': 'Email',
 'register.account.invalid.email': 'Email Tidak Sah',
 'register.account.invalid.postcode': 'Post Kod Tidak Sah',
 'register.account.lotno': 'Lot No.',
 'register.account.address': 'Nama Flat / Apmt / Jalan / Lorong',
 'register.account.address2': 'Nama Kawasan / Taman / Kampung',
 'register.account.postcode': 'Post Code',
 'register.account.state': 'Negeri',
 'register.account.choosestate': 'Pilih jenis Negeri',
 'register.account.city': 'Bandar',
 'register.otp.title': 'One-Time Password (OTP) untuk',
 'register.otp.subTitle': 'log masuk telah dihantar ke',
 'register.otp.instructions': 'Sila cari OTP di SMS dan masukkan ke  ruang bawah',
 'register.otp.smsnotrceived': 'SMS tidak diterima? Minta lagi',
 'register.otp.request': 'Minta OTP lagi',
 'register.account.pin': 'Masukkan nombor 6 angka PIN',
 'register.account.confirmPin': 'Ulangi nombor 6 angka PIN',
 'register.initialize': 'Sebentar lagi kami menyediakan akaun anda!',
 'register.pin.title1': 'Cipta Pin 6',
 'register.pin.title2': 'Angka Baru',
 'register.pin.subTitle': 'PIN 6 angka ini akan diperlukan Ketika anda membuat transaksi.',
 'register.pin.success.title': 'PIN TRANSAKI BERJAYA DICIPTA',
 'register.pin.mismatch': 'KESALAHAN PIN, CUBA LAGI',
 'register.pin.success.message': 'Sila tulis dan simpan pin ini di tempat yang selamat. Tanpa PIN, transaki tidak dapat diteruskan',
 'register.photo.title': 'Foto profil',
 'register.photo.subTitle': 'Sila pilih foto profil anda',
 'register.success.title': 'Pendaftaran Berjaya',
 'register.success.subTitle': 'Foto profil anda derjaya ditambah',
 'register.success.warning': 'Amaran!',
 'register.success.message': '*Pada waktu log masuk jika foto profil anda tidak betul. Jangan teruskan',
 'register.tnc': '"Saya bersetuju dan menerima syarat penggunaan perkhidmatan ini seperti yang dinyatakan di dalam Terma dan Syarat Pengguna. Terma dan Syarat Pengguna boleh dibaca di laman web Mobile Money International Sdn Bhd di ',
 'forgot.otp.title': 'One-Time Password (OTP) untuk',
 'forgot.otp.subTitle': 'forgot pin masuk telah dihantar ke',
 'reset.otp.subTitle': 'reset device masuk telah dihantar ke',
 'reset.device.title1': 'Confirm Reset Device',
 'reset.device.subTitle': 'Your previous device no longer able to login into this account anymore.',
 'reset.device.reset': 'Reset',
 'register.upgrade.tnc': '"Anda juga mengakui bahawa dengan meneruskan aplikasi MM App, saiz Dompet Mobile Money anda akan meningkat daripada RM1,500 kepada RM10,000 seperti yang dinyatakan dalam Jadual A dalam Terma & Syarat MM untuk Pelanggan.',
 'register.rfCode.qrcode': 'Sila imbas ID Akaun',
 'register.enterpin.close.message': 'Oops akaun anda hampir disediakan. Sila ingat bahawa jika anda menekan YES , anda mungkin menghadapi masalah membuat akaun anda. Untuk meneruskan sila tekan NO',
 'register.enterpin.close.title': 'Adakah anda pasti mahu tutup?',


 /* HOME PAGE */
 'home.header.title': 'Baki MM Wallet',
 'home.header.button.topup': 'Tambah Nilai',
 'home.header.button.fpx': 'FPX',
 'home.header.button.transhistory': 'Transaction History',
 'home.header.button.transactions': 'Transaksi',
 'home.mmproducts.product1': 'Kirim',
 'home.mmproducts.product2': 'Bil',
 'home.mmproducts.product3': 'Prepaid',
 'home.mmproducts.product4': '2FA',
 'home.mmproducts.product5': 'Transfer',
 'home.mmproducts.product6': 'Kita',
 'home.transaction.status.processing': 'Sedang diproses',
 'home.transaction.status.success': 'Berjaya',
 'home.transaction.status.all': 'All',

 /* TOP UP PAGE */
 'topup.header.title': 'Isikan Jumlah Tambah Nilai',
 'topup.header.subTitle': 'Top up sekali',
 'topup.amount.title': 'Jumlah bayaran',
 'topup.error.message1': 'Sila pilih jumlah',
 'topup.footer.button': 'Bayar',
 'topup.receipt.subTitle': 'Sudah Ditambah',
 'topup.disclaimer.title': 'Cegah deposit ditolak',
 'topup.disclaimer.desc1': 'Buat deposit dari akaun bank peribadi anda',
 'topup.disclaimer.desc2': 'Nama pemegang akaun bank MESTI SAMA dengan nama yang anda daftarkan dengan MM APP',
 'topup.disclaimer.desc3': 'Deposit yang dibuat dari akaun bank orang lain akan dikembalikan',
 'topup.disclaimer.desc4': 'Pemulangan boleh mengambil masa sehingga 14 hari perniagaan untuk diproses',


 /* PREPAID PAGE */
 'prepaid.header.title': 'Pilih Telco',

 /* Prepaid Selected PAGE */
 'prepaidselected.header.title': 'Nombor Prabayar',
 'prepaidselected.header.description': 'Pilih Jumlah',
 'prepaidselected.main.amountinput': 'Isikan Jumlah',
 'prepaidselected.footer.button': 'Tambah Nilai',
 'prepaidselected.error.message1': 'Sila pilih jumlah',
 'prepaidselected.error.message2': 'Sila berikan nombor telefon',
 'prepaidselected.receipt.subTitle': 'Dibayar',
 'prepaidselected.error.title': 'Error',
 'prepaidselected.no.denos': 'Tiada denominasi tersedia!',

 /* PIN */
 'pin.title': 'Masukkan PIN 6 angka untuk pengesahan',

 /* Transaction PAGE */
 'transaction.sendTo': 'Kirim Ke',
 'transaction.button.title1': 'Ya',
 'transaction.button.title2': 'BATAL',
 'transaction.report.problem': 'Lapor Masalah',
 'transaction.receipt': 'Resit',

 /* KIRIM PAGE */
 'kirim.bookrate.title': 'Check current rate',
 'kirim.bookrate.button': 'Seterusnya',
 'kirim.bookrate.selectbene.title': 'Pilih Penerima',
 'kirim.bookrate.selectbene.button': 'TERUSKAN',
 'kirim.bookrate.confirm.button': 'SUBMIT',
 'kirim.bookrate.selectpurpose': 'Pilih Tujuan',
 'kirim.bookrate.noBene': 'Pada masa ini anda tidak mempunyai sebarang persediaan benefisiari. Sila tambah benefisiari untuk meneruskan.',
 'kirim.header.beneficiary.add.country': 'Negara Penerima',
 'kirim.header.beneficiary.add.rship': 'Hubungan Penerima',

 /* TRANSFER PAGE */
 'transfer.selected.title': 'Massukan Jumlah yang hendak dihantar',
 'transfer.selected.balance': 'Baki semasa anda',
 'transfer.selected.send': 'HANTAR',
 'transfer.confirm.amount': 'Jumlah bayaran',
 'transfer.receipt.subTitle': 'Berjaya dihantar',

 /* PROFILE */
 'profile.name': 'Nama.',
 'profile.mobile': 'Nombor Handphone.',
 'profile.idtype': 'Jenis ID.',
 'profile.id': 'Nombor ID.',
 'profile.account_id': 'Account ID',
 'profile.scan_payroll': 'Imbas Korporat ID',
 'profile.email': 'Email.',
 'profile.lotno': 'Lot No.',
 'profile.address1': 'Alamat 1.',
 'profile.address2': 'Alamat 2.',
 'profile.postcode': 'Postcode.',
 'profile.city': 'Bandar.',
 'profile.state': 'Negeri.',
 'profile.change_language': 'Language',
 'profile.t2p_card': 'Tap 2 Pay Card',
 'profile.changepin.title': 'Tukar pin 6 digit',
 'profile.changeT2Ppin.title': 'Tukar T2P pin 6 digit',
 'profile.lost_card.title': 'Lapor Kad MM hilang',
 'profile.lost_card.disclaimer': 'Kad MM anda akan dihalang dan tidak boleh digunakan. semula Jika anda setuju sila tekan OK.',
 'profile.lost_card.success': 'Kad MM anda telah dihalang MM akan hubungi anda sebentar lagi untuk menasihati anda cara mendapatkan pengantian kad MM baru.',
 'profile.changepin.current.title': 'Masukkan pin semasa',
 'profile.changepin.new.title': 'Masukkan pin 6-Digit Baru',
 'profile.changepin.confirmnew.title': 'Ulangi pin 6-Digit Baru',
 'profile.changepin.success.message': 'PIN anda berjaya ditukar.',
 'profile.scan.instructions': 'Sila imbas ID Korporat sekarang',
 'profile.scan.pin': 'Masukkan pin',
 'profile.user_qrcode': 'QR Code',
 'profile.user_qrcode.description': 'Imbas Kod QR Pengguna ini',
 'profile.upgrade_membership': 'Keahlian',
 'profile.upgrade.button': 'Naik taraf',
 'profile.upgrade.subtitle': 'Anda kini berada di bawah',
 'profile.upgrade.description': 'Tingkatkan untuk mendapatkan faedah dan ciri yang lebih baik',
 'profile.upgrade.alert.title': 'Naik taraf kepada',
 'profile.upgrade.alert.message.gold': 'Untuk menaik taraf keahlian Gold, anda perlu menjalani proses eKYC. Sila klik pada butang OK untuk meneruskan.',
 'profile.upgrade.alert.message.silver': 'Untuk menaik taraf kepada keahlian Perak, anda perlu melakukan sekurang-kurangnya satu transaksi FPX untuk mengesahkan Nama akaun anda. Sila klik pada butang OK untuk meneruskan. Untuk menaik taraf kepada keahlian Silver, anda perlu sekurang-kurangnya melakukan satu transaksi FPX untuk mengesahkan Nama akaun anda. Sila klik pada butang OK untuk meneruskan.',
 'profile.upgrade.alert.message.platinum': 'Untuk menaik taraf keahlian Platinum, anda perlu pergi ke mana-mana ejen Promoter MM terdekat kami untuk meneruskan proses ini. Sila klik OK untuk meneruskan.',
 'profile.referral.menu.title': 'Jemput seorang kawan',
 'profile.referral.menu.description': 'Anda dan rakan anda mendapat mata percuma!',
 'profile.referral.menu.subtitle': 'Dapatkan lebih banyak mata apabila anda menjemput rakan memuat turun dan menggunakan app',
 'profile.referral.header.title': 'Referral',
 'profile.referral.question': 'Bagaimanakah ia berfungsi?',
 'profile.referral.instruction1': 'Kongsi pautan anda dengan rakan.',
 'profile.referral.instruction2': 'Rakan anda perlu memuat turun Apl MM & berjaya mendaftar.',
 'profile.referral.instruction3': 'Anda dan rakan anda akan mendapat mata percuma.',
 'profile.referral.share.button': 'Kongsi Pautan saya',
 'profile.referral.share.description': 'Anda sudah cuba MM App? Mereka ada sesuatu yang menarik untuk anda. Semak di sini: ',
 'profile.delete.account': 'Padam Akaun',
 'profile.delete.account.message': 'MM akan mengalih keluar akaun log masuk pengguna MM App anda seperti yang diminta. Untuk menamatkan akaun dompet MM anda, sila hubungi atau e-mel kepada Sokongan Pelanggan kami di crt@mobile-money.com.my. Untuk butiran lanjut, sila klik di sini',
 'profile.delete.account.submessage': 'Sila rujuk Klausa 18 dalam Terma & Syarat MM untuk Pelanggan di',
 'profile.payslip': 'Slip Gaji',
 'profile.payslip.emptylist': 'Tiada data tersedia pada masa ini',
 'profile.payslip.date': 'Sila pilih tarikh',
 'profile.payslip.employee_id': 'Sila masukkan ID pekerja anda',
 'profile.accountID_qrcode.description': 'Imbas ID Akaun Pengguna ini',
 'profile.upgrade.ic.attention': 'PERHATIAN',
 'profile.upgrade.ic.desc': 'telah tamat tempoh, sila klik di sini untuk mengemas kininya',
 'profile.upgrade.ic.message': 'Passport sudah luput. Anda ingin kemaskini passport baru? Sila tekan Update',
 'profile.upgrade.ic': 'Kemas kini Pasport',
 'profile.upgrade.ic.oldid': 'Nombor ID / Pasport semasa',
 'profile.upgrade.ic.newid': 'Masukkan nombor ID / Pasport baharu anda',
 'profile.upgrade.ic.submit': 'Hantar',
 'profile.upgrade.ic.errornewid': 'Sila MASUKKAN ID / Pasport baharu',
 'profile.upgrade.ic.errorimage': 'Sila berikan gambar ID / Pasport baharu',
 'profile.upgrade.ic.expired_dt': 'Tarikh luput',
 'profile.upgrade.ic.newidissuedt': 'Tarikh Keluaran ID / Pasport Baharu (DD/MM/YYYY)',
 'profile.upgrade.ic.newidexpirydt': 'ID Baharu / Tarikh Luput Pasport (DD/MM/YYYY)',
 'profile.upgrade.ic.errornewidissuedt': 'Sila MASUKKAN ID / Tarikh Keluaran Pasport baharu (DD/MM/YYYY)',
 'profile.upgrade.ic.errornewidexpirydt': 'Sila MASUKKAN ID baharu / Tarikh Luput Pasport (DD/MM/YYYY)',
 'profile.community.header': 'Langgan dan temui komuniti baharu',
 'profile.community.scan.instructions': 'Sila imbas Kod QR ID Akaun sekarang',

 /* PAY BILL */
 'paybill.description': 'Rujuk bil anda untuk maklumat akaun',
 'paybill.proceed': 'Teruskan',
 'paybill.paynow': 'Bayar sekarang',
 'paybill.denominations': 'Choose Amount',

 /* RECEIPT PAGE */
 'receipt.copyToClipboard': 'telah disalin ke papan keratan',

 /* KIRIM BENEFICIARY PAGE */
 'kirim.beneficiary.title': 'Tambah Penerima', // Tambah Penerima
 'kirim.beneficiary.benename': 'Nama Penerima', // Nama Penerima
 'kirim.beneficiary.benephone': 'Nombor Telefon Penerima', // Nombor Telefon Penerima
 'kirim.beneficiary.benekadno': 'Ahli Nombor Kad', // Nomor Rekening Penerima
 'kirim.beneficiary.benecity': 'Bandar Penerima', // Bandar Penerima
 'kirim.beneficiary.bene': 'Penerima', // Penerima
 'kirim.beneficiary.benerelationship': 'Hubungan Penerima', // Hubungan Penerima
 'kirim.beneficiary.benebank': 'Bank Penerima', // Bank Penerima
 'kirim.beneficiary.benebankbranch': 'Cawangan Bank Penerima', // Bank Penerima
 'kirim.beneficiary.ifsc': 'IFSC', // IFSC
 'kirim.beneficiary.benecountry': 'Negara Penerima', // Negara Penerima
 'kirim.beneficiary.beneaccno': 'Nombor Akaun', // Negara Penerima
 'kirim.beneficiary.nobene': 'Tiada Benefisiari', // Negara Penerima
 'kirim.beneficiary.middlename':' Nama Tengah Penerima (jika ada)', // Nama Tengah Penerima
 'kirim.beneficiary.lastname' :'Nama Akhir Penerima',  // Name Akhir Penerima
 'kirim.beneficiary.address' :'Alamat', // Alamat

 /* KIRIM FPX PAGE */
 'kirim.fpx.bankname': 'Pilih Bank', // Nombor Akaun bank
 'kirim.fpx.accname': 'Nama Akaun Bank', // Nama Akaun Bank
 'kirim.fpx.accno': 'Nombor Akaun bank', // Nombor Akaun bank
 'kirim.fpx.status': 'Status Pengesahan', // Nombor Akaun bank
 'kirim.fpx.email': 'Emel', // Emel

 /* KIRIM PAGE */
 'kirim.menu.bookrate': 'Kirim',
 'kirim.bookrate.beneficiary': 'Benefisiari',
 'kirim.bookrate.paynow': 'Bayar',
 'kirim.bookrate.transactionstatus': 'Status Transaksi',
 'kirim.bookrate.documents': 'Surat utk MM',
 'kirim.bookrate.help': 'Bantuan',
 'kirim.bookrate.fpx': 'Pengesahan akaun bank saya',
 'kirim.bookrate.cancel':'Batal Booking',


 /* KIRIM HEADDER PAGE */
 'kirim.header.bookrate': 'Kirim',
 'kirim.header.beneficiary': 'Benefisiari',
 'kirim.header.beneficiary.add': 'Tambah Benefisiari',
 'kirim.header.paynow': 'Bayar',
 'kirim.header.transactionstatus': 'Status Transaksi',
 'kirim.header.documents': 'Surat utk MM',
 'kirim.header.help': 'Bantuan',

 /* KIRIM STATUS TRANSAKSI PAGE */
 'kirim.transaction.transid': 'Transaksi ID',
 'kirim.transaction.beneficiary': 'Benefisiari',
 'kirim.transaction.cardno': 'Ahli Nombor Kad',
 'kirim.transaction.country': 'Negara',
 'kirim.transaction.provider': 'Provider',
 'kirim.transaction.booktime': 'Booking Date',
 'kirim.transaction.rate': 'Kadar Pertukaran',
 'kirim.transaction.date': 'Tarikh Transaksi',
 'kirim.transaction.receipt': 'Resit',
 'kirim.transaction.refund': 'Refund',
 'kirim.transaction.cancel': 'Batal',
 'kirim.transaction.customerhp':'Nombor Handphone',

 /* KIRIM PAY NOW PAGE */
 'kirim.paynow.nobook': 'Tiada Tempahan tersedia',
 'kirim.paynow.nobooking.selected': 'Sila pilih tempahan',

 /* KIRIM MONEY PIN PAGE */
 'kirim.moneypin.message': 'Mesej',
 'kirim.moneypin.promotion': 'Promo',
 'kirim.moneypin.redeem': 'Balas Rekening',
 'kirim.moneypin.time': 'Tarikh & Masa',
 'kirim.moneypin.send': 'Hantar',
 'kirim.moneypin.receipt': 'Resit Kiriman',
 'kirim.moneypin.receiver':'Penerima',
 'kirim.moneypin.amount':'Jumlah',
 'kirim.moneypin.pin':'PIN',
 'kirim.moneypin.provider':'Pembekal perkhidmatan',
 'kirim.moneypin.details':'Cash PIN Details',

 /* KITA PAGE */
 'kita.date': 'Dikemas kini pada',
 'kita.header.title': 'Tebus',
 'kita.points':'mata',
 'kita.points.toptab1': 'diguna',
 'kita.points.toptab2': 'diperolehi',
 'kita.earned': 'diperolehi',
 'kita.water': 'Air',
 'kita.water.litre':'liter',
 'kita.water.point_description':'Jumlah mata',
 'kita.water.button': 'TEBUS DENGAN MATA',
 'kita.water.qrcode_message': 'Sila scan kod QR di mesin air. Kod QR akan hilang selepas anda tekan "Tutup"',
 'kita.water.qrcode_button': 'Tutup',
 'kita.prepaid.header.title': 'Tebus Prepaid',
 'kita.voucher': 'Baucar',
 'kita.history.toptab1': 'Baru',
 'kita.history.toptab2': 'Terpakai',
 'kita.history.points_used': 'Mata ditolak',
 'kita.history.voucher_code': 'Kod Baucar',
 'kita.history.redeemed': 'Ditebus',
 'kita.history.header': 'Sejarah Penebusan',
 'kita.point.header': 'Sejarah Mata',
 'kita.advertisement': 'Iklan',
 'kita.point.empty': 'Tiada data tersedia pada masa ini',
 'kita.earn.title': 'ANDA TELAH DIPEROLEHI',
 'kita.earn.description': 'Menonton Untuk Memperolehi Mata',
 'kita.earn.header' : 'Untung Poin',
 'kita.earn.emptylist.label': 'Tiada iklan buat masa ini, sila cuba kemudian.',
 'kita.earn.ad.component.description': 'Tonton & Dapatkan mata',
 'kita.earn.counter': 'saat lagi',
 'kita.earn.counter.close': 'Tekan untuk terima poin',
 'kita.stamps.title': 'E-Setem ',
 'kita.cash.voucher.title': 'Baucar Tunai',
 'kita.cash.voucher.accumulated' : 'Baucar Tunai dikumpul',
 'kita.redeem': 'Tebus',
 'kita.revert': 'Kembalikan',
 'kita.cash.voucher.qrcode.message': 'Sila scan kod QR di pedagang untuk guna baucar tunai',
 'kita.cash.voucher.merchants': 'Pedagang',
 'kita.cash.voucher.purchase' : 'Beli Dengan Dompet',
 'kita.cash.voucher.vouchers': 'Baucar Pedagang',
 'kita.cash.voucher.balance' : 'Baki baucar',
 'kita.cash.voucher.enter' : 'Masukkan Jumlah',
 'kita.cash.voucher.generate' : 'Menjana QR',
 'kita.cash.voucher.invalid' : 'Jumlah Tidak Sah',
 'kita.cash.voucher.error' : 'Masukkan Jumlah melebihi 0',
 'kita.cash.voucher.collected': 'Baucar',
 'kita.cash.voucher.history': 'Sejarah',
 'kita.cash.voucher.use': 'Guna Baucar',
 'kita.cash.voucher.usage_left': 'Sah Sehingga',
 'kita.cash.voucher.usage': 'Kegunaan',
 'kita.cash.voucher.days': 'hari',
 'kita.cash.voucher.times': 'times',
 'kita.update.version': 'Versi yang lebih baru tersedia. Sila kemas kini sekarang',


 /* KADAR PAGE */
 'kadar.header.title': 'Kadar',

 /* PAY PAGE */
 'pay.header.title': 'Bayar',
 'pay.qrcode.desc': 'Kod QR ini akan dimuat semula setiap 60 saat.',

 /* NOTIFICATIONS */
 'notifications.header.title': 'Pemberitahuan',

 /* CASH OUT */
 'cashout.bank_name.label': 'Nama bank',
 'cashout.acc_name.label': 'Nama akaun',
 'cashout.acc_no.label': 'Nombor akaun',
 'cashout.amount.label': 'Masukkan Jumlah'
}
