import { Autocomplete, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React, { FC, useEffect, useRef, useState } from "react";
import { getLabelValue } from "../../../utils";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { theme, tokens } from "../../../theme";
import Header from "../../../components/Header";
import { addBeneDimissMessage, beneficiaryAddBene, beneficiaryAddBeneGetBank, beneficiaryAddBeneSettingListFetch, changeBeneCountry, checkifsc, dismissCheck, dismissCheckifscMessage } from "../../../actions/BeneficiaryAction";
import { BENEFICIARY_MENU } from "../../../constants/data";
import Grid2 from "@mui/material/Unstable_Grid2";
import useCheckIsMobile from "../../../hooks/useCheckIsMobile";
import { useNavigate } from "react-router-dom";

const colors = tokens();

const AddBeneficiary = () => {
    const isMobile: boolean = useCheckIsMobile();
    const { lang } = useSelector((state: RootState) => state.user);
    const { loading, loading1, error, error1, message, message1, ifsccodelist, selectedCountry, countryList, relationshipList, addbenebanklist, addbenebranchlist, showMessage, ifsc, bankpinlist, bankorcaspickupList, showMessageTitle } = useSelector((state: RootState) => state.beneficiary);
    const [bankOrCashPickupIndex, setBankOrCashPickupIndex] = useState<number>(0);
    const [bankOrCashPickupLabel, setBankOrCashPickupLabel] = useState("");
    const [beneBank, setBeneBank] = useState("");
    const [beneBankBranch, setBeneBankBranch] = useState("");
    const [beneAccountNo, setBeneAccountNo] = useState("");
    const [beneName, setBeneName] = useState("");
    const [beneMiddleName, setBeneMiddleName] = useState("");
    const [beneLastName, setBeneLastName] = useState("");
    const [beneAddress, setBeneAddress] = useState("");
    const [beneBSB, setBeneBSB] = useState("");
    const [benePhone, setBenePhone] = useState("");
    const [beneCity, setBeneCity] = useState("");
    const [beneIfsc, setBeneIfsc] = useState("");
    const [selectIfsc, setSelectIfsc] = useState(false);
    const [branchSelected, setBranchSelected] = useState(false);
    const [selectedModule, setSelectedModule] = useState<string | null>('');
    const [selectedRelationship, setSelectedRelationship] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [alertDialog, setAlertDialog] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    let addBeneSettingActId = BENEFICIARY_MENU[1];
    let addBeneGetBankActId = BENEFICIARY_MENU[2];
    let addBeneActId = BENEFICIARY_MENU[3];

    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(beneficiaryAddBeneSettingListFetch(lang, addBeneSettingActId));
            dispatch(dismissCheck());
            dispatch(addBeneDimissMessage());
            dispatch(dismissCheckifscMessage());
            handleChangeCountry(selectedCountry);
        }
        if (shouldLockEffect?.current === false) {
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, []);

    useEffect(() => {
        if (showMessage) {
            setAlertDialog(true);
        }
        if (error) {
            setErrorDialog(true);
        }
    }, [showMessage, error]);

    useEffect(() => {
        if (ifsccodelist && ifsccodelist.length > 0) {
            setBeneBank(ifsccodelist[0].bank || '');
            setBeneBankBranch(ifsccodelist[0].branch || '');
        }
    }, [ifsccodelist]);

    useEffect(() => {
        if (branchSelected && ifsc && ifsc.length > 0) {
            setBeneIfsc(ifsc[0].ifsc || '');
        }
    }, [ifsc, branchSelected]);

    const handleSwitchChange = () => {
        setSelectIfsc((prevState) => !prevState);
        dispatch(dismissCheckifscMessage());
        setBeneBank('');
        setBeneBankBranch('');
        setBeneIfsc('');
    }

    const handleCheckIFSC = () => {
        const detail = { "ifsc": beneIfsc, "act_id": 31008 }
        dispatch(dismissCheckifscMessage);
        dispatch(checkifsc(lang, detail));
    }

    const handleChangeCountry = (selectedValue: any) => {
        dispatch(changeBeneCountry(selectedValue));
        // const excludedCountries = ['Pakistan', 'Thailand', 'Cambodia', 'Sri Lanka'];
        // if (!excludedCountries.includes(selectedValue)) {
        //     dispatch(beneficiaryAddBeneGetBank(lang, addBeneGetBankActId, selectedValue, '', ''));
        // }
        dispatch(beneficiaryAddBeneGetBank(lang, addBeneGetBankActId, selectedValue, '', ''));
        setBankOrCashPickupIndex(0);
        setBankOrCashPickupLabel('');
        setSelectedRelationship(`${lang === 'en' ? 'OWN ACCOUNT' : 'AKAUN SENDIRI'}`);
        setBeneBank('');
        setBeneBankBranch('');
        setBeneAccountNo('');
        setBeneName('');
        setBeneMiddleName('');
        setBeneLastName('');
        setBeneAddress('');
        setBeneBSB('');
        setBenePhone('');
        setBeneCity('');
        setBeneIfsc('');
        switch (selectedValue) {
            case 'Indonesia':
                setSelectedModule('Indonesia');
                break;
            case 'Nepal':
                setSelectedModule('Nepal');
                break;
            case 'India':
                setSelectedModule('India');
                break;
            case 'Philippines':
                setSelectedModule('Philippines');
                break;
            case 'Myanmar':
                setSelectedModule('Myanmar');
                break;
            case 'Bangladesh':
                setSelectedModule('Bangladesh');
                break;
            case 'Vietnam':
                setSelectedModule('Vietnam');
                break;
            case 'Singapore':
                setSelectedModule('Singapore');
                break;
            case 'Australia':
                setSelectedModule('Australia');
                break;
            case 'Pakistan':
                setSelectedModule('Pakistan');
                break;
            case 'Thailand':
                setSelectedModule('Thailand');
                break;
            case 'Cambodia':
                setSelectedModule('Cambodia');
                break;
            case 'Sri Lanka':
                setSelectedModule('Sri Lanka');
                break;
            default:
                setSelectedModule(null); // Reset to default module or null
                break;
        }
    };

    const handleConfirmButton = () => {
        setModalVisible(false);
        let addBene: any = {
            bank_code: '',
            account_number: beneAccountNo,
            relationship: selectedRelationship,
            boname: beneName,
            bomiddlename: beneMiddleName,
            bolastname: beneLastName,
            boic: '',
            bonat: selectedCountry,
            bodob: '',
            boadd: '',
            boocc: '',
            bonob: '',
            boft: '',
            borel: '',
            bohp: '',
            bopur: '',
            bopurft: '',
            rcname: '',
            rcity: beneCity,
            raddress: beneAddress,
            rcphone: benePhone,
            rcbank: beneBank,
            rCashType: bankOrCashPickupIndex,
            rbanglabranch: beneBankBranch,
            rIFSC: beneIfsc,
            rbsb: beneBSB,
            ifscv: 0
        }
        dispatch(beneficiaryAddBene(lang, addBeneActId, addBene));
    };

    const handleOkButton = () => {
        if (alertDialog) {
            setAlertDialog(false);
            dispatch(addBeneDimissMessage());
            navigate('/kirim/beneficiary');
        }
        if (errorDialog) {
            setErrorDialog(false);
            dispatch(addBeneDimissMessage());
            dispatch(dismissCheckifscMessage());
        }
    }

    type AlertDialogProps = {
        open: boolean;
    };

    const ConfirmDialog: FC<AlertDialogProps> = ({ open }) => {
        const handleCancelClick = () => {

            setModalVisible(false);
        };

        return (
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}>
                    CONFIRM BENEFICIARY DETAILS
                </DialogTitle>
                <DialogContent>
                    {beneName || beneMiddleName || beneLastName ? (
                        <Typography variant="subtitle1">
                            Beneficiary Name: {`${beneName} ${beneMiddleName} ${beneLastName}` || ''}
                        </Typography>
                    ) : null}
                    {benePhone && (
                        <Typography variant="subtitle1">Beneficiary Phone: {benePhone || ""}</Typography>
                    )}
                    {selectedRelationship && (
                        <Typography variant="subtitle1">Beneficiary Relationship: {selectedRelationship || ""}</Typography>
                    )}
                    {beneBank && (
                        <Typography variant="subtitle1">Beneficiary Bank: {beneBank || ""}</Typography>
                    )}
                    {beneBankBranch && (
                        <Typography variant="subtitle1">Beneficiary Bank Branch: {beneBankBranch || ""}</Typography>
                    )}
                    {beneBSB && (
                        <Typography variant="subtitle1">BSB: {beneBSB ? beneBSB : ''}</Typography>
                    )}
                    {beneIfsc && (
                        <Typography variant="subtitle1">IFSC: {beneIfsc ? beneIfsc : ''}</Typography>
                    )}
                    {selectedCountry && (
                        <Typography variant="subtitle1">Beneficiary Country: {selectedCountry || ""}</Typography>
                    )}
                    {beneAccountNo && (
                        <Typography variant="subtitle1">Beneficiary Account Number: {beneAccountNo || ""}</Typography>
                    )}
                    {beneAddress && (
                        <Typography variant="subtitle1">Beneficiary Address: {beneAddress || ""}</Typography>
                    )}
                    {beneCity && (
                        <Typography variant="subtitle1">Beneficiary City: {beneCity || ""}</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClick} >CANCEL</Button>
                    <Button onClick={handleConfirmButton} color="primary" autoFocus>
                        {getLabelValue(lang, 'kirim.bookrate.confirm.button')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const AlertDialog: FC<AlertDialogProps> = ({ open }) => {
        return (
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    {showMessageTitle}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOkButton} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    const ErrorDialog: FC<AlertDialogProps> = ({ open }) => {
        return (
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Add Beneficiary Error
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOkButton} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    const renderModuleContent = () => {
        switch (selectedModule) {
            case "Indonesia":
                return (
                    <Container>
                        <Autocomplete
                            value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                            onChange={(event, newValue) => {
                                setBeneBank(newValue ? newValue.value : '');
                            }}
                            options={addbenebanklist}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                            )}
                        />
                        <TextField
                            sx={{ mt: 3 }}
                            label={getLabelValue(lang, 'kirim.fpx.accno')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={beneAccountNo}
                            onChange={(e) => setBeneAccountNo(e.target.value)}
                        />
                    </Container>
                );
            case "Nepal":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                    setBeneBankBranch('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <Autocomplete
                                    sx={{ mt: 3 }}
                                    value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                    onChange={(event, newValue) => {
                                        setBeneBank(newValue ? newValue.value : '');
                                    }}
                                    options={addbenebanklist}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                                    )}
                                />
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneAccountNo}
                                    onChange={(e) => setBeneAccountNo(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.middlename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneMiddleName}
                                    onChange={(e) => setBeneMiddleName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.lastname')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneLastName}
                                    onChange={(e) => setBeneLastName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benebank')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneBank}
                                    onChange={(e) => setBeneBank(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}
                    </Container>
                );
            case "India":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                    setBeneBankBranch('');
                                    setBeneIfsc('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <FormControlLabel sx={{ mt: 1, mb: 1 }}
                                    control={
                                        <Switch
                                            checked={selectIfsc}
                                            onChange={handleSwitchChange}
                                            name="selectIfscSwitch"
                                            color="primary"
                                        />
                                    }
                                    label={selectIfsc ? 'Insert IFSC' : 'Select IFSC'}
                                />
                                {selectIfsc ? (
                                    <div>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    label={getLabelValue(lang, 'kirim.beneficiary.ifsccode')}
                                                    variant="outlined"
                                                    value={beneIfsc}
                                                    onChange={(e) => setBeneIfsc(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={async () => handleCheckIFSC()}
                                                    style={{ backgroundColor: theme.palette.primary.main }}
                                                >
                                                    CHECK
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {loading1 ? (
                                            <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                                                <CircularProgress color="primary" />
                                            </Box>
                                        ) : null}
                                        {!error1 && ifsccodelist[0]?.bank ? (
                                            <div>
                                                <Typography variant="h5" component="div" color="primary" align="center" style={{ fontWeight: 'bold' }} sx={{ mt: 2, mb: 0.5 }}>
                                                    SILA SEMAK BUTIRAN IFSC
                                                </Typography>

                                                <Typography variant="subtitle1">Bank Name:</Typography>
                                                <Typography variant="body1" sx={{ mb: 0.5 }}>{ifsccodelist[0]?.bank}</Typography>

                                                <Typography variant="subtitle1">Bank Branch:</Typography>
                                                <Typography variant="body1">{ifsccodelist[0]?.branch}</Typography>
                                            </div>
                                        ) : null}
                                        {error1 && (
                                            <Grid
                                                sx={{ mt: 2 }}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Typography textAlign="center" color={colors.primary[500]}>
                                                    {message1 || ''}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <Autocomplete
                                            sx={{ mt: 2 }}
                                            value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                            onChange={(event, newValue) => {
                                                setBeneBank(newValue ? newValue.value : '');
                                                setBranchSelected(false);
                                                setBeneBankBranch('');
                                                setBeneIfsc('');
                                                dispatch(beneficiaryAddBeneGetBank(lang, addBeneGetBankActId, 'India', newValue ? newValue.value : '', ''));
                                            }}
                                            options={addbenebanklist}
                                            getOptionLabel={(option) => option ? option.label : ''}
                                            renderInput={(params) => (
                                                <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" fullWidth />
                                            )}
                                        />
                                        {Array.isArray(addbenebranchlist) && addbenebranchlist.length > 0 ? (
                                            <div>
                                                <Autocomplete
                                                    sx={{ mt: 3 }}
                                                    value={addbenebranchlist.find(branch => branch.value === beneBankBranch) || null}
                                                    onChange={(event, newValue) => {
                                                        setBeneBankBranch(newValue ? newValue.value : '');
                                                        dispatch(beneficiaryAddBeneGetBank(lang, addBeneGetBankActId, 'India', beneBank, newValue ? newValue.value : ''));
                                                        setBranchSelected(true);
                                                    }}
                                                    options={addbenebranchlist}
                                                    getOptionLabel={(option) => option ? option.label : ''}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select Bank Branch" variant="outlined" fullWidth />
                                                    )}
                                                />
                                                {beneIfsc && (
                                                    <div>
                                                        <TextField
                                                            sx={{ mt: 3 }}
                                                            fullWidth
                                                            label={getLabelValue(lang, 'kirim.beneficiary.ifsc')}
                                                            variant="outlined"
                                                            value={beneIfsc}
                                                            disabled
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ) :
                                            null
                                        }
                                    </div>
                                )}
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneAccountNo}
                                    onChange={(e) => setBeneAccountNo(e.target.value)}
                                />
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benebank')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneBank}
                                    onChange={(e) => setBeneBank(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}

                    </Container>
                );
            case "Philippines":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                    setBeneBankBranch('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <Autocomplete
                                    sx={{ mt: 3 }}
                                    value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                    onChange={(event, newValue) => {
                                        setBeneBank(newValue ? newValue.value : '');
                                    }}
                                    options={addbenebanklist}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                                    )}
                                />
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneAccountNo}
                                    onChange={(e) => setBeneAccountNo(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.middlename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneMiddleName}
                                    onChange={(e) => setBeneMiddleName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.lastname')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneLastName}
                                    onChange={(e) => setBeneLastName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.middlename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneMiddleName}
                                    onChange={(e) => setBeneMiddleName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.lastname')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneLastName}
                                    onChange={(e) => setBeneLastName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benebank')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneBank}
                                    onChange={(e) => setBeneBank(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}
                    </Container>
                );
            case "Myanmar":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                    setBeneBankBranch('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <Autocomplete
                                    sx={{ mt: 3 }}
                                    value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                    onChange={(event, newValue) => {
                                        setBeneBank(newValue ? newValue.value : '');
                                    }}
                                    options={addbenebanklist}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                                    )}
                                />
                                {beneBank === 'Kanbawza Bank Ltd (KBZ)' ? (
                                    <div>
                                        <TextField
                                            sx={{ mt: 3 }}
                                            label={getLabelValue(lang, 'kirim.fpx.accno')}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={beneAccountNo}
                                            onChange={(e) => setBeneAccountNo(e.target.value)}
                                        />
                                        <TextField
                                            label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={benePhone}
                                            onChange={(e) => setBenePhone(e.target.value)}
                                            type="tel"
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <TextField
                                            sx={{ mt: 3 }}
                                            label={getLabelValue(lang, 'kirim.fpx.accno')}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={beneAccountNo}
                                            onChange={(e) => setBeneAccountNo(e.target.value)}
                                        />
                                        <TextField
                                            label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={beneName}
                                            onChange={(e) => setBeneName(e.target.value)}
                                        />
                                        <TextField
                                            label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={benePhone}
                                            onChange={(e) => setBenePhone(e.target.value)}
                                            type="tel"
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benebank')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneBank}
                                    onChange={(e) => setBeneBank(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}
                    </Container>
                );
            case "Bangladesh":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                    setBeneBankBranch('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <Autocomplete
                                    sx={{ mt: 3 }}
                                    value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                    onChange={(event, newValue) => {
                                        setBeneBank(newValue ? newValue.value : '');
                                        setBeneBankBranch('');
                                        dispatch(beneficiaryAddBeneGetBank(lang, addBeneGetBankActId, 'Bangladesh', newValue ? newValue.value : '', ''));
                                    }}
                                    options={addbenebanklist}
                                    getOptionLabel={(option) => option ? option.label : ''}
                                    renderInput={(params) => (
                                        <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" fullWidth />
                                    )}
                                />
                                {Array.isArray(addbenebranchlist) && addbenebranchlist.length > 0 ? (
                                    <div>
                                        <Autocomplete
                                            sx={{ mt: 3 }}
                                            value={addbenebranchlist.find((branch) => branch.value === beneBankBranch) || null}
                                            onChange={(event, newValue) => {
                                                setBeneBankBranch(newValue ? newValue.value : '');
                                            }}
                                            options={addbenebranchlist}
                                            getOptionLabel={(option) => option ? option.label : ''}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Select Bank Branch" variant="outlined" fullWidth />
                                            )}
                                        />
                                        <TextField
                                            sx={{ mt: 3 }}
                                            label={getLabelValue(lang, 'kirim.fpx.accno')}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={beneAccountNo}
                                            onChange={(e) => setBeneAccountNo(e.target.value)}
                                        />
                                    </div>
                                ) : (
                                    null
                                )}
                                <TextField sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                            </div>
                        ) : (
                            <div>
                                {
                                    Array.isArray(bankpinlist) && bankpinlist.length > 0 && (
                                        <Autocomplete
                                            sx={{ mt: 3 }}
                                            value={bankpinlist.find((bank) => bank.value === beneBank) || null}
                                            onChange={(event, newValue) => {
                                                setBeneBank(newValue ? newValue.value : '');
                                            }}
                                            options={bankpinlist}
                                            getOptionLabel={(option) => option ? option.label : ''}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Search IME agents" variant="outlined" fullWidth />
                                            )}
                                            disabled={!(Array.isArray(bankpinlist) && bankpinlist.length > 0)}
                                        />
                                    )
                                }
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}
                    </Container>
                );
            case "Vietnam":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                    setBeneBankBranch('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <Autocomplete
                                    sx={{ mt: 3 }}
                                    value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                    onChange={(event, newValue) => {
                                        setBeneBank(newValue ? newValue.value : '');
                                        setBeneBankBranch('');
                                        dispatch(beneficiaryAddBeneGetBank(lang, addBeneGetBankActId, 'Vietnam', newValue?.value, ''));
                                    }}
                                    options={addbenebanklist}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                                    )}
                                />
                                {Array.isArray(addbenebranchlist) && addbenebranchlist.length > 0 ? (
                                    <div>
                                        <Autocomplete
                                            sx={{ mt: 3 }}
                                            value={addbenebranchlist.find(branch => branch.value === beneBankBranch) || null}
                                            onChange={(event, newValue) => {
                                                setBeneBankBranch(newValue ? newValue.value : '');
                                            }}
                                            options={addbenebranchlist}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Select Bank Branch" variant="outlined" />
                                            )}
                                        />
                                    </div>
                                ) : (
                                    null
                                )}
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneAccountNo}
                                    onChange={(e) => setBeneAccountNo(e.target.value)}
                                />
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benebank')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneBank}
                                    onChange={(e) => setBeneBank(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}
                    </Container>
                );
            case "Singapore":
                return (
                    <Container>
                        <Autocomplete
                            value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                            onChange={(event, newValue) => {
                                setBeneBank(newValue ? newValue.value : '');
                            }}
                            options={addbenebanklist}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                            )}
                        />
                        <TextField
                            sx={{ mt: 3 }}
                            label={getLabelValue(lang, 'kirim.fpx.accno')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={beneAccountNo}
                            onChange={(e) => setBeneAccountNo(e.target.value)}
                        />
                        <TextField
                            label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={beneName}
                            onChange={(e) => setBeneName(e.target.value)}
                        />
                        <TextField
                            label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={benePhone}
                            onChange={(e) => setBenePhone(e.target.value)}
                            type="tel"
                        />
                    </Container>
                );
            case "Australia":
                return (
                    <Container>
                        <Autocomplete
                            value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                            onChange={(event, newValue) => {
                                setBeneBank(newValue ? newValue.value : '');
                            }}
                            options={addbenebanklist}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                            )}
                        />
                        <TextField
                            sx={{ mt: 3 }}
                            label={'BSB'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={beneBSB}
                            onChange={(e) => setBeneBSB(e.target.value)}
                        />
                        <TextField
                            sx={{ mt: 2 }}
                            label={getLabelValue(lang, 'kirim.fpx.accno')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={beneAccountNo}
                            onChange={(e) => setBeneAccountNo(e.target.value)}
                        />
                        <TextField
                            label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={beneName}
                            onChange={(e) => setBeneName(e.target.value)}
                        />
                        <TextField
                            label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={benePhone}
                            onChange={(e) => setBenePhone(e.target.value)}
                            type="tel"
                        />
                    </Container>
                );
            case "Pakistan":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <Autocomplete
                                    sx={{ mt: 3 }}
                                    value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                    onChange={(event, newValue) => {
                                        setBeneBank(newValue ? newValue.value : '');
                                    }}
                                    options={addbenebanklist}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                                    )}
                                />
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneAccountNo}
                                    onChange={(e) => setBeneAccountNo(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benebank')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneBank}
                                    onChange={(e) => setBeneBank(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}
                    </Container>
                );
            case "Thailand":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <Autocomplete
                                    sx={{ mt: 3 }}
                                    value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                    onChange={(event, newValue) => {
                                        setBeneBank(newValue ? newValue.value : '');
                                    }}
                                    options={addbenebanklist}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                                    )}
                                />
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneAccountNo}
                                    onChange={(e) => setBeneAccountNo(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benebank')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneBank}
                                    onChange={(e) => setBeneBank(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}
                    </Container>
                );
            case "Cambodia":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <Autocomplete
                                    sx={{ mt: 3 }}
                                    value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                    onChange={(event, newValue) => {
                                        setBeneBank(newValue ? newValue.value : '');
                                    }}
                                    options={addbenebanklist}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                                    )}
                                />
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneAccountNo}
                                    onChange={(e) => setBeneAccountNo(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benebank')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneBank}
                                    onChange={(e) => setBeneBank(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}
                    </Container>
                );
            case "Sri Lanka":
                return (
                    <Container>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="5px"
                            mb="10px"
                            ml="5px"
                            mr="5px"
                        >
                            {"Select Bank or Cash Pickup"}
                        </Box>
                        <FormControl fullWidth>
                            <Select
                                value={bankOrCashPickupLabel || bankorcaspickupList[0].value}
                                onChange={(event) => {
                                    const itemValue = event.target.value;
                                    setBankOrCashPickupLabel(itemValue);
                                    const selectedItem = bankorcaspickupList.find((item: { value: any; }) => item.value === itemValue);
                                    setBankOrCashPickupIndex(selectedItem.value);
                                    setBeneBank('');
                                }}
                            >
                                {bankorcaspickupList.map((item: any, index: React.Key) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {bankOrCashPickupLabel === "1" ? (
                            <div>
                                <Autocomplete
                                    sx={{ mt: 3 }}
                                    value={addbenebanklist.find((bank: { value: string; }) => bank.value === beneBank) || null}
                                    onChange={(event, newValue) => {
                                        setBeneBank(newValue ? newValue.value : '');
                                    }}
                                    options={addbenebanklist}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField {...params} label={getLabelValue(lang, 'kirim.fpx.bankname')} variant="outlined" />
                                    )}
                                />
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneAccountNo}
                                    onChange={(e) => setBeneAccountNo(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                            </div>
                        ) : (
                            <div>
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.beneficiary.benename')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneName}
                                    onChange={(e) => setBeneName(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benephone')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={benePhone}
                                    onChange={(e) => setBenePhone(e.target.value)}
                                    type="tel"
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benebank')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneBank}
                                    onChange={(e) => setBeneBank(e.target.value)}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.beneficiary.benecity')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={beneCity}
                                    onChange={(e) => setBeneCity(e.target.value)}
                                />
                            </div>
                        )}
                    </Container>
                );
            default:
                return (
                    <Typography>{'Please Select ' + getLabelValue(lang, 'kirim.header.beneficiary.add.country')}</Typography>
                );
        }
    };

    return (
        <Box m="20px">
            <Box
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'kirim.beneficiary.title')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >

                        <Card
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <Box
                                    sx={{ textAlign: 'left' }}
                                    mt="5px"
                                    mb="10px"
                                    ml="5px"
                                    mr="5px"
                                >
                                    {getLabelValue(lang, 'kirim.beneficiary.benecountry')}
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                        value={selectedCountry}
                                        onChange={(event) => handleChangeCountry(event.target.value)}
                                    >
                                        {countryList.map((country: any, index: React.Key) => (
                                            <MenuItem key={index} value={country.value}>
                                                {country.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Box
                                    sx={{ textAlign: 'left', mt: 2 }}
                                    mt="5px"
                                    mb="10px"
                                    ml="5px"
                                    mr="5px"
                                >
                                    {getLabelValue(lang, 'kirim.header.beneficiary.add.rship')}
                                </Box>
                                {relationshipList.length > 0 ? (
                                    <FormControl fullWidth>
                                        <Select
                                            value={selectedRelationship || `${lang === 'en' ? 'OWN ACCOUNT' : 'AKAUN SENDIRI'}`}
                                            onChange={(event: any) => {
                                                setSelectedRelationship(event.target.value);
                                            }}
                                        >
                                            {relationshipList.map((relationship: any, index: React.Key) => (
                                                <MenuItem key={index} value={relationship.value}>
                                                    {lang === 'en' ? relationship.label_en : relationship.label_ms}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                                        <CircularProgress color="primary" />
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                        <Card
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                {renderModuleContent()}
                                <Button
                                    type="submit"
                                    fullWidth
                                    onClick={async () => setModalVisible(true)}
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={loading}
                                    endIcon={loading ? <CircularProgress size={20} /> : null}
                                >
                                    {getLabelValue(lang, 'kirim.bookrate.confirm.button')}
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
                {modalVisible && <ConfirmDialog open={modalVisible} />}
                {alertDialog && <AlertDialog open={alertDialog} />}
                {errorDialog && <ErrorDialog open={errorDialog} />}
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) => ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)',
        overflow: 'auto'
    },
    cardStyle: {
        mb: 2,
        borderRadius: 2
    }
})

export default AddBeneficiary;
