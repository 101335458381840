import {Box, Button, CircularProgress, Typography} from "@mui/material";
import Header from "../../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../theme";
import { RootState, useAppDispatch } from "../../store/store";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { getLabelValue } from "../../utils";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTopUpTransaction, unMountTopUpPayPage } from "../../actions/TopUpAction";
import { useSelector } from "react-redux";
import DialogAlert from "../../components/DialogAlert";

const TopUpFPX = () => {
    const {state} = useLocation();
    const params = state;
    const { lang, user } = useSelector((state: RootState) => state.user);
    const { loading, error, message } = useSelector((state: RootState) => state.topup);
    const navigate = useNavigate();
    
    const dispatch = useAppDispatch();

    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box component="main" sx={{flexGrow: 1}}>
                <Box sx={{ mt: 3, ml: 3 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Header title={getLabelValue(lang, 'home.header.button.topup')} subtitle={""} />
                    </Box>
                    <Container maxWidth="lg" sx={{ mb: 3 }}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} md={7} lg={7}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Box sx={{ mr: 1, justifyContent: 'center',　alignItems: 'center' }}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 20 }}>
                                                {`${getLabelValue(lang, 'topup.amount.title')}: `}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ ml: 1, justifyContent: 'center',　alignItems: 'center' }}>
                                            <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: 20, color: 'red' }}>
                                                {`RM ${params.amount}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                                <Paper sx={{ mt: 2, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>             
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h4">
                                            {params.paymentMode[lang].title}
                                        </Typography>
                                        </Box>
                                        <Typography variant="h6">
                                            {params.paymentMode[lang].description}
                                        </Typography>
                                    
                                    <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                                        <Button 
                                            variant="contained"
                                            color="primary"
                                            onClick={() => dispatch(createTopUpTransaction(params, lang, user))}
                                            endIcon={!loading ? <ArrowForwardIcon /> : <CircularProgress size={20} />}
                                            disabled={loading}
                                        >
                                            {loading ? 'Loading...' : getLabelValue(lang, 'topup.footer.button')}
                                        </Button>
                                    </Box>                         
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>                    
                </Box>
            </Box>
        </Box>
        {error &&
            <DialogAlert
                open={error}
                onSuccess={() => {
                    dispatch(unMountTopUpPayPage());
                    navigate(-1);
                }}
                title={'Attention'}
                content={new Array(message)}
                yesButton={'OK'}
                // reportIssue={error.reportIssue?.show}
                // req_idx={error.reportIssue?.idx}
                // onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
            />
        }
        </ThemeProvider>
    )
}

export default TopUpFPX;
