import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { BackendResponse, IError } from '../../type';
import { callBackendAPI, logError } from '../../utils';
import { CONFIG } from '../../config';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import Footer from '../../components/Footer';

type IItems = {
  label: string;
  value: number;
  [key: string]: any | undefined;
}

const colors =  tokens();

const RegisterEkycStatus = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [idNumber, setIDNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IError>({ error: false, message: ''})
  const navigate = useNavigate();
  
  const handleAccountRegistrationPreChecks = async () : Promise<void> => {
    try {
      if (phoneNumber === '')
        return setError({ error: true, message: 'Handphone No. is required'});
      if (idNumber === '')
        return setError({ error: true, message: 'ID Number is required'});
      if (idNumber.includes('-'))
        return setError({ error: true, message: 'ID Number should not have "-" characters'})
      if (error.error)
        setError({ error: false, message: ''});

      setLoading(true);
      let headers: HeadersInit = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }      
      let input: any = {
        ver: CONFIG?.ver,
        act: 955,
        p1: phoneNumber.trim(),
        //p2: selectedIdType?.label,
        p3: idNumber.trim(),
        m1: 'Check Register User Ekyc Status',
        m2: '',
        user: {}
      }
      let resultFromAPI: BackendResponse | undefined = await callBackendAPI('register', 'POST', headers, input);
      if (resultFromAPI === undefined) {
        setLoading(false);
        return setError({ error: true, message: "001: Something went wrong, please try again."});
      }

      if (resultFromAPI?.error !== undefined) {
        setLoading(false);
        return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
      }

      if (resultFromAPI?.results) {
        let results: any = resultFromAPI.results;
        if (results?.code && results.code !== 0) {
          setLoading(false);
          return setError({
            error: true,
            message: `${results?.message || "003: Something went wrong, please try again."}`});
        }
        
        let sendOtpInput: any = {
          ver: CONFIG?.ver,
          mobileno: phoneNumber.trim(),
          purpose: "MM App Registration OTP",
        }
        let sendOtpResultFromAPI: BackendResponse | undefined = await callBackendAPI('register', 'POST', headers, sendOtpInput);
        if (sendOtpResultFromAPI === undefined) {
          setLoading(false);
          return setError({ error: true, message: "001: Something went wrong, please try again."});
        }

        if (sendOtpResultFromAPI?.error !== undefined) {
          setLoading(false);
          return setError({ error: true, message: sendOtpResultFromAPI.error || "002: Something went wrong, please try again."});
        }

        if (sendOtpResultFromAPI?.results) {
          let sendOtpResults: any = sendOtpResultFromAPI.results;
          if (sendOtpResults?.code && sendOtpResults.code !== 0) {
            setLoading(false);
            return setError({
              error: true,
              message: `${sendOtpResults?.message || "003: Something went wrong, please try again."}`});
          }

          return navigate('/registerotp', {
            state: {
              user: results.user,
              userinput: {
                phoneNumber,
                idNumber
              },
            },
          });
        }                    
      }
      setLoading(false);
      return setError({ error: false, message: "004: Something went wrong, please try again."})
    }
    catch (e) {
      logError('handleAccountRegistrationPreChecks', e);
      setLoading(false);
      return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 3,
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box mt="5px" mb="25px" ml="5px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="10px"
              overflow="hidden"
              boxShadow={2}
              >
                <img
                  alt="logo"
                  width="230px"
                  height="60px"
                  src={'../../assets/mmlogo.png'}
                  style={{ cursor: "pointer"}}
                />
            </Box>
          </Box>
          <Typography variant="h5" sx={{fontWeight: 'bold'}} color='#ef233e'>
            REGISTER
          </Typography>
          <Box
            sx={{ textAlign: 'left' }}
            mt="15px"
            mb="5px"
            ml="5px"
            mr="5px"
          >
            Please complete the information below to complete your registration
          </Box>
          <Box sx={{ mt: 1 }} minWidth={'100%'}>
            <Box
              sx={{ textAlign: 'left'  }}
              mt="5px"
              mb="5px"
              ml="5px"
              mr="5px"
            >
              Handphone No.
            </Box>
            <TextField
              margin="none"
              required
              fullWidth
              name="phone"
              placeholder="01123456789"
              type='text'
              id="phone"
              autoFocus
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            /> 
            <Box
            sx={{ textAlign: 'left' }}
            mt="15px"
            mb="5px"
            ml="5px"
            mr="5px"
            >
              ID Number
            </Box>
            <TextField
            margin="none"
            required
            fullWidth
            name="id"
            placeholder="A 1025 2321 21"
            type="text"
            id="id"
            value={idNumber}
            onChange={(e) => setIDNumber(e.target.value)}
            />
            {error.error && (
              <Typography
                sx={{
                  mt: '10px',
                  textAlign: 'center',
                  color: colors.primary[500],
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {error.message || ''}
              </Typography>
            )}           
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={loading}
              onClick={async () => await handleAccountRegistrationPreChecks()}
              endIcon={loading ? <CircularProgress size={20} /> : null}
              sx={{ mt: 3, mb: 1 }}
            >
              NEXT
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

const theme = createTheme({
  palette: {
    secondary: {
      //MMApp signature red
      main: '#ef233e',
    },
    primary: {
      main: '#212121',
    }
  }
});

export default RegisterEkycStatus;
