export const USER_LOGIN = 'user_login';
export const USER_LOGIN_FAILED = 'user_login_failed';
export const USER_LOGIN_SUCCESSFUL = 'user_login_successful';
export const USER_CHANGE_LANGUAGE = 'user_change_language';
export const USER_PHONE_CHANGE = 'user_phone_change';
export const USER_ID_NUMBER_CHANGE = 'user_id_number_change';
export const USER_PIN_CHANGE = 'user_pin_change';
export const USER_LOG_OUT = 'user_log_out';
export const USER_LOG_OUT_FAILED = 'user_log_out_failed';
export const USER_LOG_OUT_SUCCESS = 'user_log_out_success';
export const USER_RENDER_COMPONENT = 'user_render_component';
export const USER_SIGN_UP_SUCCESSFULLY = 'user_sign_up_successfully';
export const USER_SET_LOGGED_USER = 'user_set_logged_user';
export const USER_VALIDATE_PIN = 'user_validate_pin';
export const USER_VALIDATE_PIN_FAILED = 'user_validate_pin_failed';
export const USER_VALIDATE_PIN_SUCCESS = 'user_validate_pin_success';
export const USER_UPDATE_PROFILE_IMAGE = 'user_update_profile_image';
export const USER_UNMOUNT_FORM_DATA = 'user_unmount_form_data';
export const USER_UPDATE_USER_PROFILE = 'user_update_user_profile';
export const USER_LOGIN_RBA_SUCCESSFUL = 'user_login_rba_successful';
export const USER_LOGIN_FAILED_RESET = 'user_login_failed_reset';

export const REGISTER_FETCH_NATIONALITIES_IDTYPES = 'register_fetch_nationalities_idtypes';
export const REGISTER_FETCH_NATIONALITIES_IDTYPES_FAILED = 'register_fetch_nationalities_idtypes_failed';
export const REGISTER_FETCH_NATIONALITIES_IDTYPES_SUCCESS = 'register_fetch_nationalities_idtypes_success';
export const REGISTER_RENDER_COMPONENT = 'register_render_component';
export const REGISTER_UNMOUNT = 'register_unmount';
export const REGISTER_SEND_OTP = 'register_send_otp';
export const REGISTER_SEND_OTP_SUCCESS = 'register_send_otp_success';
export const REGISTER_SEND_OTP_FAILED = 'register_send_otp_failed';
export const REGISTER_DECREASE_OTP_COUNTER = 'register_decrease_otp_counter';
export const REGISTER_OTP_CHANGE = 'register_otp_change';
export const REGISTER_OTP_CHANGEV2 = 'register_otp_changev2';
export const REGISTER_OTP_REMOVE_CHARACTERS = 'register_otp_remove_characters';
export const REGISTER_OTP_VERIFY = 'register_otp_verify';
export const REGISTER_OTP_VERIFY_SUCCESS = 'register_otp_verify_success';
export const REGISTER_OTP_VERIFY_FAILED = 'register_otp_verify_failed';
export const REGISTER_UPDATE_PIN = 'register_update_pin';
export const REGISTER_UPDATE_PIN_SUCCESS = 'register_update_pin_success';
export const REGISTER_UPDATE_PIN_FAILED = 'register_update_pin_failed';
export const REGISTER_UPDATE_SELECTED_IMAGE = 'register_update_selected_image';
export const REGISTER_UPDATE_SELECTED_IMAGE_SUCCESS = 'register_update_selected_image_success';
export const REGISTER_UPDATE_SELECTED_IMAGE_FAILED = 'register_update_selected_image_failed';
export const REGISTER_PRE_CHECKS = 'register_pre_checks';
export const REGISTER_PRE_CHECKS_FAILED = 'register_pre_checks_failed';
export const REGISTER_PRE_CHECKS_SUCCESS = 'register_pre_checks_success';
export const REGISTER_UPDATE_USER_INFO = 'register_update_user_info';
export const REGISTER_OTP_VERIFY_ONLY = 'register_otp_verify_only';
export const REGISTER_OTP_VERIFY_ONLY_FAILED = 'register_otp_verify_only_failed';
export const REGISTER_OTP_VERIFY_ONLY_SUCCESS = 'register_otp_verify_only_success';

export const SHOW_PIN_VIEW = 'show_pin_view';
export const PIN_CHANGE = 'pin_change';
export const PIN_VERIFY = 'pin_verify';
export const PIN_VERIFY_FAILED = 'pin_verify_failed';
export const PIN_VERIFY_SUCCESS = 'pin_verify_success';
export const PIN_UNMOUNT = 'pin_unmount';
export const PIN_REMOVE_CHARACTERS = 'pin_remove_characters';

export const PREPAID_TRANSACTION = 'prepaid_transaction';
export const PREPAID_TRANSACTION_SUCCESS = 'prepaid_transaction_success';
export const PREPAID_TRANSACTION_FAILED = 'prepaid_transaction_failed';
export const PREPAID_UNMOUNT = 'prepaid_unmount';
export const PREPAID_PHONE_NUMBER_CHANGE = 'prepaid_phone_number_change';
export const PREPAID_LIST_FETCH = 'prepaid_list_fetch';
export const PREPAID_LIST_FETCH_SUCCESS = 'prepaid_list_fetch_success';
export const PREPAID_LIST_FETCH_FAILED = 'prepaid_list_fetch_failed';

export const TOPUP_TRANSACTION = 'topup_transaction';
export const TOPUP_TRANSACTION_FAILED = 'topup_transaction_failed';
export const TOPUP_TRANSACTION_SUCCESS = 'topup_transaction_success';
export const TOPUP_UNMOUNT = 'topup_unmount';
export const TOPUP_FAILED = 'topup_failed';
export const TOPUP_SUCCESS = 'topup_success';
export const TOPUP_PENDING = 'topup_pending';
export const TOPUP_FETCH = 'topup_fetch';
export const TOPUP_AMOUNT_CHANGE = 'topup_amount_change';

export const FPX_BANK_LIST_FETCH = 'fpx_bank_list_fetch';
export const FPX_BANK_LIST_FETCH_FAILED = 'fpx_bank_list_fetch_failed';
export const FPX_BANK_LIST_FETCH_SUCCESS = 'fpx_bank_list_fetch_success';
export const FPX_BANK_ADD = 'fpx_bank_add';
export const FPX_BANK_ADD_FAILED = 'fpx_bank_add_failed';
export const FPX_BANK_ADD_SUCCESS = 'fpx_bank_add_success';
export const FPX_UPDATE_USER_INFO = 'fpx_update_user_info';
export const FPX_RENDER_COMPONENT = 'fpx_render_componenet';

export const BOOK_RATE_FETCH = 'book_rate_fetch';
export const BOOK_RATE_FETCH_FAILED = 'book_rate_fetch_failed';
export const BOOK_RATE_FETCH_SUCCESS = 'book_rate_fetch_success';
export const ALL_RATE_FETCH = 'all_rate_fetch';
export const ALL_RATE_FETCH_FAILED = 'all_rate_fetch_failed';
export const ALL_RATE_FETCH_SUCCESS = 'all_rate_fetch_success';
export const BOOK_RATE_CHANGE_COUNTRY = 'book_rate_change_country';
export const BOOK_RATE_CHANGE_PROVIDER = 'book_rate_change_provider';
export const BOOK_RATE_CHANGE_PROVIDER_LABEL = 'book_rate_change_provider_label';
export const BOOK_RATE_CHANGE_AMOUNTRM = 'book_rate_change_amountrm';
export const BOOK_RATE_SHOW_MESSAGE = 'book_rate_show_message';
export const BOOK_RATE_CHANGE_BENE = 'book_rate_change_bene';
export const BOOK_RATE_CHANGE_PURPOSE = 'book_rate_change_purpose';
export const BOOK_RATE_CREATE = 'book_rate_create';
export const BOOK_RATE_CREATE_FAILED = 'book_rate_create_failed';
export const BOOK_RATE_CREATE_SUCCESS = 'book_rate_create_success';
export const BOOK_RATE_PRE_CREATE = 'book_rate_pre_create';
export const BOOK_RATE_PRE_CREATE_FAILED = 'book_rate_pre_create_failed';
export const BOOK_RATE_PRE_CREATE_SUCCESS = 'book_rate_pre_create_success';
export const BOOK_RATE_CONFIRM_CREATE = 'book_rate_confirm_create';
export const BOOK_RATE_CONFIRM_CREATE_FAILED = 'book_rate_confirm_create_failed';
export const BOOK_RATE_CONFIRM_CREATE_SUCCESS = 'book_rate_confirm_create_success';
export const BOOK_RATE_CONFIRM_CREATE_EXPIRED = 'book_rate_confirm_create_expired'
export const BOOK_RATE_DISMISS_MESSAGE = 'book_rate_dismiss_message';
export const BOOK_RATE_POINT_REDEEM = 'book_rate_point_redeem';
export const BOOK_RATE_CONVERT_POINT = 'book_rate_convert_point';
export const BOOK_RATE_CONVERT_MONEY = 'book_rate_convert_money';
export const BOOK_RATE_CANCEL = 'book_rate_cancel';
export const BOOK_RATE_CANCEL_FAILED = 'book_rate_cancel_failed';
export const BOOK_RATE_CANCEL_SUCCESS = 'book_rate_cancel_success';
export const BOOK_RATE_CANCEL_DISMISS_MESSAGE = 'book_rate_cancel_dismiss_message'
export const BOOK_RATE_QUEUE = 'book_rate_queue';
export const BOOK_RATE_QUEUE_FAILED = 'book_rate_queue_failed';
export const BOOK_RATE_QUEUE_SUCCESS = 'book_rate_queue_success';
export const CLEAR_SELECTED_BENE = 'clear_selected_bene';
export const RESET_BOOK_RATE_STATE = 'reset_book_rate_state';



export const TRANSACTION_HISTORY_FETCH = 'transaction_history_fetch';
export const TRANSACTION_HISTORY_FETCH_SUCCESS = 'transaction_history_fetch_success';
export const TRANSACTION_HISTORY_FETCH_FAILED = 'transaction_history_fetch_failed';
export const TRANSACTION_DOREFUND_FETCH = 'transaction_dorefund_fetch';
export const TRANSACTION_DOREFUND_FETCH_FAILED = 'transaction_dorefund_fetch_failed';
export const TRANSACTION_DOREFUND_FETCH_SUCCESS = 'transaction_dorefund_fetch_success';
export const TRANSACTION_DO_CANCEL = 'transaction_do_cancel';
export const TRANSACTION_DO_CANCEL_SUCCESS = 'transaction_do_cancel_success';
export const TRANSACTION_DO_CANCEL_FAILED = 'transaction_do_cancel_failed';
export const TRANSACTION_HISTORY_RENDER_COMPONENT = 'transaction_history_render_componenet';

export const PAY_NOW_FETCH_BOOKINGS = 'pay_now_fetch_bookings';
export const PAY_NOW_FETCH_BOOKINGS_SUCCESS = 'pay_now_fetch_bookings_success';
export const PAY_NOW_FETCH_BOOKINGS_FAILED = 'pay_now_fetch_bookings_failed';
export const PAY_NOW_WALLET = 'pay_now_wallet';
export const PAY_NOW_WALLET_FAILED = 'pay_now_wallet_failed';
export const PAY_NOW_DISMISS_ERROR = 'pay_now_dismiss_error';
export const PAY_NOW_FPX = 'pay_now_fpx';
export const PAY_NOW_FPX_FAILED = 'pay_now_fpx_failed';
export const PAY_NOW_FPX_STATUS = 'pay_now_fpx_status';
export const PAY_NOW_FPX_STATUS_FAILED = 'pay_now_fpx_status_failed';
export const PAY_NOW_FPX_STATUS_SUCCESS = 'pay_now_status_success';

export const BENEFICIARY_FETCH = 'beneficiary_fetch';
export const BENEFICIARY_FETCH_SUCCESS = 'beneficiary_fetch_success';
export const BENEFICIARY_FETCH_FAILED = 'beneficiary_fetch_failed';
export const BENEFICIARY_CHANGE_COUNTRY = 'beneficiary_change_country';
export const BENEFICIARY_RELATIONSHIP_COUNTRY = 'beneficiary_relationship_country';
export const BENEFICIARY_CREATE = 'beneficiary_create';
export const BENEFICIARY_CREATE_SUCCESS = 'beneficiary_create_success';
export const BENEFICIARY_CREATE_FAILED = 'beneficiary_create_failed';
export const BENEFICIARY_ADD_SETTING_FETCH = 'beneficiary_add_setting_fetch';
export const BENEFICIARY_ADD_SETTING_FETCH_FAILED = 'beneficiary_add_setting_fetch_failed';
export const BENEFICIARY_ADD_SETTING_FETCH_SUCCESS = 'beneficiary_add_setting_fetch_success';
export const BENEFICIARY_ADD_BANK_FETCH = 'beneficiary_add_bank_fetch';
export const BENEFICIARY_ADD_BANK_FETCH_FAILED = 'beneficiary_add_bank_fetch_failed';
export const BENEFICIARY_ADD_BANK_FETCH_SUCCESS = 'beneficiary_add_bank_fetch_success';
export const BENEFICIARY_ADD = 'beneficiary_add';
export const BENEFICIARY_ADD_FAILED = 'beneficiary_add_failed';
export const BENEFICIARY_ADD_SUCCESS = 'beneficiary_add_success';
export const BENEFICIARY_RENDER_COMPONENT = 'beneficiary_render_component';
export const BENEFICIARY_ADD_DISMISS_MESSAGE = 'beneficiary_add_dismiss_message';
export const BENEFICIARY_FILTER = 'beneficiary_filter';
export const BENEFICIARY_FETCH_FILTER = 'beneficiary_fetch_filter';
export const BENEFICIARY_CHECK = 'beneficiary_check';
export const BENEFICIARY_CHECK_FAILED = 'beneficiary_check_failed';
export const BENEFICIARY_CHECK_SUCCESS = 'beneficiary_check_success';
export const BENEFICIARY_CHECK_DISMISS_MESSAGE = 'beneficiary_check_dismiss_message'
//// india banks
export const CHECK_IFSCCODE_FAILED = 'check_ifsccode_failed';
export const CHECK_IFSCCODE_SUCCESS = 'check_ifsccode_success';
export const CHECK_IFSCCODE = 'check_ifsccode';
export const CHECK_IFSCCODE_DISMISS_MESSAGE = 'check_ifsccode_dismiss_message'
//// check bkash 
export const CHECK_BKASH_FAILED = 'check_bkash_failed';
export const CHECK_BKASH_SUCCESS = 'check_bkash_success';
export const CHECK_BKASH = 'check_bkash';

export const MONEYPIN_HISTORY_FETCH = 'moneypin_history_fetch';
export const MONEYPIN_HISTORY_FETCH_FAILED = 'moneypin_history_fetch_failed';
export const MONEYPIN_HISTORY_FETCH_SUCCESS = 'moneypin_history_fetch_success';
export const MONEYPIN_SCREEN_UNMOUNT = 'moneypin_screen_unmount';
export const MONEYPIN_UPDATE_READ = 'moneypin_update_read';
export const MONEYPIN_UPDATE_READ_FAILED = 'moneypin_update_read_failed';
export const MONEYPIN_UPDATE_READ_SUCCESS = 'moneypin_update_read_success';
export const MONEYPIN_DELETE_MESSAGE = 'moneypin_delete_message';
export const MONEYPIN_DELETE_MESSAGE_FAILED = 'moneypin_delete_message_failed';
export const MONEYPIN_DELETE_MESSAGE_SUCCESS = 'moneypin_delete_message_success';
export const MONEYPIN_REDEEM = 'moneypin_redeem';
export const MONEYPIN_REDEEM_FAILED = 'moneypin_redeem_failed';
export const MONEYPIN_REDEEM_SUCCESS = 'moneypin_redeem_success';
export const MONEYPIN_SHOW_MESSAGE = 'moneypin_show_message';

export const HOME_GET_BALANCE = 'home_get_balance';
export const HOME_GET_BALANCE_FAILED = 'home_get_balance_failed';
export const HOME_GET_BALANCE_SUCCESS = 'home_get_balance_success';
export const HOME_DISMISS_ERROR = 'home_dismiss_error';
export const HOME_UPDATE_WALLET_BALANCE = 'home_update_wallet_balance';
export const HOME_GET_BALANCE_UNUSED_MONEYPIN = 'home_get_balance_unused_moneypin';
export const HOME_GET_BALANCE_UNUSED_MONEYPIN_SUCCESS = 'home_get_balance_unused_moneypin_success';
export const HOME_GET_BALANCE_UNUSED_MONEYPIN_FAILED = 'home_get_balance_unused_moneypin_failed';

export const HOME_GET_USER_SUCCESS = 'home_get_user_success';

export const ALL_TRANSACTIONS_FETCH = 'all_transactions_fetch';
export const ALL_TRANSACTIONS_FETCH_SUCCESS = 'all_transactions_fetch_success';
export const ALL_TRANSACTIONS_FETCH_FAILED = 'all_transactions_fetch_failed';
export const ALL_TRANSACTIONS_CHANGE_QUERY_DATE = 'all_transactions_change_query_date';
export const ALL_TRANSACTIONS_SCREEN_UNMOUNT = 'all_transactions_screen_unmount';


export const TRANSFER_CHANGE_PHONE_NUMBER = 'transfer_change_phone_number';
export const TRANSFER_GET_CONTACTS = 'transfer_get_contacts';
export const TRANSFER_GET_CONTACTS_SUCCESS = 'transfer_get_contacts_success';
export const TRANSFER_GET_CONTACTS_FAILED = 'transfer_get_contacts_failed';
export const TRANSFER_UNMOUNT = 'transfer_unmount';
export const TRANSFER_CREATE = 'transfer_create';
export const TRANSFER_CREATE_SUCCESS = 'transfer_create_success';
export const TRANSFER_CREATE_FAILED = 'transfer_create_failed';
export const TRANSFER_NAME_CHANGE = 'transfer_name_change';

export const PROFILE_VERIFY_CURRENT_PIN = 'profile_verify_current_pin';
export const PROFILE_VERIFY_CURRENT_PIN_SUCCESS = 'profile_verify_current_pin_success';
export const PROFILE_VERIFY_CURRENT_PIN_FAILED = 'profile_verify_current_pin_failed';
export const PROFILE_UNMOUNT = 'profile_unmount';
export const PROFILE_RESET_PIN = 'profile_reset_pin';
export const PROFILE_RESET_PIN_SUCCESS = 'profile_reset_pin_success';
export const PROFILE_RESET_PIN_FAILED = 'profile_reset_pin_failed';
export const PROFILE_LOST_CARD = 'profile_lost_card';
export const PROFILE_LOST_CARD_FAILED = 'profile_lost_card_failed';
export const PROFILE_LOST_CARD_SUCCESS = 'profile_lost_card_success';
export const PROFILE_FETCH = 'profile_fetch';
export const PROFILE_FETCH_SUCCESS = 'profile_fetch_success';
export const PROFILE_FETCH_FAILED = 'profile_fetch_failed';

export const RECEIPT_TRANSACTIONS_FETCH = 'receipt_transactions_fetch';
export const RECEIPT_TRANSACTIONS_FETCH_SUCCESS = 'receipt_transactions_fetch_success';
export const RECEIPT_TRANSACTIONS_FETCH_FAILED = 'receipt_transactions_fetch_failed';
export const RECEIPT_GET_TRANSACTION = 'receipt_get_transaction';
export const RECEIPT_GET_TRANSACTION_FAILED = 'receipt_get_transaction_failed';
export const RECEIPT_GET_TRANSACTION_SUCCESS = 'receipt_get_transaction_success';
export const RECEIPT_UNMOUNT = 'receipt_unmount';
export const RECEIPT_CHANGE_QUERY_DATE = 'receipt_change_query_date';
export const RECEIPT_SCREEN_UNMOUNT = 'receipt_screen_unmount';

export const PAYBILL_FETCH_BILLERS = 'paybill_fetch_billers';
export const PAYBILL_FETCH_BILLERS_SUCCESS = 'paybill_fetch_billers_success';
export const PAYBILL_FETCH_BILLERS_FAILED = 'paybill_fetch_billers_failed';

export const EKYC_CHECK = 'ekyc_check';
export const EKYC_CHECK_FAILED = 'ekyc_check_failed';
export const EKYC_CHECK_SUCCESS = 'ekyc_check_success';
export const EKYC_CHECK_SHOW_EKYC_PAGE = 'ekyc_check_show_ekyc_page';
export const EKYC_UNMOUNT = 'ekyc_unmount';
export const EKYC_VKYC_REQUIRE = 'ekyc_vkyc_require';

export const GET_REDEEM_PRODUCTS = 'GET_REDEEEM_PRODUCTS';
export const GET_REDEEM_PRODUCTS_FAILED = 'GET_REDEEM_PRODUCTS_FAILED';
export const GET_REDEEM_PRODUCTS_SUCCESS = 'GET_REDEEM_PRODUCTS_SUCCESS';
export const GET_REDEEM_VOUCHERS = 'GET_REDEEEM_VOUCHERS';
export const GET_REDEEM_VOUCHERS_FAILED = 'GET_REDEEM_VOUCHERS_FAILED';
export const GET_REDEEM_VOUCHERS_SUCCESS = 'GET_REDEEM_VOUCHERS_SUCCESS';
export const GET_REDEEM_PREPAID = 'GET_REDEEEM_PREPAID';
export const GET_REDEEM_PREPAID_FAILED = 'GET_REDEEM_PREPAID_FAILED';
export const GET_REDEEM_PREPAID_SUCCESS = 'GET_REDEEM_PREPAID_SUCCESS';
export const REDEEM_PREPAID_TRANSACTION = 'REDEEM_PREPAID_TRANSACTION';
export const REDEEM_PREPAID_TRANSACTION_FAILED = 'REDEEM_PREPAID_TRANSACTION_FAILED';
export const REDEEM_LOCATIONS = 'REDEEM_LOCATIONS';
export const REDEEM_LOCATIONS_SUCCESS = 'REDEEM_LOCATIONS_SUCCESS';
export const REDEEM_LOCATIONS_FAILED = 'REDEEM_LOCATIONS_FAILED';
export const REDEEMED_ITEMS = 'REDEEMED_ITEMS';
export const REDEEM_POINTS = 'REDEEM_POINTS';
export const REDEEM_POINTS_SUCCESS = 'REDEEM_POINTS_SUCCESS';
export const REDEEM_POINTS_FAILED = 'REDEEM_POINTS_FAILED';
export const REDEEMED_PRODUCTS = 'REDEEMED_PRODUCTS';
export const REDEEMED_PRODUCTS_SUCCESS = 'REDEEMED_PRODUCTS_SUCCESS';
export const REDEEMED_PRODUCTS_FAILED = 'REDEEMED_PRODUCTS_FAILED';
export const REDEEM_WATER = 'REDEEM_WATER';
export const REDEEM_WATER_FAILED = 'REDEEM_WATER_FAILED';
export const REDEEM_WATER_SUCCESS = 'REDEEM_WATER_SUCCESS';
export const WATER_UNMOUNT = 'WATER_UNMOUNT';
export const SHOW_QR_MODAL = 'SHOW_QR_MODAL';
export const UNMOUNT_QR_MODAL = 'UNMOUNT_QR_MODAL';
export const GET_REDEEMED_LIST = 'GET_REDEEMED_LIST';
export const GET_REDEEMED_LIST_SUCCESS = 'GET_REDEEMED_LIST_SUCCESS';
export const GET_REDEEMED_LIST_FAILED = 'GET_REDEEMED_LIST_FAILED';
export const GET_USED_POINT_HISTORY = 'GET_USED_POINT_HISTORY';
export const GET_USED_POINT_HISTORY_FAILED = 'GET_USED_POINT_HISTORY_FAILED';
export const GET_USED_POINT_HISTORY_SUCCESS = 'GET_USED_POINT_HISTORY_SUCCESS';
export const GET_RECEIVED_POINT_HISTORY = 'GET_RECEIVED_POINT_HISTORY';
export const GET_RECEIVED_POINT_HISTORY_FAILED = 'GET_RECEIVED_POINT_HISTORY_FAILED';
export const GET_RECEIVED_POINT_HISTORY_SUCCESS = 'GET_RECEIVED_POINT_HISTORY_SUCCESS';
export const REDEEM_PREPAID_UNMOUNT = 'REDEEM_PREPAID_UNMOUNT';
export const REDEEM_VOUCHER_UNMOUNT = 'REDEEM_VOUCHER_UNMOUNT';
export const GET_STAMPS_HISTORY = 'GET_STAMPS_HISTORY';
export const GET_STAMPS_HISTORY_FAILED = 'GET_STAMPS_HISTORY_FAILED';
export const GET_STAMPS_HISTORY_SUCCESS = 'GET_STAMPS_HISTORY_SUCCESS';
export const FETCH_STAMPS_LIST = 'FETCH_STAMPS_LIST';
export const FETCH_STAMPS_LIST_FAILED = 'FETCH_STAMPS_LIST_FAILED';
export const FETCH_STAMPS_LIST_SUCCESS = 'FETCH_STAMPS_LIST_SUCCESS';
export const PURCHASE_CASH_VOUCHER = 'PURCHASE_CASH_VOUCHER';
export const PURCHASE_CASH_VOUCHER_FAILED = 'PURHCASE_CASH_VOUCHER_FAILED';
export const PURCHASE_CASH_VOUCHER_SUCCESS = 'PURCHASE_CASH_VOUCHER_SUCCESS';
export const VOUCHER_CHECK_PIN = 'VOUCHER_CHECK_PIN';
export const VOUCHER_CHECK_PIN_FAILED = 'VOUCHER_CHECK_PIN_FAILED';
export const VOUCHER_CHECK_PIN_SUCCESS = 'VOUCHER_CHECK_PIN_SUCCESS';
export const FETCH_CASH_VOUCHER_MERCHANTS = 'FETCH_CASH_VOUCHER_MERCHANTS';
export const FETCH_CASH_VOUCHER_MERCHANTS_FAILED = 'FETCH_CASH_VOUCHER_MERCHANTS_FAILED';
export const FETCH_CASH_VOUCHER_MERCHANTS_SUCCESS = 'FETCH_CASH_VOUCHER_MERCHANTS_SUCCESS';
export const FETCH_MERCHANT_CASH_VOUCHERS = 'FETCH_MERCHANT_CASH_VOUCHERS';
export const FETCH_MERCHANT_CASH_VOUCHERS_FAILED = 'FETCH_MERCHANT_CASH_VOUCHERS_FAILED';
export const FETCH_MERCHANT_CASH_VOUCHERS_SUCCESS = 'FETCH_MERCHANT_CASH_VOUCHERS_SUCCESS';
export const FETCH_CASH_VOUCHER_HISTORY = 'FETCH_CASH_VOUCHER_HISTORY';
export const FETCH_CASH_VOUCHER_HISTORY_FAILED = 'FETCH_CASH_VOUCHER_HISTORY_FAILED';
export const FETCH_CASH_VOUCHER_HISTORY_SUCCESS = 'FETCH_CASH_VOUCHER_HISTORY_SUCCESS';
export const FETCH_ADS = 'FETCH_ADS';
export const FETCH_ADS_FAILED = 'FETCH_ADS_FAILED';
export const FETCH_ADS_SUCCESS = 'FETCH_ADS_SUCCESS';
export const FETCH_QR_STRING = 'FETCH_QR_STRING';
export const FETCH_QR_STRING_FAILED = 'FETCH_QR_STRING_FAILED';
export const FETCH_QR_STRING_SUCCESS = 'FETCH_QR_STRING_SUCCESS';
export const UNMOUNT_PURCHASE_VOUCHER = 'UNMOUNT_PURCHASE_VOUCHER';
export const UNMOUNT_USER_VOUCHERS = 'UNMOUNT_USER_VOUCHERS';

export const ADD_TO_CART = 'ADD_TO_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const PAY_BUY_CART_ITEMS = 'PAY_BUY_CART_ITEMS';
export const PAY_BUY_CART_ITEMS_SUCCESS = 'PAY_BUY_CART_ITEMS_SUCCESS';
export const PAY_BUY_CART_ITEMS_FAILED = 'PAY_BUY_CART_ITEMS_FAILED';
export const UPDATE_CART_TO_DB = 'UPDATE_CART_TO_DB';

//borneokaki
export const BORNEO_ADD_TO_CART = 'BORNEO_ADD_TO_CART';
export const BORNEO_ADD_TO_CART_SUCCESS = 'BORNEO_ADD_TO_CART_SUCCESS';
export const BORNEO_ADD_TO_CART_FAILED = 'BORNEO_ADD_TO_CART_FAILED';
export const BORNEO_FETCH_CART = 'BORNEO_FETCH_CART'
export const BORNEO_FETCH_CART_SUCCESS = 'BORNEO_FETCH_CART_SUCCESS'
export const BORNEO_FETCH_CART_FAILED = 'BORNEO_FETCH_CART_FAILED';
export const BORNEO_REMOVE_FROM_CART = 'BORNEO_REMOVE_FROM_CART';
export const BORNEO_REMOVE_FROM_CART_SUCCESS = 'BORNEO_REMOVE_FROM_CART_SUCCESS';
export const BORNEO_REMOVE_FROM_CART_FAILED = 'BORNEO_REMOVE_FROM_CART_FAILED';
export const BORNEO_FETCH_ADDRESS = 'BORNEO_FETCH_ADDRESS';
export const BORNEO_FETCH_ADDRESS_SUCCESS = 'BORNEO_FETCH_ADDRESS_SUCCESS';
export const BORNEO_FETCH_ADDRESS_FAILED = 'BORNEO_FETCH_ADDRESS_FAILED';
export const BORNEO_FETCH_PRODUCTS = 'BORNEO_FETCH_PRODUCTS';
export const BORNEO_FETCH_PRODUCTS_SUCCESS = 'BORNEO_FETCH_PRODUCTS_SUCCESS';
export const BORNEO_FETCH_PRODUCTS_FAILED = 'BORNEO_FETCH_PRODUCTS_FAILED';
export const BORNEO_FETCH_FEATURED_PRODUCTS = 'BORNEO_FETCH_FEATURED_PRODUCTS';
export const BORNEO_FETCH_FEATURED_PRODUCTS_FAILED = 'BORNEO_FETCH_FEATURED_PRODUCTS_FAILED';
export const BORNEO_FETCH_FEATURED_PRODUCTS_SUCCESS = 'BORNEO_FETCH_FEATURED_PRODUCTS_SUCCESS';
export const BORNEO_ADD_ADDRESS = 'BORNEO_ADD_ADDRESS';
export const BORNEO_ADD_ADDRESS_SUCCESS = 'BORNEO_ADD_ADDRESS_SUCCESS';
export const BORNEO_ADD_ADDRESS_FAILED = 'BORNEO_ADD_ADDRESS_FAILED';
export const BORNEO_FETCH_CATEGORIES = 'BORNEO_FETCH_CATEGORIES';
export const BORNEO_FETCH_CATEGORIES_FAILED = 'BORNEO_FETCH_CATEGORIES_FAILED';
export const BORNEO_FETCH_CATEGORIES_SUCCESS = 'BORNEO_FETCH_CATEGORIES_SUCCESS';
export const BORNEO_FETCH_PURCHASE_HISTORY = 'BORNEO_FETCH_PURCHASE_HISTORY';
export const BORNEO_FETCH_PURCHASE_HISTORY_FAILED = 'BORNEO_FETCH_PURCHASE_HISTORY_FAILED';
export const BORNEO_FETCH_PURCHASE_HISTORY_SUCCESS = 'BORNEO_FETCH_PURCHASE_HISTORY_SUCCESS';
export const BORNEO_FETCH_SHIPPING_STATUS = 'BORNEO_FETCH_SHIPPING_STATUS';
export const BORNEO_FETCH_SHIPPING_STATUS_FAILED = 'BORNEO_FETCH_SHIPPING_STATUS_FAILED';
export const BORNEO_FETCH_SHIPPING_STATUS_SUCCESS = 'BORNEO_FETCH_SHIPPING_STATUS_SUCCESS';
export const BORNEO_FETCH_COINS_HISTORY = 'BORNEO_FETCH_COINS_HISTORY';
export const BORNEO_FETCH_COINS_HISTORY_FAILED = 'BORNEO_FETCH_COINS_HISTORY_FAILED';
export const BORNEO_FETCH_COINS_HISTORY_SUCCESS = 'BORNEO_FETCH_COINS_HISTORY_SUCCESS';
export const BORNEO_FETCH_COINS = 'BORNEO_FETCH_COINS';
export const BORNEO_FETCH_COINS_FAILED = 'BORNEO_FETCH_COINS_FAILED';
export const BORNEO_FETCH_COINS_SUCCESS = 'BORNEO_FETCH_COINS_SUCCESS';
export const BORNEO_CHECKOUT_CART = 'BORNEO_CHECKOUT_CART';
export const BORNEO_CHECKOUT_CART_FAILED = 'BORNEO_CHECKOUT_CART_FAILED';
export const BORNEO_CHECKOUT_CART_SUCCESS = 'BORNEO_CHECKOUT_CART_SUCCESS';
export const BORNEO_UNMOUNT = 'BORNEO_UNMOUNT';
export const BORNEO_REDEEM_COINS = 'BORNEO_REDEEM_COINS';
export const BORNEO_REDEEM_COINS_FAILED = 'BORNEO_REDEEM_COINS_FAILED';
export const BORNEO_REDEEM_COINS_SUCCESS = 'BORNEO_REDEEM_COINS_SUCCESS';




