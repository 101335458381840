import {
    BOOK_RATE_CHANGE_AMOUNTRM, BOOK_RATE_CHANGE_BENE,
    BOOK_RATE_CHANGE_COUNTRY, BOOK_RATE_CHANGE_PROVIDER,
    BOOK_RATE_FETCH,
    BOOK_RATE_FETCH_FAILED,
    BOOK_RATE_FETCH_SUCCESS, BOOK_RATE_SHOW_MESSAGE,
    BOOK_RATE_CHANGE_PURPOSE, BOOK_RATE_CREATE, BOOK_RATE_CREATE_FAILED,
    BOOK_RATE_PRE_CREATE, BOOK_RATE_PRE_CREATE_FAILED, BOOK_RATE_PRE_CREATE_SUCCESS,
    BOOK_RATE_CONFIRM_CREATE, BOOK_RATE_CONFIRM_CREATE_FAILED, BOOK_RATE_CONFIRM_CREATE_SUCCESS,
    BOOK_RATE_DISMISS_MESSAGE,
    ALL_RATE_FETCH, ALL_RATE_FETCH_FAILED, ALL_RATE_FETCH_SUCCESS,
    BOOK_RATE_CHANGE_PROVIDER_LABEL,
    BOOK_RATE_POINT_REDEEM,
    BOOK_RATE_CONVERT_POINT,
    BOOK_RATE_CONVERT_MONEY,
    CLEAR_SELECTED_BENE,
    RESET_BOOK_RATE_STATE,
    BOOK_RATE_CONFIRM_CREATE_EXPIRED
} from "../actions/type";

const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    rates: [],
    country: 0,
    provider: 0,
    providerlabel: '',
    amountRM: "0.00",
    benes: [{ id: -1, name: "Select Beneficiary" }, { id: 1, name: "John Doe" }, { id: 2, name: "Jane Doe" }],
    selectedBene: undefined,
    purposes: ['Select Purpose', 'Family', 'School'],
    selectedPurpose: undefined,
    preBookHeader: [],
    preBookBody: [],
    bookRateResp: [],
    showMessage: false,
    countryname: '',
    allrates: [],
    pointredeem: false,
    points: 0,
    discountAmount: 0,
    usedPoint: 0,
    expired: false
}

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case BOOK_RATE_FETCH:
            return { ...state, loading: true }
        case BOOK_RATE_FETCH_FAILED:
            return { ...INITIAL_STATE, error: true, message: action.payload };
        case BOOK_RATE_FETCH_SUCCESS:
            return { ...state, rates: action.payload, loading: false, amountRM: "0.00" };
        case ALL_RATE_FETCH:
            return { ...state, loading: true, allrates: [] }
        case ALL_RATE_FETCH_FAILED:
            return { ...INITIAL_STATE, error: true, message: action.payload };
        case ALL_RATE_FETCH_SUCCESS:
            return { ...state, allrates: action.payload, loading: false };
        case BOOK_RATE_CHANGE_COUNTRY:
            return { ...state, country: action.payload, countryname: action.payload2, provider: 0, error: false, message: '' }
        case BOOK_RATE_CHANGE_PROVIDER:
            return { ...state, provider: action.payload }
        case BOOK_RATE_CHANGE_PROVIDER_LABEL:
            return { ...state, providerlabel: action.payload }
        case BOOK_RATE_CHANGE_AMOUNTRM:
            return { ...state, amountRM: action.payload, error: false, message: '', };
        case BOOK_RATE_SHOW_MESSAGE:
            return { ...state, error: action.payload.show, message: action.payload.message };
        case BOOK_RATE_CHANGE_BENE:
            return { ...state, selectedBene: action.payload };
        case BOOK_RATE_CHANGE_PURPOSE:
            return { ...state, selectedPurpose: action.payload };
        case BOOK_RATE_CREATE:
            return { ...state, loading: true };
        case BOOK_RATE_CREATE_FAILED:
            return { ...state, loading: false, error: true, message: action.payload }
        case BOOK_RATE_PRE_CREATE:
            return { ...state, loading: true }
        case BOOK_RATE_PRE_CREATE_SUCCESS:
            return { ...state, loading: false, error: false, preBookHeader: action.payload, preBookBody: action.payload2 };
        case BOOK_RATE_PRE_CREATE_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BOOK_RATE_CONFIRM_CREATE:
            return { ...state, loading: true }
        case BOOK_RATE_CONFIRM_CREATE_SUCCESS:
            return { ...state, loading: false, error: false, bookRateResp: action.payload, message: action.payload2, showMessage: true };
        case BOOK_RATE_CONFIRM_CREATE_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        case BOOK_RATE_CONFIRM_CREATE_EXPIRED:
            return { ...state, loading: false, expired: true, message: action.payload };
        case BOOK_RATE_DISMISS_MESSAGE:
            return { ...state, error: false, expired: false, message: '', showMessage: false };
        case BOOK_RATE_POINT_REDEEM:
            return { ...state, pointredeem: action.payload };
        case BOOK_RATE_CONVERT_POINT:
            return { ...state, points: action.payload };
        case BOOK_RATE_CONVERT_MONEY:
            return { ...state, discountAmount: action.payload.discountAmount, usedPoint: action.payload.usedPoint };
        case CLEAR_SELECTED_BENE:
            return { ...state, selectedBene: null }
        case RESET_BOOK_RATE_STATE:
            return { ...state, allrates: [], provider: 0, loading: false }
        default:
            return state;
    }
}
