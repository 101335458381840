import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { HOME_DISMISS_ERROR, HOME_GET_BALANCE, HOME_GET_BALANCE_FAILED, HOME_GET_BALANCE_SUCCESS, HOME_GET_BALANCE_UNUSED_MONEYPIN, HOME_GET_BALANCE_UNUSED_MONEYPIN_FAILED, HOME_GET_BALANCE_UNUSED_MONEYPIN_SUCCESS } from "./type";

export const homeDismissError = () => {
    return { type: HOME_DISMISS_ERROR }
}

export const getBalance = (lang : any, user : any) => {
    return async (dispatch : any) => {
        try{
        dispatch({ type: HOME_GET_BALANCE });

        if (!user)
            return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: "O01: OOPS SOMETHING WENT WRONG! Please Try again later"});
        if (!user.mobile)
            return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: "O02: OOPS SOMETHING WENT WRONG! Please Try again later"});
        if (!user.aa_uuid)
            return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: "O03: OOPS SOMETHING WENT WRONG! Please Try again later"});
        if (!user.wallet_acctno_mmp_id)
            return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: "O04: OOPS SOMETHING WENT WRONG! Please Try again later"});

        let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
        let input : any = {
            ver: CONFIG?.ver,
            act: 950,
            m1: "Balance",
            m2: "",
            lg: lang
        }
        input = addUserObjectToRequest(input);
        let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
        if (!resultsFromAPI) {
            return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: "005: Something went wrong, try again"});
        }
        if (resultsFromAPI?.error !== undefined) {
            return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: "006: Something went wrong, please check your Internet Connection and try again"});
        }
        if (resultsFromAPI?.results) {
            let results: any = resultsFromAPI.results;
            if(results.code !== 0)
                return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: `Error Code: ${results.code} :: ${results.message || ""}`});
            if(results.hasOwnProperty("balance"))
                return dispatch({ type: HOME_GET_BALANCE_SUCCESS,
                    payload: {
                        balance: results.balance,
                        pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null,
                        balance_unusedpin: results.hasOwnProperty("balance_unusedpin") ? results.balance_unusedpin : "0.00",
                        current_app_version: results?.current_app_version || undefined,
                        is_ic_expired: results?.isICExpired || 0
                    }})

            return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: "007: Something went wrong, try again"});
        }         
        return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: "008: Something went wrong, try again" });
        }
        catch (e) {
        console.log(e);
        return dispatch({ type: HOME_GET_BALANCE_FAILED, payload: "O99: OOPS SOMETHING WENT WRONG!"})
        }
    }
}