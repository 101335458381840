import {Box, Button, Card, CardContent, CircularProgress, FormControl, List, MenuItem, Select, TextField, Typography} from "@mui/material";
import Grid from '@mui/material/Grid';
import { tokens } from "../../../theme";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CONFIG } from "../../../config";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../../utils";
import { BOOK_RATE_MENU } from "../../../constants/data";
import { BOOK_RATE_PRE_CREATE_SUCCESS } from "../../../actions/type";
import Header from "../../../components/Header";
import BeneficiaryCard from "../../../components/BeneficiaryCard";
import Grid2 from "@mui/material/Unstable_Grid2";
import useCheckIsMobile from "../../../hooks/useCheckIsMobile";

const colors = tokens();

const SelectBeneficiary = () => {
    const isMobile: boolean = useCheckIsMobile();
    const {state} = useLocation();
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ error: false, message: ''});
    const [beneficiary, setBeneficiary] = useState(undefined);
    const [purposes, setPurposes] = useState([]);
    const [purpose, setPurpose] = useState<string | undefined>(undefined);
    const [isSelectedBene, setIsSelectedBene] = useState(-1);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const { rates, amountRM, country, provider, selectedBene } = useSelector((state: RootState) => state.bookrate);
    const navigate = useNavigate();
    
    const dispatch = useAppDispatch();

    const providerPrefix = rates[country].providers.find((item: { name: any; })=>item.name === state.selectedProviderValue)

    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            fetchBeneficiaryData();
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    },[]);

    const fetchBeneficiaryData = async () : Promise<void> => {
        try{
        setLoading(true);
        let input : any = {
            ver: CONFIG.ver,
            act: 30015,
            user_agent: navigator.userAgent,
            app_id: CONFIG.app_id,
            m1: 'Get Beneficiary Data',
            lg: lang
        }
        input = addUserObjectToRequest(input);
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
        if (!resultsFromAPI) {
            return setError({ error: true, message: '001: Something went wrong, try again'});
        }
        if (resultsFromAPI?.error !== undefined) {
            return setError({ error: true, message: '002: Something went wrong, try again'});
        }
        if (!resultsFromAPI.hasOwnProperty("results")) {
            return setError({ error: true, message: '003: Something went wrong, try again'});
        }
        if (resultsFromAPI?.results) {
            let results: any = resultsFromAPI.results;
            if(results.code !== 0)
            return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}`})
        
            if (results.hasOwnProperty("purposes")){
            setPurposes(results.purposes);
            console.log(results)
            if (results.purposes.length > 0)
                setPurpose(results.purposes[0]?.value)
            }

            if (results.hasOwnProperty("beneficiaries")){
                // filter bene list by selected country
                let selectedCountry = rates[country]?.country;
                let selectedProvider = rates[country]?.providers.find((i: { name: any; }) => i?.name === state.selectedProviderValue);
            
                // if the bene was selected from the Beneficiary screen
                if (selectedBene){
                    let filteredBeneList = results.beneficiaries
                    .filter((i: { value: any; card_id: any; }) =>
                        i.value === selectedCountry &&
                        i.card_id === selectedBene
                    )
                    const massagedFilteredBeneList = filteredBeneList.map((i: { ic_acc_name: any; card_id: any; }) => {
                        return { ...i, label: i?.ic_acc_name, value: i?.card_id}
                    })
                    setBeneficiaries(massagedFilteredBeneList);
                    // if (filteredBeneList.length > 0)
                    //   setBeneficiary(filteredBeneList[0]?.card_id);
                }else {
                    
                    let filteredBeneList =
                    results.beneficiaries
                        .filter((i: { value: any; pickup_method_value: any; }) =>
                            i.value === selectedCountry &&
                            i.pickup_method_value === selectedProvider?.pickup_method
                        );
                    const massagedFilteredBeneList = filteredBeneList.map((i: { ic_acc_name: any; card_id: any; }) => {
                        return { ...i, label: i?.ic_acc_name, value: i?.card_id}
                    })

                    setBeneficiaries(massagedFilteredBeneList);
                }
                }        
                return setLoading(false);
            }         
        }
        catch (e) {
            console.log(e)
            setLoading(false);
            return setError({ error: true, message: '99: OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN LATER'})
        }
    }

    const handleSubmitClick = async () : Promise<void> => {
        if (rates === undefined || rates.length === 0){
        return setError({ error: true,message: "Submit 001: Something went wrong, try again"});
        }
        else if (country < 0){ //if taking index, index = -1 issue
        return setError({ error: true,message: "Submit 002: Something went wrong, try again"});
        }
        else if (!rates[country].hasOwnProperty("country")){
        return setError({ error: true,message: "Submit 003: Something went wrong, try again"});
        }
        else if (provider < 0) {//if taking index, index = -1 issue
        return setError({ error: true,message: "Submit 004: Something went wrong, try again"});
        }
        else if (!rates[country].hasOwnProperty("providers")){
        return setError({ error: true,message: "Submit 005: Something went wrong, try again"});
        }
        else if (amountRM === undefined){
        return setError({ error: true,message: "Submit 006: Something went wrong, try again"});
        }

        if (!beneficiary) {
        return setError({ error: true,message: "Please select a beneficiary"});
        }
        if (!purpose) {
        return setError({ error: true,message: "Please select a purpose"});
        } 

        let preBookActId = BOOK_RATE_MENU[0];
        let selectedBene : any = beneficiary;
        let selectedPurpose : any = purposes.find((i : { value : any}) => i?.value === purpose);

        await createPreBooking(lang, preBookActId, amountRM, selectedBene?.card_id, selectedPurpose?.value, rates[country].country, providerPrefix?.prefix, "1", selectedBene?.ic_bank_label, selectedBene?.ic_account_number);
    }

    const createPreBooking = async (
        lang : any, 
        params : any, 
        req_send_amount : any, 
        card_no : any,
        remittance_purpose_id : any, 
        country : any,  
        provider : any, 
        currency_type : any, 
        bank : any,  
        ic_account_no : any
    ) : Promise<void> => {
        try{
            setLoading(true);
            if (params === undefined){
                setLoading(false);
                return setError({ error: true,message: "Submit 007: Something went wrong, try again"});
            }
            if (!params.hasOwnProperty("act_id")){
                setLoading(false);
                return setError({ error: true,message: "Submit 008: Something went wrong, try again"});
            }

            let act_id = params.act_id,
                m1 = params.name || '';
            let input : any = {
                ver: CONFIG.ver,
                act: act_id,
                user_agent: navigator.userAgent,
                p1: {
                    user_trans_id: new Date().valueOf(),
                    req_send_amount: req_send_amount,
                    card_no: card_no,
                    remittance_purpose_id: remittance_purpose_id,
                    country: country,
                    provider: provider,
                    currency_type: currency_type,
                    bank: bank,
                    ic_account_no: ic_account_no
                },
                lg: lang,
                m1: m1
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error: true,message: "Submit 009: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true,message: "Submit 010: Something went wrong, try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error: true,message: "Submit 011: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if(results.code !== 0){
                    setLoading(false);
                    return setError({ error: true,message: `Error Code: ${results.code} :: ${results.message || ""}`});
                }
                if (results.hasOwnProperty("providers")) {
                    let providers = results.providers;
                    dispatch({ type: BOOK_RATE_PRE_CREATE_SUCCESS, payload: providers.header, payload2: providers.body });
                    setLoading(false);
                    return navigate("/kirim/bookrate/bookrateconfirmation");
                }
            }
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true,message: "Submit 99: Something went wrong, try again"});
        }
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'kirim.menu.bookrate')} subtitle={""} />
                </Box>
                <Grid2 container>
                    <Grid2 xs={12} md={6}>
                    <Card
                        sx={styles(isMobile).cardStyle}
                        elevation={3}
                    >
                        <CardContent>
                            <Box 
                                sx={{ textAlign: 'left'  }}
                                mt="5px"
                                mb="10px"
                                ml="5px" 
                                mr="5px"
                            >
                                {getLabelValue(lang, 'transfer.confirm.amount')}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                                <TextField
                                    margin="none"
                                    required
                                    fullWidth
                                    type='text'
                                    value={`MYR          |          ${amountRM}`}
                                    sx={{ mb: "5px", "& input": { textAlign: "center" } }}
                                    inputMode="numeric"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Box>
                            <Box 
                                sx={{ textAlign: 'left'  }}
                                mt="5px"
                                mb="10px"
                                ml="5px" 
                                mr="5px"
                            >
                                {getLabelValue(lang, 'kirim.bookrate.selectpurpose')}
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                value={purpose || ""}
                                onChange={(event) => {
                                    const selectedItemValue = event.target.value;
                                    setPurpose(selectedItemValue)
                                }}
                                >
                                {purposes.map((purpose: any, index) => (
                                    <MenuItem key={index} value={purpose.value}>
                                        {purpose.label}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                    <Card
                        sx={styles(isMobile).cardStyle}
                        elevation={3}
                    >
                        <CardContent>
                            <Box 
                                sx={{ textAlign: 'left'  }}
                                mt="5px"
                                mb="10px"
                                ml="5px" 
                                mr="5px"
                            >
                                {getLabelValue(lang, 'kirim.bookrate.selectbene.title')}
                            </Box>
                            <List component="nav" sx={{ flexGrow: 1, overflowY: 'auto', alignItems: 'center' }}>
                                {beneficiaries.map((item, index) => (
                                <BeneficiaryCard
                                    beneficiary={item}
                                    selected={isSelectedBene}
                                    onSelect={(index) => {
                                        setIsSelectedBene(index);
                                        setBeneficiary(beneficiaries[index])
                                    }}
                                    key={index}
                                    index={index}
                                />
                                ))}             
                                {beneficiaries.length === 0 && (
                                <Box sx={{ textAlign: 'center', p: 3 }}>
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                    {getLabelValue(lang, 'kirim.bookrate.noBene')}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate('/kirim/addbeneficiary')}
                                    >
                                        {getLabelValue(lang, 'kirim.beneficiary.title')}
                                    </Button>
                                </Box>
                                )}
                            </List>
                            {error.error && (
                            <Grid
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography textAlign="center" color={colors.primary[500]}>
                                    {error.message || ''}
                                </Typography>
                            </Grid>
                            )} 
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="success"
                                        onClick={async () => navigate(-1)}
                                        disabled={loading} 
                                    >
                                        {getLabelValue(lang, 'login.account.button.goback')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        onClick={async () => await handleSubmitClick()}
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        endIcon={loading ? <CircularProgress size={20} /> : null}
                                    >
                                        {getLabelValue(lang, 'kirim.bookrate.selectbene.button')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    )
} 

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        m: 2,
        borderRadius: 3,
        px: 1
    }
})

export default SelectBeneficiary;