import {createSlice} from "@reduxjs/toolkit";
import { TUser } from "../../type";

export interface UserSliceState {
    isLoggedIn: boolean;
    lang: string;
    user: TUser | undefined;
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLoggedIn: false,
        lang: 'en',
        user: undefined
    } as UserSliceState,
    reducers: {
        updateLanguage: (state: UserSliceState, action) => {
            state.lang = action.payload
        },
        updateLoggedInUser: (state: UserSliceState, action) => {
            state.user = action.payload
        },
        loginUser: (state: UserSliceState) => {
            state.isLoggedIn = true;
        },
        logOutUser: (state: UserSliceState) => {
            state.isLoggedIn = false;
            state.user = undefined;
        }
    }
})

export const { loginUser, logOutUser, updateLanguage, updateLoggedInUser } = userSlice.actions;

export default userSlice.reducer
