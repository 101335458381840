import { ThemeProvider } from "@emotion/react";
import { Container, CssBaseline, Box, Typography, Button, CircularProgress, createTheme, IconButton } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRegister } from "../../actions/RegisterAction";
import { CONFIG } from "../../config";
import { tokens } from "../../theme";
import { IError, BackendResponse, IConfirm } from "../../type";
import { callBackendAPI, getLabelValue, logError } from "../../utils";
import Footer from "../../components/Footer";
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmDialog from "../../components/ConfirmDialog";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const colors = tokens();

const RegisterProfilePic = () => {
    const {state} = useLocation();
    const user: any = state?.user;
    const [, , ,profilePics] = useRegister();
    const [selectedProfilePic, setSelectedProfilePic] = useState(-1);
    const [backConfirm, setBackConfirm] = useState<IConfirm>({ confirm: false, content: [] });
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<IError>({ error: false, message: ''})
    const navigate = useNavigate();
    
    const handleUpdateProfilePic = async (): Promise<void> => {
        let selectedImage: any = profilePics[selectedProfilePic]?.value
        try {
            if (selectedProfilePic === -1)
                return setError({ error: true, message: 'Please select a picture from the list'});
            if (selectedImage === undefined || selectedImage === '')
                return setError({ error: true, message: '001: 00PS Something went wrong, please try again'});
            if (error.error)
              setError({ error: false, message: ''});

            setLoading(true);
            let headers: HeadersInit = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }       
            let input: any = {
                ver: CONFIG?.ver,
                act: 904,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: user?.mobile.trim(),
                p2: selectedImage,
                p3: String(user?.rfCode),
                m1: 'UpdateUserImage',
                m2: '',
                user: user
            }
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('register', 'POST',headers, input);
            if (resultFromAPI === undefined) {
              setLoading(false);
              return setError({ error: true, message: "001: Something went wrong, please try again."});
            }
      
            if (resultFromAPI?.error !== undefined) {
              setLoading(false);
              return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
            }
      
            if (resultFromAPI?.results) {
              let results: any = resultFromAPI.results;
              if (results?.code && results.code !== 0) {
                  setLoading(false);
                  return setError({
                      error: true,
                      message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`});
              }
              return navigate('/registersuccess', {
                state: {
                    user: user,
                    selectedProfilePic: selectedImage
                },
              });
            }
            setLoading(false);
            return setError({ error: false, message: "004: Something went wrong, please try again."})
        }
        catch (e) {
            logError('Register : handleSubmit', e);
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
        }
    }

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    px: 4,
                    py: 4,
                    marginTop: 5,
                    position: 'relative',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <IconButton 
                    onClick={() => setBackConfirm({ confirm: true, content: [getLabelValue(lang,'register.enterpin.close.message')]})}
                    sx={{
                        position: 'absolute',
                        top: 8, 
                        left: 8, 
                        zIndex: 1, 
                    }}
                >
                    <CancelIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <Box mt="5px" mb="25px" ml="5px">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderRadius="10px"
                        overflow="hidden"
                        boxShadow={2}
                    >
                        <img
                            alt="logo"
                            width="230px"
                            height="60px"
                            src={'../../assets/mmlogo.png'}
                            style={{ cursor: "pointer"}}
                        />
                </Box>
                </Box>
                <Box
                    sx={{ fontStyle: 'normal', textAlign: 'center' }}
                    mb="5px"
                    ml="50px"
                    mr="50px"
                >
                <Typography variant="h5" sx={{fontWeight: 'bold'}} color='#ef233e'>
                    {getLabelValue(lang, 'register.photo.title').toUpperCase()}
                </Typography>
                </Box>
                <Box
                    sx={{ textAlign: 'left' }}
                    mt="15px"
                    mb="5px"
                    ml="5px"
                    mr="5px"
                >
                    {getLabelValue(lang,'register.photo.subTitle')}
                </Box>        
                <Box sx={{ mt: 2 }} minWidth={'100%'}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '15px' }}>
                        {profilePics.map((item, index) => (
                            <Button
                                key={index}
                                onClick={() => setSelectedProfilePic(index)}
                                
                                color={selectedProfilePic === index ? "secondary" : "primary"}
                                sx={{
                                    borderRadius: '15%',
                                    overflow: 'hidden',
                                    width: '100%',
                                    height: '100%',
                                    padding: 0, 
                                    border: 0, 
                                    '& img': {
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '15%',
                                        border: selectedProfilePic === index ? '3px solid red' : 'none',
                                    }
                                }}
                            >
                                <img
                                    alt={`Profile Pic ${index + 1}`}
                                    src={item.value.toString()}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Button>
                        ))}
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        onClick={async () => await handleUpdateProfilePic()}
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                        endIcon={loading ? <CircularProgress size={20} /> : null}
                    >
                        {getLabelValue(lang, 'login.account.button.submit').toUpperCase()}
                    </Button>
                    {error.error && (
                        <Typography
                        sx={{
                            mt: '10px',
                            textAlign: 'center',
                            color: colors.primary[500],
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                        {error.message || ''}
                        </Typography>
                    )}                               
                </Box>
            </Box>
        </Container>
        <Footer />
        {backConfirm.confirm && (
            <ConfirmDialog
                open={backConfirm.confirm}
                onSuccess={() => {
                    setBackConfirm({ confirm: false, content: [] })
                    navigate('/login');
                }}
                onCancel={ () => setBackConfirm({ confirm: false, content: [] })}
                title={getLabelValue(lang,'register.enterpin.close.title')}
                content={(backConfirm.content)}
                yesButton='Yes'
                cancelButton='No'
            />
        )}         
      </ThemeProvider>
    );
}

const theme = createTheme({
    palette: {
      secondary: {
        //MMApp signature red
        main: '#ef233e',
      },
      primary: {
        main: '#212121',
      }
    },
  });
  
export default RegisterProfilePic;

// const colors = tokens();

// const RegisterProfilePic = () => {
//     const {state} = useLocation();
//     const user: any = state?.user;
//     const [isCapture, setCapture] = useState<boolean>(false);
//     const webcamRef = React.useRef<Webcam>(null);
//     const [url, setUrl] = useState<string | null>(null);
//     const [profileImage, setProfileImage] = useState("");
//     const { lang } = useSelector((state: RootState) => state.user);
//     const [loading, setLoading] = useState<boolean>(false); 
//     const [error, setError] = useState<Error>({ error: false, message: ""});
//     const navigate = useNavigate();

//     const videoConstraints = {
//         width: 240,
//         height: 180,
//         facingMode: "user"
//     };

//     const capture = React.useCallback(() => {
//         const imageSrc = webcamRef.current?.getScreenshot();
//         if (imageSrc) {
//             setUrl(imageSrc);
//             setCapture(false);
//         }
//     }, [webcamRef]);

//     const uploadImage = async () => {
//         try {
//             if(!url)
//                 return setError({ error: true, message: 'Failed to create appropriate type from the image. Please try another image'});
//             if (error.error)
//                 setError({ error: false, message: ''});
//             setLoading(true);
//             let base64Image = url.split(',')[1];
//             let apiUrl = '/createPhoto';
//             const payload = {
//                 photo: base64Image,
//             };
//             let resultFromAPI = await fetch(apiUrl, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(payload),
//             });
//             if (resultFromAPI === undefined) {
//                 setLoading(false);
//                 return setError({ error: true, message: "001: Something went wrong, please try again."});
//             }
//             if (resultFromAPI.ok) {
//                 let results: any = await resultFromAPI.json();
//                 console.log(results)
//                 if (!results.hasOwnProperty("status")) {
//                     setLoading(false);
//                     return setError({ error: false, message: "Failed to upload the image, please try again later."})
//                 }
//                 if (results.status !== 0) {
//                     setLoading(false);
//                     return setError({ error: true, message: `Error ${results.status}: Oops something went wrong. Failed to upload the image to the server. Please try again later`});
//                 }
//                 setProfileImage(results.fpath);
//                 setLoading(false);
//                 handleUpdateProfilePic();
//             } 
//         }
//         catch (e) {
//         setLoading(false);
//         return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});      
//         }
//     }
    
//     const handleUpdateProfilePic = async (): Promise<void> => {
//         try {
//             // if (profileImage === undefined || profileImage === '')
//             //     return setError({ error: true, message: '001: 00PS Something went wrong, please try again'});
//             if (error.error)
//               setError({ error: false, message: ''});

//             setLoading(true);
//             let headers: HeadersInit = {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//             }       
//             let input: any = {
//                 ver: CONFIG?.ver,
//                 act: 904,
//                 user_agent: navigator.userAgent,
//                 app_id: CONFIG.app_id,
//                 p1: user?.mobile.trim(),
//                 p2: '/RDSphoto/2023/10/3/b1e3369df69682cc5e1d3c0f172a6fa3.jpg',
//                 p3: user?.referralCode,
//                 m1: 'UpdateUserImage',
//                 m2: '',
//                 user: user,
//                 lg: lang
//             }
//             console.log(input)
//             let resultFromAPI: BackendResponse | undefined = await callBackendAPI('register', 'POST',headers, input);
//             if (resultFromAPI === undefined) {
//               setLoading(false);
//               return setError({ error: true, message: "001: Something went wrong, please try again."});
//             }
      
//             if (resultFromAPI?.error !== undefined) {
//               setLoading(false);
//               return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again."});
//             }
      
//             if (resultFromAPI?.results) {
//               let results: any = resultFromAPI.results;
//               if (results?.code && results.code !== 0) {
//                   setLoading(false);
//                   return setError({
//                       error: true,
//                       message: `Error Code: ${results.code}. ${results?.message || "003: Something went wrong, please try again."}`});
//               }
//               return navigate('/registersuccess', {
//                 state: {
//                     user,
//                     profileImage
//                 },
//               });
//             }
//             setLoading(false);
//             return setError({ error: false, message: "004: Something went wrong, please try again."})
//         }
//         catch (e) {
//             logError('Register : handleSubmit', e);
//             setLoading(false);
//             return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN"});
//         }
//     }
    
//     return (
//         <ThemeProvider theme={theme}>
//         <Container component="main" maxWidth="sm">
//           <CssBaseline />
//           <Box
//             sx={{
//               boxShadow: 3,
//               borderRadius: 2,
//               px: 4,
//               py: 4,
//               marginTop: 5,
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <Box mt="5px" mb="25px" ml="5px">
//               <Box
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//                 borderRadius="10px"
//                 overflow="hidden"
//                 boxShadow={2}
//                 >
//                   <img
//                       alt="logo"
//                       width="230px"
//                       height="60px"
//                       src={'../../assets/mmlogo.png'}
//                       style={{ cursor: "pointer"}}
//                   />
//               </Box>
//             </Box>
//             <Box
//               sx={{ fontStyle: 'normal', textAlign: 'center' }}
//               mb="5px"
//               ml="50px"
//               mr="50px"
//             >
//             <Typography variant="h5" sx={{fontWeight: 'bold'}} color='#ef233e'>
//                 Profile Photo
//             </Typography>
//             </Box>
//             <Box
//             sx={{ textAlign: 'left' }}
//             mt="15px"
//             mb="5px"
//             ml="5px"
//             mr="5px"
//             >
//                 Please upload your profile photo
//             </Box>        
//             <Box sx={{ mt: 2 }} minWidth={'100%'}>
//                 {isCapture && (
//                     <div>
//                     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2}}>
//                         <div
//                             style={{
//                                 width: '100%',
//                                 height: '0',
//                                 paddingBottom: '75%', // 4:3 aspect ratio (3 / 4 * 100%)
//                                 position: 'relative',
                                
//                             }}
//                         >
//                             <Webcam
//                                 audio={false}
//                                 style={{
//                                     position: 'absolute',
//                                     width: '100%',
//                                     height: '100%',
//                                 }}
//                                 ref={webcamRef}
//                                 screenshotFormat="image/jpeg"
//                                 videoConstraints={videoConstraints}
//                             />
//                         </div>
//                     </Box>
//                     <Box
//                     sx={{
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         flexDirection: 'column', // To stack the buttons vertically
//                     }}
//                     >
//                     <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                         <Button
//                             type="submit"
//                             fullWidth
//                             onClick={() => setCapture(false)}
//                             variant="contained"
//                             color="secondary"
//                             disabled={loading}
//                             endIcon={loading ? <CircularProgress size={20} /> : null}
//                         >
//                             End
//                         </Button>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                         <Button
//                             type="submit"
//                             fullWidth
//                             onClick={capture}
//                             variant="contained"
//                             color="secondary"
//                             disabled={loading}
//                             endIcon={loading ? <CircularProgress size={20} /> : null}
//                         >
//                             Capture
//                         </Button>
//                     </Grid>
//                     </Grid>
//                     </Box>
//                     </div>
//                 )}       
//                 {!url && !isCapture && (
//                     <Box
//                         sx={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             height: '100%',
//                         }}
//                     >
//                         <Button
//                             type="submit"
//                             onClick={() => setCapture(true)}
//                             variant="contained"
//                             color="secondary"
//                             disabled={loading}
//                             endIcon={loading ? <CircularProgress size={20} /> : null}
//                         >
//                             Take Photo
//                         </Button>
//                     </Box>
//                 )}                      
//                 {url && (
//                     <div style={{ textAlign: 'center' }}>
//                     <img src={url} alt="Screenshot" />
//                     <Button
//                         type="submit"
//                         fullWidth
//                         onClick={() => {
//                             setUrl(null);
//                             setCapture(true);
//                         }}
//                         variant="outlined"
//                         color="secondary"
//                         disabled={loading}
//                         endIcon={loading ? <CircularProgress size={20} /> : null}
//                         style={{ marginTop: '10px' }}
//                     >
//                         Retry
//                     </Button>
//                     </div>
//                 )}   
//                 <Button
//                     type="submit"
//                     fullWidth
//                     onClick={async () => await handleUpdateProfilePic()}
//                     variant="contained"
//                     color="secondary"
//                     sx={{ mt: 3, mb: 2 }}
//                     disabled={loading}
//                     endIcon={loading ? <CircularProgress size={20} /> : null}
//                 >
//                     Proceed
//                 </Button>
//                 {error.error && (
//                     <Typography
//                     sx={{
//                         mt: '10px',
//                         textAlign: 'center',
//                         color: colors.primary[500],
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                     }}
//                     >
//                     {error.message || ''}
//                     </Typography>
//                 )}                               
//             </Box>
//           </Box>
//         </Container>
//         </ThemeProvider>
//     );
// }

// const theme = createTheme({
//     palette: {
//       secondary: {
//             //MMApp signature red
//             main: '#ef233e',
//         },
//         primary: {
//             main: '#212121',
//         }
//     },
// });
  
// export default RegisterProfilePic;