import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { FETCH_STAMPS_LIST, FETCH_STAMPS_LIST_FAILED, FETCH_STAMPS_LIST_SUCCESS, GET_RECEIVED_POINT_HISTORY, GET_RECEIVED_POINT_HISTORY_FAILED, GET_RECEIVED_POINT_HISTORY_SUCCESS, GET_REDEEMED_LIST, GET_REDEEMED_LIST_FAILED, GET_REDEEMED_LIST_SUCCESS, GET_REDEEM_PREPAID, GET_REDEEM_PREPAID_FAILED, GET_REDEEM_PREPAID_SUCCESS, GET_REDEEM_PRODUCTS, GET_REDEEM_PRODUCTS_FAILED, GET_REDEEM_PRODUCTS_SUCCESS, GET_REDEEM_VOUCHERS, GET_REDEEM_VOUCHERS_FAILED, GET_REDEEM_VOUCHERS_SUCCESS, GET_STAMPS_HISTORY, GET_STAMPS_HISTORY_FAILED, GET_STAMPS_HISTORY_SUCCESS, GET_USED_POINT_HISTORY, GET_USED_POINT_HISTORY_FAILED, GET_USED_POINT_HISTORY_SUCCESS, REDEEMED_PRODUCTS, REDEEMED_PRODUCTS_FAILED, REDEEMED_PRODUCTS_SUCCESS, REDEEM_LOCATIONS, REDEEM_LOCATIONS_FAILED, REDEEM_LOCATIONS_SUCCESS, REDEEM_POINTS, REDEEM_POINTS_FAILED, REDEEM_POINTS_SUCCESS, REDEEM_PREPAID_TRANSACTION, REDEEM_PREPAID_TRANSACTION_FAILED, REDEEM_PREPAID_UNMOUNT, REDEEM_VOUCHER_UNMOUNT, REDEEM_WATER, REDEEM_WATER_FAILED, REDEEM_WATER_SUCCESS, SHOW_QR_MODAL, UNMOUNT_PURCHASE_VOUCHER, UNMOUNT_QR_MODAL, UNMOUNT_USER_VOUCHERS, VOUCHER_CHECK_PIN, VOUCHER_CHECK_PIN_FAILED, VOUCHER_CHECK_PIN_SUCCESS, WATER_UNMOUNT } from "./type";


export const getRedeemProducts = (user: any, lang: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({type: GET_REDEEM_PRODUCTS});
            let input: any = {
                ver: CONFIG.ver,
                act: 50000,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                m1: "Get Redeem Product",
                lg: lang,
                user: user,
                action: "redeemproduct",
                redeem_type: "0",
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: GET_REDEEM_PRODUCTS_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: GET_REDEEM_PRODUCTS_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: GET_REDEEM_PRODUCTS_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (parseInt(results.code) !== 0)
                    return dispatch({
                    type: GET_REDEEM_PRODUCTS_FAILED,
                    payload: `[${results.code}] : ${results.message || ""}`,
                });
                else {
                    return dispatch({
                        type: GET_REDEEM_PRODUCTS_SUCCESS,
                        payload: {results},
                    });
                }
            }
    
            return dispatch({
                type: GET_REDEEM_PRODUCTS_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: GET_REDEEM_PRODUCTS_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const getRedeemVouchers = (user: any, lang: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({type: GET_REDEEM_VOUCHERS});
            let input: any = {
                ver: CONFIG.ver,
                act: 50000,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "Get Redeem Product",
                lg: lang,
                user: user,
                action: "redeemproduct",
                redeem_type: "1",
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: GET_REDEEM_VOUCHERS_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: GET_REDEEM_VOUCHERS_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: GET_REDEEM_VOUCHERS_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (parseInt(results.code) !== 0)
                    return dispatch({
                    type: GET_REDEEM_VOUCHERS_FAILED,
                    payload: `[${results.code}] : ${results.message || ""}`,
                    });
                else {
                    return dispatch({
                    type: GET_REDEEM_VOUCHERS_SUCCESS,
                    payload: {results},
                    });
                }
            }
            return dispatch({
                type: GET_REDEEM_VOUCHERS_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: GET_REDEEM_VOUCHERS_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const getRedeemPrepaid = (user: any, lang: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({type: GET_REDEEM_PREPAID});
            let input: any = {
                ver: CONFIG.ver,
                act: 50000,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "Get Redeem Product",
                lg: lang,
                user: user,
                action: "redeemproduct",
                redeem_type: "3"
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: GET_REDEEM_PREPAID_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: GET_REDEEM_PREPAID_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: GET_REDEEM_PREPAID_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (parseInt(results.code) !== 0)
                    return dispatch({
                        type: GET_REDEEM_PRODUCTS_FAILED,
                        payload: `Error Code: ${results.code} :: ${results.message || ""}`,
                    });
                else {
                    return dispatch({
                        type: GET_REDEEM_PREPAID_SUCCESS,
                        payload: {results},
                    });
                }
            }
            return dispatch({
                type: GET_REDEEM_PREPAID_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: GET_REDEEM_PREPAID_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};
  
export const createRedeemPrepaidTransaction = (
    lang: any,
    navigation: any,
    route: any,
    phone = "",
    pin: any,
    selectedDenominator: any,
    enteredAmount: any,
) => {
    return async (dispatch: any) => {
        dispatch({type: REDEEM_PREPAID_TRANSACTION});
        try {
            if (phone === "")
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "001: Phone number required",
            });
            let amount,
            m1,
            act,
            m2;
            if (!pin || pin === "")
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "002: Please Enter Pin",
            });
            if (selectedDenominator === undefined) {
            if (enteredAmount === "")
                return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "Please Select Amount",
            });
            else amount = enteredAmount; //incase of EReload
            } else {
            if (!selectedDenominator.hasOwnProperty("value"))
                return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "003: Something went wrong, try again",
                });
            amount = selectedDenominator.label;
            }
            if (navigation === undefined)
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "004: Something went wrong, try again",
            });
            if (route === undefined)
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "005: Something went wrong, try again",
            });
            if (!route.hasOwnProperty("params"))
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "006: Something went wrong, try again",
            });
            if (!route.params.hasOwnProperty("id"))
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "008: Something went wrong, try again",
            });
            m1 = route.params.id;
            m2 = route.params.name;
            act = route.params.act_id;
            let input: any = {
                ver: CONFIG.ver,
                act: 50006,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                p1: phone,
                p2: String(amount),
                p3: pin,
                p4: route.params.hasOwnProperty("isEReload") ? route.params.isEReload : 0,
                m1: m1,
                m2: m2,
                lg: lang,
                redeem_point: selectedDenominator.value,
                product_id: route.params.product_id,
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "009: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "010: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "011: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0)
                    return dispatch({
                        type: REDEEM_PREPAID_TRANSACTION_FAILED,
                        payload: `Error Code: ${results.code} :: ${results.message || ""}`,
                    });

                return navigation.navigate("ReceiptPage", {
                    ...results.receipt,
                    lang,
                    messageKey: "prepaidselected.receipt.subTitle",
                });
            }
    
            return dispatch({
            type: REDEEM_PREPAID_TRANSACTION_FAILED,
            payload: "012: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: REDEEM_PREPAID_TRANSACTION_FAILED,
                payload: "Oops something went wrong!",
            });
        }
    };
};

export const redeemLocations = (user: any, lang: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({type: REDEEM_LOCATIONS});
            let input: any = {
                ver: CONFIG.ver,
                act: 50002,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "Redeem Locations",
                lg: lang,
                user: user,
                action: "pickup",
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: REDEEM_LOCATIONS_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: REDEEM_LOCATIONS_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: REDEEM_LOCATIONS_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (parseInt(results.code) !== 0)
                    return dispatch({
                        type: REDEEM_LOCATIONS_FAILED,
                        payload: `Error Code: ${results.code} :: ${results.message || ""}`,
                    });
                else {
                    return dispatch({
                        type: REDEEM_LOCATIONS_SUCCESS,
                        payload: {results},
                    });
                }
            }
    
            return dispatch({
                type: GET_REDEEM_PRODUCTS_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: GET_REDEEM_PRODUCTS_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const redeemPoints = (user: any, lang: any) => {
    const {aa_uuid} = user;
    return async (dispatch : any) => {
        try {
            dispatch({type: REDEEM_POINTS});
            let input: any = {
                ver: CONFIG.ver,
                act: 50001,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                aa_uuid: aa_uuid,
                m1: "Redeem Points",
                lg: lang,
                user: user,
                action: "userpoint",
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('request', "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: REDEEM_POINTS_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined){
                return dispatch({
                    type: REDEEM_POINTS_FAILED,
                    payload: "004: Something went wrong, please check your Internet Connection and try again",
                });
            }
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: REDEEM_POINTS_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                
                if (parseInt(results.code) !== 0)
                    return dispatch({
                        type: REDEEM_POINTS_FAILED,
                        payload: `[${results.code}] : ${results.message || ""}`,
                    });
                else {
                    return dispatch({
                        type: REDEEM_POINTS_SUCCESS,
                        payload: {results},
                    });
                }
            }
            return dispatch({
                type: REDEEM_POINTS_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: REDEEM_POINTS_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const redeemWhatsAppPoints = (user: any, lang: any) => {
    const {aa_uuid} = user;
    return async (dispatch : any) => {
        try {
            dispatch({type: REDEEM_POINTS});
            let input: any = {
                ver: CONFIG.ver,
                act: 50001,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                aa_uuid: aa_uuid,
                m1: "Redeem Points",
                lg: lang,
                user: user,
                action: "userpoint",
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('whatsapp/request', "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: REDEEM_POINTS_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined){
                return dispatch({
                    type: REDEEM_POINTS_FAILED,
                    payload: "004: Something went wrong, please check your Internet Connection and try again",
                });
            }
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: REDEEM_POINTS_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                
                if (parseInt(results.code) !== 0)
                    return dispatch({
                        type: REDEEM_POINTS_FAILED,
                        payload: `[${results.code}] : ${results.message || ""}`,
                    });
                else {
                    return dispatch({
                        type: REDEEM_POINTS_SUCCESS,
                        payload: {results},
                    });
                }
            }
            return dispatch({
                type: REDEEM_POINTS_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: REDEEM_POINTS_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

// export const redeemedProducts = (user: any, lang: any, body: any, pin: any, navigation: any) => {
//     const {aa_uuid} = user;
//     return async (dispatch: any) => {
//         try {
//             dispatch({type: REDEEMED_PRODUCTS});
//             if (!pin || pin === "") return dispatch({type: REDEEMED_PRODUCTS_FAILED, payload: "002: Please Enter Pin"});
//             let input: any = {
//                 ver: CONFIG.ver,
//                 act: 50003,
//                 user_agent: navigator.userAgent,
//                 app_id: CONFIG.app_id,
//                 m1: "Redeem Product",
//                 lg: lang,
//                 user: user,
//                 action: "redeemproduct",
//                 aa_uuid: aa_uuid,
//                 userphone: user.mobile,
//                 product_name: body.product_name,
//                 product_code: body.product_code,
//                 product_id: body.product_id,
//                 product_quantity: 1,
//                 single_points: body.product_redeem_point,
//                 total_points: body.product_redeem_point * body.quantity,
//                 redeem_type: body.redeem_type,
//                 p3: pin,
//             };
//             let headers = {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json'
//             };
//             input = addUserObjectToRequest(input);
//             let resultsFromAPI = await callBackendAPI('request', "POST", headers, input);
//             if (!resultsFromAPI)
//             return dispatch({
//                 type: REDEEMED_PRODUCTS_FAILED,
//                 payload: "003: Something went wrong, try again",
//             });
//             if (navigation === undefined)
//             return dispatch({
//                 type: REDEEM_PREPAID_TRANSACTION_FAILED,
//                 payload: "004: Something went wrong, try again",
//             });
//             if (resultsFromAPI.error !== undefined)
//             return dispatch({
//                 type: REDEEMED_PRODUCTS_FAILED,
//                 payload: "004: Something went wrong, please check your Internet Connection and try again",
//             });
//             if (!resultsFromAPI.hasOwnProperty("results"))
//             return dispatch({
//                 type: REDEEMED_PRODUCTS_FAILED,
//                 payload: "005: Something went wrong, try again",
//             });
//             let results = resultsFromAPI.results;
//             if (results.hasOwnProperty("code")) {
//                 if (parseInt(results.code) !== 0)
//                     return dispatch({
//                         type: REDEEMED_PRODUCTS_FAILED,
//                         payload: `Error Code: ${results.code} :: ${results.message || ""}`,
//                     });
//                 dispatch({
//                     type: REDEEMED_PRODUCTS_SUCCESS,
//                     payload: `${results.message}`,
//                 });
//                 return Alert.alert("Success", results.message, [
//                     {text: "OK", onPress: () => navigation.navigate("OthersRedemption")},
//                 ]);
//             }
    
//             return dispatch({
//                 type: REDEEMED_PRODUCTS_FAILED,
//                 payload: "007: Something went wrong, try again",
//             });
//         } catch (e) {
//             console.log(e);
//             return dispatch({
//                 type: REDEEMED_PRODUCTS_FAILED,
//                 payload: "OOPS SOMETHINGg WENT WRONG!",
//             });
//         }
//     };
// };


export const showQRModal = () => {
    return {type: SHOW_QR_MODAL};
};

export const unmountQRModal = () => {
    return {type: UNMOUNT_QR_MODAL};
};

export const unmountWaterView = () => {
    return {type: WATER_UNMOUNT};
};

export const unmountPurchaseVoucher = () =>{
    return {type: UNMOUNT_PURCHASE_VOUCHER}
}

export const unmountRedeemPrepaidView = () => {
    return {type: REDEEM_PREPAID_UNMOUNT};
};

export const unmountRedeemVoucherView = () => {
    return {type: REDEEM_VOUCHER_UNMOUNT};
};

export const unmountUserVouchers = () => {
    return {type: UNMOUNT_USER_VOUCHERS};
}

export const redeemWater = (user: any, lang: any, pin: any, navigation: any, name: any) => {
    const {aa_uuid} = user;
    return async (dispatch: any) => {
        try {
            dispatch({type: REDEEM_WATER});
            let pin = "";
            if (!pin || pin === "") return dispatch({type: REDEEM_WATER_FAILED, payload: "002: Please Enter Pin"});
            let input: any = {
                ver: CONFIG.ver,
                act: 50003,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "Redeem Product",
                lg: lang,
                user: user,
                action: "redeemproduct",
                aa_uuid: aa_uuid,
                userphone: user.mobile,
                product_name: name + " litre water",
                product_quantity: 1,
                total_points: name * 20,
                redeem_type: 2,
                p3: pin,
                product_id: 8,
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('request', "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: REDEEM_WATER_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: REDEEM_WATER_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
    
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: REDEEM_WATER_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
            if (parseInt(results.code) !== 0)
                return dispatch({
                    type: REDEEM_WATER_FAILED,
                    payload: `Error Code: ${results.code} :: ${results.message || ""}`,
                });
            }

            return dispatch({
                type: REDEEM_WATER_SUCCESS,
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: REDEEM_WATER_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const getRedeemedList = (user: any, lang: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({type: GET_REDEEMED_LIST});
            let input: any = {
                ver: CONFIG.ver,
                act: 50004,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "Get Redeemed List",
                lg: lang,
                user: user,
                action: "uncollected",
                aa_uuid: user.aa_uuid,
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('request', "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: GET_REDEEMED_LIST_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: GET_REDEEMED_LIST_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: GET_REDEEMED_LIST_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (parseInt(results.code) !== 0)
                    return dispatch({
                        type: GET_REDEEMED_LIST_FAILED,
                        payload: `Error Code: ${results.code} :: ${results.message || ""}`,
                    });
                else {
                    return dispatch({
                        type: GET_REDEEMED_LIST_SUCCESS,
                        payload: {results},
                    });
                }
            }
    
            return dispatch({
                type: GET_REDEEMED_LIST_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: GET_REDEEMED_LIST_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const getUsedPointHistory = (user: any, lang: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({type: GET_USED_POINT_HISTORY});
            let input: any = {
                ver: CONFIG.ver,
                act: 50007,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "Get Used Point History",
                lg: lang,
                user: user,
                action: "redeem",
                aa_uuid: user.aa_uuid,
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('request', "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: GET_USED_POINT_HISTORY_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: GET_USED_POINT_HISTORY_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: GET_USED_POINT_HISTORY_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (parseInt(results.code) !== 0)
                    return dispatch({
                        type: GET_USED_POINT_HISTORY_FAILED,
                        payload: `Error Code: ${results.code} :: ${results.message || ""}`,
                    });
                else {
                    return dispatch({
                        type: GET_USED_POINT_HISTORY_SUCCESS,
                        payload: {results},
                    });
                }
            }
    
            return dispatch({
                type: GET_USED_POINT_HISTORY_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: GET_USED_POINT_HISTORY_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const getReceivedPointHistory = (user: any, lang: any) => {
    return async (dispatch: any)=> {
        try {
            dispatch({type: GET_RECEIVED_POINT_HISTORY});
            let input: any = {
                ver: CONFIG.ver,
                act: 50007,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "Get Received Point History",
                lg: lang,
                user: user,
                action: "issue",
                aa_uuid: user.aa_uuid,
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('request', "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: GET_RECEIVED_POINT_HISTORY_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: GET_RECEIVED_POINT_HISTORY_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: GET_RECEIVED_POINT_HISTORY_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (parseInt(results.code) !== 0)
                    return dispatch({
                        type: GET_RECEIVED_POINT_HISTORY_FAILED,
                        payload: `Error Code: ${results.code} :: ${results.message || ""}`,
                    });
                else {
                    return dispatch({
                        type: GET_RECEIVED_POINT_HISTORY_SUCCESS,
                        payload: {results},
                    });
                }
            }
    
            return dispatch({
                type: GET_RECEIVED_POINT_HISTORY_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: GET_RECEIVED_POINT_HISTORY_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};
  
export const getStampsHistory = (user: any, lang: any, merchant_id: any) => {
    return async (dispatch: any)=> {
        try {
            dispatch({type: GET_STAMPS_HISTORY});
            let input: any = {
                ver: CONFIG.ver,
                act: 50011,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "Get Stamps History",
                lg: lang,
                user: user,
                action: "list-details",
                user_type: "user",
                merchant_id: merchant_id,
                aa_uuid: user.aa_uuid,
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('request', "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: GET_STAMPS_HISTORY_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: GET_STAMPS_HISTORY_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: GET_STAMPS_HISTORY_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (parseInt(results.code) !== 0)
                    return dispatch({
                        type: GET_STAMPS_HISTORY_FAILED,
                        payload: `Error Code: ${results.code} :: ${results.message || ""}`,
                    });
                else {
                    return dispatch({
                        type: GET_STAMPS_HISTORY_SUCCESS,
                        payload: {results},
                    });
                }
            }
    
            return dispatch({
                type: GET_STAMPS_HISTORY_FAILED,
                payload: "007: Something went wrong, try again",
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: GET_STAMPS_HISTORY_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};

export const fetchStampsList = (user: any, lang = 'en') => {
    return async (dispatch: any) => {
        try {
            if (user === undefined)
            return dispatch({
                type: FETCH_STAMPS_LIST_FAILED,
                payload: '001: Oops Something went wrong, Login in again'});
    
            dispatch({type: FETCH_STAMPS_LIST});
            let input: any = {
                ver: CONFIG.ver,
                act: 50011,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                aa_uuid: user?.aa_uuid || '',
                user_type: "user",
                action: 'list',
                merchant_id: "",
                m1: 'Get Kita Stamps',
                lg: lang,
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('request', "POST", headers, input);
            if (!resultsFromAPI)
            return dispatch({
                type: FETCH_STAMPS_LIST_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: FETCH_STAMPS_LIST_FAILED,
                payload: '003: Something went wrong, please check your Internet Connection and try again',
            });
            if (!resultsFromAPI.hasOwnProperty('results'))
            return dispatch({
                type: FETCH_STAMPS_LIST_FAILED,
                payload: '004 Something went wrong, try again'});
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty('code')) {
                if (results.code !== 0)
                    return dispatch({
                        type: FETCH_STAMPS_LIST_FAILED,
                        payload: `Error Code: ${results.code} :: ${results.message || ''}`,
                    });
                else {
                    return dispatch({
                        type: FETCH_STAMPS_LIST_SUCCESS,
                        payload: {results},
                    });
                }
            }
            return dispatch({
                type: FETCH_STAMPS_LIST_FAILED,
                payload: '007: Something went wrong, try again'
            });
        } catch (e) {
            return dispatch({
                type: FETCH_STAMPS_LIST_FAILED,
                payload: 'OOPS SOMETHING WENT WRONG'
            });
        }
    }
};
  
export const VoucherCheckPin = (lang: any, pin: any) => {
    return async (dispatch: any) =>{
        try{
            dispatch({type: VOUCHER_CHECK_PIN})
            if(!pin || pin === ""){
                return dispatch({type: VOUCHER_CHECK_PIN_FAILED, payload: "002: Please Enter Pin"});
            }
            let input: any = {
                ver: CONFIG.ver,
                act: 50012,
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "Pin validation",
                lg: lang,
                p3: pin,
            };
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            input = addUserObjectToRequest(input);
            let resultsFromAPI = await callBackendAPI('request', "POST", headers, input);
            if(!resultsFromAPI)
            return dispatch({
                type: VOUCHER_CHECK_PIN_FAILED,
                payload: "003: Something went wrong, try again",
            });
            if (resultsFromAPI.error !== undefined)
            return dispatch({
                type: VOUCHER_CHECK_PIN_FAILED,
                payload: "004: Something went wrong, please check your Internet Connection and try again",
            });
            if (!resultsFromAPI.hasOwnProperty("results"))
            return dispatch({
                type: VOUCHER_CHECK_PIN_FAILED,
                payload: "005: Something went wrong, try again",
            });
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
            if (parseInt(results.code) !== 0)
                return dispatch({
                    type: VOUCHER_CHECK_PIN_FAILED,
                    payload: `Error Code: ${results.code} :: ${results.message || ""}`,
                });
            }

            return dispatch({
                type: VOUCHER_CHECK_PIN_SUCCESS,
            });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: VOUCHER_CHECK_PIN_FAILED,
                payload: "OOPS SOMETHING WENT WRONG!",
            });
        }
    };
};
  
// export const PurchaseCashVoucher = (user, lang, pinArray, item, merchant_id, navigation) => {
//     const {aa_uuid} = user;
//     return async dispatch =>{
//       try{
//          dispatch({type: PURCHASE_CASH_VOUCHER})
//          let pin = "";
//          pinArray.map(i => pin = pin + i);
//          if(!pin || pin === ""){
//            return dispatch({type: PURCHASE_CASH_VOUCHER_FAILED, payload: "002: Please Enter Pin"});
//          }
  
//          let input = {
//           ver: VersionInfo.appVersion,
//           act: 50013,
//           did: DeviceInfo.getUniqueId(),
//           user_id: aa_uuid,
//           module: "KITA e-Voucher",
//           description: item.prod_name,
//           remark: item.prod_code,
//           issuePoints: item.price_Amount,
//           type: "issue",
//           merchant_id: merchant_id,
//           m1: "Purchase Cash Voucher",
//           lg: lang,
//           user: user,
//           p3: pin,
//         };
//         let headers = {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         };
//         if(user === undefined){
//           return dispatch({
//             type: PURCHASE_CASH_VOUCHER_FAILED,
//             payload: "9999: Something went wrong, try again"
//           })
//         }
//         input = addUserObjectToRequest(input, user);
//         let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
//         if(!resultsFromAPI)
//           return dispatch({
//             type: PURCHASE_CASH_VOUCHER_FAILED,
//             payload: "003: Something went wrong, try again",
//           });
//         if (resultsFromAPI.err !== null)
//           return dispatch({
//             type: PURCHASE_CASH_VOUCHER_FAILED,
//             payload: "004: Something went wrong, please check your Internet Connection and try again",
//           });
  
//         if (!resultsFromAPI.hasOwnProperty("results"))
//           return dispatch({
//             type: PURCHASE_CASH_VOUCHER_FAILED,
//             payload: "005: Something went wrong, try again",
//           });
//         let results = resultsFromAPI.results;
  
//         if (results.hasOwnProperty("code")) {
//           if (parseInt(results.code) !== 0)
//             return dispatch({
//               type: PURCHASE_CASH_VOUCHER_FAILED,
//               payload: `Error Code: ${results.code} :: ${results.message || ""}`,
//             });
//           dispatch({
//             type: PURCHASE_CASH_VOUCHER_SUCCESS,
//           });
  
//           dispatch({
//             type: PIN_UNMOUNT,
//           });
  
//           return Alert.alert("Success", results.message, [
//           {text: "OK"},
  
//         ]);
//       };
  
//       } catch (e) {
//         console.log(e);
//         return dispatch({
//           type: PURCHASE_CASH_VOUCHER_FAILED,
//           payload: "OOPS SOMETHING WENT WRONG!",
//         });
//       }
//     };
//   };
  
//   export const fetchCashVoucherMerchants = (user, lang = 'en') => {
//     return async dispatch => {
//       try {
//         if (user === undefined)
//           return dispatch({
//             type: FETCH_CASH_VOUCHER_MERCHANTS_FAILED,
//             payload: '001: Oops Something went wrong, Login in again'});
  
//         dispatch({type: FETCH_CASH_VOUCHER_MERCHANTS});
//         let input = {
//           ver: VersionInfo.appVersion,
//           act: 50014,
//           did: DeviceInfo.getUniqueId(),
//           aa_uuid: "",
//           user_type: "user",
//           action: 'list-merchant',
//           merchant_id: "",
//           m1: 'Fetch Cash Voucher Merchants List',
//           lg: lang,
//         };
//         input = addUserObjectToRequest(input, user);
//         let headers = {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         };
//         let resultsFromAPI = await callBackendAPI(
//           'request',
//           'POST',
//           headers,
//           input,
//         );
//         if (!resultsFromAPI)
//         return dispatch({
//           type: FETCH_CASH_VOUCHER_MERCHANTS_FAILED,
//           payload: "003: Something went wrong, try again",
//         });
//         if (resultsFromAPI.err !== null)
//         return dispatch({
//           type: FETCH_CASH_VOUCHER_MERCHANTS_FAILED,
//           payload: '003: Something went wrong, please check your Internet Connection and try again',
//         });
  
//         if (!resultsFromAPI.hasOwnProperty('results'))
//           return dispatch({
//             type: FETCH_CASH_VOUCHER_MERCHANTS_FAILED,
//             payload: '004 Something went wrong, try again'});
//         let results = resultsFromAPI.results;
  
  
//         if (results.hasOwnProperty('code')) {
//           if (results.code !== 0){
//             return dispatch({
//               type: FETCH_CASH_VOUCHER_MERCHANTS_FAILED,
//               payload: `Error Code: ${results.code} :: ${results.message || ''}`,
//             });
//           }
//           else {
//             return dispatch({
//               type: FETCH_CASH_VOUCHER_MERCHANTS_SUCCESS,
//               payload: {results},
//             });
//           }
//         }
//         return dispatch({
//           type: FETCH_CASH_VOUCHER_MERCHANTS_FAILED,
//           payload: '007: Something went wrong, try again'});
//       } catch (e) {
//         return dispatch({
//           type: FETCH_CASH_VOUCHER_MERCHANTS_FAILED,
//           payload: 'OOPS SOMETHING WENT WRONG'});
//       }
//     }
//   };
  
//   export const fetchMerchantCashVouchers = (user, lang = 'en', merchant_id) => {
//     return async dispatch => {
//       try {
//         if (user === undefined)
//           return dispatch({
//             type: FETCH_MERCHANT_CASH_VOUCHERS_FAILED,
//             payload: '001: Oops Something went wrong, Login in again'});
  
//         dispatch({type: FETCH_MERCHANT_CASH_VOUCHERS});
//         let input = {
//           ver: VersionInfo.appVersion,
//           act: 50014,
//           did: DeviceInfo.getUniqueId(),
//           aa_uuid: "",
//           user_type: "user",
//           action: 'list-merchant-details',
//           merchant_id: merchant_id,
//           m1: 'Fetch Merchant Cash Voucher List',
//           lg: lang,
//         };
//         input = addUserObjectToRequest(input, user);
//         let headers = {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         };
  
//         let resultsFromAPI = await callBackendAPI(
//           'request',
//           'POST',
//           headers,
//           input,
//         );
//         if (!resultsFromAPI)
//         return dispatch({
//           type: FETCH_MERCHANT_CASH_VOUCHERS_FAILED,
//           payload: "003: Something went wrong, try again",
//         });
//         if (resultsFromAPI.err !== null)
//         return dispatch({
//           type: FETCH_MERCHANT_CASH_VOUCHERS_FAILED,
//           payload: '003: Something went wrong, please check your Internet Connection and try again',
//         });
  
//         if (!resultsFromAPI.hasOwnProperty('results'))
//           return dispatch({
//             type: FETCH_MERCHANT_CASH_VOUCHERS_FAILED,
//             payload: '004 Something went wrong, try again'});
//         let results = resultsFromAPI.results;
  
  
//         if (results.hasOwnProperty('code')) {
//           if (results.code !== 0){
//             return dispatch({
//               type: FETCH_MERCHANT_CASH_VOUCHERS_FAILED,
//               payload: `Error Code: ${results.code} :: ${results.message || ''}`,
//             });
//           }
//           else {
//             return dispatch({
//               type: FETCH_MERCHANT_CASH_VOUCHERS_SUCCESS,
//               payload: {results},
//             });
//           }
//         }
//         return dispatch({
//           type: FETCH_MERCHANT_CASH_VOUCHERS_FAILED,
//           payload: '007: Something went wrong, try again'});
//       } catch (e) {
//         return dispatch({
//           type: FETCH_MERCHANT_CASH_VOUCHERS_FAILED,
//           payload: 'OOPS SOMETHING WENT WRONG'});
//       }
//     }
//   };
  
//   export const fetchCashVoucherHistory = (user, lang = 'en', merchant_id) => {
//     return async dispatch => {
//       try {
//         if (user === undefined)
//           return dispatch({
//             type: FETCH_CASH_VOUCHER_HISTORY_FAILED,
//             payload: '001: Oops Something went wrong, Login in again'});
  
//         dispatch({type: FETCH_CASH_VOUCHER_HISTORY});
//         let input = {
//           ver: VersionInfo.appVersion,
//           act: 50014,
//           did: DeviceInfo.getUniqueId(),
//           aa_uuid: user.aa_uuid,
//           user_type: "user",
//           action: 'list-details',
//           merchant_id: merchant_id,
//           m1: 'Fetch Cash Voucher History',
//           lg: lang,
//         };
//         input = addUserObjectToRequest(input, user);
//         let headers = {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         };
  
//         let resultsFromAPI = await callBackendAPI(
//           'request',
//           'POST',
//           headers,
//           input,
//         );
//         if (!resultsFromAPI)
//         return dispatch({
//           type: FETCH_CASH_VOUCHER_HISTORY_FAILED,
//           payload: "003: Something went wrong, try again",
//         });
//         if (resultsFromAPI.err !== null)
//         return dispatch({
//           type: FETCH_CASH_VOUCHER_HISTORY_FAILED,
//           payload: '003: Something went wrong, please check your Internet Connection and try again',
//         });
  
//         if (!resultsFromAPI.hasOwnProperty('results'))
//           return dispatch({
//             type: FETCH_CASH_VOUCHER_HISTORY_FAILED,
//             payload: '004 Something went wrong, try again'});
//         let results = resultsFromAPI.results;
  
  
//         if (results.hasOwnProperty('code')) {
//           if (results.code !== 0){
//             return dispatch({
//               type: FETCH_CASH_VOUCHER_HISTORY_FAILED,
//               payload: `Error Code: ${results.code} :: ${results.message || ''}`,
//             });
//           }
//           else {
//             return dispatch({
//               type: FETCH_CASH_VOUCHER_HISTORY_SUCCESS,
//               payload: {results},
//             });
//           }
//         }
//         return dispatch({
//           type: FETCH_CASH_VOUCHER_HISTORY_FAILED,
//           payload: '007: Something went wrong, try again'});
//       } catch (e) {
//         return dispatch({
//           type: FETCH_CASH_VOUCHER_HISTORY_FAILED,
//           payload: 'OOPS SOMETHING WENT WRONG'});
//       }
//     }
//   };
  
  
//   export const fetchAds = (user, lang) => {
//     return async dispatch => {
//       try {
//         dispatch({type: FETCH_ADS});
//         let input = {
//           ver: VersionInfo.appVersion,
//           act: 50008,
//           did: DeviceInfo.getUniqueId(),
//           aa_uuid: user?.aa_uuid || "",
//           action: "AdvertCarousel",
//           m1: "Get Kita ADS",
//           lg: lang
//         }
//         let headers = {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         };
//         input = addUserObjectToRequest(input, user);
//         let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
//         if (!resultsFromAPI)
//           return dispatch({
//             type: FETCH_ADS_FAILED,
//             payload: "003: Something went wrong, try again",
//           });
//         if (resultsFromAPI.err !== null)
//           return dispatch({
//             type: FETCH_ADS_FAILED,
//             payload: "004: Something went wrong, please check your Internet Connection and try again",
//           });
  
//         if (!resultsFromAPI.hasOwnProperty("results"))
//           return dispatch({
//             type: FETCH_ADS_FAILED,
//             payload: "005: Something went wrong, try again",
//           });
//         let results = resultsFromAPI.results;
  
//         if (results.hasOwnProperty("code")) {
//           if (parseInt(results.code) !== 0)
//             return dispatch({
//               type: FETCH_ADS_FAILED,
//               payload: `Error Code: ${results.code} :: ${results.message || ""}`,
//             });
//           else {
//             return dispatch({
//               type: FETCH_ADS_SUCCESS,
//               payload: {results},
//             });
//           }
//         }
  
//         return dispatch({
//           type: FETCH_ADS_FAILED,
//           payload: "007: Something went wrong, try again",
//         });
//       } catch (e) {
//         console.log(e);
//         return dispatch({
//           type: FETCH_ADS_FAILED,
//           payload: "OOPS SOMETHING WENT WRONG!",
//         });
//       }
//     };
//   };
  
//   export const fetchQRString = (user, lang, qr) => {
//     return async dispatch => {
//       try {
//         dispatch({type: FETCH_QR_STRING});
//         let a = {}
//         a = addUserObjectToRequest(a, user);
//         let input = {
//           ver: VersionInfo.appVersion,
//           act: 50015,
//           did: DeviceInfo.getUniqueId(),
//           qr_json: qr,
//           lg: lang,
//           input_json : a,
//         }
//         let headers = {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         };
//         input = addUserObjectToRequest(input, user);
  
//         let resultsFromAPI = await callBackendAPI("request", "POST", headers, input);
//         if (!resultsFromAPI)
//           return dispatch({
//             type: FETCH_QR_STRING_FAILED,
//             payload: "003: Something went wrong, try again",
//           });
//         if (resultsFromAPI.err !== null)
//           return dispatch({
//             type: FETCH_QR_STRING_FAILED,
//             payload: "004: Something went wrong, please check your Internet Connection and try again",
//           });
  
//         if (!resultsFromAPI.hasOwnProperty("results"))
//           return dispatch({
//             type: FETCH_QR_STRING_FAILED,
//             payload: "005: Something went wrong, try again",
//           });
//         let results = resultsFromAPI.results;
  
//         if (results.hasOwnProperty("code")) {
//           if (parseInt(results.code) !== 0)
//             return dispatch({
//               type: FETCH_QR_STRING_FAILED,
//               payload: `Error Code: ${results.code} :: ${results.message || ""}`,
//             });
//           else {
//             return dispatch({
//               type: FETCH_QR_STRING_SUCCESS,
//               payload: {results},
//             });
//           }
//         }
  
//         return dispatch({
//           type: FETCH_QR_STRING_FAILED,
//           payload: "007: Something went wrong, try again",
//         });
//       } catch (e) {
//         console.log(e);
//         return dispatch({
//           type: FETCH_QR_STRING_FAILED,
//           payload: "OOPS SOMETHING WENT WRONG!",
//         });
//       }
//     };
//   };