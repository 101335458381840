import { FC, useEffect, useRef, useState } from "react";
import { IError, RouteComponent } from "../../type";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import Header from "../../components/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../utils";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { checkUserEkycStatus } from "../../actions/EkycAction";
import { CONFIG } from "../../config";
import { HOME_UPDATE_WALLET_BALANCE, TRANSFER_CREATE_FAILED } from "../../actions/type";
import DialogAlert from "../../components/DialogAlert";
import { getBalance } from "../../actions/AccountAction";
import { useNavigate } from "react-router-dom";
import PinInput from "../../components/PinInput";
import _ from "lodash";

interface Props extends RouteComponent{
    logOut: () => void;
}

const Transfer: FC<Props> = (props) => {
    const { logOut } = props;
    const isMobile: boolean = useCheckIsMobile();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [receiver, setReceiver] = useState("");
    const [confirmation, setConfirmation] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const [pinNumber, setPinNumber] = useState<string>('');
    const { ekycLoader, ekycError, ekycMessage, ekycVerified, ekycShowPage } = useSelector((state: RootState) => state.ekyc);
    const { balance, pending_amt } = useSelector((state: RootState) => state.account);
    const { lang, user } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<IError>({ error: false, message: "", forgot_pin: 0});
    const navigate = useNavigate();
    
    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);
    const TOTAL_BALANCE = Number(balance || 0) + Number(pending_amt || 0);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(checkUserEkycStatus(user));
            dispatch(getBalance(lang, user));
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }

        return () => {
            shouldLockEffect.current = true;
        }
    }, [])
    
    const checkEnteredNumber = async () => {
        try{
            setLoading(true);
            if (!phoneNumber){
                setLoading(false);
                return setError({error: true, message: 'Error 001: Phone number missing, please try again'});
            }
            let input: any = {
                ver: CONFIG.ver,
                act: String(20013),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: phoneNumber.replace(/ /g, ""),
                m1: "P2P Check number",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI){
                setLoading(false);
                return setError({error: true, message: 'Error 002: Something went wrong, try again'});
            }
            if (resultsFromAPI.error !== undefined){
                setLoading(false);
                return setError({error: true, message: 'Error 003: Something went wrong, please check your Internet Connection and try again'});
            }
            if (!resultsFromAPI.hasOwnProperty("results")){
                setLoading(false);
                return setError({error: true, message: 'Error 006: Something went wrong, try again'});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                if (results.code !== 0){
                    setLoading(false);
                    return setError({ error: true, message: `[${results.code}] : ${results.message || ""}`})
                }

                if (results.hasOwnProperty("balance") && results.balance)
                    dispatch({
                    type: HOME_UPDATE_WALLET_BALANCE,
                    payload: {
                        balance: results.balance,
                        pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null
                    }
                })

                setReceiver(results?.user.name);
                setError({error: false, message: ''});
                return setLoading(false);
            }
            setLoading(false);
            return setError({ error: false, message: 'Error 007: Something went wrong, try again'})
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: false, message: 'Oops something went wrong. Please try again'})
        }
    }

    const handleCreateTransfer = async () => {
        try{
            setLoading(true);
            if (!pinNumber || pinNumber === ''){
                setShowPin(false);
                setLoading(false);
                return setError({ error: true, message: "Please enter Pin" });
            }
            let input: any = {
                ver: CONFIG.ver,
                act: String(20011),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: String(amount),
                p2: receiver,
                p3: phoneNumber.replace(/ /g, ""),
                p4: description,
                p5: pinNumber,
                m1: "Transfer",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                setPinNumber('');
                return setError({ error: true, message: "005: Something went wrong"});
            }
            if (resultsFromAPI.error !== undefined) {
                setLoading(false);
                setPinNumber('');
                return setError({ error: true, message: "006: Something went wrong"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                setPinNumber('');
                return setError({ error: true, message: "007: Something went wrong"});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")){
                if (results.code !== 0) {
                    setLoading(false);
                    setPinNumber('');
                    if (results?.renderForgotPin)
                        return setError({error: true, message: `[${results.code}] : ${results.message || ""}`, forgot_pin: results.renderForgotPin});
                }

                if (results.hasOwnProperty("balance") && results.balance)
                    dispatch({ type: HOME_UPDATE_WALLET_BALANCE,
                    payload: {balance: results.balance, pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null }})

                if (results.code === 0){
                    return navigate('/receipt', { state : { receiptInfo: results.receipt, messageKey: 'prepaidselected.receipt.subTitle' } });         
                }

                return setError({error: true, message: `[${results.code}] : ${results.message || ""}`});
            }

            setLoading(false);
            setPinNumber('');
            return setError({ error: true, message: "008: Something went wrong"});
        }
        catch (e) {
            console.log(e);
            return dispatch({ type: TRANSFER_CREATE_FAILED, payload: "Oops something went wrong!"})
        }
    }

    const renderHeader = () => {
        if(!ekycVerified) 
            return null;
        return (
            <TextField
                label={'Enter Phone no.'}
                variant="outlined"
                fullWidth
                margin="normal"
                value={phoneNumber}
                placeholder="+6012 3456789"
                onChange={(e) => setPhoneNumber(e.target.value)}
            />             
        )
    }

    const renderPhoneNumber = () => {
        if (!ekycVerified) {
            return null;
        }
        if (phoneNumber !== ""){
            return (
                <div>
                    {(loading || ekycLoader) ? (
                        <Box
                            sx={{
                                mt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress size={30} color="primary" />
                        </Box>
                    ):(
                        <Box
                            sx={{
                                mt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Grid container direction="column" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Typography variant="h6">
                                        Click Continue to Transfer to this number
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">
                                        "{phoneNumber || ''}"
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        onClick={() => checkEnteredNumber()}
                                    >
                                        Continue
                                    </Button>
                                </Grid>
                            </Grid>              
                        </Box>
                    )} 
                </div>
            )
        }
        return null
    }
    
    const renderSelectedNumber = () => {
        const initial = receiver ? receiver.charAt(0).toUpperCase() : '';
        return (
            <div>
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Card>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, padding: 1}}>
                                <Avatar>{initial}</Avatar>
                                <Typography variant="h5" component="div">
                                    {receiver || "Receiver Name"}
                                </Typography>
                            </Box>
                            <Typography variant="body1" color="text.secondary">
                                {phoneNumber || "Phone Number"}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, textAlign: 'left', width: '100%' }}>
                        {getLabelValue(lang, 'transfer.selected.title')}
                    </Typography>
                    <TextField
                        label={`${getLabelValue(lang, 'cashout.amount.label')} (RM)`}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={amount}
                        placeholder="0.00"
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <Typography variant="body1" sx={{ textAlign: 'left', width: '100%' }}>
                        {`Your current balance: (RM ${TOTAL_BALANCE || '0.00'})`}
                    </Typography>                                    
                    <TextField
                        label={'Enter description'}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={description}
                        placeholder="Enter description"
                        onChange={(e) => setDescription(e.target.value)}
                    />   
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 2}}>
                        <Button
                            variant="contained"
                            color="success" 
                            sx={{ width: '100px' }}
                            onClick={()=> setReceiver("")}
                        >
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                BACK
                            </Typography>
                        </Button>                    
                        <Button
                            variant="contained"
                            color= "primary"
                            sx={{ width: '100px' }}
                            onClick={() => setConfirmation(true)}
                            disabled={Number(amount) === 0.00 || Number(amount) > TOTAL_BALANCE}
                        >
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                {getLabelValue(lang, 'transfer.selected.send')}
                            </Typography>
                        </Button>                                  
                    </Box>                      
                      
                </Box>               
            </div>
        )
    }

    const renderTransferConfirmation = () => {
        return (
            <div>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="subtitle1" fontWeight="semiBold">
                            Transfer To
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="body1">
                            {receiver === "" ? phoneNumber || "" : receiver}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 0, mx: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="subtitle1" fontWeight="semiBold">
                            {getLabelValue(lang, 'transfer.confirm.amount')}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" fontWeight="bold" color="primary">
                            RM {amount || '0.00'}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 0, mx: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="subtitle1" fontWeight="semiBold">
                            Description
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="body1">
                            {description || ''}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 2}}>
                    <Button
                        variant="contained"
                        color="success" 
                        sx={{ width: '100px' }}
                        onClick={()=> setConfirmation(false)}
                    >
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            
                            BACK
                        </Typography>
                    </Button>                    
                    <Button
                        variant="contained"
                        color= "primary"
                        sx={{ width: '100px' }}
                        onClick={()=> setShowPin(true)}
                    >
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            CONFIRM
                        </Typography>
                    </Button>                             
                </Box>            
            </div>
        )
    }

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'home.mmproducts.product5')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6} lg={4}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >   
                            <CardContent>
                            {
                                (receiver && confirmation) ? (
                                    <>
                                        {renderTransferConfirmation()}
                                    </>
                                ) : (receiver && !loading) ? (
                                    <>
                                        {renderSelectedNumber()}
                                    </>
                                ) : (
                                    <>
                                        {renderHeader()}
                                        {renderPhoneNumber()}
                                    </>
                                )
                            }                            
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
                {showPin && 
                    <PinInput
                        open={showPin}
                        onCancel={() => setShowPin(false)}
                        onSuccess={async () => {
                            setShowPin(false);
                            await handleCreateTransfer();
                        }}
                        yesButton={'Submit'}
                        cancelButton={'Cancel'}
                        pinNumber ={pinNumber}
                        onPinChange={(e: any)=>{
                            setPinNumber(e.target.value)
                        }}
                        lang={lang}
                    />
                }
                {error.error && (
                    <DialogAlert
                        open={error.error}
                        onCancel={() => {
                            setError({ error: false, message: '', forgot_pin: 0 });
                            logOut();
                            navigate('/forgotpin', { state: { user: user } });
                        }}
                        onSuccess={() => {
                            setError({ error: false, message: '', forgot_pin: 0 });
                            setPinNumber('');
                        }}
                        title={'Attention'}
                        content={new Array(error.message)}
                        yesButton={'OK'}
                        cancelButton={error.forgot_pin === 1 ? (lang === 'en' ? 'FORGOT PIN' : 'LUPA PIN') : ''}
                        reportIssue={error.reportIssue?.show}
                        req_idx={error.reportIssue?.idx}
                        onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
                        reportTitle={error?.title}
                        reportDetails={error?.details}
                        trans_id={error?.trans_id}
                        ticket_type={error?.ticket_type}
                    />
                )} 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default Transfer;
