import React, {FC, useState} from 'react';
import {Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography} from "@mui/material";
import { ITransaction } from '../type';
import { tokens } from '../theme';
import Grid2 from "@mui/material/Unstable_Grid2";
import { getLabelValue } from '../utils';

const colors = tokens();
type PROPS = {
    transaction: ITransaction;
    trxnQueueMessage: string;
    lang: string;
    selected: number;
    isMobile: boolean;
    loading: boolean;
    handleCancel: () => void;
    checkForTrxnQueue: () => void;
    handleBack: () => void;
    handlePaymentByWallet: () => void;
    handlePaymentByFPX: () => void;
    onSelect: (index: number) => void;
    index: number;
}

const BookingCard: FC<PROPS> = (props) => {
    const {
        transaction,
        trxnQueueMessage,
        lang,
        selected,
        isMobile = false,
        loading = false,
        handleCancel,
        checkForTrxnQueue,
        handleBack,
        handlePaymentByWallet,
        handlePaymentByFPX,
        onSelect,
        index
    } = props;
    const [showPaymentMethod, setShowPaymentMethod] = useState(false);

    type AlertDialogProps = {
        open: boolean;
    };

    const ConfirmDialog: FC<AlertDialogProps> = ({ open }) => {
        const back = () => {
            handleBack();
            setShowPaymentMethod(false);
        };

        const paymentByFPX = () => {
            handlePaymentByFPX();
            setShowPaymentMethod(false);
        };

        const paymentByWallet = () => {
            handlePaymentByWallet();
            setShowPaymentMethod(false);
        };

        return(
            <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            
          >
            <DialogContent>
            <Typography variant={'h3'} align={"center"} sx={{ color: colors.primary[500], fontWeight: 'bold' }}>
                {`RM ${transaction.total_charge.toFixed(2) || ''}`}
            </Typography>
            <Typography variant={'h6'} align={"center"} sx={{ color: colors.black[900], fontWeight: 'bold' }}>
                {transaction.bene_name || ''}
            </Typography>
            <Typography variant={'h6'} align={"center"} sx={{ color: colors.black[900] }}>
                {getLabelValue(lang, 'kirim.transaction.cardno')}{transaction.bene_card_no || ''}
            </Typography>
            {trxnQueueMessage !== '' ? (
                <Box sx={{ 
                    border: 1, 
                    borderColor: 'grey.500', 
                    p: 2, 
                    borderRadius: 2,
                    backgroundColor: 'grey.200' 
                }}>
                    <Typography variant={'h6'} align={"center"} sx={{ color: colors.black[600] }}>
                        {trxnQueueMessage}
                    </Typography>
                </Box>
            ) : null}
            </DialogContent>
            <DialogActions>
                <Button sx={buttonStyle} onClick={back} color="primary">BACK</Button>
                <Button sx={buttonStyle} onClick={paymentByFPX} color="primary">
                    PAY BY FPX
                </Button>
                <Button sx={buttonStyle} onClick={paymentByWallet} color="primary">
                    PAY BY MM WALLET
                </Button>
            </DialogActions>
          </Dialog>
        );
    };

      
    return (
        <Box
            bgcolor={selected === index ? colors.blue[500] : colors.white[400]}
            sx={styles(selected, index).containerStyle}
            p={2}
            onClick={() => onSelect(index)}
        >
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} fontWeight={'bold'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        Booking ID: 
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h5'} fontWeight={'bold'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.booking_id || ''}
                    </Typography>
                </Grid2>                
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {getLabelValue(lang, 'kirim.transaction.beneficiary')}
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.bene_name || ''}
                    </Typography>
                </Grid2>
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {getLabelValue(lang, 'kirim.transaction.cardno')}
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.bene_card_no || ''}
                    </Typography>
                </Grid2>                    
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {getLabelValue(lang, 'kirim.transaction.customerhp')}
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.customer_hp || ''}
                    </Typography>
                </Grid2>                    
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {getLabelValue(lang, 'kirim.transaction.country')}
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.country || ''}
                    </Typography>
                </Grid2>                    
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {getLabelValue(lang, 'kirim.transaction.provider')}
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.providerlabel || ''}
                    </Typography>
                </Grid2>                    
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {getLabelValue(lang, 'kirim.transaction.booktime')}
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.dtCreated || ''}
                    </Typography>
                </Grid2>                    
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        Booking Expiry Time
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.booking_expiry || ''}
                    </Typography>
                </Grid2>                    
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {getLabelValue(lang, 'kirim.transaction.rate')}
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.remit_ex_rate || ''}
                    </Typography>
                </Grid2>                    
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {`Send Amount in ${transaction.remit_foreign_currency}`}
                    </Typography>
                    {transaction.special_content ? (
                        <Typography variant={'body2'} color={'primary'} sx={{ color: selected === index ? colors.primary[600] : colors.primary[500] }}>
                            {`(${transaction.special_content})`}
                        </Typography>                            
                    ) : <></>
                    }
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.special_rate > 0 ? transaction.special_rate : transaction.remit_foreign_amt || ''}
                    </Typography>
                </Grid2>                    
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        Send Amount in RM
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.remit_rm_amt || ''}
                    </Typography>
                </Grid2>                        
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        Service Charge in RM
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {transaction.service_charge || ''}
                    </Typography>
                </Grid2>                        
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        Total Discount in RM
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {`(${transaction.kita_discount_amt || ''})`}
                    </Typography>
                </Grid2>                        
            </Grid2>
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.primary[600] : colors.primary[500], fontWeight: 'bold' }}>
                        Total Charge in RM
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.primary[600] : colors.primary[500], fontWeight: 'bold' }}>
                        {transaction.total_charge.toFixed(2) || ''}
                    </Typography>
                </Grid2>                        
            </Grid2>
            {/* {trxnQueueMessage !== '' ? (
            <Grid2
                container
                p={1.5}
                pb={0}
            >
                <Grid2 xs={4} md={5}>
                    <Typography variant={'body1'} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        TrxnQueue Message:
                    </Typography>
                </Grid2>
                <Grid2 xs={8} md={7}>
                    <Typography variant={'h6'} align={"right"} sx={{ color: selected === index ? colors.white[100] : colors.black[900] }}>
                        {trxnQueueMessage}
                    </Typography>
                </Grid2>                        
            </Grid2>                     
            ) : null} */}
            <Grid2
                display="flex"
                justifyContent="center"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            fullWidth={isMobile}
                            sx={style(isMobile).submitButtonStyle}
                            onClick={handleCancel}
                            color="success"
                            disabled={loading}
                        >
                            {getLabelValue(lang, 'kirim.bookrate.cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button
                            variant="contained"
                            fullWidth={isMobile}
                            sx={style(isMobile).submitButtonStyle}
                            onClick={() => {
                                checkForTrxnQueue();
                                setShowPaymentMethod(true);
                            }}
                            color="primary"
                            disabled={loading}
                        >
                            {getLabelValue(lang, 'kirim.bookrate.paynow')}
                        </Button>
                    </Grid>
                </Grid>            
            </Grid2>  
            <Divider sx={{ mt: 2}}/>      
            {showPaymentMethod && <ConfirmDialog open={showPaymentMethod} />}                  
        </Box>
        
    )
}

const styles = (selected: number, index: number) => ({
    containerStyle: {
        borderRadius: 2
    },
    typographyStyle: {
        color: selected === index ? colors.white[100] : colors.black[900]
    }
});

const style = (isMobile: boolean) => ({ 
    submitButtonStyle: {
        px: 2,
        py: 1,
        mt: isMobile ? 1 :2,
    },
});

const buttonStyle = {
    backgroundColor: 'red', 
    color: 'white', 
    fontWeight: 'bold',
    borderRadius: '5px', 
    padding: '10px 20px', 
    margin: '5px', 
    '&:hover': {
      backgroundColor: 'darkred',
    },
};


export default BookingCard;
