import React, {FC} from 'react';
import {Box, Typography} from '@mui/material';
import {tokens} from "../theme";
import {formatCardNumberWithDashes} from "../utils";
import { IBeneficiary } from '../type';

const colors = tokens();
type PROPS = {
    beneficiary: IBeneficiary;
    selected: number;
    onSelect: (index: number) => void;
    index: number;
}

const BeneficiaryCard: FC<PROPS> = (props: PROPS) => {
    const {beneficiary, selected, onSelect, index} = props;
    return (
        <Box
            bgcolor={selected === index ? colors.blue[500] : colors.white[400]}
            sx={styles(selected, index).containerStyle}
            p={2}
            onClick={() => onSelect(index)}
        >
            <Typography
                variant="h5"
                fontWeight="bold"
                sx={styles(selected, index).typographyStyle}
            >
                {beneficiary?.ic_acc_name || ''}
            </Typography>
            <Typography
                variant="h6"
                fontWeight="bold"
                sx={styles(selected, index).typographyStyle}
            >
                {beneficiary?.country_name || ''}
            </Typography>
            <Typography
                variant="subtitle1"
                sx={[styles(selected, index).typographyStyle, {fontWeight: 600}]}
            >
                {beneficiary?.ic_bank_label || ''}
            </Typography>
            <Typography
                variant="subtitle1"
                sx={[styles(selected, index).typographyStyle, {fontWeight: 600}]}
            >
                {beneficiary?.ic_account_number || ''}
            </Typography>
            <Typography
                variant="h6"
                sx={styles(selected, index).typographyStyle}
            >
                {`Card: ${formatCardNumberWithDashes(beneficiary?.card_id)}`}
            </Typography>
            <Typography
                variant="subtitle2"
                sx={styles(selected, index).typographyStyle}
            >
                {`Pickup Type: ${
                    beneficiary.pickup_method_value === 0
                    ? 'Cash Pickup'
                    : beneficiary.pickup_method_value === 1
                    ? 'Bank Transfer'
                    : ''
                }`}
            </Typography>
            {beneficiary.ifsc && beneficiary.ifsc !== '' && (
                <Typography
                    variant="subtitle2"
                    sx={styles(selected, index).typographyStyle}
                >
                    {`IFSC: ${beneficiary.ifsc || ''}`}
                </Typography>
            )}
        </Box>
    )
}

const styles = (selected: number, index: number) => ({
    containerStyle: {
        borderRadius: 2
    },
    typographyStyle: {
        color: selected === index ? colors.white[100] : colors.black[900]
    }
});

export default BeneficiaryCard;
