import { Typography, Button, Box, Card, CardContent, Grid } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../store/store";
import { tokens } from "../theme";
import { getLabelValue } from "../utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const colors = tokens();
const ReceiptPage = () => {
    const {state} = useLocation();
    const receiptInfo: any = state.receiptInfo;
    const messageKey: any = state.messageKey;
    const { lang } = useSelector((state: RootState) => state.user);

    const navigate = useNavigate();

    const renderReceiptData = () => {
        let receiptData: any = [];
        try {
            if (receiptInfo) {
                receiptData = receiptInfo[lang] ? receiptInfo[lang] : receiptInfo['bm'];
            }
        } catch (e) {
            console.log('Error processing receipt data:', e);
        }   
        
        if (!receiptData.hasOwnProperty("data")) return null;

        return(
            <>
                {receiptData.data.map((i: any, v: any) => (
                <Grid container key={v} alignItems="center" sx={{ m: 2, my: 1 }}>
                    <Grid item xs={6} md={5} sx={{ py: 0.5 }} style={{ textAlign: 'left' }}>
                        <Typography variant="h6" sx={styles.labelStyle}>
                            {i?.label || ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} md={6} sx={{ py: 0.5 }} style={{ textAlign: 'right' }}>
                        <Typography variant="h6" sx={styles.valueStyle}>
                            {i?.value || ''}
                        </Typography>
                    </Grid>
                    {i.url ? (
                    <Grid2
                        xs={12} md={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            variant="contained"
                            sx={[styles.submitButtonStyle, {px: 5, }]}
                            onClick={() => {
                                if (i.url) {
                                    window.open(i.url, '_blank');
                                }
                            }}
                        >
                            {i?.action || ''}
                        </Button>
                    </Grid2>
                    ) : (
                        null
                    )}
                </Grid>                
                ))}
                <Grid2
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ m: 2, my: 1}}
                >
                    <Typography variant="h6" sx={styles.valueStyle}>
                        {receiptData.remarks || ''}
                    </Typography>
                </Grid2>
            </>
        )
    }
    
    return (
        <Box m="20px">
            <Grid2
                container
            >
                <Grid2
                    xs={12} md={5}
                    mdOffset={3}
                >
                    <Card
                        sx={styles.cardStyle}
                        elevation={15}>
                        <CardContent>
                            <Grid2
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CheckCircleIcon
                                    sx={{
                                        color: colors.green[700],
                                        fontSize: 70
                                    }}
                                />
                            </Grid2>
                            <Grid2
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                                {`RM ${receiptInfo ? receiptInfo.amount || '' : ''}`}
                            </Typography>                                
                            </Grid2>
                            <Grid2
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                {getLabelValue(receiptInfo.lang, messageKey ? messageKey || '' : '')}
                            </Typography>                                
                            </Grid2>
                            {renderReceiptData()}                                   
                            <Grid2
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={[styles.submitButtonStyle, {backgroundColor: colors.green[700]}]}
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                >
                                    OK
                                </Button>
                            </Grid2>                            
                        </CardContent>          
                    </Card>
                </Grid2>
            </Grid2>
        </Box>
    )
}

const styles = {
    cardStyle: {
        mt: 2,
        borderRadius: 2,
        maxHeight: '80vh', 
        overflow: 'auto'
    },
    labelStyle: {
        color: colors.gray[300],
        fontWeight: 400,
        fontSize: 13
    },
    valueStyle: {
        colors: colors.black[900],
        fontWeight: 600,
        fontSize: 13,
        wordBreak: 'break-word'
    },
    submitButtonStyle: {
        px: 4,
        py: 0.9,
        mt: 2,
        fontWeight: 'bold'
    }
};

export default ReceiptPage;