import {Box, Card, CardContent, CircularProgress, Typography} from "@mui/material";
import Header from "../../components/Header";
import React, { FC, createRef, useEffect, useRef, useState } from "react";
import { IConfirm, IError, ISuccess, RouteComponent } from "../../type";
import { RootState, useAppDispatch } from "../../store/store";
import { addUserObjectToRequest, callBackendAPI, getLabelValue, navigateToExternalUrl } from "../../utils";
import { CONFIG } from "../../config";
import { KIRIM_MENU } from "../../constants/data";
import DialogAlert from "../../components/DialogAlert";
import { useSelector } from "react-redux";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { useLocation, useNavigate } from "react-router-dom";
import { HOME_UPDATE_WALLET_BALANCE } from "../../actions/type";
import BookingCard from "../../components/BookingCard";
import PinInput from "../../components/PinInput";
import ReceiptMessage from "../../components/ReceiptMessage";
import Grid2 from "@mui/material/Unstable_Grid2";

interface Props extends RouteComponent{
    logOut: () => void;
}

const PayNow: FC<Props> = (props) => {
    const { logOut } = props;
    const isMobile: boolean = useCheckIsMobile();
    const {state} = useLocation();
    const [bookings, setBookings] = useState([]); 
    const [selectedBooking, setSelectedBooking] = useState(-1);
    const [paymentMode, setPaymentMode] = useState(-1);
    const [showPin, setShowPin] = useState(false);
    const [pinNumber, setPinNumber] = useState<string>('');
    const [trxnQueueMessage, setTrxnQueueMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState<IConfirm>({confirm: false, content: []});
    const [success, setSuccess] = useState<ISuccess>({success: false, data: undefined});
    const [successCancel, setSuccessCancel] = useState<any>({lang: '', success: false, message: []});
    const { lang, user } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<IError>({ error: false, message: "", forgot_pin: 0});
    const navigate = useNavigate();
    
    let params : any = {item: KIRIM_MENU[2]};
    const bookRateResp = state?.bookRateResp;

    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);
    const bookingRefs: any = useRef([]);
    bookingRefs.current = bookings.map((_, i) => bookingRefs.current[i] ?? createRef());

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            fetchBookings();
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        if (selectedBooking !== -1 && bookingRefs.current[selectedBooking]) {
            const scroll = () => {
                bookingRefs.current[selectedBooking].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            };
            setTimeout(scroll, 0);
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [selectedBooking])

    const fetchBookings = async () => {
        try{
            setLoading(true);
            if (user === undefined) {
                setLoading(false);
                return setError({ error: true, message: "001: Oops Something went wrong, Login in again"});
            }
            if (params === undefined) {
                setLoading(false);
                return setError({ error: true, message: "002: Oops Something went wrong, Login in again"});
            }
            if (!params.hasOwnProperty("item")) {
                setLoading(false);
                return setError({ error: true, message: "003: Oops Something went wrong, Login in again"});
            }
            if (!params.item.hasOwnProperty("act_id")) {
                setLoading(false);
                return setError({ error: true, message: "004: Oops Something went wrong, Login in again"});
            }
      
            let act_id = params.item.act_id,
                m1 = params.item.name || '';

            let input : any = {
                ver: CONFIG.ver,
                act: String(act_id),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                m1: m1,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error: true, message: "005: Oops Something went wrong, Login in again"});
            }
            if (resultsFromAPI.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: "006: Oops Something went wrong, Login in again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error: true, message: "007: Oops Something went wrong, Login in again"});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")) {
                
                if (results.code !== 0) {
                    setLoading(false);
                    return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}`});
                }
                
                if (results.hasOwnProperty("providers")) {
                    setLoading(false);
                    setError({ error: false, message: ''});
                    // if this page was routed from a successful book rate transaction
                    // lets check the booking in the array
                    try{
                        if (bookRateResp){
                            let bookingId = bookRateResp?.body?.booking_id;
                            if (bookingId) {
                                let arrayIndex = results.providers.findIndex((i: { booking_id: any; }) => i?.booking_id === bookingId);
                                setSelectedBooking(arrayIndex);
                            }
                        }
                    }catch (e) {
                        // exception caught lets do nothing
                        console.log(e);
                    }
                    return setBookings(results.providers);
                }
            }
            setLoading(false);
            return setError({ error: true, message: "008: Oops Something went wrong, Login in again"});
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: "Oops Something went wrong"})
        }
    }

    const checkForTrxnQueue = async () => {
        try{
            let booking: any = bookings[selectedBooking];
            let input: any = {
                ver: CONFIG.ver,
                act: String(30017),
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                p1: booking.booking_id,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            console.log('1', input)
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (resultsFromAPI) {
                if (resultsFromAPI.error === undefined) {
                    if (resultsFromAPI.hasOwnProperty("results")) {
                        let results = resultsFromAPI.results;
                        if (results.hasOwnProperty("code")){
                            if (results.code === 0) {
                                if (results.status === 1)
                                setTrxnQueueMessage(results.content);
                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handlePaymentByWallet = async (pin: string) => {
        try{
            setShowPin(false);
            setPinNumber('');
            setLoading(true);
            let booking: any = bookings[selectedBooking];
            if (!booking.hasOwnProperty("booking_id")) {
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "001: Something went wrong"})
            }
            if (!pin || pin === '') {
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "002: Pin is required"})
            }
        
            let input: any = {
                ver: CONFIG.ver,
                act: String(33000),
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                p1: booking?.booking_id,
                p2: pin || "",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "003: Something went wrong"})
            }
            if (resultsFromAPI.error !== undefined) {
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "004: Something went wrong"})
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "005: Something went wrong"})
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")){
                if (results.code !== 0) {
                    setLoading(false);
                    setPaymentMode(-1);
                    return setError({ error: true, message: `[${results.code}] : ${results.message || ""}`, forgot_pin: results.renderForgotPin});
                }
        
                if (results.hasOwnProperty("balance") && results.balance)
                dispatch({ type: HOME_UPDATE_WALLET_BALANCE,
                    payload: {balance: results.balance, pending_amt: results.hasOwnProperty("pending_amt") ? results.pending_amt : null }})
                
                if (results.code === 0){
                    return setSuccess({success: true, data: results?.receipt});             
                }
            }
        
            setLoading(false);
            setPaymentMode(-1);
            return setError({ error: true, message: "006: Something went wrong"})
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setPaymentMode(-1);
            return setError({ error: true, message: 'Oops something went wrong'});
        }
      }
    
      const handlePaymentByFPX = async (pin: string) => {
        try{
            setShowPin(false);
            setPinNumber('');
            setLoading(true);
            let booking: any = bookings[selectedBooking];
            if (!booking.hasOwnProperty("booking_id")) {
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "001: Something went wrong"})
            }
            if (!pin || pin === ''){
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "002: Pin is required"})
            }
            let input: any = {
                ver: CONFIG.ver,
                act: String(33010),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: {
                    total_amount: String(booking.total_charge),
                    payment_desc: 'MM APP WEB USER PAY BOOKING',//req_send_amount,
                    isMobile: 1,
                    appId: 6, //2 is eremitApp 6 is webApp
                    SubStemID: 8, //8 SuperApp
                    booking_id: booking.booking_id,
                    user_trans_id: new Date().valueOf(),
                },
                p2: pin || "",
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "003: Something went wrong"})
            }
            if (resultsFromAPI.error !== undefined) {
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "004: Something went wrong"})
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                setPaymentMode(-1);
                return setError({ error: true, message: "005: Something went wrong"})
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")){
                if (results.code !== 0){
                    setLoading(false);
                    setPaymentMode(-1);
                    return setError({ error: true, message: `[${results.code}] : ${results.message || ""}`, forgot_pin:  results?.renderForgotPin});
                }
        
                if (results.hasOwnProperty("url")
                && results.hasOwnProperty("request_id")
                ) {
                    return navigateToExternalUrl(results.url);
                }
            }
            setLoading(false);
            setPaymentMode(-1);
            return setError({ error: true, message: "006: Something went wrong"})
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setPaymentMode(-1);
            return setError({ error: true, message: 'Oops something went wrong'})
        }
    }

    const handleCancelClick = async () => {
        try{
            setLoading(true);
            setShowConfirmation({ confirm: false, content: []});
            let booking : any = bookings[selectedBooking];

            if (!booking.hasOwnProperty("booking_id")) {
                setLoading(false);
                return setError({ error: true, message: "001: Something went wrong"});
            }

            let input : any = {
                ver: CONFIG.ver,
                act: String(30016),
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                p1: booking.booking_id,
                lg: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error: true, message: "002: Something went wrong"});
            }
            if (resultsFromAPI.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: "003: Something went wrong"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error: true, message: "004: Something went wrong"});
            }
            let results = resultsFromAPI.results;
            if (results.hasOwnProperty("code")){
              if (results.code !== 0) {
                    setLoading(false);
                    return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}`});
                }
                setLoading(false);
                setError({ error: false, message: ''});
                setSelectedBooking(-1);
                fetchBookings();
                return setSuccessCancel({lang: lang, success: true, message: [results?.message || 'Booking has been cancelled successfully']});
            }
    
            setLoading(false);
            return setError({ error: true, message: "005: Something went wrong"});
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: "Oops Something went wrong"})
        }
    }
    
    if (success.success) {
        return (
            <ReceiptMessage
                success={success.success}
                data={success.data}
                onOKClick={() => navigate('/')}
            />
        )
    }
    
    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'kirim.bookrate.paynow')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} 
                    >
                        {bookings && bookings.length > 0 ? (
                            <Card  
                                sx={styles(isMobile).cardStyle}
                                elevation={2}
                                style={{ height: isMobile ? 'auto' : '100%', width: isMobile ? '100%' : '33%', overflow: 'auto' }}
                            >        
                                <CardContent>
                                {bookings.map((item: any, index) => (
                                    <div ref={bookingRefs.current[index]} key={item.id}>
                                        <BookingCard
                                            transaction={item}
                                            trxnQueueMessage={trxnQueueMessage}
                                            lang={lang}
                                            selected={selectedBooking}
                                            isMobile={isMobile}
                                            loading={loading}
                                            handleCancel={async () => setShowConfirmation({
                                                confirm: true,
                                                content: ['Are you sure you want to cancel this booking?']
                                            })}
                                            checkForTrxnQueue={async () => await checkForTrxnQueue()}
                                            handleBack={() => setTrxnQueueMessage('')}
                                            handlePaymentByWallet={async () => {
                                                setPaymentMode(0);
                                                setTrxnQueueMessage('');
                                                setShowPin(true);
                                            }}
                                            handlePaymentByFPX={async () => {
                                                setPaymentMode(1);
                                                setTrxnQueueMessage('');
                                                setShowPin(true);                      
                                            }}
                                            onSelect={(index) => {
                                                setSelectedBooking(index)
                                            }}
                                            index={index}
                                        />
                                    </div>
                                ))}                               
                                </CardContent>
                            </Card>                
                        ) : (
                            <Box
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "transparent",
                                    pointerEvents: "none", 
                                }}
                            >
                                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                                    {getLabelValue(lang, 'kirim.paynow.nobook')}
                                </Typography>  
                            </Box>
                        )}                            
                    </Grid2>
                </Grid2> 
                {loading && (
                    <Box
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                    >
                        <CircularProgress size={60} color="primary" />
                    </Box>
                )}
                {showPin && 
                    <PinInput
                        open={showPin}
                        onCancel={() => setShowPin(false)}
                        onSuccess={async () => {
                            if(paymentMode === 0){
                                await handlePaymentByWallet(pinNumber);
                            }else{
                                await handlePaymentByFPX(pinNumber);
                            }
                        }}
                        yesButton={'Submit'}
                        cancelButton={'Cancel'}
                        pinNumber ={pinNumber}
                        onPinChange={(e: any)=>{
                            setPinNumber(e.target.value)
                        }}
                        lang={lang}
                    />
                }
                {error.error && (
                    <DialogAlert
                        open={error.error}
                        onCancel={() => {
                            setError({ error: false, message: '', forgot_pin: 0 });
                            logOut();
                            navigate('/forgotpin', { state: { user: user } });
                        }}
                        onSuccess={() => setError({ error: false, message: '', forgot_pin: 0 })}
                        title={'Attention'}
                        content={new Array(error.message)}
                        yesButton={'OK'}
                        cancelButton={error.forgot_pin === 1 ? (lang === 'en' ? 'FORGOT PIN' : 'LUPA PIN') : ''}
                        reportIssue={error.reportIssue?.show}
                        req_idx={error.reportIssue?.idx}
                        onReportIssueDone={() => setError({ ...error, reportIssue: undefined })}
                        reportTitle={error?.title}
                        reportDetails={error?.details}
                        trans_id={error?.trans_id}
                        ticket_type={error?.ticket_type}
                    />
                )}
                {showConfirmation.confirm &&
                    <DialogAlert
                        open={showConfirmation.confirm}
                        onCancel={() => setShowConfirmation({confirm: false, content: []})}
                        onSuccess={async () => handleCancelClick()}
                        title={'Cancel Booking'}
                        content={showConfirmation.content}
                        yesButton={'YES'}
                        cancelButton={'NO'}
                    />
                }
                {successCancel.success &&
                    <DialogAlert
                        open={success.success}
                        onSuccess={() => {
                            setSuccessCancel({lang: '', success: false, message: []});
                            return navigate('/kirim/paynow');
                        }}
                        title={'Booking Cancelled'}
                        content={successCancel.message}
                        yesButton={'OK'}
                    />
                }  
            </Box>          
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    },
    submitButtonStyle: {
        px: 5,
        py: isMobile ? 2 :1,
        mt: 2
    }
})

export default PayNow;
