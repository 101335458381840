export const BN_LANG_CONFIG: { [key: string]: any} = {
    /*  APP NAVIGATOR */
    'navigation.tabs.home.title': 'বাড়ি',
    'navigation.tabs.receipt.title': 'প্রাপ্তি',
    'navigation.tabs.profile.title': 'অ্যাকাউন্ট',
    'navigation.tabs.kita.title': 'কিটা',

    /* LOGIN PAGE */
    'login.title': 'একটি ভাষা নির্বাচন করুন',
    'login.newuser': 'নতুন ব্যবহারকারী?',
    'login.button.newuser': 'নতুন ব্যবহারকারী',
    'login.button.login': 'প্রবেশ করুন',
    'login.account.login.title': 'প্রবেশ করুন',
    'login.account.login': 'অ্যাকাউন্ট নেই?',
    'login.account.button.next': 'পরবর্তী',
    'login.account.button.submit': 'অগ্রসর',
    'login.account.button.goback': 'পেছনে',
    'login.account.button.newuser': 'নিবন্ধন',
    'login.account.title': 'আপনার প্রোফাইল পৃষ্ঠা প্রবেশ করতে দয়া করে নীচের তথ্য সম্পূর্ণ করুন.',
    'login.account.handphone': 'হ্যান্ডফোন নং',
    'login.account.id': 'আইডি নাম্বার',
    'login.acoount.forgotpassword': 'পিন ভুলে গেছি',
    'login.account.warning.title': 'সতর্কতা!',
    'login.account.warning.subTitle1': 'এটি যদি আপনার প্রোফাইল ফটো না হয় তবে আপনার ',
    'login.account.warning.subTitle2': 'পিনটি প্রবেশ করবেন না।',
    'login.account.photo.confirm': 'হ্যাঁ, এটি আমার প্রোফাইল ছবি।',
    'login.account.pin.title': 'আপনার 6 ডিজিটের পিন নম্বর লিখুন',
    'login.scan.instructions': 'অনুগ্রহ করে এখনই অ্যাকাউন্ট আইডি QR কোড স্ক্যান করুন',

    /* REGISTER PAGE */
    'register.title': 'নিবন্ধন',
    'register.account.title': 'একটি নতুন অ্যাকাউন্ট নিবন্ধন করতে নীচের তথ্য সম্পূর্ণ করুন',
    'register.account.handphone': 'হ্যান্ডফোন নং',
    'register.account.idType': 'একটি আইডি টাইপ নির্বাচন করুন',
    'register.account.id': 'আইডি নাম্বার',
    'register.account.nationality': 'জাতীয়তা নির্বাচন করুন',
    'register.account.rfCode': 'আপনার পরিচয়কারীর কোড লিখুন',
    'register.account.name': 'পুরো নাম',
    'register.account.name.helpertext': '** অনুগ্রহ করে আইসি/পাসপোর্ট অনুযায়ী আপনার পুরো নাম লিখুন **',
    'register.account.email': 'ইমেল',
    'register.account.lotno': 'বাসা নং.',
    'register.account.address': 'বিল্ডিংয়ের নাম / রাস্তার নাম',
    'register.account.address2': 'অঞ্চল / তামান / গ্রামের নাম',
    'register.account.postcode': 'পোস্টকোড',
    'register.account.state': 'জেলা',
    'register.account.choosestate': 'জেলা নির্বাচন করুন',
    'register.account.city': 'শহর',
    'register.otp.title': 'আমরা আপনার ফোন এসএমএসে',
    'register.otp.subTitle': 'পাসওয়ার্ড প্রেরণ করেছি',
    'register.otp.instructions': 'এখানে পাসওয়ার্ড লিখুন',
    'register.otp.smsnotrceived': 'এসএমএস পাইনি? আবার অনুরোধ',
    'register.otp.request': 'আমাকে একটি নতুন পাসওয়ার্ড দিন',
    'register.account.pin': '6 ডিজিটের পিন নম্বর লিখুন',
    'register.account.confirmPin': '6 -ডিজিট পিন নম্বরটি পুনরাবৃত্তি করুন',
    'register.initialize': 'অনুগ্রহপূর্বক অপেক্ষা করুন',
    'register.pin.title1': '6 ডিজিট পিন তৈরি করুন',
    'register.pin.title2': 'নতুন সংখ্যা',
    'register.pin.subTitle': 'এই পিন প্রতিটি লেনদেনের জন্য প্রয়োজন হবে।',
    'register.pin.success.title': 'পিন প্রস্তুত',
    'register.pin.mismatch': 'একই পিন নম্বরটি দুবার লিখুন',
    'register.pin.success.message': 'পরের বারের ব্যবহারের জন্য দয়া করে পিনটি মুখস্থ করুন',
    'register.photo.title': 'প্রোফাইল ছবি',
    'register.photo.subTitle': 'আপনার প্রোফাইল ছবি নির্বাচন করুন',
    'register.success.title': 'নিবন্ধকরণ সম্পূর্ণ',
    'register.success.subTitle': 'প্রোফাইল ছবি নির্বাচিত',
    'register.success.warning': 'সতর্কতা!',
    'register.success.message': '*লগইন চলাকালীন, যদি প্রোফাইল ছবি আপনি নির্বাচিত করেছেন তার মতো না হয় তবে দয়া করে ব্যবহার বন্ধ করুন',
    'register.tnc': '"আমি এমএম-অ্যাপ্লিকেশন শর্তাদি এবং শর্তাদি পড়েছি এবং সম্মত করেছি।"',
    'forgot.otp.title': 'রিসেট পিনের জন্য পাসওয়ার্ড আপনার',
    'forgot.otp.subTitle': 'ফোন এসএমএসে প্রেরণ করা হয়েছে',
    'reset.otp.subTitle': 'পরিবর্তন ফোন ডিভাইসের জন্য পাসওয়ার্ড আপনার ফোন এসএমএসে প্রেরণ করা হয়েছে',
    'reset.device.title1': 'ফোন ডিভাইস পুনরায় সেট করুন নিশ্চিত করুন',
    'reset.device.subTitle': 'আপনার পুরানো ফোন ডিভাইসে এমএম-অ্যাপ্লিকেশনটি আপনি পুনরায় সেট করার বিষয়টি নিশ্চিত করার পরে কাজ বন্ধ করবেন।',
    'reset.device.reset': 'রিসেট',
    'register.upgrade.tnc': 'গ্রাহকের জন্য শর্তাদি এবং শর্তগুলির ভিত্তিতে, আমি আমার এমএম ই-অর্থের সীমাটি 1500 থেকে 10000 এ বাড়াতেও সম্মত',
    'register.rfCode.qrcode': 'একটি অ্যাকাউন্ট আইডি স্ক্যান করুন',
    'register.enterpin.close.message': 'ওহো আপনার অ্যাকাউন্ট প্রায় সেট আপ করা হয়েছে৷ অনুগ্রহ করে মনে রাখবেন যে আপনি যদি হ্যাঁ চাপেন, আপনার অ্যাকাউন্ট তৈরি করতে আপনার সমস্যা হতে পারে৷ চালিয়ে যেতে অনুগ্রহ করে NO চাপুন',
    'register.enterpin.close.title': 'আপনি কি নিশ্চিত আপনি বন্ধ করতে চান?',


    /* HOME PAGE */
    'home.header.title': 'হিসাবের পরিমান',
    'home.header.button.topup': 'টপআপ',
    'home.header.button.fpx': 'এফপিএক্স',
    'home.header.button.transhistory': 'লেনদেনের ইতিহাস',
    'home.header.button.transactions': 'লেনদেন',
    'home.mmproducts.product1': 'প্রেরণ',
    'home.mmproducts.product2': 'বিল পরিশোধ করুন',
    'home.mmproducts.product3': 'প্রিপেইড',
    'home.mmproducts.product4': '2FA',
    'home.mmproducts.product5': 'স্থানান্তর',
    'home.mmproducts.product6': 'কিটা',
    'home.transaction.status.processing': 'চলমান',
    'home.transaction.status.success': 'সাফল্য',
    'home.transaction.status.all': 'সব',

    /* TOP UP PAGE */
    'topup.header.title': 'টপআপ পরিমাণ প্রবেশ করান',
    'topup.header.subTitle': 'টপআপ পরিমাণ',
    'topup.amount.title': 'সর্বমোট পরিমাণ',
    'topup.error.message1': 'পরিমাণ নির্বাচন করুন',
    'topup.footer.button': 'এখন পরিশোধ করুন',
    'topup.receipt.subTitle': 'ইতিমধ্যে যোগ',
    'topup.disclaimer.title': 'আমানত প্রত্যাখ্যান প্রতিরোধ',
    'topup.disclaimer.desc1': 'আপনার ব্যক্তিগত ব্যাঙ্ক অ্যাকাউন্ট থেকে আমানত করুন',
    'topup.disclaimer.desc2': 'ব্যাঙ্ক অ্যাকাউন্ট ধারকের নামটি আপনার MM APP-এ সাইন আপ করা নামের সাথে অবশ্যই মিলবে৷',
    'topup.disclaimer.desc3': 'অন্য কারো ব্যাঙ্ক অ্যাকাউন্ট থেকে জমা করা টাকা ফেরত দেওয়া হবে',
    'topup.disclaimer.desc4': 'রিফান্ড প্রক্রিয়া করতে 14 ব্যবসায়িক দিন সময় নিতে পারে',


    /* PREPAID PAGE */
    'prepaid.header.title': 'সরবরাহকারী নির্বাচন করুন',

    /* Prepaid Selected PAGE */
    'prepaidselected.header.title': 'ফোন নম্বর',
    'prepaidselected.header.description': 'পরিমাণ নির্বাচন করুন',
    'prepaidselected.main.amountinput': 'পরিমান লিখুন',
    'prepaidselected.footer.button': 'টপআপ',
    'prepaidselected.error.message1': 'পরিমাণ নির্বাচন করুন',
    'prepaidselected.receipt.subTitle': 'অর্থ প্রদান',
    'prepaidselected.error.title': 'ভুল',
    'prepaidselected.no.denos': 'পাওয়া যায় না',

    /* PIN */
    'pin.title': 'অর্থ প্রদানের বিষয়টি নিশ্চিত করতে আপনার 6 ডিজিটের পিনটি প্রবেশ করান',

    /* Transaction PAGE */
    'transaction.sendTo': 'পাঠানো',
    'transaction.button.title1': 'হ্যাঁ',
    'transaction.button.title2': 'বাতিল',
    'transaction.report.problem': 'একটি সমস্যা রিপোর্ট',
    'transaction.receipt': 'প্রাপ্তি',

    /* KIRIM PAGE */
    'kirim.bookrate.title': 'মুদ্রার হার',
    'kirim.bookrate.button': 'পরবর্তী',
    'kirim.bookrate.selectbene.title': 'একটি রিসিভার চয়ন করুন',
    'kirim.bookrate.selectbene.button': 'পরবর্তী',
    'kirim.bookrate.confirm.button': 'জমা দিন',
    'kirim.bookrate.selectpurpose': 'উদ্দেশ্য নির্বাচন করুন',
    'kirim.bookrate.noBene': 'আপনার বর্তমানে কোনো সুবিধাভোগী সেটআপ নেই। চালিয়ে যেতে একজন সুবিধাভোগী যোগ করুন.',
    'kirim.header.beneficiary.add.country': 'সুবিধাভোগী দেশ',
    'kirim.header.beneficiary.add.rship': 'সুবিধাভোগী সম্পর্ক',

    /* KIRIM FPX PAGE */
    'kirim.fpx.bankname': 'একটি ব্যাংক নির্বাচন করুন',
    'kirim.fpx.accname': 'হিসাবের নাম',
    'kirim.fpx.accno': 'হিসাব নাম্বার',
    'kirim.fpx.status': 'যাচাইকরণের স্থিতি',
    'kirim.fpx.email': 'ইমেইল', // Emel,

    /* KIRIM PAY NOW */
    'kirim.paynow.nobooking.selected': 'একটি বুকিং নির্বাচন করুন',

    /* TRANSFER PAGE */
    'transfer.selected.title': 'প্রেরণে পরিমাণ প্রবেশ করান',
    'transfer.selected.balance': 'হিসাবের পরিমান',
    'transfer.selected.send': 'প্রেরণ',
    'transfer.confirm.amount': 'পাঠানোর পরিমাণ',
    'transfer.receipt.subTitle': 'অর্থ স্থানান্তর সম্পন্ন',

    /* PROFILE */
    'profile.name': 'নাম',
    'profile.mobile': 'হ্যান্ডফোন নং',
    'profile.idtype': 'আইডি প্রকার.',
    'profile.id': 'আইডি নাম্বার.',
    'profile.account_id': 'হিসাব নাম্বার',
    'profile.scan_payroll': 'স্ক্যান কর্পোরেট আইডি',
    'profile.email': 'ইমেল',
    'profile.lotno': 'লট নং',
    'profile.address1': 'ঠিকানা 1.',
    'profile.address2': 'ঠিকানা ২.',
    'profile.postcode': 'পোস্টকোড।',
    'profile.city': 'শহর',
    'profile.state': 'জেলা',
    'profile.change_language': 'ভাষা',
    'profile.t2p_card': 'Tap 2 Pay Card',
    'profile.changepin.title': '6 ডিজিট পিন পরিবর্তন করুন',
    'profile.changeT2Ppin.title': 'টি 2 পি এর জন্য পিন পরিবর্তন করুন',
    'profile.lost_card.title': 'হারানো কার্ড রিপোর্ট',
    'profile.lost_card.disclaimer': 'আপনার পুরানো এমএম কার্ডটি ব্লক করতে ওকে নির্বাচন করুন',
    'profile.lost_card.success': '"আপনার এমএম কার্ড অবরুদ্ধ করা হয়েছে। এমএম আপনার সাথে যোগাযোগ করবে এবং কীভাবে একটি নতুন কার্ড পাবেন সে সম্পর্কে আপনাকে পরামর্শ দেবে।"',
    'profile.changepin.current.title': 'বর্তমান 6 ডিজিট পিন লিখুন',
    'profile.changepin.new.title': 'নতুন 6-অঙ্কের পিন লিখুন',
    'profile.changepin.confirmnew.title': 'নতুন 6-অঙ্কের পিনটি পুনরাবৃত্তি করুন',
    'profile.changepin.success.message': 'আপনার পিন সফলভাবে পরিবর্তন করা হয়েছে।',
    'profile.scan.instructions': 'দয়া করে এখন কর্পোরেট আইডি স্ক্যান করুন',
    'profile.scan.pin': 'পিন প্রবেশ করান',
    'profile.user_qrcode': 'কিউআর কোড',
    'profile.user_qrcode.description': 'এই ব্যবহারকারীর কিউআর কোডটি স্ক্যান করুন',
    'profile.upgrade_membership': 'সদস্যতা',
    'profile.upgrade.button': 'আপগ্রেড',
    'profile.upgrade.subtitle': 'বর্তমানে আপনি',
    'profile.upgrade.description': 'আরও ভাল সুবিধা এবং বৈশিষ্ট্য পেতে আপগ্রেড করুন',
    'profile.upgrade.alert.title': 'আপগ্রেড',
    'profile.upgrade.alert.message.gold': 'সোনার সদস্যপদে আপগ্রেড করতে, আপনাকে EKYC প্রক্রিয়াটি অতিক্রম করতে হবে। চালিয়ে যেতে ওকে ক্লিক করুন।',
    'profile.upgrade.alert.message.silver': 'রৌপ্য সদস্যপদে আপগ্রেড করতে, আপনার অ্যাকাউন্টের নাম যাচাই করতে আপনাকে কমপক্ষে একটি এফপিএক্স লেনদেন সম্পাদন করতে হবে। চালিয়ে যেতে ওকে ক্লিক করুন।',
    'profile.upgrade.alert.message.platinum': 'প্ল্যাটিনাম সদস্যপদে আপগ্রেড করতে, আপনাকে এই প্রক্রিয়াটি চালিয়ে যেতে আমাদের নিকটতম এমএম প্রমোটার এজেন্টদের কাছে যেতে হবে। চালিয়ে যেতে ওকে ক্লিক করুন।',
    'profile.referral.menu.title': 'এক বন্ধুকে আমন্ত্রন',
    'profile.referral.menu.description': 'আপনি এবং বন্ধু বিনামূল্যে পয়েন্ট পাবেন!',
    'profile.referral.menu.subtitle': 'আপনি যখন কোনও বন্ধুকে অ্যাপটি ডাউনলোড এবং ব্যবহার করতে আমন্ত্রণ জানান তখন আরও পয়েন্ট পান',
    'profile.referral.header.title': 'সুপারিশ',
    'profile.referral.question': 'এটা কিভাবে কাজ করে?',
    'profile.referral.instruction1': 'আপনার লিঙ্কটি একটি বন্ধুর সাথে ভাগ করুন।',
    'profile.referral.instruction2': 'আপনার বন্ধুকে এমএম অ্যাপ্লিকেশনটি ডাউনলোড করতে হবে এবং সফলভাবে সাইন আপ করতে হবে।',
    'profile.referral.instruction3': 'আপনি এবং আপনার বন্ধু বিনামূল্যে পয়েন্ট পাবেন।',
    'profile.referral.share.button': 'আমার লিঙ্কটি ভাগ করুন',
    'profile.referral.share.description': 'আপনি কি এখনও এমএম অ্যাপ্লিকেশন চেষ্টা করেছেন? আপনার জন্য তাদের কাছে উত্তেজনাপূর্ণ কিছু রয়েছে, এটি এখানে পরীক্ষা করে দেখুন:',
    'profile.delete.account': 'হিসাব মুছে ফেলা',
    'profile.delete.account.message': 'মিমি অনুরোধ অনুসারে আপনার এমএম অ্যাপ্লিকেশন ব্যবহারকারী লগইন অ্যাকাউন্টটি সরিয়ে ফেলবে। আপনার এমএম ওয়ালেট অ্যাকাউন্টটি বন্ধ করতে, দয়া করে Crt@mobile-money.com.my এ আমাদের গ্রাহক সমর্থনকে ইমেল করুন। আরও বিশদের জন্য, এখানে ক্লিক করুন',
    'profile.delete.account.submessage': 'গ্রাহকদের জন্য এমএম শর্তাদি এবং শর্তাবলী 18 বিভাগে দেখুন দয়া করে',
    'profile.payslip': 'পে স্লিপ',
    'profile.payslip.emptylist': 'একটি তারিখ নির্বাচন করুন',
    'profile.payslip.date': 'এই মুহুর্তে কোনও ডেটা উপলব্ধ নেই!',
    'profile.payslip.employee_id': 'আপনার কর্মচারী আইডি লিখুন',
    'profile.accountID_qrcode.description': 'এই ব্যবহারকারীর অ্যাকাউন্ট আইডি স্ক্যান করুন',
    'profile.upgrade.ic.attention': 'মনোযোগ',
    'profile.upgrade.ic.desc': 'মেয়াদ শেষ, এটি আপডেট করতে এখানে ক্লিক করুন',
    'profile.upgrade.ic.message': 'পাসপোর্টের মেয়াদ শেষ হয়ে গেছে। আপনি কি আপনার নতুন পাসপোর্ট আপডেট করতে চান? অনুগ্রহ করে আপডেট টিপুন',
    'profile.upgrade.ic': 'পাসপোর্ট আপডেট করুন',
    'profile.upgrade.ic.oldid': 'বর্তমান আইডি/পাসপোর্ট নম্বর',
    'profile.upgrade.ic.newid': 'আপনার নতুন আইডি/পাসপোর্ট নম্বর লিখুন',
    'profile.upgrade.ic.submit': 'জমা দিন',
    'profile.upgrade.ic.errornewid': 'অনুগ্রহ করে নতুন আইডি/পাসপোর্টের ছবি দিন',
    'profile.upgrade.ic.errorimage': 'অনুগ্রহ করে নতুন আইডি/পাসপোর্টের ছবি দিন',
    'profile.upgrade.ic.expired_dt': 'মেয়াদ শেষ হওয়ার তারিখ',
    'profile.upgrade.ic.newidissuedt': 'নতুন আইডি/পাসপোর্ট ইস্যু তারিখ (DD/MM/YYYY)',
    'profile.upgrade.ic.newidexpirydt': 'নতুন আইডি/পাসপোর্টের মেয়াদ শেষ হওয়ার তারিখ (DD/MM/YYYY)',
    'profile.upgrade.ic.errornewidissuedt': 'অনুগ্রহ করে নতুন আইডি/পাসপোর্ট ইস্যু তারিখ লিখুন (DD/MM/YYYY)',
    'profile.upgrade.ic.errornewidexpirydt': 'অনুগ্রহ করে নতুন আইডি/পাসপোর্টের মেয়াদ শেষ হওয়ার তারিখ লিখুন (DD/MM/YYYY)',
    'profile.community.header': 'সদস্যতা নিন এবং নতুন সম্প্রদায়গুলি আবিষ্কার করুন৷',
    'profile.community.scan.instructions': 'অনুগ্রহ করে এখনই অ্যাকাউন্ট আইডি QR কোড স্ক্যান করুন',


    /* PAY BILL */
    'paybill.description': 'অ্যাকাউন্টের বিশদগুলির জন্য আপনার বিলটি দেখুন',
    'paybill.proceed': 'অগ্রসর',
    'paybill.paynow': 'এখন পরিশোধ করুন',
    'paybill.denominations': 'পরিমাণ চয়ন করুন',

    /* RECEIPT PAGE */
    'receipt.copyToClipboard': 'তথ্য অনুলিপি',

    /* KIRIM BENEFICIARY PAGE */
    'kirim.beneficiary.title': 'প্রাপক যোগ',
    'kirim.beneficiary.benename': 'প্রাপকের নাম',
    'kirim.beneficiary.benephone': 'প্রাপক মোবাইল নম্বর',
    'kirim.beneficiary.benekadno': 'সদস্য কার্ড নং',
    'kirim.beneficiary.benecity': 'প্রাপক শহর',
    'kirim.beneficiary.bene': 'প্রাপক',
    'kirim.beneficiary.benerelationship': 'প্রাপক সম্পর্ক',
    'kirim.beneficiary.benebank': 'প্রাপক ব্যাংক',
    'kirim.beneficiary.benebankbranch': 'প্রাপক ব্যাংক শাখা',
    'kirim.beneficiary.ifsc': 'আইএফএসসি',
    'kirim.beneficiary.benecountry': 'প্রাপক দেশ',
    'kirim.beneficiary.beneaccno': 'হিসাব নাম্বার',
    'kirim.beneficiary.nobene': 'কোনও প্রাপক নেই',

    /* KIRIM PAGE */
    'kirim.menu.bookrate': 'অনুরোধ রেমিট',
    'kirim.bookrate.beneficiary': 'প্রাপক',
    'kirim.bookrate.paynow': 'এখন পরিশোধ করুন',
    'kirim.bookrate.transactionstatus': 'লেনদেনের পরিস্থিতি',
    'kirim.bookrate.documents': 'এমএম জন্য নথি',
    'kirim.bookrate.help': 'সহায়তা লাইন',

    /* KIRIM HEADDER PAGE */
    'kirim.header.bookrate': 'অনুরোধ রেমিট',
    'kirim.header.beneficiary': 'প্রাপক',
    'kirim.header.beneficiary.add': 'প্রাপক যোগ',
    'kirim.header.paynow': 'এখন পরিশোধ করুন',
    'kirim.header.transactionstatus': 'লেনদেনের পরিস্থিতি',
    'kirim.header.documents': 'এমএম জন্য নথি',
    'kirim.header.help': 'সহায়তা লাইন',

    /* KIRIM STATUS TRANSAKSI PAGE */
    'kirim.transaction.transid': 'লেনদেন নাম্বার',
    'kirim.transaction.beneficiary': 'প্রাপক',
    'kirim.transaction.cardno': 'সদস্য কার্ড নং',
    'kirim.transaction.country': 'দেশ',
    'kirim.transaction.provider': 'প্রদানকারী',
    'kirim.transaction.booktime': 'সংরক্ষণের তারিখ',
    'kirim.transaction.rate': 'বিনিময় হার',
    'kirim.transaction.date': 'লেনদেন তারিখ',
    'kirim.transaction.receipt': 'প্রাপ্তি',

    /* KIRIM PAY NOW PAGE */
    'kirim.paynow.nobook': 'কোন বুকিং উপলব্ধ',

    /* KIRIM MONEY PIN PAGE */
    'kirim.moneypin.message': 'বার্তা',
    'kirim.moneypin.promotion': 'প্রচার',
    'kirim.moneypin.redeem': 'মানি পিনটি খালাস করুন',
    'kirim.moneypin.time': 'তারিখ সময়',
    'kirim.moneypin.send': 'প্রেরণ',
    'kirim.moneypin.receipt': 'eremit রসিদ',
    'kirim.moneypin.receiver':'প্রাপকেরনাম',
    'kirim.moneypin.amount':'পররমাণ',
    'kirim.moneypin.pin':'PIN',
    'kirim.moneypin.provider':'পিন প্রদানকারী',
    'kirim.moneypin.details':'Cash PIN Details',


    /* KITA PAGE */
    'kita.date': 'আপডেট',
    'kita.header.title': 'মুক্তি',
    'kita.points':'পয়েন্ট',
    'kita.points.toptab1': 'ব্যবহৃত',
    'kita.points.toptab2': 'প্রাপ্ত',
    'kita.earned': 'অর্জিত',
    'kita.water': 'জল',
    'kita.water.litre':'লিটার',
    'kita.water.point_description':'মোট পয়েন্ট',
    'kita.water.button': 'পয়েন্ট সহ খালাস',
    'kita.water.qrcode_message': 'জল মেশিনে স্ক্যানারে আপনার কিউআর কোডটি দেখান। আপনি কাছাকাছি টিপানোর পরে কিউআর কোড মুছে ফেলা হবে।',
    'kita.water.qrcode_button': 'বন্ধ',
    'kita.prepaid.header.title': 'একটি প্রিপেইড পিন খালাস',
    'kita.voucher': 'ভাউচার',
    'kita.history.toptab1': 'ব্যবহার করা',
    'kita.history.toptab2': 'ব্যবহৃত',
    'kita.history.points_used': 'পয়েন্ট ব্যবহৃত',
    'kita.history.voucher_code': 'ভাউচার কোড',
    'kita.history.redeemed': 'খালাস',
    'kita.history.header': 'ইতিহাস খালাস',
    'kita.point.header': 'পয়েন্ট ইতিহাস',
    'kita.advertisement': 'বিজ্ঞাপন',
    'kita.point.empty': 'এই মুহুর্তে কোনও ডেটা উপলব্ধ নেই!',
    'kita.earn.title': 'তুমি অর্জন করেছ',
    'kita.earn.description': 'দেখুন এবং উপার্জন পয়েন্ট',
    'kita.earn.header' : 'পয়েন্ট উপার্জন',
    'kita.earn.emptylist.label': 'এই মুহুর্তে কোনও ভিডিও নেই, দয়া করে পরে চেষ্টা করুন।',
    'kita.earn.ad.component.description': 'দেখুন এবং উপার্জন পয়েন্ট',
    'kita.earn.counter': 'অবশিষ্ট সময়',
    'kita.earn.counter.close': 'পয়েন্ট অর্জন করতে ক্লিক করুন',
    'kita.stamps.title': 'ই-স্ট্যাম্প',
    'kita.cash.voucher.title': 'টাকা প্রাপ্তির রশিদ',
    'kita.cash.voucher.accumulated' : 'নগদ ভাউচার জমে',
    'kita.redeem': 'খালাস',
    'kita.revert': 'প্রত্যাবর্তন',
    'kita.cash.voucher.qrcode.message': 'নগদ ভাউচার ব্যবহার করতে এই কিউআর কোডটি ব্যবসায়ীকে দেখান',
    'kita.cash.voucher.merchants': 'ব্যবসায়ী',
    'kita.cash.voucher.purchase' : 'ওয়ালেট দিয়ে ক্রয় করুন',
    'kita.cash.voucher.vouchers': 'ব্যবসায়ীদের ভাউচার',
    'kita.cash.voucher.balance' : 'বাকি ভাউচার',
    'kita.cash.voucher.enter' : 'পরিমান লিখুন',
    'kita.cash.voucher.generate' : 'কিউআর কোড তৈরি করুন',
    'kita.cash.voucher.invalid' : 'ভুল পরিমাণ',
    'kita.cash.voucher.error' : 'পরিমাণ শূন্য হতে পারে না',
    'kita.cash.voucher.collected': 'ভাউচার',
    'kita.cash.voucher.history': 'ইতিহাস',
    'kita.cash.voucher.use': 'ভাউচার ব্যবহার করুন',
    'kita.cash.voucher.usage_left': 'পর্যন্ত বৈধ',
    'kita.cash.voucher.usage': 'ব্যবহার',
    'kita.cash.voucher.days': 'দিন',
    'kita.cash.voucher.times': 'বার',
    'kita.update.version': 'একটি নতুন সংস্করণ উপলব্ধ. এখন আপডেট করুন',

    /* KADAR PAGE */
    'kadar.header.title': 'কদর',

    /* PAY PAGE */
    'pay.header.title': 'বেতন',
    'pay.qrcode.desc': 'এই QR কোড প্রতি 60 সেকেন্ডে রিফ্রেশ হবে',

    /* NOTIFICATIONS */
    'notifications.header.title': 'বিজ্ঞপ্তি',

    /* CASH OUT */
    'cashout.bank_name.label': 'ব্যাংকের নাম',
    'cashout.acc_name.label': 'হিসাবের নাম',
    'cashout.acc_no.label': 'হিসাব নাম্বার',
    'cashout.amount.label': 'হিসাব নাম্বার',
}