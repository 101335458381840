export const NE_LANG_CONFIG: { [key : string]: any } = {
    /*  APP NAVIGATOR */
    'navigation.tabs.home.title': 'घर',
    'navigation.tabs.receipt.title': 'रसिद',
    'navigation.tabs.profile.title': 'खाता',
    'navigation.tabs.kita.title': 'किलो',

    /* LOGIN PAGE */
    'login.title': 'कृपया एक भाषा चयन गर्नुहोस्',
    'login.newuser': 'नयाँ प्रयोगकर्ता?',
    'login.button.newuser': 'नया क्रियापद',
    'login.button.login': 'लग - इन',
    'login.account.login.title': 'लग - इन',
    'login.account.login': 'खाता छैन?',
    'login.account.button.next': 'अर्को',
    'login.account.button.submit': 'अघि बढ्नु',
    'login.account.button.goback': 'पिठिउ',
    'login.account.button.newuser': 'दर्ता गर्नु',
    'login.account.title': 'कृपया तपाईंको प्रोफाइल पृष्ठ प्रविष्ट गर्न तलको जानकारी पूरा गर्नुहोस्।',
    'login.account.handphone': 'ह्यान्डफोन नम्बर',
    'login.account.id': 'आईडी नम्बर',
    'login.acoount.forgotpassword': 'पिन बिर्सनुभयो',
    'login.account.warning.title': 'चेतावनी!',
    'login.account.warning.subTitle1': 'तपाईंको पिन प्रविष्ट नगर्नुहोस् यदि यो',
    'login.account.warning.subTitle2': 'तपाईंको प्रोफाइल फोटो होईन भने।',
    'login.account.photo.confirm': 'हो, यो मेरो प्रोफाइल तस्वीर हो।',
    'login.account.pin.title': 'तपाईंको digit अंकको पिन नम्बर प्रविष्ट गर्नुहोस्',
    'login.scan.instructions': 'कृपया अब खाता ID QR कोड स्क्यान गर्नुहोस्',

    /* REGISTER PAGE */
    'register.title': 'दर्ता गर्नु',
    'register.account.title': 'कृपया तलका जानकारीहरू पूरा गर्नुहोस् नयाँ खाता दर्ता गर्न',
    'register.account.handphone': 'ह्यान्डफोन नम्बर',
    'register.account.idType': 'आईडी प्रकार चयन गर्नुहोस्',
    'register.account.id': 'आईडी नम्बर',
    'register.account.nationality': 'राष्ट्रियता चयन गर्नुहोस्',
    'register.account.rfCode': 'तपाईंको परिचयको कोड प्रविष्ट गर्नुहोस्',
    'register.account.name': 'पुरा नाम',
    'register.account.name.helpertext': '** कृपया IC/पासपोर्ट अनुसार आफ्नो पूरा नाम प्रविष्ट गर्नुहोस् **',
    'register.account.email': 'इमेज',
    'register.account.lotno': 'घर नम्बर।',
    'register.account.address': 'भवन नाम / सडक नाम',
    'register.account.address2': 'क्षेत्र / तनन / गाउँको नाम',
    'register.account.postcode': 'हुलाकी',
    'register.account.state': 'जिल्ला',
    'register.account.choosestate': 'जिल्ला छान्नुहोस्',
    'register.account.city': 'शहर',
    'register.otp.title': 'हामीले तपाईंको फोन',
    'register.otp.subTitle': 'एसएमएसमा पासवर्ड पठाएका छौं',
    'register.otp.instructions': 'यहाँ पासवर्ड प्रविष्ट गर्नुहोस्',
    'register.otp.smsnotrceived': 'एसएमएस प्राप्त भएन? पुन: अनुरोध गर्नुहोस्',
    'register.otp.request': 'मलाई नयाँ पासवर्ड दिनुहोस्',
    'register.account.pin': 'Digit अंकको पिन नम्बर प्रविष्ट गर्नुहोस्',
    'register.account.confirmPin': '--डिजिट पिन नम्बर दोहोर्याउनुहोस्',
    'register.initialize': 'कृपया पर्खनुहोस्',
    'register.pin.title1': 'Digit अंकको पिन सिर्जना गर्नुहोस्',
    'register.pin.title2': 'नया संख्या',
    'register.pin.subTitle': 'यो पिन सबै लेनदेनको लागि आवश्यक हुनेछ।',
    'register.pin.mismatch': 'दुई पटक समान पिन नम्बर प्रविष्ट गर्नुहोस्',
    'register.pin.success.title': 'पिन तयार छ',
    'register.pin.success.message': 'कृपया अर्को समय प्रयोगको लागि पिन याद गर्नुहोस्',
    'register.photo.title': 'प्रोफाइल तस्वीर',
    'register.photo.subTitle': 'कृपया तपाईंको प्रोफाइल तस्वीर चयन गर्नुहोस्',
    'register.success.title': 'दर्ता पूरा भयो',
    'register.success.subTitle': 'प्रोफाइल तस्वीर चयन गरियो',
    'register.success.warning': 'चेतावनी!',
    'register.success.message': '*लगइनको बखत, यदि प्रोफाइल तस्वीर तपाईले चयन गर्नु भएको जस्तो छैन भने, कृपया प्रयोग गर्न रोक्नुहोस्',
    'register.tnc': 'मैले MM-अनुप्रयोग नियम र सर्तहरू पढेको छु र सहमत छु।',
    'forgot.otp.title': 'पासस्थान रिसेट पिनको',
    'forgot.otp.subTitle': 'लागि तपाईंको फोन एसएमएस पठाइएको छ',
    'reset.otp.subTitle': 'पासवेर्डहरू फोन उपकरणलाई तपाईंको फोन एसएमएस पठाइएको छ',
    'reset.device.title1': 'रिसेट फोन उपकरण पुष्टि गर्नुहोस्',
    'reset.device.subTitle': 'तपाईंको पुरानो फोन उपकरणमा MM-अनुप्रयोगले काम गर्न रोक्दछ जुन तपाईं रिसेट रिसेट पछि।',
    'reset.device.reset': 'रिसेट गर्नुहोस्',
    'register.upgrade.tnc': 'सर्तहरू र ग्राहकहरूको लागि सर्तहरूमा आधारित, म पनि मेरो MM ई-मनी ग्रेट 1 150000 सम्म 10000 सम्म बढाउन सहमत छु',
    'register.rfCode.qrcode': 'कृपया खाता ID स्क्यान गर्नुहोस्',
    'register.enterpin.close.message': 'उफ् तपाईको खाता लगभग सेटअप भएको छ। कृपया ध्यान दिनुहोस् कि यदि तपाईंले हो थिच्नु भयो भने, तपाईंलाई आफ्नो खाता सिर्जना गर्न समस्या हुन सक्छ। जारी राख्न कृपया NO थिच्नुहोस्',
    'register.enterpin.close.title': 'के तपाई पक्का बन्द गर्न चाहनुहुन्छ?',


    /* HOME PAGE */
    'home.header.title': 'खाता रकम',
    'home.header.button.topup': 'थप्नु',
    'home.header.button.fpx': 'हावाको',
    'home.header.button.transactions': 'लेन लाग्ने-ले',
    'home.header.button.transhistory': 'लेनदेन इतिहास',
    'home.mmproducts.product1': 'पठाउनु',
    'home.mmproducts.product2': 'खसाउ',
    'home.mmproducts.product3': 'अग्रिम भुतानी',
    'home.mmproducts.product4': '2FA',
    'home.mmproducts.product5': 'सरुवा गर्नु',
    'home.mmproducts.product6': 'किलो',
    'home.transaction.status.processing': 'प्रगति हुदैछ',
    'home.transaction.status.success': 'सफलता',
    'home.transaction.status.all': 'सबै',

    /* TOP UP PAGE */
    'topup.header.title': 'टपअप रकम प्रविष्ट गर्नुहोस्',
    'topup.header.subTitle': 'टपअप रकम',
    'topup.amount.title': 'कुल रकम',
    'topup.error.message1': 'रकम चयन गर्नुहोस्',
    'topup.footer.button': 'अबो तिरे',
    'topup.receipt.subTitle': 'थपिएको छ',
    'topup.disclaimer.title': 'जम्मा अस्वीकारहरू रोक्नुहोस्',
    'topup.disclaimer.desc1': 'आफ्नो व्यक्तिगत बैंक खाताबाट जम्मा गर्नुहोस्',
    'topup.disclaimer.desc2': 'बैंक खातावालाको नाम तपाईले MM APPt मा साइन अप गर्नुभएको नामसँग मिल्नुपर्छ',
    'topup.disclaimer.desc3': 'कसैको बैंक खाताबाट निक्षेपहरू फिर्ता गरिनेछ',
    'topup.disclaimer.desc4': 'फिर्ता रकम प्रक्रियामा 14 व्यापार दिन लाग्न सक्छ',

    /* PREPAID PAGE */
    'prepaid.header.title': 'प्रदायक चयन गर्नुहोस्',

    /* Prepaid Selected PAGE */
    'prepaidselected.header.title': 'फोन नम्बर',
    'prepaidselected.header.description': 'रकम चयन गर्नुहोस्',
    'prepaidselected.main.amountinput': 'रकम प्रविष्ट गर्नुहोस्',
    'prepaidselected.footer.button': 'थप्नु',
    'prepaidselected.error.message1': 'रकम चयन गर्नुहोस्',
    'prepaidselected.error.message2': 'कृपया फोन नम्बर प्रदान गर्नुहोस्',
    'prepaidselected.receipt.subTitle': 'पैसा तिर्ने',
    'prepaidselected.error.title': 'गलत',
    'prepaidselected.no.denos': 'उपलब्ध छैन',

    /* PIN */
    'pin.title': 'भुक्तानी पुष्टि गर्न तपाईंको digit अंकको पिन प्रविष्ट गर्नुहोस्',

    /* Transaction PAGE */
    'transaction.sendTo': 'पठाएर',
    'transaction.button.title1': 'हुन्छ',
    'transaction.button.title2': 'रद्द गर्नु',
    'transaction.report.problem': 'एउटा समस्या रिपोर्ट गर्नुहोस्',
    'transaction.receipt': 'रसिद',

    /* KIRIM PAGE */
    'kirim.bookrate.title': 'मुद्रा दर',
    'kirim.bookrate.button': 'अर्को',
    'kirim.bookrate.selectbene.title': 'एक प्राप्तकर्ता छान्नुहोस्',
    'kirim.bookrate.selectbene.button': 'अर्को',
    'kirim.bookrate.confirm.button': 'स्वीकार गर्न',
    'kirim.bookrate.selectpurpose': 'उद्देश्य चयन गर्नुहोस्',
    'kirim.bookrate.noBene': 'तपाईंसँग हाल कुनै पनि लाभार्थी सेटअप छैन। जारी राख्न कृपया एक लाभार्थी थप्नुहोस्।',
    'kirim.header.beneficiary.add.country': 'लाभार्थी देश',
    'kirim.header.beneficiary.add.rship': 'लाभार्थी सम्बन्ध',

    /* KIRIM FPX PAGE */
    'kirim.fpx.bankname': 'बैंक चयन गर्नुहोस्',
    'kirim.fpx.accname': 'खाताको नाम',
    'kirim.fpx.accno': 'खाता नम्बर',
    'kirim.fpx.status': 'प्रमाणीकरण स्थिति',
    'kirim.fpx.email': 'इमेल',

    /* KIRIM PAY NOW */
    'kirim.paynow.nobooking.selected': 'कृपया बुकिङ चयन गर्नुहोस्',

    /* TRANSFER PAGE */
    'transfer.selected.title': 'पठाउनको लागि रकम प्रविष्ट गर्नुहोस्',
    'transfer.selected.balance': 'खाता रकम',
    'transfer.selected.send': 'पठाउनु',
    'transfer.confirm.amount': 'पठाउनको लागि रकम',
    'transfer.receipt.subTitle': 'पैसा हस्तान्तरण गरियो',

    /* PROFILE */
    'profile.name': 'नाम',
    'profile.mobile': 'ह्यान्डफोन नम्बर',
    'profile.idtype': 'आईडी प्रकार',
    'profile.id': 'आईडी नम्बर',
    'profile.account_id': 'खाता नम्बर',
    'profile.scan_payroll': 'स्क्यान कर्पोरेट आईडी',
    'profile.email': 'ईमेल',
    'profile.lotno': 'धेरै संख्या',
    'profile.address1': 'ठेगाना 1.',
    'profile.address2': 'ठेगाना 2.',
    'profile.postcode': 'पोष्टकोड.',
    'profile.city': 'शहर.',
    'profile.state': 'जिल्ल',
    'profile.change_language': 'भाषा',
    'profile.t2p_card': '2 भुक्तानी कार्ड ट्याप गर्नुहोस्',
    'profile.changepin.title': 'Digit अंकको पिन परिवर्तन गर्नुहोस्',
    'profile.changeT2Ppin.title': 'T2P को लागी पिन परिवर्तन गर्नुहोस्',
    'profile.lost_card.title': 'हराएको कार्ड रिपोर्ट गर्नुहोस्',
    'profile.lost_card.disclaimer': 'तपाईंको पुरानो MM कार्ड ब्लक गर्न ठीक छ चयन गर्नुहोस्',
    'profile.lost_card.success': 'तपाईंको MM कार्ड रोकिएको छ. MM ले तपाईंलाई सम्पर्क गर्नेछ र तपाईंलाई नयाँ कार्ड कसरी प्राप्त गर्ने भनेर सल्लाह दिईन्छ।',
    'profile.changepin.current.title': 'हालको digit अंकको पिन प्रविष्ट गर्नुहोस्',
    'profile.changepin.new.title': 'नयाँ - अंकको पिन प्रविष्ट गर्नुहोस्',
    'profile.changepin.confirmnew.title': 'नयाँ-अंकको पिन दोहोर्याउनुहोस्',
    'profile.changepin.success.message': 'तपाईंको पिन सफलतापूर्वक परिवर्तन गरिएको छ',
    'profile.scan.instructions': 'कृपया अब कर्पोरेट आईडी स्क्यान गर्नुहोस्',
    'profile.scan.pin': 'पिन प्रविष्ट गर्नुहोस्',
    'profile.user_qrcode': 'QR कोड',
    'profile.user_qrcode.description': 'यस प्रयोगकर्ताको QR कोड स्क्यान गर्नुहोस्',
    'profile.upgrade_membership': 'सदस्यतापत्र',
    'profile.upgrade.button': 'अपग्रेड गर्नुहोस्',
    'profile.upgrade.subtitle': 'हाल तिमी',
    'profile.upgrade.description': 'राम्रो फाइदाहरू र सुविधाहरू प्राप्त गर्न अपग्रेड गर्नुहोस्',
    'profile.upgrade.alert.title': 'अपग्रेड गर्न',
    'profile.upgrade.alert.message.gold': 'सुनको सदस्यता अपग्रेड गर्न, तपाइँ ekyc प्रक्रिया पार गर्न आवश्यक छ। कृपया जारी राख्न ठीक छ मा क्लिक गर्नुहोस्.',
    'profile.upgrade.alert.message.silver': 'चाँदी सदस्यत्व अपग्रेड गर्न, तपाइँले आफ्नो खाता नाम प्रमाणित गर्न कम्तिमा एक fpx लेनदेन प्रदर्शन गर्न आवश्यक छ। कृपया जारी राख्न ठीक छ मा क्लिक गर्नुहोस्.',
    'profile.upgrade.alert.message.platinum': 'प्लेटिनममा सदस्यता अपग्रेड गर्न, तपाईले यस प्रक्रियाको साथ जारी राख्नको लागि हाम्रो कुनै नजिक मिमी प्रचारक एजेन्ट एजेन्टमा जान आवश्यक छ। कृपया जारी राख्न ठीक छ मा क्लिक गर्नुहोस्',
    'profile.referral.menu.title': 'एक साथीलाई आमन्त्रित गर्नुहोस्',
    'profile.referral.menu.subtitle': 'अधिक पोइन्टहरू पाउनुहोस् जब तपाईं एक मित्रलाई डाउनलोड गर्न र अनुप्रयोग प्रयोग गर्न आमन्त्रित गर्नुहुन्छ',
    'profile.referral.menu.description': 'तपाईं र साथी नि: शुल्क पोइन्ट प्राप्त हुनेछ!',
    'profile.referral.header.title': 'रेफरल',
    'profile.referral.question': 'यस्ले कसरी काम गर्छ?',
    'profile.referral.instruction1': 'एक साथी संग आफ्नो लिंक साझा गर्नुहोस्',
    'profile.referral.instruction2': 'तपाईंको साथीलाई MM अनुप्रयोग डाउनलोड गर्न आवश्यक छ र सफलतापूर्वक साइन अप गर्नुहोस्.',
    'profile.referral.instruction3': 'तपाईं र तपाईंको साथी नि: शुल्क पोइन्ट प्राप्त हुनेछ',
    'profile.referral.share.button': 'मेरो लिंक साझा गर्नुहोस्',
    'profile.referral.share.description': 'के तपाईंले अझै MM अनुप्रयोग प्रयास गर्नुभयो? तिनीहरूसँग तपाईंको लागि केहि रमाईलो छ, यहाँ जाँच गर्नुहोस्:',
    'profile.delete.account': 'खाता मेट्नुहोस्',
    'profile.delete.account.message': 'MM ले अनुरोध गरे अनुसार तपाइँको MM अनुप्रयोग प्रयोगकर्ता लगइन खाता हटाउनेछ। तपाईंको MM वालेट खाता बन्द गर्न कृपया CT @Mobile-mone.com.my मा हाम्रो ग्राहक समर्थनमा ईमेल गर्नुहोस्। थप विवरणको लागि, कृपया यहाँ क्लिक गर्नुहोस्',
    'profile.delete.account.submessage': 'कृपया सेमी सर्तहरू र ग्राहकहरूका लागि सर्तहरू 1 18 मा सन्दर्भ गर्नुहोस्',
    'profile.payslip': 'चुपचाष गर्नु',
    'profile.payslip.emptylist': 'कुनै डाटा अहिले उपलब्ध छैन',
    'profile.payslip.date': 'कृपया मिति चयन गर्नुहोस्',
    'profile.payslip.employee_id': 'कृपया आफ्नो कर्मचारी आईडी प्रविष्ट गर्नुहोस्',
    'profile.accountID_qrcode.description': "यो प्रयोगकर्ताको खाता ID स्क्यान गर्नुहोस्",
    'profile.upgrade.ic.attention': 'ध्यान दिनुहोस्',
    'profile.upgrade.ic.desc': 'म्याद सकिएको छ, कृपया यसलाई अपडेट गर्न यहाँ क्लिक गर्नुहोस्',
    'profile.upgrade.ic.message': 'राहदानीको म्याद सकिएको छ। के तपाई आफ्नो नयाँ राहदानी अपडेट गर्न चाहनुहुन्छ? कृपया अपडेट थिच्नुहोस्',
    'profile.upgrade.ic': 'पासपोर्ट अपडेट गर्नुहोस्',
    'profile.upgrade.ic.oldid': 'हालको आईडी / राहदानी नम्बर',
    'profile.upgrade.ic.newid': 'आफ्नो नयाँ आईडी / पासपोर्ट नम्बर प्रविष्ट गर्नुहोस्',
    'profile.upgrade.ic.submit': 'पेश गर्नुहोस्',
    'profile.upgrade.ic.errornewid': 'कृपया नयाँ आईडी / पासपोर्ट प्रविष्ट गर्नुहोस्',
    'profile.upgrade.ic.errorimage': 'कृपया नयाँ आईडी / राहदानी को तस्वीर प्रदान गर्नुहोस्',
    'profile.upgrade.ic.expired_dt': 'म्याद सकिने मिति',
    'profile.upgrade.ic.newidissuedt': 'नयाँ आईडी / राहदानी जारी मिति (DD/MM/YYYY)',
    'profile.upgrade.ic.newidexpirydt': 'नयाँ आईडी / राहदानी समाप्ति मिति (DD/MM/YYYY)',
    'profile.upgrade.ic.errornewidissuedt': 'कृपया ढाँचामा नयाँ आईडी / राहदानी जारी मिति प्रविष्ट गर्नुहोस् (DD/MM/YYYY)',
    'profile.upgrade.ic.errornewidexpirydt': 'कृपया नयाँ आईडी / राहदानी समाप्ति मिति प्रविष्ट गर्नुहोस् (DD/MM/YYYY)',
    'profile.community.header': 'सदस्यता लिनुहोस् र नयाँ समुदायहरू पत्ता लगाउनुहोस्',
    'profile.community.scan.instructions': 'कृपया अब खाता ID QR कोड स्क्यान गर्नुहोस्',


    /* PAY BILL */
    'paybill.description': 'खाता विवरणहरूको लागि तपाईंको बिल सन्दर्भ गर्नुहोस्',
    'paybill.proceed': 'अघि बढ्नु',
    'paybill.paynow': 'अबो तिरे',
    'paybill.denominations': 'रकम छनौट गर्नुहोस्',

    /*  RECEIPT PAGE */
    'receipt.copyToClipboard': 'जानकारी प्रतिलिपि गरियो',

    /* KIRIM BENEFICIARY PAGE */
    'kirim.beneficiary.title': 'प्राप्तकर्ता थप्नुहोस्',
    'kirim.beneficiary.benename': 'प्राप्तकर्ता नाम',
    'kirim.beneficiary.benephone': 'प्राप्तकर्ता मोबाइल नम्बर',
    'kirim.beneficiary.benekadno': 'सदस्य कार्ड नम्बर',
    'kirim.beneficiary.benecity': 'पारस्परिक शहर',
    'kirim.beneficiary.bene': 'पाउने व्यक्ति',
    'kirim.beneficiary.benerelationship': 'पावर सम्बन्ध',
    'kirim.beneficiary.benebank': 'प्राप्तकर्ता बैंक',
    'kirim.beneficiary.benebankbranch': 'प्राप्तकर्ता बैंक शाखा',
    'kirim.beneficiary.ifsc': 'IFSC', // IFSC
    'kirim.beneficiary.benecountry': 'प्राप्तकर्ता देश',
    'kirim.beneficiary.beneaccno': 'खाता नम्बर',
    'kirim.beneficiary.nobene': 'कुनै प्राप्तकर्ता छैन',

      /* KIRIM PAGE */
      'kirim.menu.bookrate': 'अनुरोध रेमिट',
      'kirim.bookrate.beneficiary': 'पाउने व्यक्ति',
      'kirim.bookrate.paynow': 'अबो तिरे',
      'kirim.bookrate.transactionstatus': 'लेनवेसना अवस्था',
      'kirim.bookrate.documents': 'MM को लागी कागजातहरू',
      'kirim.bookrate.help': 'सहयोगी लाइन',

    /* KIRIM HEADDER PAGE */
    'kirim.header.bookrate': 'अनुरोध रेमिट',
    'kirim.header.beneficiary': 'पाउने व्यक्ति',
    'kirim.header.beneficiary.add': 'प्राप्तकर्ता थप्नुहोस्',
    'kirim.header.paynow': 'अबो तिरे',
    'kirim.header.transactionstatus': 'लेनवेसना अवस्था',
    'kirim.header.documents': 'MM को लागी कागजातहरू',
    'kirim.header.help': 'सहयोगी लाइन',

    /* KIRIM STATUS TRANSAKSI PAGE */
    'kirim.transaction.transid': 'कारोबार आईडी',
    'kirim.transaction.beneficiary': 'पाउने व्यक्ति',
    'kirim.transaction.cardno': 'सदस्य कार्ड नम्बर',
    'kirim.transaction.country': 'देश',
    'kirim.transaction.provider': 'प्रदीयन',
    'kirim.transaction.booktime': 'आरक्षणको मिति',
    'kirim.transaction.rate': 'विनिमय दर',
    'kirim.transaction.date': 'सहमति मिति',
    'kirim.transaction.receipt': 'रसिद',

    /* KIRIM PAY NOW PAGE */
    'kirim.paynow.nobook': 'कुनै बुकिङ उपलब्ध छैन',

    /* KIRIM MONEY PIN PAGE */
    'kirim.moneypin.message': 'सन्देश',
    'kirim.moneypin.promotion': 'प्रेरणा',
    'kirim.moneypin.redeem': 'पैसा पिन रिडिम गर्नुहोस्',
    'kirim.moneypin.time': 'मिति समय',
    'kirim.moneypin.send': 'पठाउनु',
    'kirim.moneypin.receipt': 'र्याट रसीद',
    'kirim.moneypin.receiver':'प्राप्तकर्ता',
    'kirim.moneypin.amount':'रकम',
    'kirim.moneypin.pin':'PIN',
    'kirim.moneypin.provider':'सेवा प्रदायक',
    'kirim.moneypin.details':'Cash PIN Details',

    /* KITA PAGE */
    'kita.date': 'अपडेट गरिएको',
    'kita.header.title': 'बचाउनु',
    'kita.points': 'अंक',
    'kita.points.toptab1': 'प्रयोगो',
    'kita.points.toptab2': 'पाउनु',
    'kita.earned': 'कमाएको',
    'kita.water': 'पानी',
    'kita.water.litre': 'लिइर',
    'kita.water.point_description': 'कुल पोइन्टहरू',
    'kita.water.button': 'पोइन्टको साथ रिडिम गर्नुहोस्',
    'kita.water.qrcode_message': 'पानी मेशिनमा स्क्यानरमा तपाईंको QR कोड देखाउनुहोस्. क्यूआर कोड बन्द भए पछि हटाइनेछ.',
    'kita.water.qrcode_button': 'घनिष्ट',
    'kita.prepaid.header.title': 'एक प्रीपेड पिन रिडिम गर्नुहोस्',
    'kita.voucher': 'भाउचर',
    'kita.history.toptab1': 'प्रयोग गर्न',
    'kita.history.toptab2': 'प्रयोगो',
    'kita.history.points_used': 'पोइन्ट प्रयोग गरियो',
    'kita.history.voucher_code': 'भौचर कोड',
    'kita.history.redeemed': 'छुटकारा दिए',
    'kita.history.header': 'रिडिम ईतिहास फिर्ता गर्नुहोस्',
    'kita.point.header': 'पोइन्ट ईतिहास',
    'kita.advertisement': 'विज्ञापन',
    'kita.point.empty': 'कुनै डाटा अहिले उपलब्ध छैन!',
    'kita.earn.title' : 'तपाईंले कमाउनुभएको छ',
    'kita.earn.description': 'हेर्नुहोस् र कमाई पोइन्टहरू',
    'kita.earn.header' : 'अंक कमाउनुहोस',
    'kita.earn.emptylist.label': 'यस समयमा कुनै भिडियो छैन, कृपया पछि प्रयास गर्नुहोस्',
    'kita.earn.ad.component.description': 'हेर्नुहोस् र कमाई पोइन्टहरू',
    'kita.earn.counter': 'बाँकी समय',
    'kita.earn.counter.close': 'पोइन्टहरू कमाउन क्लिक गर्नुहोस्',
    'kita.stamps.title': 'ई-टिकट',
    'kita.cash.voucher.title' : 'नगद भौचर',
    'kita.cash.voucher.accumulated' : 'क्यास भौचरहरू संकलन गर्छन्',
    'kita.redeem': 'रि आगो गर्नु',
    'kita.revert': 'पहिले को अवस्थामा फर्कनु',
    'kita.cash.voucher.qrcode.message': 'नगद भौचर प्रयोग गर्न यो QR कोड देखाउनुहोस्',
    'kita.cash.voucher.merchants': 'व्यापारी',
    'kita.cash.voucher.purchase' : 'वालेट संग खरीद',
    'kita.cash.voucher.vouchers': 'ट्रेडरको भाउचर',
    'kita.cash.voucher.balance' : 'बाँया भौचर',
    'kita.cash.voucher.enter' : 'रकम प्रविष्ट गर्नुहोस्',
    'kita.cash.voucher.generate' : 'QR कोड बनाउनुहोस्',
    'kita.cash.voucher.invalid' : 'गलत रकम',
    'kita.cash.voucher.error' : 'रकम शून्य हुन सक्दैन',
    'kita.cash.voucher.collected': 'भाउचर',
    'kita.cash.voucher.history': 'इतिहास',
    'kita.cash.voucher.use': 'भाउचर प्रयोग गर्नुहोस्',
    'kita.cash.voucher.usage_left': 'सम्म मान्य',
    'kita.cash.voucher.usage': 'प्रयोग',
    'kita.cash.voucher.days': 'दिनहरू',
    'kita.cash.voucher.times': 'पटक',
    'kita.update.version': 'नयाँ संस्करण उपलब्ध छ। कृपया अहिले अपडेट गर्नुहोस्',

    /* KADAR PAGE */
    'kadar.header.title': 'दरहरू',

    /* PAY PAGE */
    'pay.header.title': 'अबो तिरे',
    'pay.qrcode.desc': 'यो क्यूआर कोड हरेक ६० सेकेन्डमा रिफ्रेस हुनेछ',

    /* NOTIFICATIONS */
    'notifications.header.title': 'सूचनाहरू',

    /* CASH OUT */
    'cashout.bank_name.label': 'बैंक नाम',
    'cashout.acc_name.label': 'खाताको नाम',
    'cashout.acc_no.label': 'खाता नम्बर',
    'cashout.amount.label': 'रकम प्रविष्ट गर्नुहोस्',
}