import { Box, InputBase, IconButton } from "@mui/material";
import { tokens } from "../theme";
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useProSidebar } from "react-pro-sidebar";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useAppDispatch } from "../store/store";
import { useNavigate } from "react-router-dom";
import { FC } from "react";

interface Props {
    logOut: () => void
}

const colors = tokens();

const TopBar: FC<Props> = (props) => {
    const { logOut } = props;
    const { broken, toggleSidebar } = useProSidebar();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            sx={{
                boxShadow: 3,
                color: colors.gray[200],
            }}
        >
            <Box display="flex">
                {broken && (
                    <IconButton onClick={() => toggleSidebar()}>
                        <MenuOutlinedIcon />
                    </IconButton>
                )}
            </Box>
            <Box display="flex" sx={{ mt: 1 }}>
                <IconButton onClick={() => logOut()}>
                    <LogoutIcon />
                </IconButton>
                <IconButton onClick={() => navigate('/notification')}>
                    <NotificationsActiveIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TopBar;
