import { ThemeProvider } from "@emotion/react";
import { Container, CssBaseline, Card, CardContent, Box, Grid, Button, Typography, TextField, IconButton, CircularProgress, createTheme, FormControl, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import QrReader from "react-qr-reader";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import { RootState } from "../../store/store";
import { callBackendAPI, getLabelValue } from "../../utils";
import { BackendResponse, Error } from "../../type";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { tokens } from "../../theme";
import { CONFIG } from "../../config";
import { useRegister } from "../../actions/RegisterAction";

type IItems = {
    label: string;
    value: number;
    [key: string]: any | undefined;
}

const colors = tokens();

const RegisterExistUser = () => {
    const { state } = useLocation();
    const user: any = state.user;
    const rfCode = state.hasOwnProperty("referralCode") ? state.referralCode : "";
    const [referralCode, setReferralCode] = useState<string>(rfCode);
    const [scanQR, setScanQR] = useState(false);
    const [, idTypes, nationalities] = useRegister();
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>({ error: false, message: "" });
    const navigate = useNavigate();

    const [selected, setSelected] = useState<"environment" | "user">("environment");

    const handleWhatsAppRegistration = async (): Promise<void> => {
        let selectedIdType = idTypes.find((item: IItems) => item.label === user.idType.label);
        let selectedNationality = nationalities.find((item: IItems) => item.label === user.nationality);

        navigate('/registerpagetwo', {
            state: {
                user: state.user,
                userinput: {
                    phoneNumber: user.mobile,
                    idType: selectedIdType,
                    idNumber: user.idNumber,
                    nationality: selectedNationality,
                    referralCode: referralCode,
                    otp: state.otp || ''
                },
                wa: true,
            }
        })
    }

    const handleAccountRegistrationPreChecks = async (): Promise<void> => {
        try {
            if (user.mobile === '')
                return setError({ error: true, message: "Error getting user's phone number" });
            if (!user.idType)
                return setError({ error: true, message: "Error getting user's ID Type" });
            if (user.idNumber === '')
                return setError({ error: true, message: "Error getting user's ID Number" });
            if (!user.nationality)
                return setError({ error: true, message: "Error getting user's Nationality" });
            if (error.error)
                setError({ error: false, message: '' });

            let selectedIdType = idTypes.find((item: IItems) => item.label === user.idType.label);
            let selectedNationality = nationalities.find((item: IItems) => item.label === user.nationality);

            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let input: any = {
                ver: CONFIG?.ver,
                act: 901,
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                p1: user.mobile.trim(),
                p2: selectedIdType,
                p3: user.idNumber.trim(),
                p4: selectedNationality,
                p5: referralCode.trim(),
                p6: 1, //rbaRegister
                m1: "Register",
                m2: "",
                lg: lang,
            }
            let resultFromAPI: BackendResponse | undefined = await callBackendAPI('register', 'POST', headers, input);
            if (resultFromAPI === undefined) {
                setLoading(false);
                return setError({ error: true, message: "001: Something went wrong, please try again." });
            }
            if (resultFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: resultFromAPI.error || "002: Something went wrong, please try again." });
            }
            if (!resultFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error: true, message: resultFromAPI.error || "003: Something went wrong, please try again." });
            }
            if (resultFromAPI?.results) {
                let results: any = resultFromAPI.results;
                if (results.code !== 0) {
                    setLoading(false);
                    return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}` });
                }
                if (!results.hasOwnProperty("user")) {
                    setLoading(false);
                    return setError({ error: true, message: "004: Something went wrong, try again" });
                }
                setLoading(false);
                return navigate('/registerpagetwo', {
                    state: {
                        user: results.user,
                        userinput: {
                            phoneNumber: user.mobile,
                            idType: selectedIdType,
                            idNumber: user.idNumber,
                            nationality: selectedNationality,
                            referralCode: referralCode,
                            otp: state.otp || ''
                        },
                        rba: true,
                    }
                });
            }
            setLoading(false);
            return setError({ error: false, message: "005: Something went wrong, please try again." })
        }
        catch (e) {
            setLoading(false);
            return setError({ error: false, message: "OOPS SOMETHING WENT WRONG, PLEASE TRY AGAIN" });
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                {scanQR ? (
                    <Card

                        elevation={3}
                    >
                        <CardContent>
                            <Box mt="5px" mb="25px" ml="5px">
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="10px"
                                    overflow="hidden"
                                    boxShadow={2}
                                >
                                    <img
                                        alt="logo"
                                        width="230px"
                                        height="60px"
                                        src={'../../assets/mmlogo.png'}
                                        style={{ cursor: "pointer" }}
                                    />
                                </Box>
                            </Box>
                            <QrReader
                                facingMode={selected}
                                delay={1000}
                                onError={() => setError({ error: false, message: 'QRCode Error' })}
                                onScan={(data) => {
                                    if (data) {
                                        setReferralCode(data);
                                        setScanQR(false);
                                    }
                                }}
                            />
                            <Grid container spacing={2} alignItems="center" justifyContent="center" mt={1}>
                                <Grid item xs={6} md={6} lg={6}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                                            <Select
                                                value={selected || undefined}
                                                onChange={(e) => setSelected(e.target.value as "environment" | "user")}
                                            >
                                                <MenuItem value={"environment"}>Back Camera</MenuItem>
                                                <MenuItem value={"user"}>Front Camera</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={6} lg={6}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setScanQR(false)}
                                            sx={{ width: '100%' }}
                                        >
                                            Stop Scan
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ) : (
                    <Box
                        sx={{
                            boxShadow: 3,
                            borderRadius: 2,
                            px: 4,
                            py: 3,
                            marginTop: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box mt="5px" mb="25px" ml="5px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="10px"
                                overflow="hidden"
                                boxShadow={2}
                            >
                                <img
                                    alt="logo"
                                    width="230px"
                                    height="60px"
                                    src={'../../assets/mmlogo.png'}
                                    style={{ cursor: "pointer" }}
                                />
                            </Box>
                        </Box>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }} color='#ef233e'>
                            {getLabelValue(lang, 'register.title')}
                        </Typography>
                        <Box
                            sx={{ textAlign: 'left' }}
                            mt="15px"
                            mb="5px"
                            ml="5px"
                            mr="5px"
                        >
                            {getLabelValue(lang, 'register.account.title')}
                        </Box>
                        <Box sx={{ mt: 1 }} minWidth={'100%'}>
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="5px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.handphone')}
                            </Box>
                            <TextField
                                margin="none"
                                fullWidth
                                type='text'
                                value={user.mobile || ''}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="15px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.idType')}
                            </Box>
                            <TextField
                                margin="none"
                                fullWidth
                                type="text"
                                value={user.idType.label || ''}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="15px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.id')}
                            </Box>
                            <TextField
                                margin="none"
                                fullWidth
                                type="text"
                                value={user.idNumber || ''}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="20px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.nationality')}
                            </Box>
                            <TextField
                                margin="none"
                                required
                                fullWidth
                                type="text"
                                value={user.nationality || ''}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            <Box
                                sx={{ textAlign: 'left' }}
                                mt="20px"
                                mb="5px"
                                ml="5px"
                                mr="5px"
                            >
                                {getLabelValue(lang, 'register.account.rfCode')}
                                <IconButton
                                    onClick={() => setScanQR(true)}
                                    sx={{ marginRight: 1, color: 'black' }}
                                >
                                    <QrCodeScannerIcon sx={{ fontSize: '1.5rem' }} />
                                </IconButton>
                            </Box>
                            <TextField
                                margin="none"
                                fullWidth
                                placeholder="1234567890"
                                type="text"
                                value={referralCode}
                                onChange={(e) => setReferralCode(e.target.value)}
                            />
                            {error.error && (
                                <Typography
                                    sx={{
                                        mt: '10px',
                                        textAlign: 'center',
                                        color: colors.primary[500],
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {error.message || ''}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                onClick={async () => {
                                    if (state.hasOwnProperty("wa") && state.wa === true)
                                        await handleWhatsAppRegistration();
                                    else
                                        await handleAccountRegistrationPreChecks();
                                }}
                                endIcon={loading ? <CircularProgress size={20} /> : null}
                                sx={{ mt: 3, mb: 1 }}
                            >
                                {getLabelValue(lang, 'login.account.button.next')}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Container>
            <Footer />
        </ThemeProvider>
    )
}

const theme = createTheme({
    palette: {
        secondary: {
            main: '#ef233e',
        },
        primary: {
            main: '#212121',
        }
    }
});

const styles = (isMobile: boolean) => ({
    cardStyle: {
        mt: 2,
        borderRadius: 2,
        maxHeight: isMobile ? 'auto' : '70vh',
        overflow: 'auto'
    }
})
export default RegisterExistUser;