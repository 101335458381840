import {Box, Button, CircularProgress, Container, CssBaseline, Grid, Paper, TextField, ThemeProvider, Typography} from "@mui/material";
import Header from "../../components/Header";
import React, { useState } from "react";
import { theme } from "../../theme";
import { getLabelValue } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Documents = () => {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [transactionId, setTransactionID] = useState('');
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box component="main" sx={{flexGrow: 1}}>
                <Box sx={{ mt: 3, ml: 3 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Header title={"Documents"} subtitle={""} />
                    </Box>
                    <Container maxWidth="lg" sx={{ mb: 3 }}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} md={7} lg={7}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: 4 }}>
                                    <Box 
                                        sx={{ textAlign: 'center' }}
                                        mt="5px"
                                        mb="10px"
                                        ml="5px" 
                                        mr="5px"
                                    >
                                        <Typography variant="body1">
                                            We will contact you through email.
                                        </Typography>
                                        <Typography variant="body1">
                                            Please enter a precise email.
                                        </Typography>
                                    </Box>
                                    <TextField
                                        label={getLabelValue(lang, 'register.account.email')}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <TextField                              
                                        label={'Subject'}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                    />
                                    <TextField
                                        label={'Transaction ID'}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={transactionId}
                                        onChange={(e) => setTransactionID(e.target.value)}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        // onClick={async () => setModalVisible(true)}
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 3, mb: 2 }}
                                        disabled={loading}
                                        endIcon={loading ? <CircularProgress size={20} /> : null}
                                    >
                                        {getLabelValue(lang, 'kirim.bookrate.confirm.button')}
                                    </Button>                                                                        
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
          </Box>
        </ThemeProvider>
    )
}
  
export default Documents;