import FPX_LOGO from "../assets/icons/fpx.png";
import KIRIM_FPX_LOGO from "../assets/icons/kirim/fpx.png";

import RATE_LOGO from "../assets/icons/kirim/rate.png";
import DOCUMENTS from "../assets/icons/kirim/documents.png";
import BENEFICIARY from "../assets/icons/kirim/beneficiary.png";
import HELP from "../assets/icons/kirim/help.png";

export const LANGUAGES = [
  {value: "bm", label: "Bahasa Malaysia"},
  {value: "en", label: "English"},
  {value: "bn", label: "বাংলা"},
  {value: "ne", label: "नेपाली"},
];

export const SHORT_LANGUAGES = [
    {value: "bm", label: "BM"},
    {value: "en", label: "ENG"},
    {value: "bn", label: "বাংলা"},
    {value: "ne", label: "नेपाली"},
];
  

export const COLORS = {
    PRIMARY: '#EF233E',
    SECONDARY: '#D82B48',
    SUCCESS: '#3A823A',
    GREY: '#8E8E8E',
    DANGER: 'red',
    GREYPRIMARY: '#CC8591',
    WARNING: '#FECA38',
    BLUE: '#6D9DE0',
    MUTED: '#D1CFCF',
    OVERLAY: "#707070",
    GREYFILL: '#EDEDED',
    WHITE: '#FFFFFF',
    BLACK: '#000',
    LINKBLUE: '#0000EE',
    PURPLE: '#34347b'
};

export const FONTS = {
  black: 'Montserrat-Black', blackItalic: 'Montserrat-BlackItalic',
  bold: 'Montserrat-Bold', boldItalic: 'Montserrat-BoldItalic',
  extraBold: 'Montserrat-ExtraBold', extraBoldItalic: 'Montserrat-ExtraBoldItalic',
  extraLight: 'Montserrat-ExtraLight', extraLightItalic: 'Montserrat-ExtraLightItalic',
  italic: 'Montserrat-Italic', light: 'Montserrat-Light', lightItalic: 'Montserrat-LightItalic',
  medium: 'Montserrat-Medium', mediumItalic: 'Montserrat-MediumItalic',
  regular: 'Montserrat-Regular', semiBold: 'Montserrat-SemiBold', semiBoldItalic: 'Montserrat-SemiBoldItalic',
  thin: 'Montserrat-Thin', thinItalic: 'Montserrat-ThinItalic',
};

export const MEMBERSHIP_TYPES = [
  {id: 1, name: "Silver", color: "#C0C0C0", wallet_size: "(up to RM 1,500 Wallet size)", message: 'profile.upgrade.alert.message.silver'},
  {id: 2, name: "Gold", color: "#FFD700", wallet_size: "(up to RM 4,999 Wallet size)", message: 'profile.upgrade.alert.message.gold'},
  {id: 3, name: "Platinum", color: "#E5E4E2", wallet_size: "(up to RM 10,000 Wallet size)", message: 'profile.upgrade.alert.message.platinum'},
]

export const KIRIM_MENU = [
  {
    act_id: 30000,
    name: "kirim.menu.bookrate",
    short_name: "BookRate",
    icon: RATE_LOGO,
    navigateTo: "BookRate",
    enabled: true,
  },
  {
    act_id: 30001,
    name: "kirim.bookrate.beneficiary",
    short_name: "bene",
    icon: BENEFICIARY,
    navigateTo: "BeneficiaryV2",
    enabled: true,
  },
  {
    act_id: 30002,
    name: "kirim.bookrate.paynow",
    short_name: "pay",
    icon: RATE_LOGO,
    navigateTo: "PayNow",
    enabled: true,
  },
  {
    act_id: 30003,
    name: "kirim.bookrate.transactionstatus",
    short_name: "trxn",
    icon: RATE_LOGO,
    navigateTo: "TransactionHistory",
    enabled: true,
  },
  {
    act_id: 30004,
    name: "kirim.bookrate.documents",
    short_name: "docs",
    icon: DOCUMENTS,
    navigateTo: "Documents",
    enabled: true,
  },
  {
    act_id: 30005,
    name: "kirim.bookrate.help",
    short_name: "help",
    icon: HELP,
    navigateTo: "HelpLine",
    enabled: true,
  },
  {
    act_id: 36000,
    name: "kirim.bookrate.fpx",
    short_name: "fpx",
    icon: KIRIM_FPX_LOGO,
    navigateTo: "FPXBankAccount",
    enabled: true,
  },
];

export const BOOK_RATE_MENU = [
  {
    act_id: 30000,
    name: "Pre Book Screen",
    short_name: "PreBookScreen",
    navigateTo: "",
  },
  {
    act_id: 32000,
    name: "Book Confirm Screen",
    short_name: "BookConfirmScreen",
    navigateTo: "",
  },
];

export const BENEFICIARY_MENU = [
  {
    act_id: 31000,
    name: "Add Beneficiary Screen",
    short_name: "AddBeneficiaryScreen",
    navigateTo: "AddBeneficiaryScreen",
  },
  {
    act_id: 31001,
    name: "Add Beneficiary Screen",
    short_name: "AddBeneficiaryScreen",
    navigateTo: "AddBeneficiaryScreen",
  },
  {
    act_id: 31002,
    name: "Add Beneficiary Bank List",
    short_name: "AddBeneBankList",
    navigateTo: "",
  },
  {
    act_id: 31003,
    name: "Add Beneficiary",
    short_name: "AddBeneficiary",
    navigateTo: "",
  },
  {
    act_id: 31004,
    name: "Documents for MM",
    short_name: "docs",
    navigateTo: "Documents",
  },
  {
    act_id: 31005,
    name: "Help Line",
    short_name: "help",
    navigateTo: "HelpLine",
  },
];

export const TOPUP = {
  denos: [
    {value: 5, label: 5},
    {value: 10, label: 10},
    {value: 20, label: 20},
    {value: 50, label: 50},
    {value: 100, label: 100},
    {value: 200, label: 200},
  ],
  paymentModes: [
    {
      id: 1,
      icon: FPX_LOGO,
      bm: {
        title: "Cara Tamba Nilai Lain",
        description: "FPX Online Banking",
      },
      en: {
        title: "Top up amount",
        description: "FPX Online Banking",
      },
    },
  ],
};

export const KIRIM_PURPOSES = [
    {
        value: 1,
        label: "Utk Keluarga"
    },
    {
        value: 2,
        label: "Pendidikan"
    },
    {
        value: 3,
        label: "Perubatan"
    },
    {
        value: 4,
        label: "Simpanan"
    },
    {
        value: 5,
        label: "Pelaburan"
    },
    {
        value: 6,
        label: "Bayar Hutang"
    },
    {
        value: 7,
        label: "Berniaga"
    },
    {
        value: 8,
        label: "Melabur"
    },
    {
        value: 9,
        label: "Wakil Hantar"
    },
    {
        value: 10,
        label: "Lain-lain"
    },
    {
        value: 11,
        label: "Tidak Pasti"
    },
    {
        value: 100,
        label: "Goods and services"
    },
    {
        value: 101,
        label: "Wages and salaries"
    },
    {
        value: 102,
        label: "Wages and salaries in kind/benefits attributable to employees"
    },
    {
        value: 103,
        label: "Education-related"
    },
    {
        value: 104,
        label: "Deposits with/from financial institutions abroad"
    },
    {
        value: 105,
        label: "Goods and services purchased by travellers"
    },
    {
        value: 106,
        label: "Health-related"
    },
    {
        value: 107,
        label: "Interest"
    },
    {
        value: 108,
        label: "Investment income"
    },
    {
        value: 109,
        label: "Legal services"
    },
    {
        value: 110,
        label: "Management consulting services"
    },
    {
        value: 111,
        label: "Merchanting trade"
    },
    {
        value: 112,
        label: "Passenger fare"
    },
    {
        value: 113,
        label: "Real estate in host country"
    },
    {
        value: 114,
        label: "Term loans (short- term)"
    },
    {
        value: 115,
        label: "Terms loans (long- term)"
    },
    {
        value: 116,
        label: "Grants and gifts"
    }
]

export const STATES = [
    { value: 100, label: 'JOHOR' },
    { value: 101, label: 'KEDAH' },
    { value: 102, label: 'KELANTAN' },
    { value: 103, label: 'LABUAN' },
    { value: 104, label: 'MELAKA' },
    { value: 105, label: 'NEGERI SEMBILAN' },
    { value: 106, label: 'PAHANG' },
    { value: 107, label: 'PERAK' },
    { value: 108, label: 'PERLIS' },
    { value: 109, label: 'PULAU PINANG' },
    { value: 110, label: 'PUTRAJAYA' },
    { value: 111, label: 'SABAH' },
    { value: 112, label: 'SARAWAK' },
    { value: 113, label: 'SELANGOR' },
    { value: 114, label: 'TERENGGANU' },
    { value: 115, label: 'WILAYAH PERSEKUTUAN' }
];

export const ELECTRICITY = [
    { value: 200, label: 'PLN Indonesia'},
    { value: 201, label: 'Tenaga Nasional'}
];

export const TELCO = [
    { value: 300, label: 'Pulsa'},
    { value: 301, label: 'Celcom Postpaid'},
    { value: 302, label: 'Digi Postpaid'},
    { value: 303, label: 'Maxis Postpaid'},
    { value: 304, label: 'Telekom Malaysia'}
];

export const TV = [
    { value: 400, label: 'ASTRO'}
];

export const PREPAID = [
    { value: 500, label: 'U Mobile'},
    { value: 501, label: 'Celcom'},
    { value: 502, label: 'ALTEL'},
    { value: 503, label: 'BeOne'},
    { value: 504, label: 'Halo'},
    { value: 505, label: 'Digi'},
    { value: 506, label: 'Maxis'},
    { value: 507, label: 'Merchant Trade'},
    { value: 508, label: 'XOX'},
    { value: 509, label: 'RedOne'},
    { value: 510, label: 'Tune Talk'},
    { value: 511, label: 'Yes'}
];

export const PREPAID_AMOUNT = [
  { value: 600, label: 'RM 5'},
  { value: 601, label: 'RM 10'},
  { value: 602, label: 'RM 30'},
  { value: 603, label: 'RM 50'},
  { value: 604, label: 'RM 100'}
];

export const RECEIPT_DURATION = [
  { value: 700, label: 'Today'},
  { value: 701, label: 'Last 7 days'},
  { value: 702, label: 'Last 30 days'},
  { value: 703, label: 'Last 90 days'}
];

export const REDEEM_CATEGORY = [
  { value: 705, label: 'Voucher'},
  { value: 706, label: 'Water'},
  { value: 707, label: 'Prepaid'}
];

export const REDEEM_PREPAID = [
  { value: 710, label: 'Digi Malaysia'},
  { value: 711, label: 'U Mobile'},
  { value: 712, label: 'Maxis Mobile'},
  { value: 713, label: 'Celcom'}
];

export const REDEEM_AMOUNT = [
  { value: 720, label: '500 points', amount: 5},
  { value: 721, label: '1000 points', amount: 10}
];

export const WATER_AMOUNT = [
  { value: '1L', label: '1 Litre = 20 points'},
  { value: '2L', label: '2 Litre = 40 points'},
  { value: '3L', label: '3 Litre = 60 points'},
  { value: '4L', label: '4 Litre = 80 points'},
  { value: '5L', label: '5 Litre = 100 points'}
];

export const RECEIPT_DATA = [
  {
    id: 1,
    date: "2022-01-01",
    amount: 700,
    description: "Grocery shopping",
    category: "Food",
  },
  {
    id: 2,
    date: "2022-01-02",
    amount: 50.00,
    description: "Clothing purchase",
    category: "Shopping",
  },
  {
    id: 3,
    date: "2022-01-03",
    amount: 10.00,
    description: "Lunch",
    category: "Food",
  },
  {
    id: 4,
    date: "2022-01-04",
    amount: 150.00,
    description: "Electronics purchase",
    category: "Shopping",
  }
];

export const DATE_RANGES = {
  bm: [
    {value: 0, label: "Hari ini"},
    {value: 7, label: "Selama 7 hari"},
    {value: 30, label: "Selama 30 hari"},
    {value: 90, label: "Selama 90 hari"},

  ],
  en: [
    {value: 0, label: "Today"},
    {value: 7, label: "Last 7 days"},
    {value: 30, label: "Last 30 days"},
    {value: 90, label: "Last 90 days"},
  ]
};

export const BOOKING_STATUS = {
  bm: [
    {value: "ALL", label: "All Booking Status"},
    {value: "PAID", label: "PAID"},
    {value: "UN-PAID", label: "UN-PAID"},
    {value: "CANCELLED", label: "CANCELLED"},
    {value: "EXPIRED", label :"EXPIRED"}
  ],
  en: [
    {value: "ALL", label: "All Booking Status"},
    {value: "PAID", label: "PAID"},
    {value: "UN-PAID", label: "UN-PAID"},
    {value: "CANCELLED", label: "CANCELLED"},
    {value: "EXPIRED", label :"EXPIRED"}
  ]
};

export const TRANSACTION_STATUS = {
  bm: [
    {value:"ALL", label: "All Transaction Status"},
    {value:"PAID", label: "PAID"},
    {value:"UN-PAID", label: "UN-PAID"},
    {value:"PROCESSING", label: "PROCESSING"},
    {value:"REFUNDED", label: "REFUNDED"},
    {value:"CANCELED", label: "CANCELED"},
    {value:"DECLINED", label: "DECLINED"},
    {value: "FAIL", label: "FAIL"},
  ],
  en: [
    {value:"ALL", label: "All Transaction Status"},
    {value:"PAID", label: "PAID"},
    {value:"UN-PAID", label: "UN-PAID"},
    {value:"PROCESSING", label: "PROCESSING"},
    {value:"REFUNDED", label: "REFUNDED"},
    {value:"CANCELED", label: "CANCELED"},
    {value:"DECLINED", label: "DECLINED"},
    {value: "FAIL", label: "FAIL"},
  ]
};

export const CONSTANTS = {
  OTP_COUNTER: 60,
};