import { useSelector } from "react-redux";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import { RootState, useAppDispatch } from "../../store/store";
import { useEffect, useRef, useState } from "react";
import { getReceivedPointHistory, getUsedPointHistory } from "../../actions/RedeemAction";
import { Box, Card, CardContent, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Header from "../../components/Header";
import Grid2 from "@mui/material/Unstable_Grid2";
import { getLabelValue, getLastUpdatedTime } from "../../utils";
import moment from "moment-timezone";
import KitaHeader from "../../components/KitaHeader";

const PointsHistory = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [value, setValue] = useState('1');
    const [usedPage, setUsedPage] = useState(0);
    const [usedRowsPerPage, setUsedRowsPerPage] = useState(5);
    const [receivedPage, setReceivedPage] = useState(0);
    const [receivedRowsPerPage, setReceivedRowsPerPage] = useState(5);
    const { loading, used_point_history, received_point_history } = useSelector((state: RootState) => state.redeem);
    const { lang, user } = useSelector((state: RootState) => state.user);
    
    const dispatch = useAppDispatch();
    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            dispatch(getUsedPointHistory(user, lang));
            dispatch(getReceivedPointHistory(user, lang));
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    }, [])

    const handleChangeTab = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const handleChangeUsedPage = (event: any, newPage: any) => {
        setUsedPage(newPage);
    };

    const handleChangeUsedRowsPerPage = (event: any) => {
        setUsedRowsPerPage(parseInt(event.target.value, 10));
        setUsedPage(0);
    };

    const handleChangeReceivedPage = (event: any, newPage: any) => {
        setReceivedPage(newPage);
    };

    const handleChangeReceivedRowsPerPage = (event: any) => {
        setReceivedRowsPerPage(parseInt(event.target.value, 10));
        setReceivedPage(0);
    };    

    const usedPointRows = used_point_history
    .slice(usedPage * usedRowsPerPage, (usedPage + 1) * usedRowsPerPage)
    .map((item: any, index: any) => (
        <TableRow key={index}>
            <TableCell>{moment.tz(item.redeem_datetime, 'Asia/Singapore').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
            <TableCell>{item.product_name}</TableCell>
            <TableCell sx={{ color: 'red' }}>{"-"}{item.point}</TableCell>
        </TableRow>
    ));

    const receivedPointRows = received_point_history
    .slice(receivedPage * receivedRowsPerPage, (receivedPage + 1) * receivedRowsPerPage)
    .map((item: any, index: any) => (
        <TableRow key={index}>
            <TableCell>{moment.tz(item.redeem_datetime, 'Asia/Singapore').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
            <TableCell>{item.product_name}</TableCell>
            <TableCell sx={{ color: 'green' }}>{"+"}{item.point}</TableCell>
        </TableRow>
    ));

    return (
        <Box m="20px">
            <Box 
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'kita.history.header')} subtitle={getLastUpdatedTime(lang)} />
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <KitaHeader />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                                        <Tab 
                                            label={getLabelValue(lang, 'kita.points.toptab1')} 
                                            value="1"
                                            sx={{ flexGrow: 1, textAlign: 'center', maxWidth: 'none', color: 'red' }} 
                                        />
                                        <Tab 
                                            label={getLabelValue(lang, 'kita.points.toptab2')} 
                                            value="2"
                                            sx={{ flexGrow: 1, textAlign: 'center', maxWidth: 'none', color: 'green' }} 
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <TableContainer>
                                        <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{getLabelValue(lang, 'kirim.moneypin.time')}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{getLabelValue(lang, 'kita.points')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {!loading && (!used_point_history || used_point_history.length === 0) && (
                                            <TableRow>
                                                <TableCell colSpan={3} align="center">{getLabelValue(lang, 'kita.point.empty')}</TableCell>
                                            </TableRow>                                                  
                                        )}
                                        {loading && (
                                            <TableRow>
                                                <TableCell colSpan={3} align="center">
                                                    <CircularProgress color="primary" />
                                                </TableCell>
                                            </TableRow>                                               
                                        )}       
                                        {usedPointRows}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>         
                                    <TablePagination  
                                        component="div"
                                        count={used_point_history.length}
                                        rowsPerPage={usedRowsPerPage}
                                        page={usedPage}
                                        onPageChange={handleChangeUsedPage}
                                        onRowsPerPageChange={handleChangeUsedRowsPerPage}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    />                   
                                </TabPanel>
                                <TabPanel value="2">
                                    <TableContainer>
                                        <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{getLabelValue(lang, 'kirim.moneypin.time')}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{getLabelValue(lang, 'kita.points')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                            <TableBody>
                                            {!loading && (!received_point_history || received_point_history.length === 0) && (
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center">{getLabelValue(lang, 'kita.point.empty')}</TableCell>
                                                </TableRow>                                                  
                                            )}
                                            {loading && (
                                                <TableRow>
                                                    <TableCell colSpan={3} align="center">
                                                        <CircularProgress color="primary" />
                                                    </TableCell>
                                                </TableRow>                                               
                                            )}                                                               
                                            {receivedPointRows}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>     
                                    <TablePagination
                                        component="div"
                                        count={received_point_history.length}
                                        rowsPerPage={receivedRowsPerPage}
                                        page={receivedPage}
                                        onPageChange={handleChangeReceivedPage}
                                        onRowsPerPageChange={handleChangeReceivedRowsPerPage}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    />                           
                                </TabPanel>
                                </TabContext>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    }
})

export default PointsHistory;