import { PAYBILL_FETCH_BILLERS, PAYBILL_FETCH_BILLERS_FAILED, PAYBILL_FETCH_BILLERS_SUCCESS } from "../actions/type";

const INITIAL_STATE = {
    loading: false,
    error: false,
    message: '',
    payBillBillers: []
}

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type){
        case PAYBILL_FETCH_BILLERS:
            return { ...state, loading: true, error: false, message: '' };
        case PAYBILL_FETCH_BILLERS_SUCCESS:
            return { ...INITIAL_STATE, payBillBillers: action.payload };
        case PAYBILL_FETCH_BILLERS_FAILED:
            return { ...state, loading: false, error: true, message: action.payload };
        default:
            return state;
    }
}
