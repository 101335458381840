import React, { FC, useState, useEffect } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { logError, sendReportIssueRequest } from "../utils";

interface PROPS {
    open: boolean;
    title?: string;
    content?: string[] | undefined;
    cancelButton?: string;
    onCancel?: () => void;
    yesButton?: string;
    onSuccess?: () => void;
    reportIssue?: boolean;
    req_idx?: number;
    onReportIssueDone?: () => void;
    reportTitle?: string;
    reportDetails?: string;
    trans_id?: number;
    ticket_type?: number;
}

type IAlert = {
    show: boolean;
    error: boolean;
    message: string;
};

const DialogAlert: FC<PROPS> = (props) => {
    const [showAlert, setShowAlert] = useState<IAlert>({
        show: false,
        message: "",
        error: false,
    });
    const {
        open,
        onSuccess,
        onCancel,
        title = "",
        content = [],
        cancelButton = "",
        yesButton,
        reportIssue = undefined,
        req_idx = -1,
        onReportIssueDone,
        reportTitle = "",
        reportDetails = "",
        trans_id = 0,
        ticket_type = 0,
    } = props;

  const handleReportIssue = async (): Promise<void> => {
    try {
        let details: string =
            `Trans ID: ${trans_id || 0}\n` +
            `Log IDX: ${req_idx || 0}\n` +
            reportDetails;
        const response = await sendReportIssueRequest(
            req_idx,
            reportTitle,
            details,
            trans_id,
            ticket_type
        );
        if (response === undefined) {
            setShowAlert({
            show: true,
            message: "Your issue has been submitted to Customer Care.",
            error: false,
            });
        } else {
            setShowAlert({
            show: true,
            message: response,
            error: true,
            });
        }

        if (onReportIssueDone) onReportIssueDone();
        } catch (e) {
            logError("handleReportIssue", e);
        }
    };

  // Handle the Escape key event
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape" && onCancel) {
            event.preventDefault();
            onCancel();
        }
        };

        if (open) {
        window.addEventListener("keydown", handleKeyDown);
        }

        return () => {
        window.removeEventListener("keydown", handleKeyDown);
        };
    }, [open, onCancel]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (!target.closest(".MuiDialog-root") && onCancel) {
            onCancel();
        }
        };

        if (open) {
        window.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
        window.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, onCancel]);

    return (
        <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            {content.map((i: string, v) => {
            if (i === "br") return <br key={v} />;
            return (
                <Typography key={v} variant="body1">
                {i}
                </Typography>
            );
            })}
        </DialogContent>
        <DialogActions>
            {reportIssue ? (
            <Button onClick={async () => await handleReportIssue()} autoFocus>
                REPORT ISSUE
            </Button>
            ) : null}
            {cancelButton !== "" ? (
            <Button onClick={onCancel}>{cancelButton}</Button>
            ) : null}
            {yesButton !== "" ? (
            <Button onClick={onSuccess} autoFocus>
                {yesButton}
            </Button>
            ) : null}
        </DialogActions>
        {showAlert.show && (
            <Alert variant="filled" severity={showAlert.error ? "error" : "success"}>
            {showAlert.message || ""}
            </Alert>
        )}
        </Dialog>
    );
};

export default DialogAlert;
