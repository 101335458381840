import { useEffect, useRef, useState } from "react";
import { callBackendAPI } from "../utils";
import { BackendResponse, IError } from "../type";

type IItems = {
  label: string;
  value: number;
  [key: string]: any | undefined;
}

//Custom hooks must start with 'use'
export const useRegister = (): [states: string[], idTypes: IItems[], nationalities: IItems[], profilePics: IItems[],　tnc: any, loading: boolean, error: IError] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<IError>({ error: false, message: "" });
  const [states, setStates] = useState<string[]>([]);
  const [idTypes, setIdTypes] = useState<IItems[]>([]);
  const [profilePics, setProfilePics] = useState<IItems[]>([]);
  const [nationalities, setNationalities] = useState<IItems[]>([]);
  const [tnc, setTnc] = useState<IItems[]>([]);

  const shouldLockEffect = useRef(false);

  useEffect(() => {
    const getNationalitiesAndIDTypes = async (): Promise<void> => {
      try {
        setLoading(true);
        let headers: HeadersInit = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        let input: any = {
          m1: "getNationalitiesAndIDTypes",
          lang: "en",
        };
        let resultsFromAPI: BackendResponse | undefined = await callBackendAPI(
          "getNationalitiesAndIDTypes",
          "POST",
          headers,
          input
        );
        if (!resultsFromAPI) {
          setLoading(false);
          return setError({
            error: true,
            message: "001: Something went wrong, try again",
          });
        }

        if (resultsFromAPI?.error !== undefined) {
          setLoading(false);
          return setError({
            error: true,
            message: "002: Something went wrong, try again",
          });
        }

        if (resultsFromAPI?.results) {
          let results: any = resultsFromAPI.results;
          if (results?.code && results.code !== 0) {
            setLoading(false);
            return setError({
              error: true,
              message: `Error Code: ${results.code} :: ${
                results.message || ""
              }`,
            });
          }

          setLoading(false);
          setIdTypes(results.id_types || [])
          setNationalities(results.nationalities || []);
          setProfilePics(results.profile_pics || []);
          setTnc(results.tnc.tnc_version_idx);
          return setStates(results.states || []);
        }

        setLoading(false);
        return setError({
          error: true,
          message: "002: Something went wrong, try again",
        });
      } catch (e) {
        console.error("Error getNationalitiesAndIDTypes", e);
        return setLoading(false);
      }
    };
    if (shouldLockEffect?.current === false) {
      getNationalitiesAndIDTypes();
    }
    // clean up
    return () => {
      shouldLockEffect.current = true;
    };
  }, []);

  return [states, idTypes, nationalities, profilePics, tnc, loading, error];
};
