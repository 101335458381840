import {Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select, TextField, Typography} from "@mui/material";
import Header from "../../components/Header";
import React, { useEffect, useRef, useState } from "react";
import { CONFIG } from "../../config";
import { addUserObjectToRequest, callBackendAPI, getLabelValue } from "../../utils";
import { tokens } from "../../theme";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useCheckIsMobile from "../../hooks/useCheckIsMobile";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Style } from "@mui/icons-material";

const colors =  tokens();

const VerifyBankAccount = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [fpxBankList, setFpxBankList] = useState([]);
    const [fpxBankValue, setFpxBankValue] = useState('');
    const [fpxBankAccountName, setFpxBankAccountName] = useState('');
    const [fpxBankAccountNo, setFpxBankAccountNo] = useState('');
    const [enteredEmail, setEnteredEmail] = useState('');
    const [fpxData, setFpxData] = useState<any>({});
    const [showMessage, setShowMessage] = useState({ showMessage: false, message: ""});
    const { lang } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ error: false, message: ""});

    const shouldLockEffect = useRef(false);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            getFPXBankList();
        }
        if (shouldLockEffect?.current === false){
            initialize();
        }
        return () => {
            shouldLockEffect.current = true;
        }
    },[]);

    const getFPXBankList = async () => {
        try{
            setLoading(true);
            let input : any = {
                ver: CONFIG.ver,
                act: String(36000),
                user_agent: navigator.userAgent,
                app_id: CONFIG.app_id,
                m1: "GetAllFpxBankList",
                lang: lang
            }
            input = addUserObjectToRequest(input);
            let headers = {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error:true, message: "001: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error:true, message: "002: Something went wrong, please check your Internet Connection and try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error:true, message: "003: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.hasOwnProperty("code")) {
                    if (results.code !== 0){
                        setLoading(false);
                        return setError({ error:true, message: `Error Code: ${results.code} :: ${results.message || ""}` });
                    }
                    setFpxBankList(results.fpxbanklist || []);
                    setFpxBankValue(results.fpxbankvalue);
                    setFpxBankAccountName(results.fpxaccname || '');
                    setFpxBankAccountNo(results.fpxaccno || '');
                    if (results.fpxemail !== '') 
                        setEnteredEmail(results.fpxemail);
                    setFpxData(results);
                    return setLoading(false);
                }
            }                           
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: "Oops something went wrong!"})
        }
    }

    const handleSubmit = async () => {
        try{
            if (error.error)
                setError({ error: false, message: ''});
            setLoading(true);
            let input : any = {
                ver: CONFIG.ver,
                act: String(36001),
                user_agent: navigator.userAgent,
              app_id: CONFIG.app_id,
                m1: "SaveFPXBankAccount",
                lang: lang,
                p1: fpxBankValue,
                p2: fpxBankAccountName,
                p3: fpxBankAccountNo,
                p4: enteredEmail
            }
            input = addUserObjectToRequest(input);
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
            if (!resultsFromAPI) {
                setLoading(false);
                return setError({ error:true, message: "001: Something went wrong, try again"});
            }
            if (resultsFromAPI?.error !== undefined) {
                setLoading(false);
                return setError({ error:true, message: "002: Something went wrong, please check your Internet Connection and try again"});
            }
            if (!resultsFromAPI.hasOwnProperty("results")) {
                setLoading(false);
                return setError({ error:true, message: "003: Something went wrong, try again"});
            }
            if (resultsFromAPI?.results) {
                let results: any = resultsFromAPI.results;
                if (results.code !== 0) {
                    setLoading(false);
                    return setError({ error: true, message: `Error Code: ${results.code} :: ${results.message || ""}` });
                }
                setLoading(false);
                setError({ error: false, message: ''});
                return setShowMessage({ showMessage: true, message: results.message || 'Your FPX Data has been saved successfully'})
            }   
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            return setError({ error: true, message: "Oops something went wrong!"})
        }
    }
       
    const shouldRenderfpxaccstatus = () => {
        if (!fpxData.hasOwnProperty("fpxaccstatus")) return null;
        if (fpxData.fpxaccstatus === '') return null;
        return (
            <TextField
                label={getLabelValue(lang, 'kirim.fpx.status')}
                variant="outlined"
                fullWidth
                margin="normal"
                value={fpxData.fpxaccstatus}
                onChange={(e) => setEnteredEmail(e.target.value)}
                disabled={true}
            /> 
        )
    }

    const isInputEditable = (inputKey: any, inputValue: any) => {
        if (fpxData.hasOwnProperty("fpxaccstatus")) {
        if (fpxData.fpxaccstatus !== 'Pass CDD Verification') {
            if (inputKey === 'email') {
                return fpxData?.fpxemail === '';
            }
            return inputKey !== 'bank';
        } else {
            if (inputKey === 'email')
                return inputValue === ''
            return inputKey === 'bank';
        }
        } else {
            if (inputKey === 'bank')
                return false;
            if (inputKey === 'email') {
                if (inputValue === 'mmcare169@gmail.com' || inputValue === '')
                    return true;
            }
        }
        return true;
    }

    const shouldRenderButton = () => {
        const shouldShow = !fpxData.hasOwnProperty("fpxaccstatus") || fpxData.fpxaccstatus !== 'Pass CDD Verification';
        if (shouldShow) {
            return (
                <Button
                    type="submit"
                    fullWidth
                    onClick={() => handleSubmit()}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                >
                    {getLabelValue(lang, 'kirim.bookrate.confirm.button')}
                </Button>
            );
        }
        return null;
    };

    const handleOkClick = () => {
        setShowMessage({showMessage: false, message: ""});
    }

    return (
        <Box m="20px">
            <Box
                sx={styles(isMobile).boxStyle}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title={getLabelValue(lang, 'kirim.bookrate.fpx')} subtitle={""} />
                </Box>
                <Grid2
                    container
                >
                    <Grid2
                        xs={12} md={6}
                    >
                        <Card  
                            sx={styles(isMobile).cardStyle}
                            elevation={3}
                        >
                            <CardContent>
                                <Box 
                                    sx={{ textAlign: 'left'  }}
                                    mt="5px"
                                    mb="10px"
                                    ml="5px" 
                                    mr="5px"
                                >
                                    {getLabelValue(lang, 'kirim.fpx.bankname')}
                                </Box>
                                <FormControl fullWidth>
                                    <Select
                                    value={fpxBankValue}
                                    onChange={(event) => setFpxBankValue(event.target.value)}
                                    disabled={isInputEditable('bank', fpxBankValue)}
                                    >
                                    {fpxBankList.map((bank: any) => (
                                        <MenuItem key={bank.index} value={bank.value}>
                                        {bank.label}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>  
                                <TextField
                                    sx={{ mt: 3 }}
                                    label={getLabelValue(lang, 'kirim.fpx.accname')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={fpxBankAccountName}
                                    onChange={(e) => setFpxBankAccountName(e.target.value)}
                                    InputProps={{
                                        readOnly: !isInputEditable('bank_name', fpxBankAccountName)
                                    }}
                                />                            
                                <TextField
                                    label={getLabelValue(lang, 'kirim.fpx.accno')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={fpxBankAccountNo}
                                    onChange={(e) => setFpxBankAccountNo(e.target.value)}
                                    InputProps={{
                                        readOnly: !isInputEditable('bank_account', fpxBankAccountNo)
                                    }}
                                />
                                <TextField
                                    label={getLabelValue(lang, 'kirim.fpx.email')}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={enteredEmail}
                                    onChange={(e) => setEnteredEmail(e.target.value)}  
                                    InputProps={{
                                        readOnly: !isInputEditable('email', enteredEmail)
                                    }}
                                />
                                {shouldRenderfpxaccstatus()}   
                                {shouldRenderButton()}
                                {error.error && (
                                    <Grid
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                    <Typography textAlign="center" color={colors.primary[500]}>
                                        {error.message || ''}
                                    </Typography>
                                    </Grid>
                                )}                                         
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2> 
                {
                    showMessage.showMessage && (
                        <Dialog
                        open 
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle>
                            {getLabelValue(lang, 'home.transaction.status.success')}
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="subtitle1">
                                {showMessage.message}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleOkClick} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Box>
        </Box>
    )
}

const styles = (isMobile: boolean) =>  ({
    boxStyle: {
        padding: '2px',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 100px)', 
        overflow: 'auto'
    },
    cardStyle: {
        mb:2,
        borderRadius: 2
    },
})

export default VerifyBankAccount;
