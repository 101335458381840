import { CONFIG } from "../config";
import { addUserObjectToRequest, callBackendAPI } from "../utils";
import { EKYC_CHECK, EKYC_CHECK_FAILED, EKYC_CHECK_SHOW_EKYC_PAGE, EKYC_CHECK_SUCCESS, EKYC_UNMOUNT, EKYC_VKYC_REQUIRE } from "./type";

export const checkUserEkycStatus = (user : any) => {
    return async (dispatch : any) => {
      try{
        dispatch({type: EKYC_CHECK });
        if (!user)
          return dispatch({ type: EKYC_CHECK_FAILED, payload: "001: Something went wrong"});
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }  
        let input : any = {
          ver: CONFIG?.ver,
          act: 914,
          m1: 'eKYC Check',
          lg: 'en'
        }
        input = addUserObjectToRequest(input);
        let resultsFromAPI = await callBackendAPI('request', 'POST', headers, input);
        if (!resultsFromAPI) {
          return dispatch({ type: EKYC_CHECK_FAILED, payload: "002: Something went wrong, try again"});
        }
        if (resultsFromAPI?.error !== undefined) {
          return dispatch({ type: EKYC_CHECK_FAILED, payload: "003: Something went wrong, please check your Internet Connection and try again"});
        }        
        if (resultsFromAPI?.results) {
          let results: any = resultsFromAPI.results;
          if (results.code === 0)
            return dispatch({ type: EKYC_CHECK_SUCCESS });
          if (results.code === -1){
            return dispatch({ type: EKYC_CHECK_SHOW_EKYC_PAGE, payload: results.message });
          }
          if (results.code === -3){
            return dispatch({ type: EKYC_VKYC_REQUIRE, payload: results.message });
          }
          if (results?.code && results.code !== 0)
            return dispatch({ type: EKYC_CHECK_FAILED, payload: results.message});

          return dispatch({ type: EKYC_CHECK_FAILED, payload: results.message });          
        }
        return dispatch({ type: EKYC_CHECK_FAILED, payload: "004: Something went wrong, try again"});
      }
      catch (e) {
        console.log(e);
        return dispatch({ type: EKYC_CHECK_FAILED, payload: "Oops something went wrong!"});
      }
    }
  }
  
  export const ekycUnmount = () => {
    return { type: EKYC_UNMOUNT }
  }