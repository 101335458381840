import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ProSidebarProvider } from 'react-pro-sidebar';
import {Provider} from "react-redux";
import store from './store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// add the Provider component at the top of our app layout and import our store
// so that its accessible across the app

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>
              <ProSidebarProvider>
                  <App />
              </ProSidebarProvider>
          </BrowserRouter>
      </Provider>
  </React.StrictMode>
);
